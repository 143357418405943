<template>
	<b-modal :visible="visible" modal-class="DeactivateLinkModal" size="sm" @hide="handleClose">
		<div>
			<font-awesome-icon icon="exclamation-triangle" class="icon-warning" />
			<div class="mb-1 deactivate-info">
				<span>
					{{ $t('Are you sure you want to deactivate the account link with ') }}<b>{{ partnerName }}</b>
				</span>
				<span>{{ $t('?') }}</span>
			</div>
		</div>
		<section slot="modal-footer">
			<b-btn v-t="'Cancel'" class="mr-1" @click="$emit('hide')" />
			<b-btn v-t="'Deactivate'" variant="primary" class="mr-1" @click="handleDeactivate(item)" />
		</section>
	</b-modal>
</template>

<script>
import { mapActions } from 'vuex';
import { $t } from '../../../vuex';
import { displayError } from '../../../lib/helpers';

export default {
	props: {
		visible: {
			type: Boolean,
			default: false
		},
		item: {
			type: Object,
			default: () => ({})
		},
		partnerName: {
			type: String,
			default: ''
		}
	},
	methods: {
		...mapActions({
			deactivate: 'accountlink/deactivate'
		}),
		async handleDeactivate(item) {
			try {
				await this.deactivate({ linkId: item._id });
				this.$notify({ type: 'success', text: $t('Account link deactivated') });
				this.$emit('update');
			} catch (err) {
				this.$notify({ type: 'error', text: $t(displayError(err)) });
			} finally {
				this.handleClose();
			}
		},
		handleClose() {
			this.$emit('hide');
		}
	}
};
</script>

<style lang="scss">
@import '../../../styles/shared';
.DeactivateLinkModal {
	@include deactivate-delete-confirmation-modal;
}
</style>
