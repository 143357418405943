export const SET_ACCOUNT = (state, account) => {
	state.currentAccount = account;
};
export const SET_MEMBERSHIPS = (state, memberships) => {
	state.memberships = memberships;
};
export const SET_MEMBERSHIPS_COUNT = (state, count) => {
	state.membershipsCount = count;
};
export const SET_USER_MEMBERSHIPS = (state, memberships) => {
	state.memberships = memberships;
};
export const SET_ACCOUNTS = (state, accounts) => {
	//filter out non-psps accounts in SFL
	state.accounts = accounts.filter(account => account.type === 'psp' || (account.types || []).includes('psp'));
};
export const SET_VARS = (state, doc) => {
	state.vars = doc;
};
export const UPDATE_VARS_ACCOUNT_SETTINGS = (state, settings) => {
	state.vars.oneflowAccountSettings = settings;
};
export const SET_PSP_ACCOUNTS = (state, docs) => {
	state.pspAccounts = docs;
};
export const SET_MEMBER = (state, member) => {
	state.member = member;
};
