<template>
	<section class="OrderPostbacks pb-3">
		<div class="OrderPostbacks-tableContainer">
			<table class="w-100">
				<thead>
					<th>{{ $t('Event') }}</th>
					<th>{{ $t('Date') }}</th>
					<th>{{ $t('Status') }}</th>
				</thead>
				<tbody>
					<template v-for="postback in displayedPostbacks">
						<tr :key="postback._id" :class="{ 'has-details': rowDetails[postback._id] }">
							<td class="OrderPostbacks-event" @click="handleEventClick(postback._id)">
								<section class="d-flex flex-nowrap">
									<i class="icon ion-md-arrow-dropright mr-1" />
									<span>{{ postback.event }}</span>
								</section>
							</td>
							<td class="OrderPostbacks-date">
								<date :date="postback.timestamp" :showTime="true"></date>
							</td>
							<td>
								<div class="d-flex align-center">
									<order-status :status="postback.status"></order-status>
								</div>
							</td>
						</tr>
						<transition name="height" :key="`details-${postback._id}`">
							<tr v-if="rowDetails[postback._id]">
								<td class="OrderPostbacks-rowDetails" colspan="3">
									<b-row class="m-0">
										<b-col class="OrderPostbacks-rowDetailsLabel pl-0" cols="3">{{
											$t('Payload')
										}}</b-col>
										<b-col class="OrderPostbacks-rowDetailsValue px-0" cols="9">{{
											postback.payload
										}}</b-col>
									</b-row>
									<b-row class="m-0">
										<b-col class="OrderPostbacks-rowDetailsLabel pl-0" cols="3">{{
											$t('Result')
										}}</b-col>
										<b-col class="OrderPostbacks-rowDetailsValue px-0" cols="9">{{
											postback.result
										}}</b-col>
									</b-row>
									<b-row class="m-0">
										<b-col class="OrderPostbacks-rowDetailsLabel pl-0" cols="3">{{
											$t('Code')
										}}</b-col>
										<b-col class="OrderPostbacks-rowDetailsValue px-0" cols="9">{{
											postback.code
										}}</b-col>
									</b-row>
								</td>
							</tr>
						</transition>
					</template>
				</tbody>
			</table>
		</div>
		<b-row class="mx-0 mt-2 justify-content-center" v-if="postbackCount > defaultPostbacksCount">
			<b-button variant="secondary" class="OrderPostbacks-showButton" @click="isShowingAll = !isShowingAll">{{
				isShowingAll ? $t('Show Less') : $t('Show All')
			}}</b-button>
		</b-row>
	</section>
</template>

<script>
import { mapGetters } from 'vuex';
import _ from 'lodash';
import Date from '../../../components/Date';
import OrderStatus from '../../../components/OrderStatus';

export default {
	name: 'OrderPostbacks',
	components: {
		Date,
		OrderStatus
	},
	data() {
		return {
			isShowingAll: false,
			defaultPostbacksCount: 3,
			rowDetails: {}
		};
	},
	computed: {
		...mapGetters({
			postbacksData: 'order/postbacks'
		}),
		postbacks() {
			return _.get(this.postbacksData, 'data', []);
		},
		processedPostbacks() {
			const postbacks = [];
			_.forEach(this.postbacks, postback => {
				_.forEach(postback.results, (result, index) => {
					postbacks.push({
						_id: `${postback._id}${index}`,
						event: postback.additionalInfo.category,
						timestamp: result.date,
						code: result.statusCode,
						status: result.statusCode < 300 ? 'success' : 'error',
						result: result.response,
						payload: postback.additionalInfo.payload
					});
				});
			});

			return postbacks;
		},
		displayedPostbacks() {
			if (!this.isShowingAll) {
				return _.take(this.processedPostbacks, this.defaultPostbacksCount);
			}

			return this.processedPostbacks;
		},
		postbackCount() {
			return _.size(this.processedPostbacks);
		}
	},
	mounted() {
		_.map(this.processedPostbacks, postback => this.$set(this.rowDetails, postback._id, false));
	},
	methods: {
		handleEventClick(id) {
			this.$set(this.rowDetails, id, !this.rowDetails[id]);
		}
	}
};
</script>

<style lang="scss">
@import '../../../styles/variables';

.height-enter-active,
.height-leave-active {
	transition: height 0.3s;
}
.height-enter,
.height-leave-to {
	height: 0;
}

.OrderPostbacks {
	font-size: 0.95em;

	&-tableContainer {
		overflow-x: auto;
	}

	&-showButton {
		margin-top: 1rem;
	}

	&-event {
		cursor: pointer;

		.ion-md-arrow-dropright:before {
			color: $greyColour;
		}
	}

	&-date,
	&-rowDetailsLabel {
		color: $greyColour;
	}

	&-rowDetails {
		border-bottom: 1px solid $primaryColour;
	}

	&-status {
		font-size: 0.76em;
		font-weight: bold;
		text-transform: uppercase;
	}

	table {
		tr {
			transition: color 0.3s;
			border-top: 1px solid $cardBorderColor;

			&:last-child {
				border-bottom: 1px solid $cardBorderColor;
			}

			&.has-details {
				border-top-color: $primaryColour;

				+ tr {
					border-top: none;
				}

				.ion-md-arrow-dropright:before {
					transform: rotate(90deg);
				}
			}
		}

		th {
			font-size: 0.85em;
			font-weight: normal;
			color: $greyColour;
			text-transform: uppercase;
			border: none;
		}

		td,
		th {
			padding: 0.8em 0.6em 0.8em 0;
			width: 33.3%;
			max-width: 33.3%;
		}
	}
}
</style>
