<template>
	<div class="ComponentErrors" v-if="errors">
		<div class="ComponentErrors-item d-flex flex-row mb-1" v-for="(error, index) in errors" :key="`error-${index}`">
			<div class="ComponentErrors-icon d-flex justify-content-center align-items-center">
				<v-icon name="exclamation-triangle" />
			</div>
			<div class="ComponentErrors-title d-flex align-items-center p-2">{{ error.title }}</div>
			<div class="ComponentErrors-description py-2">
				<span v-html="$options.filters.linkifyStr(error.description)" />
			</div>
			<div class="ComponentErrors-date p-2 d-flex justify-content-center align-items-center">
				<span>{{ getDate(error.timestamp) }}</span>
			</div>
		</div>
	</div>
</template>

<script>
import _ from 'lodash';
import { mapGetters } from 'vuex';
import { niceDate } from '../lib/filters';
import { linkifyStr } from '../lib/helpers';

export default {
	name: 'ComponentFileErrors',
	props: ['errors'],
	filters: {
		linkifyStr
	},
	data() {
		return {
			defaultDateFormat: 'YYYY/MM/DD'
		};
	},
	computed: {
		...mapGetters({
			accountSettings: 'account/accountSettings'
		}),
		dateFormat() {
			return _.get(this.accountSettings, 'dateFormat');
		}
	},
	methods: {
		getDate(timestamp) {
			if (this.dateFormat) {
				return niceDate(timestamp, this.dateFormat.toUpperCase());
			}

			return niceDate(timestamp, this.defaultDateFormat);
		}
	}
};
</script>

<style lang="scss">
@import '../styles/variables';

.ComponentErrors {
	&-item {
		border: solid 1px $colourOrderFileErrorBorder;
		border-radius: $smallBorderRadius;
	}

	&-icon {
		background-color: $colourOrderFileErrorBackground;
		width: 30px;
		color: #fff;
	}

	&-title {
		max-width: 20%;
		font-weight: bold;
	}

	&-date {
		max-width: 20%;
	}

	&-description {
		display: flex;
		flex-direction: row;
		flex: 1;
		word-break: break-word;
		min-width: 50%;
	}
}
</style>
