import moment from 'moment';
import linkify from 'linkifyjs/string';
import _ from 'lodash';

// eslint-disable-next-line max-len
const isoDateRegex = /^(-?(?:[1-9][0-9]*)?[0-9]{4})-(1[0-2]|0[1-9])-(3[01]|0[1-9]|[12][0-9])T(2[0-3]|[01][0-9]):([0-5][0-9]):([0-5][0-9])(.[0-9]+)?(Z)?$/;

const getStartDate = period => {
	if (period === 'past-week') {
		const from = moment()
			.add(-1, 'week')
			.startOf('isoweek')
			.toISOString();
		const to = moment()
			.add(-1, 'week')
			.endOf('isoweek')
			.toISOString();
		return `${from}_${to}`;
	}

	if (period === 'past-month') {
		const from = moment()
			.add(-1, 'month')
			.startOf('month')
			.toISOString();
		const to = moment()
			.add(-1, 'month')
			.endOf('month')
			.toISOString();
		return `${from}_${to}`;
	}

	return moment()
		.startOf(period)
		.toISOString();
};

const roleList = [
	{ value: 'production', text: 'Production' },
	{ value: 'simple', text: 'Simple' },
	{ value: 'exports', text: 'Exports' },
	{ value: 'owner', text: 'Owner' },
	{ value: 'managers', text: 'Managers' },
	{ value: 'customer', text: 'Customer' }
];
const validateDateFormat = (dateString, format = 'YYYY-MM-DD H:mm') => moment(dateString, format, true).isValid();

const linkifyStr = (str, options = {}) => linkify(str, { className: '', ...options });

const downloadFileByUrls = (element, urls) => {
	const url = _.head(urls);

	if (url) {
		const link = document.createElement('a');
		link.href = url;
		element.appendChild(link);
		link.click();
		_.delay(() => {
			element.removeChild(link);
			downloadFileByUrls(element, _.tail(urls));
		}, 400);
	}
};

const displayError = err => {
	let message = null;

	message = err.response?.data?.error?.message;
	if (message && _.isString(message)) return message;

	message = err.response?.data?.error;
	if (message && _.isString(message)) return message;

	message = err.response?.data?.message;
	if (message && _.isString(message)) return message;

	message = err.response?.data;
	if (message && _.isString(message)) return message;

	message = err.message;
	if (message && _.isString(message)) return message;

	return 'Something went wrong. Unspecified error.';
}

export { displayError, getStartDate, isoDateRegex, roleList, validateDateFormat, linkifyStr, downloadFileByUrls };
