import Vue from 'vue';

export default function() {
	Vue.$ofsCrud.addResource({
		name: 'file',
		options: { namespaced: true },
		transport: 'connect',
		basePath: 'file',
		actions: {
			updateFile: ({ dispatch }, { fileId, data }) => {
				const path = `file/${fileId}`;
				return dispatch('request', { method: 'PUT', path, payload: { ...data, _id: fileId } }).then(
					r => r.data
				);
			},
			getDownload: ({ dispatch }, { fileId, params = { preview: true } }) => {
				const path = `file/download/${fileId}`;
				return dispatch('request', { method: 'GET', path, params }).then(r => r.data);
			},
			getFilesByOrderId({ dispatch }, orderId) {
				return dispatch('request', { method: 'GET', path: `file/byorder/${orderId}` }).then(r => r.data);
			},
			getThumbnails: ({ dispatch }, fileid) => {
				const path = `file/${fileid}/thumbnails`;
				return dispatch('request', { method: 'GET', path }).then(r => r.data);
			},
			refetch: ({ dispatch }, id) => {
				const path = `file/${id}/refetch`;
				return dispatch('request', { method: 'PUT', path }).then(r => r.data);
			},
			reset: ({ commit }) => commit('RESET_FILE')
		},
		mutations: {
			RESET_FILE(state) {
				state.file = null;
			}
		}
	});
}
