<template>
	<DefaultLayout class="UserList">
		<ofs-panel>
			<ListTable
				:table-title="$t('User Management')"
				:items="users"
				:config="config"
				:fields="fieldsArray"
				:total-items="usersCount"
				:per-page="perPage"
				:current-page="currentPage"
				:fetchData="fetchData"
				:isLoading="isLoading"
				:loaderAbsolutePosition="true"
				:page-position-prefix="$t('Showing')"
				:page-position-join="$t('of')"
				@table-change="handleTableChange"
				@row-clicked="onUserClick"
				hover
			>
				<template slot="prev-text" slot-scope="{}">
					{{ $t('Prev') }}
				</template>
				<template slot="next-text" slot-scope="{}">
					{{ $t('Next') }}
				</template>
				<template slot="empty">
					<span>
						<i>{{ $t('No Data') }}</i>
					</span>
				</template>
				<template v-slot:TableButtons-Slot-left>
					<b-button variant="primary" data-test-id="invite-user" @click="inviteUser">
						{{ $t('Invite User') }}
					</b-button>
					<b-form-input
						v-if="search !== null"
						v-focus
						type="text"
						class="UserList__searchInput ml-2"
						:placeholder="$t('Search user by name/username')"
						v-model="search"
						@input="onChangeSearch"
					/>
				</template>
				<template v-slot:cell(status)="user">
					<div
						class="UserList__icon"
						:class="{
							'UserList__icon--active': user.item.active,
							'UserList__icon--inactive': !user.item.active
						}"
					/>
				</template>
				<template v-slot:cell(actions)="user">
					<UserActionsDropdown :user="user.item" :onUserClick="onUserClick" />
				</template>
			</ListTable>
		</ofs-panel>
		<InviteUserModal :showModal="isInviteUserModalVisible" :refresh="fetchData" :onClose="onCloseInviteUserModal" />
	</DefaultLayout>
</template>

<script>
import { OfsPanel, ListTable } from '@workflow-solutions/ofs-vue-layout';
import { mapGetters, mapActions } from 'vuex';
import get from 'lodash/get';
import debounce from 'lodash/debounce';
import { niceDate, elementsCount } from '../../../lib/filters';
import DefaultLayout from '../../../components/DefaultLayout';
import UserActionsDropdown from './UserActionsDropdown';
import InviteUserModal from './InviteUserModal';
import clickOutside from '../../../directives/clickOutside';

export default {
	components: {
		ListTable,
		DefaultLayout,
		OfsPanel,
		UserActionsDropdown,
		InviteUserModal
	},
	filters: {
		niceDate,
		elementsCount
	},
	data() {
		return {
			isInviteUserModalVisible: false,
			perPage: 10,
			sort: 'username',
			search: null,
			sortDirection: 1,
			currentPage: 1,
			isLoading: false
		};
	},
	directives: {
		clickOutside
	},
	computed: {
		...mapGetters({
			usersData: 'user/users'
		}),
		fieldsArray() {
			return [
				{ key: 'status', label: this.$t('Active') },
				{ key: 'name', label: this.$t('Name') },
				{ key: 'email', label: this.$t('Email') },
				{ key: 'actions', label: ' ' }
			];
		},
		config() {
			return {
				breadcrumbs: { visible: false },
				filter: { visible: false },
				search: {
					visible: true,
					searchCallback: this.onClickSearch
				},
				add: { visible: false },
				refresh: { visible: true },
				columns: { visible: false }
			};
		},
		users() {
			return get(this.usersData, 'data');
		},
		usersCount() {
			return get(this.usersData, 'count');
		},
		fetchQuery() {
			return {
				page: this.currentPage,
				pagesize: this.perPage,
				direction: this.sortDirection,
				sort: this.sort
			};
		}
	},
	mounted() {
		return this.fetchData();
	},
	methods: {
		...mapActions({
			getUsers: 'user/find'
		}),
		async fetchData() {
			try {
				this.isLoading = true;

				if (this.search) {
					await this.searchHandler();
				} else {
					await this.getUsers({ query: this.fetchQuery });
				}
			} catch (err) {
				this.$notify({
					type: 'error',
					title: this.$t('Error'),
					text: this.$t('An error occurred while fetching account users')
				});
			} finally {
				this.isLoading = false;
			}
		},
		handleTableChange({ currentPage, perPage }) {
			this.currentPage = currentPage;
			this.perPage = perPage;
		},
		inviteUser() {
			this.isInviteUserModalVisible = true;
		},
		onCloseInviteUserModal() {
			this.isInviteUserModalVisible = false;
		},
		onUserClick({ _id: id }) {
			this.$router.push({ name: 'user.details', params: { id } });
		},
		onClickSearch() {
			if (this.search === null) {
				this.search = '';
			} else {
				this.search = null;
			}
		},
		fetchDataWithDelay: debounce(function fetchDataWithDelay() {
			this.fetchData();
		}, 300),
		async searchHandler() {
			// Search by name
			await this.getUsers({ query: { name: this.search, ...this.fetchQuery } });

			// Search by username if no users found by name
			if (!this.usersCount) {
				await this.getUsers({ query: { username: this.search, ...this.fetchQuery } });
			}
		},
		onChangeSearch() {
			this.currentPage = 1;
			return this.fetchDataWithDelay();
		}
	}
};
</script>

<style lang="scss">
@import '../../../styles/_variables.scss';

.UserList {
	&__searchInput {
		max-width: 200px;
	}

	&__icon {
		$size: 16px;
		width: $size;
		height: $size;
		border-radius: 50%;

		&--active {
			background-color: $successColour;
		}

		&--inactive {
			background-color: $liteGreyColour;
		}
	}
}
</style>
