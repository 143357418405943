<template>
	<section class="panel panel--sidebar">
		<aside class="panel__sidebar">
			<img class="artwork" :src="file.thumbnailUrl" v-if="!file.localFile" />
			<div class="localfile" v-else>
				<v-icon class="localfile__icon" name="custom-hdd" scale="3" />
				<span class="localfile__text">{{ $t('Local file') }}</span>
			</div>
		</aside>
		<div>
			<div class="grid grid--2">
				<div class="spec">
					<h3 class="spec__heading">{{ $t('SKU') }}</h3>
					<p class="spec__value" v-text="item.sku"></p>
				</div>
				<div class="spec">
					<h3 class="spec__heading">{{ $t('Component') }}</h3>
					<ul class="spec__value spec-list">
						<li
							class="spec-list__item"
							:class="{ 'spec-list__item--highlight': component._id === itemComponent._id }"
							v-for="itemComponent in item.components"
							:key="itemComponent._id"
							v-text="itemComponent.code"
						></li>
					</ul>
				</div>
				<div class="spec" v-if="item.description">
					<h3 class="spec__heading">{{ $t('Description') }}</h3>
					<p class="spec__value" v-text="item.description"></p>
				</div>
				<div class="spec">
					<h3 class="spec__heading">{{ $t('Filename') }}</h3>
					<p class="spec__value">{{ fileName }}</p>
				</div>
				<!-- <div class="spec">
					<h3 class="spec__heading">Pages</h3>
					<p class="spec__value" v-text="component.pages"></p>
				</div> -->
			</div>
			<div class="spec attributes" v-if="component.attributes">
				<h3 class="spec__heading">{{ $t('Attributes') }}</h3>
				<ul class="pill-list">
					<li class="pill-list__item pill" v-for="(value, key) in component.attributes" :key="key">
						<span class="pill__key" v-text="key"></span>
						<span class="pill__value" v-text="value"></span>
					</li>
				</ul>
			</div>
		</div>
	</section>
</template>

<script>
import _ from 'lodash';

export default {
	props: ['item', 'file', 'order', 'component', 'shipment', 'shipments'],
	computed: {
		sourceItemId() {
			return _.get(this.item, 'sourceItemId');
		},
		componentCode() {
			return _.get(this.component, 'code');
		},
		fileExtension() {
			const filePath = _.get(this.file, 'path');
			return _.last(_.split(filePath, '.'));
		},
		fileName() {
			return `${this.sourceItemId}_${this.componentCode}.${this.fileExtension}`;
		}
	}
};
</script>

<style></style>
