<template>
	<DefaultLayout class="OrderView">
		<content-header :title="$t('Order Details')">
			<section class="d-flex flex-column flex-sm-row">
				<BarcodeScanner class="OrderView-barcodeScanner ml-3" :onScan="handleScan" :showInput="true" />
				<b-button
					data-test-id="Orders_cancelOrderButton"
					class="OrderView-cancelButton mt-2 ml-2 mt-sm-0"
					variant="danger"
					v-show="isCancelBtnVisible"
					@click="cancelOrder"
					>{{ $t('Cancel Order') }}</b-button
				>
			</section>
		</content-header>
		<SmartLoader :isLoading="isLoading" :absolutePosition="true">
			<b-container fluid>
				<b-row class="m-0" :key="orderId">
					<b-col class="px-0 pr-xl-3" cols="12" xl="8">
						<b-row class="OrderView-items">
							<h5 class="OrderView-header m-0">{{ $t('Order Items') }} ({{ orderItemsCount }})</h5>
							<div>
								<b-button
									size="sm"
									class="mr-2"
									@click="viewJobSheet"
									v-if="orderStatus === 'printready' || orderStatus === 'complete'"
									>{{ $t('Print Job Sheets') }}</b-button
								>
								<b-button
									size="sm"
									@click="onDownloadAllFiles"
									v-if="orderStatus === 'printready' || orderStatus === 'complete'"
									>{{ $t('Download files') }}</b-button
								>
							</div>
						</b-row>
						<b-alert :show="orderHasFileErrors" class="OrderView-headerErrors p-2" variant="warning">
							<b-row class="m-0 align-items-center">
								<v-icon name="times-circle" class="OrderView-headerErrorsIcon" />
								<strong class="OrderView-headerErrorsTitle px-2">{{ $t('Errors Found') }}</strong>
								<span class="OrderView-headerErrorsText">{{ $t('Order has stopped being processed. Fix the file errors to continue.') }}</span>
								<span class="OrderView-headerErrorsAction ml-auto" @click="expandAllErroredItems">{{
									$t('Expand All')
								}}</span>
							</b-row>
						</b-alert>
						<List
							:erroredItemsIds="erroredItemsIds"
							:showAllErroredItems="showAllErroredItems"
							:refresh="backgroundRefresh"
							:highlightedComponent="highlightedComponent"
							@highlightChange="handleHighlightChange"
						/>
					</b-col>
					<b-col class="OrderView-info px-0" cols="12" xl="4">
						<ofs-panel class="m-0 mr-2 mb-3">
							<h5 class="OrderView-header">{{ $t('Order Details') }}</h5>
							<Details />
							<h5 class="OrderView-header">{{ $t('Order Logs') }}</h5>
							<Logs />
							<template v-if="!postbacksIsEmpty">
								<h5 class="OrderView-header">{{ $t('Order Postbacks') }}</h5>
								<Postbacks />
							</template>
						</ofs-panel>
					</b-col>
				</b-row>
			</b-container>
		</SmartLoader>
	</DefaultLayout>
</template>

<script>
import { ContentHeader, OfsPanel } from '@workflow-solutions/ofs-vue-layout';
import { mapGetters, mapActions } from 'vuex';
import Promise from 'bluebird';
import _ from 'lodash';
import isEmpty from 'lodash/isEmpty';
import DefaultLayout from '../../../components/DefaultLayout';
import SmartLoader from '../../../components/SmartLoader';
import List from './Item/List';
import Details from './Details';
import Logs from './Logs';
import Postbacks from './Postbacks';
import BarcodeScanner from '../../../components/BarcodeScanner';
import { downloadFileByUrls as download } from '../../../lib/helpers';

export default {
	name: 'OrderView',
	components: {
		DefaultLayout,
		ContentHeader,
		List,
		SmartLoader,
		Details,
		Logs,
		Postbacks,
		OfsPanel,
		BarcodeScanner
	},
	data() {
		return {
			showAllErroredItems: false,
			isLoading: false,
			highlightedComponent: null
		};
	},
	computed: {
		...mapGetters({
			order: 'order/order',
			postbacksData: 'order/postbacks'
		}),
		orderId() {
			return _.get(this.$route, 'params.id');
		},
		orderItems() {
			return _.get(this.order, 'order.orderData.items', []);
		},
		orderComponents() {
			return _.flatMap(
				_.map(this.orderItems, item => _.map(item.components, c => ({ ...c, sourceItemId: item.sourceItemId })))
			);
		},
		orderItemsCount() {
			return _.size(this.orderItems);
		},
		orderStatus() {
			return _.get(this.order, 'order.orderData.status');
		},
		shipments() {
			return _.get(this.order, 'shipments', []);
		},
		postbacks() {
			return _.get(this.postbacksData, 'data', []);
		},
		postbacksIsEmpty() {
			return isEmpty(this.postbacks);
		},
		isCancelBtnVisible() {
			return !['pending', 'cancelled', 'shipped'].includes(this.orderStatus);
		},
		files() {
			return _.get(this.order, 'files', []);
		},
		failedFiles() {
			return _.filter(this.files, { status: 'failed' });
		},
		erroredFiles() {
			return _.filter(this.failedFiles, file => {
				const fileErrors = _.get(file, 'versions[0].errorList');

				if (_.isEmpty(fileErrors)) {
					return false;
				}

				return file;
			});
		},
		erroredComponentIds() {
			return _.map(this.erroredFiles, 'componentId');
		},
		erroredItemsIds() {
			const erroredItems = _.map(this.orderItems, item => {
				const intersection = _.intersection(this.erroredComponentIds, _.map(item.components, '_id'));

				if (_.isEmpty(intersection)) {
					return false;
				}

				return _.get(item, '_id');
			});

			return _.filter(erroredItems, id => id);
		},
		orderHasFileErrors() {
			return !_.isEmpty(this.erroredFiles);
		}
	},
	async mounted() {
		if (this.orderId) {
			await this.fetchData();
		}
	},
	destroyed() {
		this.clearOrderShipments();
	},
	watch: {
		orderId() {
			this.fetchData();
		},
		order: 'highlightComponentOnMount',
		'$route.query.itemId': 'highlightComponentOnMount'
	},
	methods: {
		...mapActions({
			clearOrderShipments: 'order/clearOrderShipments',
			getShipmentsByOrderId: 'order/getShipmentsByOrderId',
			getOrderDetails: 'order/getOrderDetails',
			cancelOrderRequest: 'order/cancelOrder',
			getDownload: 'file/getDownload',
			getOrderPostbacks: 'order/getOrderPostbacks',
			scanBarcode: 'barcode/getById'
		}),
		async fetchData() {
			try {
				this.isLoading = true;
				await Promise.all([
					this.getOrderDetails(this.orderId),
					this.getOrderPostbacks(this.orderId),
					this.getShipmentsByOrderId(this.orderId)
				]);
			} catch (err) {
				this.$notify({
					type: 'error',
					title: this.$t('Error'),
					text: this.$t('An error occurred while fetching order data')
				});
			} finally {
				this.isLoading = false;
			}
		},
		backgroundRefresh() {
			return Promise.all([
				this.getOrderDetails(this.orderId),
				this.getOrderPostbacks(this.orderId),
				this.getShipmentsByOrderId(this.orderId)
			]);
		},
		async handleCancelOrder() {
			try {
				await this.cancelOrderRequest(this.orderId);
				this.fetchData();
				this.$notify({
					type: 'success',
					title: 'Success',
					text: this.$t('Order has been canceled successfully')
				});
			} catch (err) {
				this.$notify({
					type: 'error',
					title: this.$t('Error'),
					text: this.$t('An error occurred while cancelling order')
				});
			}
		},
		async cancelOrder() {
			try {
				await this.$confirm({
					title: this.$t('Cancel order'),
					body: this.$t('Are you sure you want to cancel this order?'),
					onOk: this.handleCancelOrder
				});
			} catch (err) {
				this.$notify({ text: this.$t('Order has not been canceled') });
			}
		},
		async handleScan(barcode) {
			if (barcode.length >= 5) {
				try {
					const result = await this.scanBarcode(barcode.toUpperCase());

					if (result.order) {
						// eslint-disable-next-line
						if (result.order._id === this.orderId) {
							this.highlightedComponent = result.barcode.barcode;
						} else {
							this.$notify({
								type: 'error',
								title: this.$t('Not Found'),
								text: this.$t('The component you have scanned is not part of this order')
							});
						}
					}
				} catch (e) {
					this.$notify({
						type: 'error',
						title: this.$t('Not Found'),
						text: this.$t('No component was found matching the supplied barcode')
					});
				}
			}
		},
		highlightComponentOnMount() {
			if (this.order && this.$route.query.barcode) {
				this.handleScan(this.$route.query.barcode);
			}
		},
		handleHighlightChange() {
			this.highlightedComponent = null;
		},
		viewJobSheet() {
			const routeData = this.$router.resolve({ name: 'job-sheet', query: { orderIds: [this.orderId] } });
			window.open(routeData.href, '_blank');
		},
		restoreShowAllErroredItems: _.debounce(function restore() {
			this.showAllErroredItems = false;
		}, 300),
		expandAllErroredItems() {
			this.showAllErroredItems = true;
			this.restoreShowAllErroredItems();
		},
		async onDownloadAllFiles() {
			try {
				const urls = await Promise.map(this.orderComponents, async ({ fileId, code, sourceItemId }) => {
					const file = _.find(this.files, { _id: fileId });
					const fileExtension = _.last(_.split(file.path, '.'));

					const filename = `${sourceItemId}_${code}.${fileExtension}`;
					return this.getDownload({ fileId, params: { filename, preview: false } });
				});
				download(this.$el, urls);
			} catch (err) {
				this.$notify({
					type: 'error',
					title: this.$t('Error'),
					text: this.$t('An error occurred while downloading files')
				});
			}
		}
	}
};
</script>

<style lang="scss">
@import '../../../styles/variables';

.OrderView {
	.ContentHeader {
		flex: 0;
	}

	&-header {
		font-size: 1.1em;
		font-weight: bold;

		&Errors {
			background-color: $colourOrderErrorsBackground;
			border: 1px solid $colourOrderErrorsBorder;

			&Icon {
				color: $colourErrorComponent;
			}

			&Action {
				cursor: pointer;
				text-decoration: underline;
			}
		}
	}

	&-item {
		margin-top: 1rem;
	}

	&-items {
		padding: 0 1.25rem;
		display: flex;
		justify-content: space-between;
		align-items: center;
	}
}
</style>
