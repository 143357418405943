<template>
	<b-modal
		v-model="show"
		ok-only
		centered
		:title="$t('Invite User')"
		@show="onModalShow"
		:ok-disabled="formState.invalid"
		@ok="handleInviteUser"
		modal-class="InviteUserModal"
		:ok-title="$t('Invite')"
		size="md"
	>
		<b-form class>
			<of-form-input
				:horizontal="false"
				:label="$t('Username')"
				:placeholder="$t('Enter Username')"
				data-test-id="invite-user-username"
				name="name"
				type="text"
				required
			/>
			<of-form-input
				:horizontal="false"
				:label="$t('User Email')"
				:placeholder="$t('Enter User Email')"
				data-test-id="invite-user-email"
				name="email"
				type="email"
				required
			/>
			<of-form-select
				name="role"
				:horizontal="false"
				:label="$t('User Role')"
				data-test-id="Setup_inviteUserRoleDropdown"
				:options="userRolesOptions"
				required
			/>
		</b-form>
	</b-modal>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import get from 'lodash/get';
import { OfFormInput, OfFormSelect, withForm } from '@workflow-solutions/ofs-vue-layout';
import { roleList } from '../../../lib/helpers';
export default {
	name: 'InviteUserModal',
	mixins: [withForm('InviteUserModalForm')],
	props: {
		showModal: {
			type: Boolean,
			default: false
		},
		onClose: {
			type: Function,
			default: () => {}
		},
		refresh: {
			type: Function,
			default: () => {}
		}
	},
	components: {
		OfFormInput,
		OfFormSelect
	},
	data() {
		return {
			userRolesOptions: roleList
		};
	},
	computed: {
		...mapGetters({
			vars: 'account/vars'
		}),
		show: {
			get() {
				return this.showModal;
			},
			set(value) {
				if (value === false) {
					this.onClose();
				}
			}
		},
		invitedBy() {
			return get(this.vars, 'username');
		},
		currentAccount() {
			return get(this.vars, 'currentAccount');
		},
		inviteUserData() {
			return {
				...this.formData,
				invitedBy: this.invitedBy,
				redirectUrl: window.location.origin,
				domain: `account:${this.currentAccount}:${this.formData.role}`,
				accountName: this.currentAccount,
				appName: 'sfl'
			};
		}
	},
	mounted() {
		return this.setFormData();
	},
	methods: {
		...mapActions({
			inviteUser: 'user/inviteUser',
			getVars: 'account/getVars'
		}),
		onModalShow() {
			if (!this.vars) {
				this.getVars();
			}
		},
		async handleInviteUser() {
			try {
				await this.inviteUser(this.inviteUserData);
				this.refresh();
				this.$notify({
					type: 'success',
					title: this.$t('Success'),
					text: this.$t('User has been invited successfully')
				});
			} catch (err) {
				this.$notify({
					type: 'error',
					title: this.$t('Error'),
					text: this.$t('An error occurred while user invitation')
				});
			}
		}
	}
};
</script>
