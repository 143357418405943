<template>
	<router-link :to="{ name: 'products.view', params: { id: item.objectID } }" class="SearchCell">
		<v-icon name="book" class="SearchCell__icon"></v-icon>
		<span class="SearchCell__content">
			<header class="SearchCell__header">
				<h3 class="SearchCell__heading">
					<span
						class="SearchCell__status"
						v-if="item.status"
						:class="{ 'SearchCell__status--success': item.status === 'closed' }"
						:title="$t('Status:') + ' ' + item.status"
						v-text="item.status"
					></span>
					{{ $t('Enquiry') }}
				</h3>
				<time class="SearchCell__date" :datetime="created | toDate">{{ created | fromNow }}</time>
			</header>
			<p class="SearchCell__title" v-html="from"></p>
			<p class="SearchCell__subtitle" v-html="subject"></p>
		</span>
	</router-link>
</template>

<script>
import moment from 'moment';

export default {
	props: {
		item: {
			required: true,
			type: Object
		}
	},
	computed: {
		created() {
			return moment(this.item.created);
		},
		from() {
			if (this.item._highlightResult.from) {
				return this.item._highlightResult.from.value;
			}

			return this.item.from;
		},
		subject() {
			if (this.item._highlightResult.subject) {
				return this.item._highlightResult.subject.value;
			}

			return this.item.subject;
		}
	},
	filters: {
		fromNow: date => date.fromNow(),
		toDate: date => date.toString()
	}
};
</script>

<style></style>
