<template>
	<b-modal
		:title="$t('Shipment Confirmation')"
		:visible="showModal"
		@show="onShowModal"
		@change="onChangeModalVisibility"
		body-class="TrackingNumberModal"
		static
	>
		<SmartLoader :isLoading="isLoading" :absolutePosition="true">
			<div>
				<section class="d-flex flex-column pt-2 mb-3 justify-content-center align-items-center">
					<div class="TrackingNumberModal-icon">
						<img src="../../../../../public/images/tracking-number.svg" />
					</div>
					<span class="mt-3">{{ $t('Enter the shipment tracking number') }}</span>
				</section>
				<b-form @submit.prevent="onOk">
					<of-form-input
						data-test-id="Orders_trackingNumInput"
						withoutLabel
						:placeholder="`${$t('e.g.')} 1827368755HNG8736`"
						name="trackingNumber"
						type="text"
						class="TrackingNumberModal-input"
					/>
				</b-form>
			</div>
		</SmartLoader>
		<template slot="modal-footer">
			<b-button @click="onClose">{{ $t('Cancel') }}</b-button>
			<b-button variant="primary" :disabled="isLoading" @click="onOk">{{
				trackingNumber ? $t('Save') : $t('Ship Shipment')
			}}</b-button>
		</template>
	</b-modal>
</template>

<script>
import { mapActions } from 'vuex';
import { OfFormInput, withForm } from '@workflow-solutions/ofs-vue-layout';
import SmartLoader from '../../../../components/SmartLoader';
import { $t } from '../../../../vuex';

export default {
	name: 'TrackingNumberModal',
	mixins: [withForm('TrackingNumberModalForm')],
	props: {
		showModal: {
			type: Boolean,
			default: false
		},
		isStatic: {
			type: Boolean,
			default: false
		},
		onClose: {
			type: Function,
			default: () => {}
		},
		refresh: {
			type: Function,
			default: () => {}
		},
		shipmentId: {
			type: String,
			default: ''
		},
		trackingNumber: {
			type: String,
			default: ''
		}
	},
	components: {
		OfFormInput,
		SmartLoader
	},
	computed: {},
	data() {
		return {
			isLoading: false
		};
	},
	methods: {
		...mapActions({
			setShipped: 'order/setShipped',
			saveShipment: 'order/saveShipment'
		}),
		onShowModal() {
			this.updateFormData({ trackingNumber: this.trackingNumber });
		},
		onChangeModalVisibility(visible) {
			if (!visible) {
				this.onClose();
			}
		},
		async onOk() {
			this.isLoading = true;
			let text;

			if (this.trackingNumber) { // edit shipment tracking number
				try {
					await this.saveShipment({ id: this.shipmentId, payload: this.formData });
					await this.refresh();
					this.onClose();
					text = $t('Tracking number has been updated successfully');
					this.$notify({ type: 'success', title: 'Success', text });
				} catch (err) {
					text = $t('An error occurred while updating this shipment');
					this.$notify({ type: 'error', title: 'Error', text });
					throw err;
				} finally {
					this.isLoading = false;
				}
			} else { // ship the shipment
				try {
					await this.setShipped({ id: this.shipmentId, payload: this.formData });
					await this.refresh();
					this.onClose();
					await this.$nextTick();
					this.$emit('shipped');
				} catch (err) {
					this.$emit('error', err);
				} finally {
					this.isLoading = false;
				}
			}
		}
	}
};
</script>

<style lang="scss">
@import '../../../../styles/shared';

.TrackingNumberModal {
	font-size: $of-font-size-normal;
	color: $modalBodyColor;

	.modal-body {
		min-height: 150px;
	}

	&-icon {
		&Container {
			width: 3em;
			height: 3em;
			color: #fff;
			justify-content: center;
			align-items: center;
		}
	}

	&-input {
		&,
		&:focus {
			background-color: $extraLiteGreyColour;
		}
	}
}
</style>
