<template>
	<div class="measurements">
		<div v-if="boxes.length >= 1" class="measurements__box" :class="`measurements__box--${boxes[0].type}`">
			<span class="measurements__label" v-text="`${boxes[0].width} x ${boxes[0].height}mm`"></span>
			<div v-if="boxes.length >= 2" class="measurements__box" :class="`measurements__box--${boxes[1].type}`">
				<span class="measurements__label" v-text="`${boxes[1].width} x ${boxes[1].height}mm`"></span>
				<div v-if="boxes.length >= 3" class="measurements__box" :class="`measurements__box--${boxes[2].type}`">
					<span class="measurements__label" v-text="`${boxes[2].width} x ${boxes[2].height}mm`"></span>
					<div
						v-if="boxes.length === 4"
						class="measurements__box"
						:class="`measurements__box--${boxes[3].type}`"
					>
						<span class="measurements__label" v-text="`${boxes[3].width} x ${boxes[3].height}mm`"></span>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	props: {
		media: Object,
		bleed: Object,
		trim: Object,
		artwork: Object
	},
	computed: {
		boxes() {
			const boxTypes = ['media', 'bleed', 'trim', 'artwork'];

			const boxes = [];

			boxTypes.forEach(type => {
				if (this[type]) {
					boxes.push({
						type,
						...this[type]
					});
				}
			});

			return boxes;
		}
	}
};
</script>

<style lang="scss">
.measurements {
	padding-top: 8px;
	display: flex;
	justify-content: center;

	&__label {
		position: absolute;
		top: 0;
		left: 10px;
		font-size: 11px;
		background: #ffffff;
		line-height: 1;
		border-radius: 3px;
		overflow: hidden;
		padding: 2px 3px 2px 18px;
		transform: translateY(-50%);
		color: rgba(0, 0, 0, 0.7);
		align-items: center;
		display: flex;

		&:before {
			width: 15px;
			height: 100%;
			position: absolute;
			left: 0;
			border-radius: 3px;
			overflow: hidden;
			content: ' ';
			display: flex;
			justify-content: center;
			align-items: center;
			color: rgba(255, 255, 255, 0.8);
			font-weight: bold;
			margin-right: 3px;
		}
	}

	&__box {
		border: 1px dotted;
		display: flex;
		justify-content: center;
		flex: 1;
		padding: 15px;
		position: relative;
		border-radius: 3px;
		background: #fff;

		&--media {
			border-color: #ff59ac;

			&:hover {
				background: rgba(#ff59ac, 0.1);
			}

			.measurements__label:before {
				content: 'M';
				background: #ff59ac;
			}
		}

		&--bleed {
			border-color: #f23030;

			&:hover {
				background: rgba(#f23030, 0.1);
			}

			.measurements__label:before {
				content: 'B';
				background: #f23030;
			}
		}

		&--trim {
			border-color: #c34cff;

			&:hover {
				background: rgba(#c34cff, 0.1);
			}

			.measurements__label:before {
				content: 'T';
				background: #c34cff;
			}
		}

		&--artwork {
			border-color: #29c6ff;

			&:hover {
				background: rgba(#29c6ff, 0.1);
			}

			.measurements__label:before {
				content: 'A';
				background: #29c6ff;
			}
		}
	}
}
</style>
