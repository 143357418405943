import Vue from 'vue';

export default function() {
	Vue.$ofsCrud.addResource({
		name: 'user',
		transport: 'connect',
		options: { namespaced: true },
		actions: {
			inviteUser({ dispatch }, payload) {
				return dispatch('request', { method: 'POST', path: 'user/invite', payload }).then(r => r.data);
			},
			resendInvitation({ dispatch }, payload) {
				return dispatch('request', { method: 'POST', path: 'user/invite/resend', payload }).then(r => r.data);
			},
			async updateUser({ commit, dispatch }, { id, payload }) {
				delete payload.accountRoles;
				delete payload.accounts;
				const data = await dispatch('request', { method: 'PUT', path: `user/${id}`, payload }).then(
					r => r.data
				);
				commit('GET_ITEM_SUCCESS', { data });

				return data;
			},
			addUserAccountRole({ dispatch }, { role, userId }) {
				const path = `user/${userId}/accountRoles`;
				return dispatch('request', { method: 'POST', path, payload: role });
			},
			deleteUserAccountRole({ dispatch }, { roleId, userId }) {
				const path = `user/${userId}/accountRoles/${roleId}`;
				return dispatch('request', { method: 'DELETE', path });
			},
			async getUserAccountRoles({ dispatch, commit }, { userId }) {
				const path = `user/${userId}/accountRoles`;
				const { data } = await dispatch('request', { method: 'GET', path });
				commit('SET_USER_ACCOUNT_ROLES', data);
			},
			setUserAccountRoles({ commit }, { roles }) {
				commit('SET_USER_ACCOUNT_ROLES', roles);
			}
		},
		mutations: {
			SET_USER_ACCOUNT_ROLES(state, roles) {
				state.userAccountRoles = roles;
			}
		},
		getters: {
			userAccountRoles(state) {
				return state.userAccountRoles;
			}
		},
		state: {
			userAccountRoles: []
		}
	});
}
