<template>
	<b-container fluid class="LoginPage d-flex flex-column">
		<div class="LoginPage"></div>
		<b-row align-h="center">
			<b-col sm="6" lg="4">
				<b-form>
					<div slot="logo">
						<div class="LoginPage-top">
							<div class="LoginPage-topHeader">
								<img src="images/hp.svg" alt="Site Flow Light" />
								{{ $t('Site Flow Light') }}
							</div>
						</div>
					</div>
					<div class="LoginPage-topHeader-hpid">
						<b-card class="mx-auto" header-tag="header" align="center" body-class="body">
							<b-card-text class="text-left">
								<h5 class="title text-center mb-3">
									{{ $t('Sign in with your') }} <b>{{ $t('HP account') }}</b>
								</h5>
								<b-button
									v-t="'Sign in'"
									class="w-100 hpid"
									variant="info"
									@click="handleLoginWithHPID"
								/>
							</b-card-text>
						</b-card>
					</div>
				</b-form>
			</b-col>
		</b-row>
	</b-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { localStorage } from '@workflow-solutions/siteflow-section-layout';
import { niceDate } from '../../lib/filters';

export default {
	name: 'LoginPage',
	filters: { niceDate },
	computed: {
		...mapGetters({
			isFeatureFlagActive: 'featureToggle/isActive',
			lang: 'lang/lang'
		})
	},
	methods: {
		...mapActions('auth', ['login', 'resetPassword']),
		...mapActions('account', ['fetchBaseAccountData']),
		handleLoginWithHPID() {
			window.location.href = `${window.$config.hpIdAuthorize}&locale=${this.lang}`;
		}
	}
};
</script>

<style lang="scss">
.LoginPage {
	height: 60vh;
	width: 100vw;

	> .row.justify-content-center {
		height: 100%;
		> .col-sm-6.col-lg-4 {
			align-self: center;
		}
	}

	&-topHeader-hpid {
		text-align: center;
		vertical-align: bottom;
		padding: 20px 20px;

		img {
			height: 60px;
			line-height: 60px;
		}
	}

	&-topHeader {
		background-color: #008ec7;
		text-align: center;
		vertical-align: center;
		padding: 10px 20px;
		margin-bottom: 20px;
		color: #fff;
		font-size: 24px;
		font-weight: 700;

		img {
			vertical-align: middle;
			height: 22px;
			line-height: 25px;
		}
	}

	&-form {
		.btn-info {
			background: #23b7e5;
		}
	}

	.LoginPage-toggleLoginMode {
		text-align: center;
		margin-top: 20px;
	}
}
</style>
