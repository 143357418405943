export default {
	NEW_PAIRED_PRODUCT(state, pairedProduct) {
		state.newPair = pairedProduct;
	},
	ADD_SKUS(state, skus) {
		state.newPair.skus = skus;
	},
	ADD_ACCOUNT_LINKS(state, accountLinks) {
		state.newPair.accountLinks = accountLinks;
	},
	RESET_NEW_PAIR(state) {
		state.newPair = null;
	}
};
