import { createRouter } from '@workflow-solutions/siteflow-section-layout';
import routes from './routes';
import menuStructure from './menuStructure';

const router = createRouter({
	routes: [...routes]
});

export default router;

export { routes, menuStructure };
