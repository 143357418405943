function validate(binding) {
	if (typeof binding.value !== 'function') {
		console.warn('[Vue-click-outside:] provided expression', binding.expression, 'is not a function.');
		return false;
	}

	return true;
}

function isPopup(popupItem, elements) {
	if (!popupItem || !elements) return false;

	for (let i = 0, len = elements.length; i < len; i++) {
		try {
			if (popupItem.contains(elements[i])) {
				return true;
			}
			if (elements[i].contains(popupItem)) {
				return false;
			}
		} catch (e) {
			return false;
		}
	}

	return false;
}

function isServer(vNode) {
	return typeof vNode.componentInstance !== 'undefined' && vNode.componentInstance.$isServer;
}

export default {
	bind(el, binding, vNode) {
		if (!validate(binding)) return;

		// Define Handler and cache it on the element
		function handler(e) {
			if (!vNode.context) return;

			// some components may have related popup item, on which we shall prevent the click outside event handler.
			const elements = e.path || (e.composedPath && e.composedPath());
			elements && elements.length > 0 && elements.unshift(e.target);

			if (el.contains(e.target) || isPopup(vNode.context.popupItem, elements)) return;

			// eslint-disable-next-line
			el.__vueClickOutside__.callback(e);
		}

		// add Event Listeners
		// eslint-disable-next-line
		el.__vueClickOutside__ = {
			handler,
			callback: binding.value
		};
		!isServer(vNode) && document.addEventListener('click', handler);
	},

	update(el, binding) {
		// eslint-disable-next-line
		if (validate(binding)) el.__vueClickOutside__.callback = binding.value;
	},

	unbind(el, binding, vNode) {
		// Remove Event Listeners
		// eslint-disable-next-line
		!isServer(vNode) && document.removeEventListener('click', el.__vueClickOutside__.handler);
		// eslint-disable-next-line
		delete el.__vueClickOutside__;
	}
};
