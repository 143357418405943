import Vue from 'vue';
import OfsCrud from '@workflow-solutions/ofs-vuex-crud';
import authModule from './auth';
import registerAccountModule from './account';
import registerOrdersModule from './orders';
import registerBatchModule from './batch';
import registerTriggersModule from './triggers';
import registerBarcodeModule from './barcode';
import registerFileModule from './file';
import registerTemplateModule from './template';
import registerJobModule from './job';
import registerUserModule from './user';
import registerSearchModule from './search';
import registerLangStoreModule from './lang';
import httpConnectHttpTransport from './httpConnectHttpTransport';
import registerItemsModule from './items';
import registerHelpModule from './help';
import registerAccountLinkModule from './accountlink';
import registerLinkCodeModule from './linkcode';
import registerFeatureFlagsModule from './featureFlags';
import registerPairedProductModule from './pairedProduct';

const setupModules = (store, i18n) => {
	Vue.use(OfsCrud, { store });

	Vue.$ofsCrud.registerTransport({ name: 'connect', fn: () => httpConnectHttpTransport });
	Vue.$ofsCrud.registerTransport({ name: 'davinci', fn: () => httpDavinciTransport });

	// Register Modules
	Vue.$ofsCrud.registerAuthModule(authModule);
	registerAccountModule(store);
	registerBatchModule();
	registerBarcodeModule();
	registerJobModule();
	registerUserModule();
	registerSearchModule();
	registerTemplateModule();
	registerFileModule();
	registerLangStoreModule(store, i18n);
	registerOrdersModule();
	registerTriggersModule();
	registerItemsModule(store);
	registerHelpModule(store);
	registerAccountLinkModule();
	registerLinkCodeModule();
	registerFeatureFlagsModule(store);
	registerPairedProductModule(store);
};

export default setupModules;
