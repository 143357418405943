<template>
	<b-modal :visible="visible" modal-class="DeleteCodeModal" size="sm" @hide="handleClose">
		<div>
			<font-awesome-icon icon="exclamation-triangle" class="icon-warning" />
			<div class="mb-1 deactivate-info">
				<!-- eslint-disable-next-line max-len, prettier/prettier -->
				{{ $t('Are you sure you want to remove the pending invitation? The invitation code will become invalid and cannot be restored once removed.') }}
			</div>
		</div>
		<section slot="modal-footer">
			<b-btn v-t="'Cancel'" class="mr-1" @click="$emit('hide')" />
			<b-btn v-t="'Remove'" variant="primary" class="mr-1" @click="handleRemove(item)" />
		</section>
	</b-modal>
</template>

<script>
import { mapActions } from 'vuex';
import { $t } from '../../../vuex';
import { displayError } from '../../../lib/helpers';

export default {
	props: {
		visible: {
			type: Boolean,
			default: false
		},
		item: {
			type: Object,
			default: () => ({})
		}
	},
	methods: {
		...mapActions({
			delete: 'linkcode/delete'
		}),
		async handleRemove(item) {
			try {
				await this.delete({ codeId: item._id });
				this.$notify({ type: 'success', text: $t('Invitation code removed') });
				this.$emit('delete');
			} catch (err) {
				this.$notify({ type: 'error', text: $t(displayError(err)) });
			} finally {
				this.handleClose();
			}
		},
		handleClose() {
			this.$emit('hide');
		}
	}
};
</script>

<style lang="scss">
@import '../../../styles/shared';
.DeleteCodeModal {
	@include deactivate-delete-confirmation-modal;
}
</style>
