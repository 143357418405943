import Vue from 'vue';

export default function() {
	Vue.$ofsCrud.addResource({
		name: 'linkcode',
		pluralName: 'linkcodes',
		transport: 'connect',
		options: {
			namespaced: true,
			actions: {
				find: {
					dataProp: 'data', // -> `data` in the response payload
					countProp: 'count' // -> `count` in the response payload
				}
			}
		},
		state: {
			linkCode: null,
			pendingPermissions: [],
			pendingPermissionsCount: 0,
			permissionsToAcknowledge: [],
			permissionsToAcknowledgeCount: 0
		},
		actions: {
			delete({ dispatch }, { codeId }) {
				const path = `linkcode/${codeId}`;
				return dispatch('request', { method: 'DELETE', path });
			},
			verify({ dispatch }, { code }) {
				const path = 'linkcode/verify';
				const payload = { code: code };
				return dispatch('request', { method: 'POST', path, payload });
			},
			async createLinkCode(
				{ commit, dispatch },
				{ email, accountType, orderSubmissionPermission, outsourcingPermission }
			) {
				const path = 'linkcode';
				const payload = { email, accountType, orderSubmissionPermission, outsourcingPermission };
				const { data } = await dispatch('request', { method: 'POST', path, payload });
				commit('CREATE_NEW_LINKCODE', data);
			},
			async regenerate({ commit, dispatch }, { codeId }) {
				const path = `linkcode/${codeId}/regenerate`;
				const { data } = await dispatch('request', { method: 'PUT', path });
				commit('REGENERATE_LINKCODE', data);
			},
			async fetchPermissionsByStatus({ dispatch, commit }, { query }) {
				const path = 'linkcode';
				const response = await dispatch('request', { method: 'GET', path, params: query }).then(r => r.data);
				if (query.status === 'pending') {
					commit('SET_PENDING_PERMISSIONS', { permissions: response.data, count: response.count });
				} else if (query.status === 'acknowledge') {
					commit('SET_ACKNOWLEDGE_PERMISSIONS', { permissions: response.data, count: response.count });
				}
				return response;
			},
			resetPermissions({ commit }) {
				commit('SET_PENDING_PERMISSIONS', { permissions: [], count: 0 });
				commit('SET_ACKNOWLEDGE_PERMISSIONS', { permissions: [], count: 0 });
			}
		},
		getters: {
			linkCode: state => state.linkCode,
			pendingPermissions: state => state.pendingPermissions,
			pendingPermissionsCount: state => state.pendingPermissionsCount,
			permissionsToAcknowledge: state => state.permissionsToAcknowledge,
			permissionsToAcknowledgeCount: state => state.permissionsToAcknowledgeCount
		},
		mutations: {
			CREATE_NEW_LINKCODE(state, linkCode) {
				state.linkCode = linkCode;
			},
			REGENERATE_LINKCODE(state, linkCode) {
				state.linkCode = linkCode;
			},
			SET_PENDING_PERMISSIONS(state, { permissions, count }) {
				state.pendingPermissions = permissions;
				state.pendingPermissionsCount = count;
			},
			SET_ACKNOWLEDGE_PERMISSIONS(state, { permissions, count }) {
				state.permissionsToAcknowledge = permissions;
				state.permissionsToAcknowledgeCount = count;
			}
		}
	});
}
