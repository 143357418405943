export default {
	SET_TOTAL_PAGES(state, count) {
		state.totalPages = count;
	},
	SET_SEARCH_QUERY(state, { query }) {
		state.search.query = query;
	},
	SET_SEARCH_FILTER(state, { filter }) {
		state.search.filter = filter;
	},
	SET_SEARCH_RESULTS(state, { hits }) {
		state.search.results = hits;
	},
	SET_SEARCH_FACETS(state, { facets }) {
		state.search.facets = facets;
	},
	SET_ORDERS_COUNT(state, count) {
		state.count = count;
	},
	SET_ORDER_POSTBACKS(state, postbacks) {
		state.postbacks = postbacks;
	},
	SET_ORDER_SHIPMENTS(state, shipments) {
		state.shipments = shipments;
	},
	SET_ORDERS(state, orders) {
		state.orders = orders;
	},
	SET_STATUS(state, status) {
		state.status = status;
	},
	SET_EXTRA_DATA(state, data) {
		state.extraData = data;
	},
	ADD_SELECTED_FACET(state, { key, value }) {
		if (!state.search.filter[key]) {
			state.search.filter[key] = [];
		}

		state.search.filter[key].push(value);
	},
	REMOVE_SELECTED_FACET(state, key) {
		delete state.search.filter[key];
	}
};
