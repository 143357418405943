<template>
	<DefaultLayout class="PendingLinks">
		<OfsPanel class="mx-3 mb-3">
			<AsyncLoader :is-loading="isLoading">
				<ListTable
					:table-title="$t('Pending Requests')"
					:breadcrumbs="breadcrumbs"
					:config="config"
					bordered
					:items="invitations"
					:current-page="currentPage"
					:fields="fields"
					:total-items="count"
					:per-page="perPage"
					:selected="selected"
					:fetch-data="fetchData"
					:page-position-prefix="$t('Showing')"
					:page-position-join="$t('of')"
					@table-change="handleTableChange"
				>
					<template slot="TableButtons-Slot-right" slot-scope="{}">
						<b-btn
							class="ml-2"
							variant="secondary"
							data-test-id="verifyInviteButton"
							@click="openVerifyLinkModal"
						>
							{{ $t('Verify Invite') }}
						</b-btn>
						<b-btn class="ml-2" variant="primary" data-test-id="newInviteButton" @click="openNewLinkModal">
							{{ $t('New Invite') }}
						</b-btn>
					</template>
					<template #cell(type)="data">
						<span data-test-id="requestType">
							{{ displayPendingRequestType(data.item) }}
						</span>
					</template>
					<template #cell(recipient)="data">
						<span data-test-id="recipient">
							{{ displayRecipient(data.item) }}
						</span>
					</template>
					<template #cell(code)="data">
						<span data-test-id="inviteId" :class="{ expired: data.item.expired }">
							{{ displayCode(data.item) }}
						</span>
					</template>
					<template #cell(updatedAt)="data">
						<span data-test-id="updatedAt">
							{{ formatDate(data.item.updatedAt) }}
						</span>
					</template>
					<template #cell(expirationDate)="data">
						<span data-test-id="expired" :class="{ expired: data.item.expired }">
							{{ displayExpirationDate(data.item) }}
						</span>
					</template>
					<template slot="empty" slot-scope="{}">
						<span>
							<i>{{ $t('No data') }}</i>
						</span>
					</template>
					<template slot="next-text" slot-scope="{}">
						{{ $t('Next') }}
					</template>
					<template slot="prev-text" slot-scope="{}">
						{{ $t('Prev') }}
					</template>
					<template #cell(actions)="data">
						<b-dropdown right size="sm" data-test-id="dropdown" no-caret>
							<template slot="button-content">
								<font-awesome-icon icon="ellipsis-h" />
							</template>
							<b-dropdown-item
								v-if="requestReceived(data.item)"
								data-test-id="acceptRequest"
								@click="handleAcceptPermission(data.item)"
							>
								<icon name="check" class="mr-1" />
								{{ $t('Accept Request') }}
							</b-dropdown-item>
							<b-dropdown-item
								v-if="requestReceived(data.item)"
								data-test-id="declineRequest"
								@click="handleDeclinePermission(data.item)"
							>
								<icon name="times" class="mr-1" />
								{{ $t('Decline Request') }}
							</b-dropdown-item>
							<b-dropdown-item
								v-if="data.item.type === 'accountLink'"
								data-test-id="resend"
								@click="handleResendCode(data.item)"
							>
								<icon name="sync-alt" class="mr-1" />
								{{ $t('Resend') }}
							</b-dropdown-item>
							<b-dropdown-item
								v-if="data.item.type === 'accountLink' || requestSent(data.item)"
								variant="danger"
								data-test-id="delete"
								@click="openDeleteCodeModal(data.item)"
							>
								<icon name="trash-alt" class="mr-1" />
								{{ $t('Delete') }}
							</b-dropdown-item>
						</b-dropdown>
					</template>
				</ListTable>
			</AsyncLoader>
		</OfsPanel>
		<verify-link-modal :visible="isVerifyLinkModalVisible" @hide="handleVerifyLinkModalClose" />
		<new-link-modal :visible="isNewLinkModalVisible" @hide="handleNewLinkModalClose" @create="fetchData" />
		<delete-code-modal
			:visible="isDeleteCodeModalVisible"
			:item="selectedItem"
			:type="permissionType"
			@delete="handleItemDeleted"
			@hide="handleDeleteCodeModalClose"
		/>
	</DefaultLayout>
</template>

<script>
import moment from 'moment';
import { mapActions, mapGetters } from 'vuex';
import { ListTable, OfsPanel } from '@workflow-solutions/ofs-vue-layout';
import { AsyncLoader, navigationMixin, tableMixin } from '@workflow-solutions/siteflow-section-layout';
import DefaultLayout from '../../../components/DefaultLayout.vue';
import VerifyLinkModal from '../LinkedAccount/VerifyLinkModal';
import NewLinkModal from '../LinkedAccount/NewLinkModal';
import DeleteCodeModal from './DeleteCodeModal';
import _ from 'lodash';
import { $t } from '../../../vuex';

export default {
	name: 'PendingLinks',
	components: {
		ListTable,
		OfsPanel,
		DefaultLayout,
		AsyncLoader,
		VerifyLinkModal,
		NewLinkModal,
		DeleteCodeModal
	},
	mixins: [navigationMixin, tableMixin],
	data() {
		return {
			fields: [
				{ key: 'type', label: this.$t('Type') },
				{ key: 'recipient', label: this.$t('To/From') },
				{ key: 'code', label: this.$t('Invite ID') },
				{ key: 'updatedAt', label: this.$t('Date created') },
				{ key: 'expirationDate', label: this.$t('Expires') },
				{ key: 'actions', label: this.$t('Actions') },
				{ key: 'resend', label: '' },
				{ key: 'delete', label: '' }
			],
			config: {
				activeFilter: { visible: false },
				search: { visible: false },
				breadcrumbs: { visible: true },
				refresh: { visible: false },
				columns: { visible: false }
			},
			selected: [],
			isVerifyLinkModalVisible: false,
			isNewLinkModalVisible: false,
			isDeleteCodeModalVisible: false,
			selectedItem: null,
			isLoading: false,
			permissionType: null
		};
	},
	computed: {
		...mapGetters({
			count: 'linkcode/pendingPermissionsCount',
			linkCode: 'linkcode/linkCode',
			vars: 'account/vars',
			accountlinks: 'accountlink/accountlinks',
			pendingPermissions: 'linkcode/pendingPermissions'
		}),
		breadcrumbs() {
			return [
				{ text: 'Linked Accounts /', to: { name: 'accountlink.list' } },
				{ text: 'Pending Links', active: true }
			];
		},
		invitations() {
			return this.pendingPermissions.map(linkcode => {
				if (linkcode.type === 'accountLink')
					linkcode.expired = moment(linkcode.expirationDate).isBefore(this.now);
				return linkcode;
			});
		},
		accounts() {
			let accounts = [];
			_.each(this.accountlinks, link => {
				link.accountIdA === this.vars.accountId
					? accounts.push({ _id: link.accountIdB, name: link.accountNameB })
					: accounts.push({ _id: link.accountIdA, name: link.accountNameA });
			});
			return accounts;
		}
	},
	async created() {
		this.isLoading = true;
		// getting all links to determine partners' account names
		await this.getAllAccountLinks({ query: { active: true } });
		await this.fetchData();
		this.isLoading = false;
	},
	methods: {
		...mapActions({
			getLinkcodes: 'linkcode/fetchPermissionsByStatus',
			regenerateLinkCode: 'linkcode/regenerate',
			getAllAccountLinks: 'accountlink/findAll',
			acceptPermission: 'accountlink/acceptPermission',
			declinePermission: 'accountlink/declinePermission'
		}),
		async fetchData() {
			this.updateUrl();
			await this.getLinkcodes({
				query: {
					status: 'pending',
					page: this.currentPage,
					pagesize: this.perPage
				}
			});
		},
		openVerifyLinkModal() {
			this.isVerifyLinkModalVisible = true;
		},
		handleVerifyLinkModalClose() {
			this.isVerifyLinkModalVisible = false;
		},
		openNewLinkModal() {
			this.isNewLinkModalVisible = true;
		},
		handleNewLinkModalClose() {
			this.isNewLinkModalVisible = false;
		},
		openDeleteCodeModal(item) {
			this.selectedItem = item;
			this.isDeleteCodeModalVisible = true;
			this.permissionType = item.type;
		},
		handleDeleteCodeModalClose() {
			this.isDeleteCodeModalVisible = false;
		},
		async handleAcceptPermission(item) {
			this.$bvModal
				.msgBoxConfirm($t('Are you sure you want to accept this permission request?'), {
					title: $t('Accept permission request'),
					okTitle: $t('Confirm'),
					cancelTitle: $t('Cancel'),
					centered: true
				})
				.then(async confirmed => {
					if (confirmed) {
						await this.acceptPermissionRequest(item);
					} else this.$notify({ type: 'info', text: $t('Permission request has not been accepted') });
				})
				.catch(err => {
					const message = $t(this.$errorUtils.displayError(err));
					this.$notify({ type: 'error ', text: message });
				});
		},
		async acceptPermissionRequest(item) {
			try {
				await this.acceptPermission({ requestId: item._id });
				this.$notify({ type: 'success', text: $t('Permission request accepted') });
				await this.fetchData();
			} catch (err) {
				this.$notify({ type: 'error', text: $t(this.$errorUtils.displayError(err)) });
			}
		},
		async handleDeclinePermission(item) {
			this.$bvModal
				.msgBoxConfirm($t('Are you sure you want to decline this permission request?'), {
					title: $t('Decline permission request'),
					okTitle: $t('Confirm'),
					cancelTitle: $t('Cancel'),
					centered: true
				})
				.then(async confirmed => {
					if (confirmed) {
						await this.declinePermissionRequest(item);
					} else this.$notify({ type: 'info', text: $t('Permission request has not been declined') });
				})
				.catch(err => {
					const message = $t(this.$errorUtils.displayError(err));
					this.$notify({ type: 'error ', text: message });
				});
		},
		async declinePermissionRequest(item) {
			try {
				await this.declinePermission({ requestId: item._id });
				this.$notify({ type: 'success', text: $t('Permission request declined') });
				await this.fetchData();
			} catch (err) {
				this.$notify({ type: 'error', text: $t(this.$errorUtils.displayError(err)) });
			}
		},
		handleItemDeleted() {
			return this.fetchData();
		},
		displayPendingRequestType(item) {
			const type = item.type;
			const permission = item.permission;
			const status = item.status;
			const accountId = item.accountId;
			const accountDestinationId = item.accountDestinationId;
			const currentAccountId = this.vars?.accountId;
			if (type === 'accountLink') {
				const accountLinkPermissions = item.permission
					.map(p => (p === 'ordersubmission' ? 'Order Submission' : 'Outsourcing'))
					.join(', ');
				return $t(`Linked account request (Sent: ${accountLinkPermissions})`);
			}
			if (type === 'permission' && status === 'pending') {
				if (permission === 'ordersubmission') {
					if (currentAccountId === accountDestinationId) return $t('Add Order Sending request (Received)');
					else if (currentAccountId == accountId) return $t('Order Sending request (Sent)');
				}
				if (permission === 'outsource') {
					if (currentAccountId === accountDestinationId) return $t('Add Outsourcing request (Received)');
					else if (currentAccountId == accountId) return $t('Outsourcing request (Sent)');
				}
			}
		},
		displayRecipient(item) {
			if (item.type === 'accountLink') {
				return item.email;
			} else if (item.type === 'permission') {
				if (item.accountDestinationId) {
					const currentAccountId = this.vars?.accountId;
					let partner = null;
					if (currentAccountId === item.accountId) {
						partner = _.find(this.accounts, ['_id', item.accountDestinationId]);
						return partner ? partner.name : item.accountDestinationId;
					} else if (currentAccountId === item.accountDestinationId) {
						partner = _.find(this.accounts, ['_id', item.accountId]);
						return partner ? partner.name : item.accountId;
					}
				}
			}
		},
		displayCode(item) {
			if (item.type === 'accountLink') return item.code;
			return '-';
		},
		displayExpirationDate(item) {
			if (item.type === 'accountLink') return this.formatRelative(item.expirationDate);
			if (item.type === 'permission') return $t('never');
		},
		async handleResendCode(item) {
			try {
				await this.regenerateLinkCode({ codeId: item._id });
				if (this.linkCode?.emailSuccess === false) {
					this.$notify({ type: 'warning', text: $t('Invitation code renewed but sending email has failed') });
				} else if (this.linkCode?.emailSuccess === true) {
					this.$notify({ type: 'success', text: $t('Invitation email resent') });
				}
				this.fetchData();
			} catch (err) {
				this.$notify({ type: 'error', text: $t(this.$errorUtils.displayError(err)) });
			}
		},
		formatDate(time) {
			return moment(time).accountFormat(' LT');
		},
		formatRelative(date) {
			return `${moment(date).accountFormat(' LT')} (${moment(date).fromNow()})`;
		},
		requestReceived(item) {
			return (
				item.type === 'permission' &&
				item.accountDestinationId &&
				this.vars?.accountId === item.accountDestinationId
			);
		},
		requestSent(item) {
			return item.type === 'permission' && item.accountId && this.vars?.accountId === item.accountId;
		}
	}
};
</script>

<style lang="scss">
@import '../../../styles/shared';

.expired {
	color: $of-color-red;
}
</style>
