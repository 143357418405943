import Vue from 'vue';
import OfsMenu, { OfsForm, OfsConfirmationModal, OfsFeatureToggle } from '@workflow-solutions/ofs-vue-layout';
import BootstrapVue from 'bootstrap-vue';
import VueClipboard from 'vue-clipboard2';
import * as Sentry from '@sentry/browser';
import * as Integrations from '@sentry/integrations';
import '@workflow-solutions/ofs-vue-layout/dist/index.css';
import 'vue-awesome/icons';
import 'ionicons/dist/css/ionicons.css';
import Icon from 'vue-awesome/components/Icon';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import store, { i18n } from './vuex';
import router, { menuStructure } from './router';
import rawSvgIcons from './assets/customIcons';
import App from './App';
import './directives';
import './styles/main.scss';
import './styles/components/work/_item.scss';
import packageJson from '../package.json';

// Sentry Error Logging
const sentryKey = window.$config.sentryKey;
if (sentryKey && window.location.hostname !== 'localhost') {
	Vue.config.errorHandler = error => console.error(error);

	Sentry.init({
		dsn: sentryKey,
		release: packageJson.version,
		environment: window.$config.environment,
		integrations: [new Integrations.Vue({ Vue })]
	});
}

Vue.component('v-icon', Icon);
Icon.register(rawSvgIcons);

library.add(fas); // add the the whole icon pack
Vue.component('font-awesome-icon', FontAwesomeIcon);

Vue.config.productionTip = false;
Vue.use(OfsMenu, { router, store, menu: menuStructure });
Vue.use(OfsForm, { store });
Vue.use(OfsFeatureToggle, { store });
Vue.use(OfsConfirmationModal, { store });
Vue.use(BootstrapVue);
Vue.use(VueClipboard);

/* eslint-disable no-new */
new Vue({
	i18n,
	el: '#app',
	router,
	store,
	template: '<App/>',
	components: { App }
});
