import moment from 'moment';
import _replace from 'lodash/replace';
import round from 'lodash/round';
import toArray from 'lodash/toArray';
import filesize from 'filesize';

/**
 * Humanise a file size
 * @param {any} bytes - Size in bytes
 * @param {number} [precision=0] - Rounding precision
 * @returns {string} - Humanised file size
 */
const fileSize = (bytes, precision = 0) => filesize(bytes, { base: precision });

/**
 * Output formatted json
 * @param {any} v - Json
 * @returns {string} - Formatted json
 */
const niceJson = v => JSON.stringify(v, null, 4);

/**
 * Output formatted date
 * @param {string} d - The date
 * @param {string} [format='MMM Do YYYY  H:mm:ss'] - Date format
 * @returns {string} - Formatted date
 */
const niceDate = (d, format = 'YYYY-MM-DD H:mm') => moment(d).format(format);

/**
 * Output duration from now
 * @param {string} d - The date
 * @returns {string} - From now humanized duration
 */
const duration = d => moment(d).fromNow();

/**
 * Pluralise a string
 * @param {number} v - The item count
 * @param {string} [t='item'] - The string to pluralise
 * @returns {string} - Pluralised string
 */
const plural = (v, t = 'item') => (v > 1 ? `${v} ${t}s` : `${v} ${t}`);

const toFixed = (number, decimalPlaces = 2) => (number ? round(number, decimalPlaces) : number);

const shorten = (str, length = 40) => (str.length > length ? `${(str || '').substring(0, length - 1)}...` : str);

const replace = (str, replacement = '', pattern = '') => _replace(str, pattern, replacement);

const elementsCount = elem => toArray(elem).length;

export { fileSize, niceJson, niceDate, duration, plural, shorten, replace, toFixed, elementsCount };
