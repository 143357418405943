import * as Sentry from '@sentry/browser';
import moment from 'moment';
import each from 'lodash/each';
import values from 'lodash/values';
import find from 'lodash/find';
import pick from 'lodash/pick';
import _sortBy from 'lodash/sortBy';

const $config = window.$config || {};

// siteflow-section-layout expects fileApiBase var to be in config,
// but siteflow-lite config does not define it. This is a temporary workaround.
// TODO: upload correct config to s3 (or modify siteflow-section-layout accordingly)
$config.filesApiBase = '';

// Account
export const getAccount = async ({ dispatch }) => {
	const path = `${window.$config.apiBase}/api/account`;
	const results = await dispatch('api/request', { method: 'GET', path }, { root: true });
	return results;
};

export const setCurrentAccount = ({ commit }, account) => {
	commit('SET_ACCOUNT', account);
};

export const getVars = async ({ dispatch, commit }) => {
	const path = `${window.$config.apiBase}/api/vars`;
	const { data: vars } = await dispatch('request', {
		method: 'GET',
		path,
		transport: 'connect'
	});

	// TODO: should we use vars vuex module from siteflow-section-layout instead?
	moment.tz.setDefault(vars.oneflowAccountSettings.timezone);
	moment.fn.accountFormat = function accountDateFormat(timeFormat = '') {
		return this.format(`${vars.oneflowAccountSettings.dateFormat.toUpperCase()}${timeFormat}`);
	};

	vars.userAccountLookup = {};
	each(vars.userAccountHash, account => {
		vars.userAccountLookup[account._id] = account.name;
	});

	const accounts = _sortBy(values(vars.userAccountHash), 'name');
	commit('SET_VARS', vars);
	commit('SET_ACCOUNTS', accounts);

	return vars;
};

export const getPspAccounts = async ({ dispatch, commit }) => {
	const path = `${window.$config.apiBase}/api/vars/pspAccounts`;
	const { data } = await dispatch('request', {
		method: 'GET',
		path,
		transport: 'connect'
	});

	commit('SET_PSP_ACCOUNTS', data);
};

export const changeAccount = async ({ dispatch }, account) => dispatch('setCurrentAccount', account);

export const saveAccountSettings = async ({ dispatch, state, commit, getters }, accountSettings) => {
	const { _id: accountId } = state.currentAccount || {};

	const payload = {
		accountId,
		...pick(accountSettings, getters.accountSettingsWhitelist)
	};

	const { settings } = await dispatch('request', {
		method: 'PUT',
		path: 'accountSettings/save',
		payload,
		transport: 'connect'
	}).then(r => r.data);

	commit('UPDATE_VARS_ACCOUNT_SETTINGS', settings);
	return settings;
};

export const getMember = async ({ commit, dispatch }, userId) => {
	const { data } = await dispatch('request', {
		method: 'GET',
		path: `account/memberships/${userId}`
	});

	commit('SET_MEMBER', data);
};

const findOneflowAccount = accounts => {
	const enviromentSuffix = $config.environment === 'stage' ? `-${$config.environment}` : '';
	const accountName = `oneflow${enviromentSuffix}`;

	return find(accounts, { name: accountName });
};

export const fetchBaseAccountData = async ({ dispatch, getters, rootGetters }) => {
	const { currentAccount } = getters;
	const isAuthenticated = rootGetters['auth/isAuthenticated'];

	if (isAuthenticated) {
		const user = await dispatch('auth/getUser', null, { root: true });
		Sentry.configureScope(scope => {
			scope.setUser({
				id: user._id,
				email: user.email,
				username: user.username
			});
		});
		await dispatch('getVars');
		const accounts = rootGetters['account/accounts'];

		let account;
		if (!currentAccount) {
			account = findOneflowAccount(accounts) || accounts[0];
			if (!account) {
				await dispatch('auth/logout', null, { root: true });
				await dispatch('changeAccount', null);
				window.location.href = `${window.$config.hpIdLogout}&auth_error=1`;
			}
			await dispatch('setCurrentAccount', account);
		}

		Sentry.configureScope(scope => {
			scope.setExtra('accountId', (currentAccount || account)._id);
		});
	}

	return null;
};
