import Vue from 'vue';
import _ from 'lodash';

export default function() {
	Vue.$ofsCrud.addResource({
		name: 'item',
		options: {
			namespaced: true,
			actions: {
				find: {
					dataProp: 'data',
					countProp: 'total'
				}
			}
		},
		transport: 'connect',
		actions: {
			markAsComplete: async ({ dispatch, commit }, itemId) => {
				const path = `item/${itemId}/complete`;
				const { data } = await dispatch('request', { method: 'PUT', path });
				commit('REMOVE_ITEM', itemId);
				return data;
			},
			reset: ({ commit }) => {
				commit('RESET_ITEMS');
			},
			getThumbnail: async ({ dispatch }, fileId) => {
				const path = `file/thumbnail/${fileId}`;
				const { data } = await dispatch('request', { method: 'GET', path, params: { resignUrl: true } });
				return data;
			},
			getShipmentById: async ({ dispatch }, shipmentId) => {
				const { data: shipment } = await dispatch('request', {
					method: 'get',
					path: `shipment/${shipmentId}`
				});

				return shipment;
			}
		},
		mutations: {
			FIND_SUCCESS(state, { data }) {
				state.items = [...state.items, ...data];
			},
			RESET_ITEMS(state) {
				state.items = [];
			},
			REMOVE_ITEM(state, itemId) {
				state.items = _.filter(state.items, item => item._id !== itemId);
			}
		}
	});
}
