<template>
	<div class="article">
		<header class="article__header article-header">
			<div class="article-header__inner">
				<h1 class="article-header__title">{{ $t('Change the order status to shipped') }}</h1>
				<p class="article-header__text">
					{{ $t("Let's set change the status of an order to shipped after you have finished the production, and you have shipped the order. You can configure to trigger a Postback to the source informing that the order has shipped.") }}
				</p>
			</div>
		</header>

		<section class="article__panel article__step">
			<h2 class="article__heading">{{ $t('Order details') }}</h2>
			<p>
				{{ $t('To be able to change the status of an order to shipped, you need to know the shipmentId. You can retrieve this value using a Postback Trigger.') }}
			</p>
			<div class="article-panel article-panel--highlight">
				<b>{{ $t('An ID for the order') }} <code>sourceOrderId</code></b>
				<p>
					{{ $t('We can get your shipmentIds via your sourceOrderId (ORDER ID). Please provide the sourceOrderId for the order that you would like to set as shipped:') }}
				</p>
				<b-form-input
					v-model="sourceOrderId"
					v-focus
					type="text"
					class="form-control"
					name="search"
					:placeholder="$t('Eg. MyPSPTestOrder')"
					@change="handleSourceOrderIdChange"
				/>
			</div>
		</section>
		<template v-if="shipments.length > 0">
			<section class="article__panel article__step">
				<h2 class="article__heading">{{ $t('Shipping details') }}</h2>
				<div class="article-panel article-panel--highlight">
					<b>{{ $t('An ID for the shipment') }} <code>shipmentId</code></b>
					<p>
						{{ $t('Select one of the available shipments that you would like to set as shipped:') }}
					</p>
					<b-form-select :options="shipments" :value="shipmentId" @change="handleShipmentIdChange">
					</b-form-select>
				</div>
				<div class="article-panel article-panel--highlight">
					<b>{{ $t('Shipping address') }}</b>
					<p>
						{{ $t('This is the address for the order to be shipped to:') }}
					</p>
					<of-form-input
						:horizontal="false"
						name="selectedShipment.shipTo.name"
						no-label
						class="mb-1"
						:placeholder="$t('Name')"
						disabled
					/>
					<of-form-input
						:horizontal="false"
						name="selectedShipment.shipTo.address1"
						no-label
						class="mb-1"
						:placeholder="$t('Address')"
						disabled
					/>
					<of-form-input
						:horizontal="false"
						name="selectedShipment.shipTo.town"
						no-label
						class="mb-1"
						:placeholder="$t('Town')"
						disabled
					/>
					<of-form-select
						:horizontal="false"
						name="selectedShipment.shipTo.isoCountry"
						no-label
						class="mb-1"
						:placeholder="$t('Country')"
						:options="countries"
						disabled
					/>
					<of-form-input
						:horizontal="false"
						name="selectedShipment.shipTo.postcode"
						no-label
						:placeholder="$t('Postcode')"
						disabled
					/>
				</div>
				<div class="article-panel article-panel--highlight">
					<b>{{ $t('Tracking number') }}</b>
					<p>{{ $t('If required you can provide a') }} <code>trackingNumber</code>.</p>
					<b-form-input
						v-model="trackingNumber"
						type="text"
						class="form-control"
						name="trackingNumber"
						:placeholder="$t('Tracking Number')"
					/>
					<b-form-group
						class="mt-3"
						:description="
							$t(`You can also enter an optional tracking URL for viewing the shipments location with the carrier e.g. https://www.dpd.co.uk/apps/tracking/?parcel=12345`)
						"
						label-for="trackingUrl"
					>
						<b-form-input
							id="trackingUrl"
							v-model="trackingUrl"
							type="text"
							class="form-control"
							name="trackingUrl"
							:placeholder="$t('Tracking URL')"
						/>
					</b-form-group>
				</div>
			</section>
			<section class="article__panel article__step">
				<h2 class="article__heading">{{ $t('Setting the Shipment as shipped') }}</h2>
				<p>{{ $t("Here's the body of the request in JSON") }}</p>
				<div class="mb-3">
					<codemirror :value="payloadString" :options="options" />
				</div>
				<p>
					{{ $t('API Calls to Site Flow Light are submitted via REST API, using HTTP header authorisation. Full documentation on how to do this can be viewed') }}
					<a href="/#/help/docs/api">{{ $t('here') }}</a>.
				</p>
				<template v-if="!isSuccess">
					<p>
						{{ $t('To submit this API Call we will need a token and secret (from your') }}
						<router-link :to="{ name: 'user.details', params: { id: userId } }">{{ $t('user profile') }}</router-link>
						{{ $t(") and a destination account (your account name). We've pulled these in below.") }}
					</p>
					<div class="article-panel article-panel--highlight">
						<of-form-input label="Token" name="credentials.token" required disabled />
						<of-form-input label="Secret" name="credentials.secret" required disabled />
						<of-form-input class="mb-0" name="apiUrl" label="PUT URL" disabled />
					</div>

					<hr class="article__rule" />

					<div class="article-panel mt-0">
						<h2 class="article__heading">{{ $t("You're ready to go") }}</h2>
						<p>{{ $t("That's everything we need, you're ready to change the status of order to shipped!") }}</p>

						<b-button
							variant="info"
							size="lg"
							:disabled="isSubmitting"
							data-test-identifier="shipment-shipped-submit"
							@click="handleSubmit"
						>
							<template v-if="isSubmitting"><v-icon name="spinner" spin /> {{ $t("Submitting") }} </template>
							<template v-else>
								{{ $t("Submit") }}
							</template>
						</b-button>
					</div>
				</template>
				<template v-else>
					<hr class="article__rule" />
					<div class="article-panel mt-0" data-test-identifier="shipment-shipped-success">
						<h2 class="article__heading">
							<v-icon name="check" class="article__heading-icon text-success" scale="2" />
							{{ $t("Your order status is shipped") }}
						</h2>
						<p>
							{{ $t("To follow your order through the production process,") }}
							<router-link :to="{ name: 'help.docs.tutorial' }">{{ $t("view our tutorial") }}</router-link>.
						</p>
						<b-button
							variant="primary"
							size="lg"
							:to="{ name: 'orders.view', params: { id: orderId } }"
							data-test-identifier="shipment-shipped-success-view">
							{{ $t("View Order") }}
						</b-button>
						<a
							class="btn btn-lg btn-link"
							data-test-identifier="shipment-shipped-success-another"
							@click="handleStartFromScratchBtnClick">
							{{ $t("Submit another") }}
						</a>
					</div>
				</template>
			</section>
		</template>
	</div>
</template>

<script>
import { withForm, OfFormInput, OfFormSelect } from '@workflow-solutions/ofs-vue-layout';
import _find from 'lodash/find';
import _get from 'lodash/get';
import _map from 'lodash/map';
import { mapActions, mapGetters } from 'vuex';
import ISOcountries from '../../data/ISOcountries';

export default {
	mixins: [withForm('setShipmentShipped')],
	components: {
		OfFormInput,
		OfFormSelect,
		codemirror: async () => {
			await Promise.all([
				import('codemirror/lib/codemirror.css'),
				import('codemirror/theme/material.css'),
				import('codemirror/addon/lint/lint.css'),
				import('codemirror/mode/javascript/javascript'),
				import('codemirror/addon/selection/active-line'),
				import('codemirror/addon/edit/closebrackets'),
				import('codemirror/addon/edit/matchbrackets'),
				import('codemirror/addon/comment/comment'),
				import('codemirror/addon/lint/lint'),
				import('codemirror/addon/lint/json-lint')
			]);

			return import('vue-codemirror').then(r => r.codemirror);
		}
	},
	data() {
		return {
			orderId: '',
			sourceOrderId: '',
			selectedShipment: {},
			availableShipments: [],
			trackingNumber: '',
			trackingUrl: '',
			isSubmitting: false,
			isSuccess: false,
			options: {
				lint: true,
				autoCloseBrackets: true,
				mode: 'application/json',
				lineWrapping: true,
				gutters: ['CodeMirror-lint-markers'],
				tabSize: 4,
				styleActiveLine: false,
				lineNumbers: true,
				theme: 'material'
			}
		};
	},
	computed: {
		...mapGetters({
			currentAccount: 'account/currentAccount',
			vars: 'account/vars',
			userAccountRoles: 'user/userAccountRoles'
		}),
		shipments() {
			return _map(this.availableShipments, shipment => ({
				text: shipment._id,
				value: shipment._id
			}));
		},
		shipmentId() {
			return this.selectedShipment._id;
		},
		countries() {
			return _map(ISOcountries, country => ({
				text: country.name,
				value: country['alpha-2']
			}));
		},
		payload() {
			return {
				trackingNumber: this.trackingNumber,
				trackingUrl: this.trackingUrl
			};
		},
		payloadString() {
			return JSON.stringify(this.payload, null, 2);
		},
		userId() {
			return _get(this, 'vars.userId');
		},
		currentAccountRole() {
			if (!this.currentAccount || !this.userAccountRoles) return null;
			return _find(this.userAccountRoles, { id: this.currentAccount._id });
		},
		apiUrl() {
			return `${window.$config.apiBase}/api/shipment/shipped/${this.shipmentId}`;
		}
	},
	watch: {
		currentAccountRole() {
			if (!this.currentAccountRole) return null;
			return this.updateFormData({
				credentials: this.currentAccountRole
			});
		},
		selectedShipment() {
			return this.updateFormData({
				selectedShipment: this.selectedShipment
			});
		},
		apiUrl() {
			return this.updateFormData({
				apiUrl: this.apiUrl
			});
		}
	},
	mounted() {
		this.setFormData({
			selectedShipment: {},
			apiUrl: ''
		});

		this.fetchData();
	},
	methods: {
		...mapActions({
			getVars: 'account/getVars',
			getShipmentsByOrderId: 'order/getShipmentsByOrderId',
			getOrdersBySourceOrderId: 'order/getOrdersBySourceOrderId',
			setShipped: 'order/setShipped',
			getUserAccountRoles: 'user/getUserAccountRoles'
		}),
		async handleSourceOrderIdChange() {
			if (this.sourceOrderId) {
				try {
					const { data: orders } = await this.getOrdersBySourceOrderId(this.sourceOrderId);
					this.orderId = _get(orders, '0._id');
					if (!this.orderId) {
						return this.$notify({
							type: 'error',
							text: this.$t('No order found with that sourceId')
						});
					}
					const { data: shipments } = await this.getShipmentsByOrderId(this.orderId);
					this.availableShipments = shipments.filter(({ status }) => status === 'live');
					if (this.availableShipments.length === 0) {
						this.$notify({ type: 'info', text: this.$t('There are no shipments ready to be shipped') });
					} else {
						this.selectedShipment = this.availableShipments[0];
					}
				} catch (err) {
					this.availableShipments = [];
					this.$notify({
						type: 'error',
						title: this.$t('Error'),
						text: this.$t('An error occurred while fetching shipments')
					});
				}
			} else {
				this.availableShipments = [];
			}
		},
		handleShipmentIdChange(shipmentId) {
			this.selectedShipment = this.availableShipments.find(shipment => shipment._id === shipmentId);
		},
		async fetchData() {
			try {
				await this.getVars();
				await this.getUserAccountRoles({ userId: this.userId });
			} catch (err) {
				this.$notify({
					type: 'error',
					title: this.$t('Error'),
					text: this.$t('An error occurred while fetching user details')
				});
			}
		},
		async handleSubmit() {
			try {
				this.isSubmitting = true;
				await this.setShipped({ id: this.shipmentId, payload: this.payload });
				this.isSuccess = true;
			} catch (err) {
				const { message, response: { status, data: { errors = [] } = {} } = {} } = err;

				if (status === 400) {
					const errorsString = errors.map(error => `${error.dataPath} ${error.message}`).join('<br/><br/>');
					this.$notify({ type: 'error', text: `${this.$t('Invalid data')}: ${errorsString}` });
				} else {
					this.$notify({ type: 'error', text: `${message || this.$t('Error during calling API')}` });
				}
			} finally {
				this.isSubmitting = false;
			}
		},
		handleStartFromScratchBtnClick() {
			this.isSuccess = false;
			this.orderId = '';
			this.sourceOrderId = '';
			this.availableShipments = [];
			this.trackingNumber = '';
		}
	}
};
</script>

<style lang="scss">
.article .CodeMirror {
	height: auto;
}
</style>
