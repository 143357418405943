<template>
	<b-modal
		v-model="isVisible"
		size="lg"
		hide-header
		:ok-title="$t('Complete')"
		:cancel-title="$t('Close')"
		@ok.prevent="complete"
		:ok-disabled="completed"
	>
		<div class="work-item work-item--expanded" v-if="item">
			<div class="work-item__inner">
				<div class="work-item__bar">
					<div class="work-item__content work-item-content">
						<item-thumbnail class="work-item-content__image" :item="item" />
						<div class="work-item-content__body">
							<div class="work-item-content__details">
								<p class="work-item-content__title" v-text="itemTitle" data-test-id="batchTitle"></p>
								<p
									class="work-item-content__text"
									data-test-id="quantity"
									v-text="`Qty: ${item.quantity}`"
								></p>
							</div>
							<div class="work-item-content__actions">
								<router-link
									:to="{
										name: 'orders.view',
										params: { id: shipment && shipment.orderId },
										query: { barcode: item.barcode }
									}"
								>
									<b-button size="sm">{{ $t('Go to shipment') }}</b-button>
								</router-link>
							</div>
						</div>
					</div>
					<div
						class="work-item__status work-item-status"
						:class="`work-item-status--${status}`"
						v-if="!completed"
					>
						<span class="work-item-status__label">{{ $t('Out on') }}</span>
						<span class="work-item-status__value">{{ item.shipByDate | day }}</span>
						<span class="work-item-status__label">{{ item.shipByDate | date }}</span>
					</div>
					<div class="work-item__status work-item-status" :class="`work-item-status--${status}`" v-else>
						<span class="work-item-status__label">Completed</span>
						<span class="work-item-status__value">{{ item.shippedDate | day }}</span>
						<span class="work-item-status__label">{{ item.shippedDate | date }}</span>
					</div>
				</div>
				<div class="work-item__extended-content">
					<ul class="work-item-components">
						<work-component
							:component="component"
							v-for="component in item.components"
							:order="order"
							:key="component._id"
						/>
					</ul>
				</div>
			</div>
		</div>
	</b-modal>
</template>

<script>
import { mapActions } from 'vuex';
import moment from 'moment';
import _ from 'lodash';
import WorkComponent from './WorkComponent';
import ItemThumbnail from './ItemThumbnail';

export default {
	components: {
		WorkComponent,
		ItemThumbnail
	},
	props: {
		item: {
			type: Object
		},
		show: {
			type: Boolean,
			required: true
		},
		order: { type: Object },
		modalClose: {
			type: Function,
			required: true
		}
	},
	filters: {
		day(date) {
			return moment(date).format('ddd');
		},
		date(date) {
			return moment(date).format('D/MM/YY');
		}
	},
	data() {
		return {
			subBatches: [],
			shipment: null
		};
	},
	methods: {
		...mapActions({
			markAsComplete: 'item/markAsComplete',
			getShipmentById: 'item/getShipmentById'
		}),
		async complete() {
			try {
				await this.markAsComplete(this.item._id);

				this.$notify({
					type: 'success',
					text: 'Item completed'
				});

				this.modalClose();
			} catch (e) {
				this.$notify({ type: 'error', text: 'There was a problem completing your Item', title: 'Ooops' });
			}
		}
	},
	computed: {
		orderStatus() {
			return _.get(this.order, 'orderData.status');
		},
		completed() {
			if (!this.item) {
				return false;
			}

			return [this.item.status, this.orderStatus].includes('complete');
		},
		itemTitle() {
			return `${this.item.sourceItemId} (${this.item.sku})`;
		},
		componentNames() {
			return _.map(this.item.components, 'code').join(', ');
		},
		status() {
			const shipByDate = moment(this.item.shipByDate);

			if (!this.completed) {
				if (shipByDate.isBefore(moment())) {
					return 'error';
				} else if (moment().isBetween(shipByDate, moment(shipByDate).subtract(2, 'hours'))) {
					return 'warning';
				}
			} else {
				const completed = moment(this.item.completed);

				if (completed.isAfter(shipByDate)) {
					return 'error';
				} else if (completed.isBetween(shipByDate, moment(shipByDate).subtract(2, 'hours'))) {
					return 'warning';
				}
			}

			return 'success';
		},
		isVisible: {
			get() {
				return this.show;
			},
			set(value) {
				if (!value) this.modalClose();
			}
		}
	},
	watch: {
		item: {
			async handler() {
				if (!this.item) return;
				this.shipment = this.shipment || (await this.getShipmentById(this.item.shipmentId));
			},
			immediate: true
		}
	}
};
</script>

<style></style>
