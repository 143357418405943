import Vue from 'vue';
import * as actions from './actions';
import * as mutations from './mutations';
import * as getters from './getters';

export default function() {
	Vue.$ofsCrud.registerAccountModule({
		authBase: window.$config.authBase,
		state: {
			account: {
				accountSettings: {}
			},
			currentAccount: null,
			accounts: [],
			memberships: [],
			membershipsCount: null,
			accountMemberships: null,
			user: {},
			member: null,
			vars: {
				oneflowAccountSettings: {},
				userAccountHash: {}
			},
			pspAccounts: []
		},
		actions,
		mutations,
		getters
	});
}
