<template>
	<router-link :to="{ name: 'products.view', params: { id: item.objectID } }" class="SearchCell">
		<v-icon name="book" class="SearchCell__icon"></v-icon>
		<span class="SearchCell__content">
			<header class="SearchCell__header">
				<h3 class="SearchCell__heading">Product</h3>
			</header>
			<p class="SearchCell__title" v-html="productCode"></p>
			<p class="SearchCell__subtitle" v-html="description"></p>
		</span>
	</router-link>
</template>

<script>
export default {
	props: {
		item: {
			required: true,
			type: Object
		}
	},
	computed: {
		productCode() {
			if (this.item._highlightResult.productCode) {
				return this.item._highlightResult.productCode.value;
			}

			return this.item.productCode;
		},
		description() {
			if (this.item._highlightResult.description) {
				return this.item._highlightResult.description.value;
			}

			return this.item.description;
		}
	}
};
</script>
