<script type="text/jsx">
import Loader from './Loader';

export default {
	props: {
		width: {
			type: [String, Number]
		},
		height: {
			type: [String, Number]
		},
		fetchData: {
			type: Function
		},
		isLoading: {
			type: Boolean,
			default: false
		},
		onLoaded: {
			type: Function,
			default: () => () => {}
		},
		onError: {
			type: Function,
			default: () => () => {}
		},
		absolutePosition: {
			type: Boolean
		}
	},
	data() {
		return {
			isLoadingLocal: this.isLoading,
			error: null
		};
	},
	computed: {
		classes() {
			return { 'is-absolutePositioned': this.absolutePosition };
		}
	},
	watch: {
		isLoading() {
			this.isLoadingLocal = this.isLoading;
		}
	},
	async mounted() {
		if (typeof this.fetchData === 'function') {
			this.isLoadingLocal = true;
			try {
				await this.fetchData();
				this.onLoaded();
			} catch (err) {
				this.onError(err);
				this.error = err;
			} finally {
				this.isLoadingLocal = false;
			}
		}
	},
	render() {
		if (this.$scopedSlots.default) {
			return this.$scopedSlots.default({
				isLoading: this.isLoadingLocal,
				hasError: !!this.error,
				error: this.error,
				Loader
			});
		}

		if (this.isLoadingLocal) return <Loader class={this.classes} width={this.width} height={this.height} />;

		return this.$slots.default[0];
	}
};
</script>

<style scoped>
.is-absolutePositioned {
	top: 50%;
	left: 50%;
	position: absolute;
	transform: translate(-50%, -50%);
}
</style>
