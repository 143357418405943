<template>
	<div class="Filters">
		<div class="Filters-leftContent">
			<slot name="left-content" />
		</div>

		<div class="Filters-rightContent" v-if="!areFiltersEmpty">
			<section class="Filters-selected">
				<a
					href
					v-if="!areSelectedFiltersEmpty"
					class="mr-3"
					v-b-tooltip.hover
					:title="$t('Remove all filters')"
					@click.prevent="onClearFilters"
					data-test-id="clearFilters"
					>{{ $t('Clear filters') }}</a
				>
				<div
					v-for="(item, index) in selectedFilters"
					:key="`selected-filters-${index}`"
					class="Filters-selectedItem d-flex flex-nowrap align-items-center mr-2"
				>
					<span>{{ filters[item.index].items[item.itemsIndex].title }}</span>
					<span
						v-b-tooltip.hover
						:title="$t('Remove filter')"
						class="Filters-selectedItemCloseIcon ml-1"
						@click="onRemoveFilter(index)"
					>
						<slot name="close-icon" v-if="$slots['close-icon']" />
						<i v-else class="icon ion-ios-close-circle" />
					</span>
				</div>
			</section>
			<b-button
				data-test-id="Orders_filtersButton"
				@click.stop="onFiltersClick"
				aria-controls="filtersContent"
				:aria-expanded="areFiltersVisible"
				class="Filters-dropdown"
				:class="{ rotated: areFiltersVisible }"
			>
				<span class="d-flex mr-2">
					<slot v-if="$slots.label" name="label" />
					<template v-else>{{ label }}</template>
				</span>
				<span class="Filters-dropdownIcon">
					<slot v-if="$slots.icon" name="icon" />
					<i v-else class="icon ion-ios-arrow-down" />
				</span>
			</b-button>
			<b-collapse
				id="filtersContent"
				v-if="areFiltersVisible"
				:visible="areFiltersVisible"
				class="Filters-content mt-2"
			>
				<OfsPanel :isPadded="true" v-click-outside="handleClickOutside" class="m-0">
					<section class="Filters-contentInner d-flex flex-row">
						<template v-for="(item, index) in filters">
							<section
								:key="`filter-row-${index}`"
								class="Filters-contentInnerContainer d-flex flex-column"
								:class="getFilterClasses(index, item)"
								v-if="isFilterVisible(item)"
							>
								<section class="Filters-contentInnerHeader">{{ item.header }}</section>
								<ul class="Filters-contentInnerList">
									<li
										class="Filters-contentInnerCell d-flex flex-row mt-1"
										v-for="(subItem, itemsIndex) in item.items"
										:key="`filter-cell-${index}-${itemsIndex}`"
										:class="{
											checked: item.type === 'single' && isFilterSelected(index, itemsIndex),
											disabled: subItem.labelClass === 'disabled'
										}"
										@click="onItemClick(item.type, item.key, subItem.value, index, itemsIndex)"
									>
										<span
											class="Filters-contentInnerCellIcon"
											:class="{ checked: isFilterSelected(index, itemsIndex) }"
											v-if="item.type === 'multi'"
										>
											<slot name="selected-icon" v-if="$slots['selected-icon']" />
											<i v-else class="icon ion-md-checkmark" />
										</span>
										<span
											:class="{ 'ml-2': item.type === 'multi' }"
											:data-test-id="`Filters_${subItem.title}`"
											>{{ subItem.title }}</span
										>
									</li>
								</ul>
							</section>
						</template>
					</section>
				</OfsPanel>
			</b-collapse>
		</div>
	</div>
</template>

<script>
import _ from 'lodash';
import { OfsPanel } from '@workflow-solutions/ofs-vue-layout';
import find from 'lodash/find';
import findIndex from 'lodash/findIndex';
import concat from 'lodash/concat';
import uniqWith from 'lodash/uniqWith';
import isEqual from 'lodash/isEqual';
import isEmpty from 'lodash/isEmpty';
import debounce from 'lodash/debounce';
import clickOutside from '../directives/clickOutside';

export default {
	name: 'Filters',
	props: {
		filters: {
			type: Array,
			default: () => []
		},
		label: {
			type: String,
			default: ''
		},
		disabled: {
			type: Boolean,
			default: false
		}
	},
	components: {
		OfsPanel
	},
	directives: {
		clickOutside
	},
	data() {
		return {
			selectedFilters: null,
			areFiltersLockedForSelection: false,
			areFiltersVisible: false
		};
	},
	mounted() {
		this.selectedFilters = this.selectedFiltersOnRender;
	},
	computed: {
		selectedFiltersOnRender() {
			return _.flatMap(JSON.parse(_.get(this.$route, 'query.filters', '{}')));
		},
		areSelectedFiltersEmpty() {
			return isEmpty(this.selectedFilters);
		},
		areFiltersEmpty() {
			return isEmpty(this.filters);
		}
	},
	watch: {
		selectedFilters(newVal, oldVal) {
			if (!_.isNull(oldVal)) {
				this.$emit('filters-changed', this.selectedFilters);
				this.areFiltersLockedForSelection = true;
			}
		}
	},
	methods: {
		getFilterClasses(index, { cssClass }) {
			if (index === 0) {
				return [cssClass, 'pr-2'];
			}

			return [cssClass, 'pl-2', 'borderLeft'];
		},
		onFiltersClick() {
			this.areFiltersVisible = !this.areFiltersVisible;
			this.areFiltersLockedForSelection = false;
		},
		onRemoveFilter(index) {
			this.selectedFilters = this.selectedFilters.filter((f, idx) => idx !== index);
		},
		unlockItemClick: debounce(function handleItemClick() {
			this.areFiltersLockedForSelection = false;
		}, 300),
		onItemClick(type, key, value, index, itemsIndex) {
			if (this.areFiltersLockedForSelection) {
				return false;
			}

			this.unlockItemClick();
			const filter = { key, value, index, itemsIndex };
			const currentSelectedFilterIndex = findIndex(this.selectedFilters, filter);

			if (currentSelectedFilterIndex !== -1) {
				return this.onRemoveFilter(currentSelectedFilterIndex);
			}

			if (type === 'single') {
				this.selectedFilters = this.selectedFilters.filter(f => f.key !== key).concat(filter);
			} else {
				this.selectedFilters = uniqWith(concat(this.selectedFilters, filter), isEqual);
			}

			return true;
		},
		isFilterSelected(index, itemsIndex) {
			return find(this.selectedFilters, { index, itemsIndex });
		},
		onClearFilters() {
			this.selectedFilters = [];
		},
		handleClickOutside() {
			this.areFiltersVisible = false;
		},
		isFilterVisible(filter) {
			if (_.isFunction(filter.visible)) return filter.visible();
			if (_.isBoolean(filter.visible)) return filter.visible;

			return true;
		}
	}
};
</script>

<style lang="scss">
@import '../styles/_variables.scss';

.Filters {
	font-size: 14px;
	display: flex;
	justify-content: space-between;
	align-items: center;

	&-dropdown {
		min-width: 100px;
		display: flex;
		justify-content: space-between;
		align-items: center;

		&Icon {
			transition: transform 0.15s;
			max-height: 18px;
		}

		&.rotated {
			border-color: $liteInfoColour;

			.Filters-dropdownIcon {
				transform: rotate(180deg);
			}
		}
	}

	&-content {
		z-index: 2;
		position: absolute;
		top: 100%;
		right: 0;

		&Inner {
			&Header,
			&Cell {
				padding: 0.3rem 0.8rem;
			}

			&Container {
				&.borderLeft {
					border-left: 1px solid #eaeff0;
				}
			}

			&List {
				list-style: none;
				padding: 0;
				margin: 0;
				max-height: 300px;
				overflow-y: auto;
			}

			&Header {
				font-weight: bold;
				color: $headersMutedColor;
				white-space: nowrap;
			}

			&Cell {
				cursor: pointer;
				white-space: nowrap;

				&.disabled {
					color: $mediumGreyColour;
				}

				&:hover,
				&.checked {
					color: #fff;
					background-color: $infoColour;
					border-radius: $borderRadius;
				}

				&:hover {
					.Filters-contentInnerCellIcon {
						color: rgba(255, 255, 255, 0.4);
					}

					.Filters-contentInnerCellIcon.checked {
						color: #fff;
					}
				}

				&Icon {
					color: rgba(0, 0, 0, 0.1);
					min-width: 0.8rem;

					&.checked {
						color: $infoColour;
					}
				}
			}
		}
	}

	&-selected {
		display: flex;
		align-items: center;

		&Item {
			padding: 0.2rem 0.4rem;
			border-radius: 1.8 * $borderRadius;
			background-color: $extraLiteInfoColour;
			border: 1px solid $liteInfoColour;

			span {
				white-space: nowrap;
			}

			&CloseIcon {
				color: $greyColour;
				cursor: pointer;
			}
		}
	}

	&-rightContent {
		position: relative;
		flex: 1;
		display: flex;
		justify-content: flex-end;
	}
}
</style>
