import Vue from 'vue';
import _trim from 'lodash/trim';
import _get from 'lodash/get';
import localStorage from '../lib/localStorage';

const setupMiddleware = store => {
	Vue.$ofsCrud.addMiddleware('post', async (args, next) => {
		const { status } = args.response || {};

		// If request configured to bypass logout - ignore status checks
		if (args.config && args.config.preventLogout) {
			next();
		} else {
			if ([401].includes(status)) {
				await store.dispatch('auth/logout');
				await store.dispatch('account/changeAccount', null);
				window.location.href = `${window.$config.hpIdLogout}&auth_error=1`;
			}

			next();
		}
	});

	Vue.$ofsCrud.addMiddleware('pre', (args, next) => {
		const token = _get(store, 'state.auth.token');
		const accountId = _get(store, 'state.account.currentAccount._id');

		if (args.replaceHeaders) {
			return next();
		}

		const isAuthRequest = args.transport && args.transport.name === 'auth';
		const isConnectRequest = args.transport && args.transport.name === 'connect';

		args.headers = { Authorization: `Bearer ${token}` };

		if (accountId) {
			args.headers['x-oneflow-accountid'] = accountId;
		}

		if (!isAuthRequest) {
			// local enviroments
			const testUserId = localStorage.get('test-userId');
			if (testUserId) {
				args.headers['x-consumer-custom-id'] = testUserId;
			}

			// local enviroments
			const testAccounts = localStorage.get('test-accounts');
			if (testAccounts && !isConnectRequest) {
				const aclGroupsHeader = _trim(
					testAccounts.split(',').reduce((acc, a) => `${acc},accountId:${a}`, ''),
					','
				);
				args.headers['x-consumer-groups'] = aclGroupsHeader;
			}
		}

		return next();
	});
};

export default setupMiddleware;
