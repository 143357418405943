<template>
	<section class="MenuControls">
		<AccountSwitcher
			:currentAccount="currentAccount"
			:accounts="accountOptions"
			:onAccountSelection="onAccountChange"
		>
			<span slot="current-account-text">{{ $t('Account') }}: {{ currentAccount.name }}</span>
		</AccountSwitcher>

		<b-dropdown
			size="sm"
			right
			variant="link"
			class="MenuControls-userDropdown"
			ref="userDropdown"
			@hide="onDropdownHide"
		>
			<template slot="button-content">
				<font-awesome-icon icon="user" name="user" class="MenuControls-userIcon" />
			</template>
			<b-dropdown-item :href="userPath">{{ $t('User Profile') }}</b-dropdown-item>
			<button
				role="menuitem"
				@click="onLanguagesClick"
				class="dropdown-item MenuControls-userDropdown-languageMenuItem"
			>
				{{ $t('Languages') }}
				<div
					role="menu"
					class="dropdown-menu"
					:x-placement="languagesPlacement"
					:class="{ show: showLanguages }"
				>
					<button
						role="menuitem"
						type="button"
						class="dropdown-item"
						:key="lang.code"
						v-for="lang in languages"
						@click="onLanguagesSelection(lang)"
					>
						{{ $t(lang.name) }}
					</button>
				</div>
			</button>
			<b-dropdown-divider></b-dropdown-divider>
			<b-dropdown-item
				href="https://hpsiteflow.com/docs/siteflow/siteflow-open-source-software.html"
				target="_blank"
			>
				{{ $t('Open Source Software') }}
			</b-dropdown-item>
			<button role="menuitem" type="button" class="dropdown-item" @click="onLogoutClick">
				{{ $t('Sign Out') }}
			</button>
		</b-dropdown>
	</section>
</template>

<script type="text/javascript">
import { mapGetters, mapActions } from 'vuex';
import { sortBy, get } from 'lodash';
import { AccountSwitcher } from '@workflow-solutions/ofs-vue-layout';

export default {
	props: {
		languagesPlacement: String
	},
	components: {
		AccountSwitcher
	},
	data() {
		return {
			showLanguages: false
		};
	},
	computed: {
		...mapGetters('auth', ['user']),
		...mapGetters({
			languages: 'lang/languages',
			currentAccount: 'account/currentAccount',
			accounts: 'account/accounts',
			vars: 'account/vars'
		}),
		accountOptions() {
			return sortBy(this.accounts, 'name');
		},
		userPath() {
			if (!this.userId) {
				return null;
			}

			const route = this.$router.resolve({ name: 'user.details', params: { id: this.userId } });

			if (route) {
				return route.href;
			}

			return '';
		},
		userId() {
			return get(this.vars, 'userId');
		}
	},
	methods: {
		...mapActions({
			setCurrentAccount: 'account/setCurrentAccount',
			logout: 'auth/logout',
			setLanguage: 'lang/setLanguage'
		}),
		onAccountChange(account) {
			this.setCurrentAccount(account);
			this.$nextTick(() => {
				window.location.href = '/';
			});
		},
		onLanguagesClick(e) {
			e.stopPropagation();
			this.showLanguages = !this.showLanguages;
		},
		async onLanguagesSelection(lang) {
			this.$refs.userDropdown.hide();
			await this.setLanguage({ lang: lang.code });
		},
		onDropdownHide() {
			this.showLanguages = false;
		},
		onSetHomepageClick() {
			this.$pageService.setHomepage();
		},
		async onLogoutClick() {
			this.setCurrentAccount(null);
			await this.logout();
			if (window.$config?.hpIdLogout) {
				window.location.href = window.$config.hpIdLogout;
			} else {
				this.$router.push('/login');
			}
		}
	}
};
</script>

<style lang="scss" type="text/scss">
.MenuControls {
	&-userIcon {
		width: 11px;
		height: 14px;
	}

	.AccountSwitcher {
		display: flex;
		align-items: center;

		&-current {
			margin-right: 10px;
		}

		.form-control {
			width: 100%;
			height: 34px;
			padding: 6px 12px;
			background-color: #fff;
			border-radius: 2px 2px 0 0;
			border: 1px solid #ccc;
		}
	}

	&-userDropdown {
		.dropdown-toggle {
			display: flex;
			align-items: center;

			&:after {
				margin-left: 2px;
				vertical-align: middle;
				border-top: 4px dashed;
				border-right: 4px solid transparent;
				border-left: 4px solid transparent;
			}
		}

		&-languageMenuItem {
			position: relative;

			& > .dropdown-menu {
				left: -164px;
				top: -2px;
			}
		}
	}
}
</style>
