<template>
	<b-modal
		:title="$t('Shipment Confirmation')"
		:visible="visible"
		:ok-title="$t('Confirm')"
		:cancel-title="$t('Cancel')"
		@ok="$emit('ok')"
		@hide="$emit('hide')"
		static
		body-class="ShipmentConfirmationModal"
	>
		<section class="ShipmentConfirmationModal-icon text-center mt-2 mb-3">
			<img src="../../../../../../public/images/shipment-confirmation.svg" />
		</section>
		<p class="ShipmentConfirmationModal-body text-center">
			{{$t('You are about to set this shipment as shipped.')}}
			<br />
			{{$t('Do you wish to continue?')}}
		</p>
	</b-modal>
</template>

<script>
export default {
	name: 'ShipmentConfirmationModal',
	props: {
		visible: {
			type: Boolean,
			default: false
		}
	}
};
</script>

<style lang="scss">
@import '../../../../../styles/shared';

.ShipmentConfirmationModal {
	&-body {
		font-size: $of-font-size-normal;
		color: $modalBodyColor;
	}
}
</style>
