<template>
	<DefaultLayout class="UserDetails">
		<b-form novalidate>
			<ofs-panel :isPadded="false" class="UserDetails-form">
				<ContentHeader :title="`${$t('User Details')}: ${userName}`" class="mb-3">
					<b-button variant="primary" @click="handleResendInvite">{{ $t('Resend Invite') }}</b-button>
					<b-button variant="primary" @click="handleResetPassword" class="ml-2">{{
						$t('Reset Password')
					}}</b-button>
				</ContentHeader>
				<SmartLoader :isLoading="isLoading">
					<b-container fluid class="UserDetails-inner">
						<b-row class="m-0">
							<b-col class="d-flex flex-column" cols="12" lg="6">
								<h5 class="Form-header">{{ $t('User Profile') }}</h5>
								<b-form-row class="Form-row m-0">
									<of-form-input
										:horizontal="false"
										name="id"
										type="text"
										:label="$t('User ID')"
										:placeholder="$t('User ID')"
										data-test-id="user-details-userid"
										class="w-100 m-0"
										disabled
										required
									/>
								</b-form-row>
								<b-form-row class="Form-row m-0 mt-2">
									<of-form-input
										name="username"
										type="text"
										:horizontal="false"
										:label="$t('User Name')"
										:placeholder="$t('User Name')"
										data-test-id="user-details-username"
										class="w-100 m-0"
										disabled
										required
									/>
								</b-form-row>
								<b-form-row class="Form-row m-0 mt-2">
									<of-form-input
										:horizontal="false"
										name="name"
										type="text"
										:label="$t('Name')"
										:placeholder="$t('Name')"
										data-test-id="user-details-name"
										class="w-100 m-0"
										required
									/>
								</b-form-row>
								<b-form-row class="Form-row m-0 mt-2">
									<of-form-input
										:horizontal="false"
										name="email"
										type="email"
										:label="$t('Email')"
										:placeholder="$t('Email')"
										data-test-id="user-details-email"
										class="w-100 m-0"
										disabled
										required
									/>
								</b-form-row>
							</b-col>
							<b-col class="d-flex flex-column" cols="12" lg="6">
								<h5 class="Form-header mt-3 mt-lg-0">{{ $t('Account Roles') }}</h5>
								<b-form-row class="Form-row m-0">
									<b-col class="pl-0" cols="5">
										<of-form-select
											:horizontal="false"
											name="selectedAccount.name"
											:label="$t('Account')"
											:options="localAccountsOptions"
											data-test-id="selected-account"
											class="w-100 m-0"
											required
										/>
									</b-col>
									<b-col cols="5">
										<of-form-select
											:horizontal="false"
											name="selectedAccount.role"
											:label="$t('Role')"
											data-test-id="selected-role"
											:options="roleOptions"
											class="w-100 m-0"
										/>
									</b-col>
									<b-col class="pr-0 d-flex" cols="2">
										<div class="mt-auto">
											<b-button
												v-b-tooltip.hover
												:title="$t('Add user to account')"
												@click="handleAddUser"
												class="UserDetails-addButton"
												>{{ $t('Add') }}</b-button
											>
										</div>
									</b-col>
								</b-form-row>
								<b-form-row class="Form-row m-0 my-2">
									<b-table :items="userAccountRoles" :fields="accountRolesFields">
										<template v-slot:cell(token)="account">
											<b-button
												v-if="account.item && account.item.token"
												variant="link"
												v-b-tooltip.hover
												:title="$t('Click to see token details')"
												@click="handleOpenApiTokenModal(account.item)"
												data-test-id="view-api-token"
												class="UserDetails-apiToken p-0"
											>
												{{ $t('API Token') }}
											</b-button>
											<b-button
												v-else
												variant="link"
												class="text-muted p-0"
												v-b-tooltip.hover
												data-test-id="save-api-token"
												:title="$t('Save user to access API Token')"
											>
												{{ $t('API Token') }}
											</b-button>
										</template>
										<template v-slot:cell(remove)="account">
											<b-button
												variant="link"
												v-b-tooltip.hover
												:title="$t('Remove user role')"
												@click="handleRemoveUserRole(account.item)"
												class="UserDetails-apiRemove"
											>
												<i class="icon ion-ios-close-circle" />
											</b-button>
										</template>
									</b-table>
								</b-form-row>
							</b-col>
						</b-row>
					</b-container>
				</SmartLoader>
				<template slot="actions">
					<b-button variant="primary" @click="onSaveUserDetails">
						<span class="px-3">{{ $t('Save') }}</span>
					</b-button>
				</template>
			</ofs-panel>
		</b-form>
		<ApiTokenModal
			:showModal="isApiTokenModalVisible"
			:onClose="handleCloseApiTokenModal"
			:secret="currentApiSecret"
			:token="currentApiToken"
		/>
	</DefaultLayout>
</template>

<script>
import { ContentHeader, OfFormInput, OfFormSelect, OfsPanel, withForm } from '@workflow-solutions/ofs-vue-layout';
import Promise from 'bluebird';
import { mapGetters, mapActions } from 'vuex';
import get from 'lodash/get';
import map from 'lodash/map';
import find from 'lodash/find';
import { roleList } from '../../../lib/helpers';
import DefaultLayout from '../../../components/DefaultLayout';
import SmartLoader from '../../../components/SmartLoader';
import ApiTokenModal from './ApiTokenModal';

export default {
	name: 'UserDetails',
	mixins: [withForm('UserDetailsForm')],
	components: {
		DefaultLayout,
		ContentHeader,
		OfFormInput,
		OfFormSelect,
		OfsPanel,
		SmartLoader,
		ApiTokenModal
	},
	data() {
		return {
			roleOptions: roleList,
			isLoading: false,
			isApiTokenModalVisible: false,
			currentApiSecret: '',
			currentApiToken: ''
		};
	},
	computed: {
		...mapGetters({
			accountSettings: 'account/accountSettings',
			currentAccount: 'account/currentAccount',
			user: 'user/user',
			vars: 'account/vars',
			userAccountRoles: 'user/userAccountRoles'
		}),
		accountRolesFields() {
			return [
				{ key: 'name', label: this.$t('Account') },
				{ key: 'role', label: this.$t('Role') },
				{ key: 'token', label: this.$t('Token') },
				{ key: 'remove', label: '' }
			];
		},
		currentAccountName() {
			return get(this.currentAccount, 'name');
		},
		currentAccountId() {
			return get(this.currentAccount, '_id');
		},
		userId() {
			return get(this.$route, 'params.id');
		},
		userName() {
			return get(this.user, 'name');
		},
		userEmail() {
			return get(this.user, 'email');
		},
		accounts() {
			return get(this.vars, 'userAccountHash');
		},
		localAccountsOptions() {
			if (!this.userOwnedAccounts) {
				return [];
			}

			return map(this.userOwnedAccounts, account => account.name);
		},
		accountRole() {
			return find(this.userAccountRoles, { id: this.currentAccountId });
		},
		role() {
			return get(this.accountRole, 'role');
		},
		resendInvitationData() {
			return {
				domain: `account:${this.currentAccountName}:${this.role}`,
				email: this.userEmail,
				appName: 'sfl'
			};
		},
		userOwnedAccounts() {
			const { userAccountHash = {}, userAccountRoles = [] } = this.vars;
			const accountsOwnedByUser = [];
			_.each(userAccountHash, (account, name) => {
				const isOwnerInCurrentAccount =
					account._id === _.get(this, 'currentAccount._id') && userAccountRoles.includes('owner');
				// Show all accounts for admin and current account for owners
				if (isOwnerInCurrentAccount || account.role === 'admin') {
					userAccountHash[name] = account;
					accountsOwnedByUser.push({
						name: name,
						account: account,
						_id: account._id
					});
				}
			});
			return accountsOwnedByUser;
		}
	},
	watch: {
		userId() {
			this.fetchData();
		}
	},
	mounted() {
		this.fetchData();
	},
	beforeDestroy() {
		this.setUserAccountRoles({ roles: [] });
	},
	methods: {
		...mapActions({
			getVars: 'account/getVars',
			getUserById: 'user/get',
			updateUser: 'user/updateUser',
			resetPassword: 'auth/resetPassword',
			resendInvitation: 'user/resendInvitation',
			addUserAccountRole: 'user/addUserAccountRole',
			deleteUserAccountRole: 'user/deleteUserAccountRole',
			getUserAccountRoles: 'user/getUserAccountRoles',
			setUserAccountRoles: 'user/setUserAccountRoles'
		}),
		async fetchData() {
			try {
				this.isLoading = true;
				await Promise.all([
					this.getVars(),
					this.getUserById({ id: this.userId }),
					this.getUserAccountRoles({ userId: this.userId })
				]);
				this.updateFormData({ ...this.user, id: this.user._id });
				this.updateFormData({ accountRoles: this.userAccountRoles });
			} catch (err) {
				this.$notify({
					type: 'error',
					title: this.$t('Error'),
					text: this.$t('An error occurred while fetching user details')
				});
			} finally {
				this.isLoading = false;
			}
		},

		async onSaveUserDetails() {
			try {
				this.isLoading = true;
				await this.updateUser({ id: this.userId, payload: this.formData });
				this.updateFormData({ ...this.user, id: this.user._id });
				this.$notify({
					type: 'success',
					title: 'Success',
					text: this.$t('User details have been saved successfully')
				});
			} catch (err) {
				this.$notify({
					type: 'error',
					title: 'Error',
					text: this.$t('An error occurred while updating user details')
				});
			} finally {
				this.isLoading = false;
			}
		},
		async handleAddUser() {
			const name = get(this.formData, 'selectedAccount.name');
			const role = get(this.formData, 'selectedAccount.role');
			const id = this.accounts && this.accounts[name] && this.accounts[name]._id;

			if (name && role && id) {
				this.isLoading = true;

				try {
					await this.addUserAccountRole({ userId: this.userId, role: { id, role } });
					await this.getUserAccountRoles({ userId: this.userId });
					this.isLoading = false;

					this.$notify({
						type: 'success',
						title: this.$t('Success'),
						text: this.$t('The user role has been added successfully')
					});
				} catch (e) {
					this.isLoading = false;

					this.$notify({
						type: 'error',
						title: this.$t('Error'),
						text: this.$t('An error occurred while sending user invitation')
					});
				}
			}
		},
		handleRemoveUserRole(item) {
			this.$confirm({
				title: this.$t('Remove role'),
				body: this.$t('Are you sure you want to remove this role?'),
				onOk: async () => {
					try {
						this.isLoading = true;
						await this.deleteUserAccountRole({ roleId: item._id, userId: this.userId });
						await this.getUserAccountRoles({ userId: this.userId });
						this.isLoading = false;

						this.$notify({
							type: 'success',
							title: this.$t('Success'),
							text: this.$t('The user role has been removed successfully')
						});
					} catch (e) {
						this.$notify({
							type: 'error',
							title: this.$t('Error'),
							text: this.$t('There was an issue removing the user role')
						});
					}
				}
			});
		},
		async handleResendInvite() {
			try {
				await this.resendInvitation(this.resendInvitationData);
				this.$notify({
					type: 'success',
					title: this.$t('Success'),
					text: this.$t('User invitation has been sent successfully')
				});
			} catch (err) {
				this.$notify({
					type: 'error',
					title: this.$t('Error'),
					text: this.$t('An error occurred while sending user invitation')
				});
			}
		},
		async handleResetPassword() {
			try {
				await this.resetPassword(this.userEmail);
				this.$notify({
					type: 'success',
					title: this.$t('Success'),
					text: this.$t('Password reset email has been sent')
				});
			} catch (err) {
				this.$notify({
					type: 'error',
					title: this.$t('Error'),
					text: this.$t('An error occurred while reseting password')
				});
			}
		},
		handleOpenApiTokenModal({ token, secret }) {
			this.isApiTokenModalVisible = true;
			this.currentApiSecret = secret;
			this.currentApiToken = token;
		},
		handleCloseApiTokenModal() {
			this.isApiTokenModalVisible = false;
			this.currentApiSecret = '';
			this.currentApiToken = '';
		}
	}
};
</script>

<style lang="scss">
@import '../../../styles/_variables.scss';

.UserDetails {
	max-width: 1160px;

	&-inner {
		margin: 0 0 1rem;
	}

	&-apiToken {
		cursor: pointer;

		&:hover {
			text-decoration: underline;
		}
	}

	&-apiRemove {
		color: $greyColour;
		cursor: pointer;
	}

	table {
		th {
			font-size: 0.8em;
			padding-bottom: 0.3em;
			color: $darkGreyColour;
		}

		td {
			&[aria-colindex='4'] {
				text-align: center;
			}
		}
	}
}
</style>
