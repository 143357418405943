<template>
	<ofs-badge v-if="status" :status="status" :text="getBadgeStatusText(status)" />
</template>

<script>
import { OfsBadge } from '@workflow-solutions/ofs-vue-layout';

export default {
	props: ['status'],
	components: { OfsBadge },
	methods: {
		getBadgeStatusText(status) {
			const variants = {
				success: this.$t('success'),
				live: this.$t('live'),
				cancelled: this.$t('cancelled'),
				shipped: this.$t('shipped'),
				complete: this.$t('complete'),
				pending: this.$t('pending'),
				checked: this.$t('checked'),
				started: this.$t('started'),
				dataready: this.$t('dataready'),
				printready: this.$t('printready'),
				error: this.$t('error')
			};

			return variants[status.toLowerCase()];
		}
	}
};
</script>
