<template>
	<b-modal
		:title="$t('Shipping Documentation')"
		:visible="visible"
		:ok-title="$t('Download')"
		:cancel-title="$t('Print')"
		@ok="fetchAndDownloadDoc"
		@cancel="fetchAndPrintDoc"
		@show="onShow"
		@hide="$emit('hide')"
		static
		body-class="ShipmentDocumentationModal"
	>
		<section class="ShipmentDocumentationModal-icon text-center mt-2 mb-3">
			<img src="../../../../../../public/images/delivery-note.svg" />
		</section>
		<p class="ShipmentDocumentationModal-body text-center">
			{{$t('Order shipment processed successfully.')}}
			<br />
			{{$t('Please print and attach the shipping document to the shipment.')}}
		</p>
	</b-modal>
</template>

<script>
import axios from 'axios';
import { mapActions } from 'vuex';
import { $t } from '../../../../../vuex';
import { downloadFileByUrls as download } from '../../../../../lib/helpers';

export default {
	name: 'ShipmentDocumentationModal',
	props: {
		visible: {
			type: Boolean,
			default: false
		},
		shippingDocFileId: {
			type: String,
			default: ''
		}
	},
	data() {
		return {
			printJS: null
		};
	},
	methods: {
		...mapActions({
			getFile: 'file/get'
		}),
		async onShow() {
			this.printJS = await import('print-js').then(r => r.default);
		},
		async fetchAndDownloadDoc() {
			try {
				const { fetchUrl } = await this.getFile({ id: this.shippingDocFileId });
				const { data } = await axios.get(fetchUrl, { responseType: 'blob' });
				const link = document.createElement('a');
				link.style.display = 'none';
				link.target = '_blank';
				link.href = window.URL.createObjectURL(new Blob([data], { type: 'application/pdf' }));
				link.download = `${this.shippingDocFileId}.pdf`;
				this.$el.appendChild(link);
				link.click();

				// Cleanup
				window.URL.revokeObjectURL(link.href);
				this.$el.removeChild(link);
			} catch (err) {
				this.$notify({
					type: 'error',
					title: $t('Error'),
					text: $t('An error occurred while downloading a document')
				});
			}
		},
		async fetchAndPrintDoc() {
			try {
				const { fetchUrl } = await this.getFile({ id: this.shippingDocFileId });
				const { data } = await axios.get(fetchUrl, { responseType: 'blob' });
				const pdfUrlToPrint = window.URL.createObjectURL(new Blob([data], { type: 'application/pdf' }));
				this.printJS(pdfUrlToPrint);

				// Cleanup
				window.URL.revokeObjectURL(pdfUrlToPrint);
			} catch (err) {
				this.$notify({
					type: 'error',
					title: $t('Error'),
					text: $t('An error occurred while printing a document')
				});
			}
		}
	}
};
</script>

<style lang="scss">
@import '../../../../../styles/shared';

.ShipmentDocumentationModal {
	&-body {
		font-size: $of-font-size-normal;
		color: $modalBodyColor;
	}
}
</style>
