<template>
	<div class="page" v-if="order">
		<template v-for="item in items">
			<template v-for="component in item.components">
				<div class="page" :key="component._id" v-if="isComponentVisible(component._id)">
					<header class="header">
						<barcode :text="component.barcode" class="header__barcode" :wscale="3" :hscale="2" />
						<aside class="header__aside">
							<h1 class="header__title" v-text="order.order.orderData.sourceOrderId"></h1>
							<h2 class="header__subtitle">
								{{ item.sourceItemId }} <span class="highlight">{{ component.code }}</span>
							</h2>
							<h2 class="header__subtitle">
								{{ $t('Quantity:') }} <span class="header__quantity">{{ item.quantity }}</span>
							</h2>
						</aside>
					</header>
					<div class="content">
						<job-shipment
							:item="item"
							:file="getFileForComponent(component)"
							:component="component"
							:order="order.order"
							:shipment="getShipmentForItem(item)"
							:shipments="shipments"
						></job-shipment>
						<job-component
							:item="item"
							:file="getFileForComponent(component)"
							:component="component"
							:order="order.order"
							:shipment="getShipmentForItem(item)"
							:shipments="shipments"
						></job-component>
					</div>
				</div>
			</template>
		</template>
	</div>
</template>

<script>
import _ from 'lodash';
import { mapActions } from 'vuex';
import JobComponent from './Component';
import JobShipment from './Shipment';
import Barcode from '../Barcode';

export default {
	props: {
		orderId: {
			required: true,
			type: String
		},
		componentIds: {
			type: Array,
			default: () => []
		}
	},
	components: {
		Barcode,
		JobComponent,
		JobShipment
	},
	data() {
		return {
			order: null,
			shipments: null
		};
	},
	mounted() {
		this.fetchData();
	},
	watch: {
		async orderId() {
			this.fetchData();
		}
	},
	computed: {
		files() {
			return _.get(this.order, 'files', []);
		},
		items() {
			let items = [];

			if (this.shipments) {
				this.shipments.forEach(shipment => {
					items = [...items, ...shipment.items];
				});
			}

			return items;
		}
	},
	methods: {
		...mapActions({
			getShipmentsByOrderId: 'order/getShipmentsByOrderId',
			getOrderDetails: 'order/getOrderDetails',
			generateBarcode: 'barcode/generate'
		}),
		async fetchData() {
			this.order = await this.getOrderDetails(this.orderId);
			const shipmentResponse = await this.getShipmentsByOrderId(this.orderId);
			this.shipments = shipmentResponse.data;
			this.$emit('loaded', this.orderId);
		},
		getShipmentForItem(item) {
			return _.find(this.shipments, { shipmentIndex: item.shipmentIndex });
		},
		getFileForComponent(component) {
			return _.find(this.files, { _id: component.fileId });
		},
		isComponentVisible(componentId) {
			return this.componentIds.length === 0 || this.componentIds.includes(componentId);
		}
	}
};
</script>

<style lang="scss"></style>
