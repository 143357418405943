<template>
	<section class="ContentComponent d-flex p-3">
		<div
			role="button"
			class="ContentComponent-thumbnail px-2 d-flex align-items-center"
			:class="{ 'ContentComponent-thumbnail--clickable': componentFile }"
			:data-img="src"
			:style="{ backgroundImage: `url(${src})` }"
			data-test="Component_FileButton"
			@click="showFileDetailModal"
		>
			<div v-if="component.localFile" class="d-flex flex-column align-items-center">
				<v-icon class="ContentComponent-thumbnail--local" name="custom-hdd" scale="2" />
				<span class="ContentComponent-thumbnailText mt-1">{{ $t('Local File') }}</span>
			</div>
		</div>
		<div class="ContentComponent-info pl-3 d-flex flex-column">
			<div
				class="m-0 d-flex flex-row align-items-center"
				:class="{ error: componentFileErrorsCount, 'py-2 pr-2': componentFileErrorsCount }"
			>
				<div class="d-flex flex-column">
					<span class="ContentComponent-infoLabel text-capitalize">{{ component.code }}</span>
					<section class="ContentComponent-barcode" v-if="barcode">
						<span class="ContentComponent-barcodeLabel">{{ $t('Barcode') }}</span>
						<span class="ContentComponent-barcodeText">{{ barcode }}</span>
					</section>
				</div>
				<b-button
					v-if="componentFileErrorsCount"
					size="sm"
					class="ContentComponent-refetchButton ml-auto"
					@click="onRefetchFile"
				>
					{{ $t('Refetch') }}
				</b-button>
			</div>
			<ComponentErrors :errors="componentFileErrors" class="mt-1" />
			<div class="mt-2">
				<b-badge
					pill
					variant="light"
					class="ContentComponent-infoAttribute px-2 py-1 mr-1 mb-1"
					v-for="(attr, index) in componentAttributes"
					:key="`componentAttribute-${index}`"
					v-b-tooltip.hover
					:title="attr.text"
				>
					<span class="ContentComponent-infoAttributeLabel text-capitalize" v-if="attr.label">
						{{ attr.label }}:
					</span>
					<span class="ContentComponent-infoAttributeText text-capitalize">{{ attr.text }}</span>
				</b-badge>
			</div>
		</div>
		<file-detail-modal
			v-if="componentFile && isFileDetailModalVisible"
			:show="isFileDetailModalVisible"
			:onClose="closeFileDetailModal"
			:component="component"
			:componentFile="componentFile"
			:shipmentStatus="shipmentStatus"
		/>
	</section>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import _ from 'lodash';
import FileDetailModal from '../../../../components/FileDetail';
import ComponentErrors from '../../../../components/ComponentFileErrors';

export default {
	name: 'ContentComponent',
	components: {
		FileDetailModal,
		ComponentErrors
	},
	props: {
		component: {
			type: Object,
			default: () => ({})
		},
		refresh: {
			type: Function,
			default: () => {}
		},
		shipmentStatus: {
			type: String,
			default: ''
		}
	},
	data() {
		return {
			isFileDetailModalVisible: false
		};
	},
	computed: {
		...mapGetters({
			order: 'order/order'
		}),
		componentFileId() {
			return _.get(this.component, 'fileId');
		},
		componentAttributes() {
			const attributes = _.get(this.component, 'attributes');

			if (!attributes) {
				return [];
			}

			return _.map(attributes, (attribute, index) => {
				if (_.isBoolean(attribute)) {
					return { text: index };
				}

				return { label: index, text: attribute };
			});
		},
		componentFile() {
			return _.find(this.files, { _id: this.componentFileId });
		},
		componentFileErrors() {
			const conmponentsFileStatus = _.get(this.componentFile, 'status');

			if (conmponentsFileStatus !== 'failed') {
				return [];
			}

			return _.get(this.componentFile, 'versions[0].errorList');
		},
		componentFileErrorsCount() {
			return _.size(this.componentFileErrors);
		},
		files() {
			return _.get(this.order, 'files', []);
		},
		src() {
			return _.get(this.componentFile, 'thumbnailUrl');
		},
		barcode() {
			return _.get(this.component, 'barcode');
		}
	},
	methods: {
		...mapActions({
			refetchFile: 'order/refetchFile'
		}),
		async onRefetchFile() {
			try {
				await this.refetchFile(this.componentFileId);
				this.refresh();
			} catch (err) {
				this.$notify({
					type: 'error',
					title: this.$t('Error'),
					text: this.$t('An error occurred while refetching a component file')
				});
			}
		},
		showFileDetailModal() {
			if (this.componentFile) this.isFileDetailModalVisible = true;
		},
		closeFileDetailModal() {
			this.isFileDetailModalVisible = false;
		}
	}
};
</script>

<style lang="scss">
@import '../../../../styles/variables';

.ContentComponent {
	&-thumbnail {
		background-color: $extraLiteInfoColour;
		border: 1px solid $cardBorderColor;
		border-radius: $borderRadius;
		min-width: 80px;
		height: 100px;
		padding: 0.5rem;
		background-size: contain;
		background-repeat: no-repeat;
		background-position: 50%;
		display: flex;
		align-items: center;
		justify-content: center;

		&--local {
			opacity: 0.7;
		}

		&--clickable {
			cursor: pointer;
		}

		&Text {
			font-size: 0.8rem;
			font-weight: bold;
		}
	}

	&-info {
		font-size: 0.95em;
		flex: 1;

		.error {
			padding-left: 0.3rem;
			background-color: $colourErrorComponentBackground;
			color: $colourErrorComponent;
			border-left: 3px solid $colourErrorComponentBorder;
			border-radius: 0 $smallBorderRadius $smallBorderRadius 0;
		}

		&Label {
			font-weight: bold;
			margin-right: 0.5rem;
		}

		&Attribute {
			max-width: 220px;
			overflow: auto;
			color: #58666e;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
			font-size: 0.85em;
			border: 1px solid $cardBorderColor;

			&Label {
				color: $greyColour;
			}
		}
	}
}
</style>
