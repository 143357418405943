<template>
	<DefaultLayout v-if="!isEmbedded">
		<ShipmentShippedForm />
	</DefaultLayout>
	<section class="ShipmentShipped ShipmentShipped--embedded" v-else>
		<div class="ShipmentShipped-top">
			<b-button variant="link" :to="{ name: 'orders.list' }" size="lg">{{ $t('Skip >>') }}</b-button>
		</div>
		<div class="ShipmentShipped-content">
			<ShipmentShippedForm />
		</div>
	</section>
</template>

<script>
import DefaultLayout from '../../components/DefaultLayout';
import ShipmentShippedForm from './ShipmentShippedForm';

export default {
	components: {
		DefaultLayout,
		ShipmentShippedForm
	},
	computed: {
		isEmbedded() {
			return this.$route.query.embedded;
		}
	}
};
</script>

<style lang="scss">
.ShipmentShipped {
	&--embedded {
		display: flex;
		width: 100vw;
		height: 100vh;
		flex-direction: column;
		.ShipmentShipped-top {
			width: 100%;
			flex-shrink: 1;
			text-align: right;
		}
		.ShipmentShipped-content {
			width: 100%;
			flex-grow: 1;
			overflow: auto;
		}
	}
}
</style>
