<template>
	<form class="BarcodeScan" @submit.prevent="scanBarcode" v-if="showInput">
		<img src="../../public/images/scanner.svg" />
		<input
			v-model="barcode"
			class="BarcodeScan__input form-control form-control-sm"
			:placeholder="$t('Enter Barcode')"
			data-test-id="Production_EnterBarcode"
			size="sm"
		/>
	</form>
</template>

<script>
import BarcodeScanner from '../mixins/barcodeScanner';

export default {
	mixins: [BarcodeScanner],
	props: {
		onScan: {
			type: Function,
			required: true
		},
		resetAfterScan: {
			type: Boolean,
			default: true
		},
		showInput: {
			type: Boolean,
			default: false
		}
	},
	methods: {
		async scanBarcode() {
			await this.onScan(this.barcode);

			if (this.resetAfterScan) {
				this.barcode = '';
			}
		}
	}
};
</script>

<style lang="scss">
.BarcodeScan {
	display: flex;
	align-items: center;

	img {
		margin-right: 10px;
	}

	&__input {
		min-width: 150px;
	}
}
</style>
