<template>
	<section class="OrderDetails pb-3 mb-3">
		<b-row class="m-0">
			<b-col class="pl-0 d-flex flex-column">
				<span class="OrderDetails-label text-uppercase">{{ $t('Order Id') }}</span>
				<span class="OrderDetails-value" data-test-id="orderId">{{ orderId }}</span>
			</b-col>
			<b-col class="pr-0 d-flex flex-column">
				<span class="OrderDetails-label text-uppercase">{{ $t('Status') }}</span>
				<order-status :status="status" data-test-id="orderStatus"></order-status>
			</b-col>
		</b-row>
		<b-row class="m-0 mt-3">
			<b-col class="pl-0 d-flex flex-column">
				<span class="OrderDetails-label text-uppercase">{{ $t('From') }}</span>
				<span class="OrderDetails-value text-capitalize" data-test-id="orderSource">{{
					customerName || sourceName
				}}</span>
			</b-col>
			<b-col class="pr-0 d-flex flex-column">
				<span class="OrderDetails-label text-uppercase">{{ $t('Shipments') }}</span>
				<span class="OrderDetails-value">{{ shipmentsCount }}</span>
			</b-col>
		</b-row>
		<b-row class="m-0 mt-3">
			<b-col class="pl-0 d-flex flex-column">
				<span class="OrderDetails-label text-uppercase">{{ $t('Submitted') }}</span>
				<span class="OrderDetails-value">{{ submitted | duration }}</span>
			</b-col>
			<b-col class="pr-0 d-flex flex-column">
				<span class="OrderDetails-label text-uppercase">{{ $t('Due Out') }}</span>
				<span class="OrderDetails-value">{{ dueOut | formatDate }}</span>
			</b-col>
		</b-row>
	</section>
</template>

<script>
import { mapGetters } from 'vuex';
import _ from 'lodash';
import moment from 'moment';
import OrderStatus from '../../../components/OrderStatus';
import { duration } from '../../../lib/filters';

export default {
	name: 'OrderDetails',
	filters: {
		duration,
		formatDate(date) {
			return moment(date).format('ddd, Do MMM, YYYY');
		}
	},
	components: {
		OrderStatus
	},
	computed: {
		...mapGetters({
			order: 'order/order'
		}),
		orderId() {
			return _.get(this.order, 'order.orderData.sourceOrderId');
		},
		customerName() {
			return _.get(this.order, 'order.orderData.customerName');
		},
		sourceName() {
			return _.get(this.order, 'order.source.name');
		},
		status() {
			return _.get(this.order, 'order.orderData.status');
		},
		shipments() {
			return _.get(this.order, 'shipments', []);
		},
		shipmentsCount() {
			return _.size(this.shipments);
		},
		submitted() {
			return _.get(this.order, 'order.orderData.date');
		},
		dueOut() {
			return _.get(this.shipments, '[0].shipByDate');
			// return _.get(this.order, 'order.orderData.slaTimestamp');
		}
	}
};
</script>

<style lang="scss">
@import '../../../styles/variables';

.OrderDetails {
	font-size: 0.95em;
	border-bottom: 1px dashed $cardBorderColor;

	&-label {
		font-size: 0.85em;
		color: $greyColour;
	}

	&-badge {
		font-size: 0.72em;
		font-weight: bold;
	}
}
</style>
