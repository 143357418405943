<template>
	<aside class="Search__sidebar SearchSidebar" v-if="searchFacets && facets">
		<div v-for="(facets, facetCategory) in searchFacets" :key="facetCategory" class="SearchSidebar__section">
			<h3 class="SearchSidebar__title">{{ formatFacetName(facetCategory) }}</h3>
			<ul class="SearchSidebar__items">
				<li v-for="(count, facet) in facets" class="SearchSidebar__item" :key="facet">
					<pill
						:label="facet"
						:value="count"
						class="Pill--condensed"
						:identifier="`${facetCategory}:${facet}`"
						:selected="isSelected(`${facetCategory}:${facet}`)"
						:on-click="filterClicked"
					></pill>
				</li>
			</ul>
		</div>
	</aside>
</template>

<script>
import _ from 'lodash';
import { mapActions, mapGetters } from 'vuex';
import Pill from '../Pill';

export default {
	props: {
		onFilterChange: {
			required: true,
			type: Function
		}
	},
	components: {
		Pill
	},
	computed: {
		...mapGetters({
			filters: 'search/filters',
			searchFacets: 'search/facets'
		})
	},
	data() {
		return {
			facets: {
				// ORDERS
				status: this.$t('Status'),
				'dateIn.hour': this.$t('Date in Hour'),
				'dateIn.day': this.$t('Date in Day'),
				'dateIn.month': this.$t('Date in month'),
				'dateIn.year': this.$t('Date in Year'),
				'shipBy.day': this.$t('Ship by Day'),
				'shipBy.month': this.$t('Ship by Month'),
				'shipBy.year': this.$t('Ship by Year'),
				'shipTo.town': this.$t('Shipping Town'),
				'carrier.code': this.$t('Carrier Code'),
				'carrier.service': this.$t('Carrier Service'),

				// ENQUIRIES
				'createdDate.hour': this.$t('Creation Hour'),
				'createdDate.day': this.$t('Creation Day'),
				'createdDate.month': this.$t('Creation Month'),
				'createdDate.year': this.$t('Creation Year'),
				'lastUpdatedDate.hour': this.$t('Last update Hour'),
				'lastUpdatedDate.day': this.$t('Last update Day'),
				'lastUpdatedDate.month': this.$t('Last update Month'),
				'lastUpdatedDate.year': this.$t('Last update Year'),

				// CLIENTS
				town: this.$t('Town'),
				isoCountry: this.$t('ISO Country')
			}
		};
	},
	methods: {
		...mapActions({
			toggleFilter: 'search/toggleFilter'
		}),
		filterClicked(identifier) {
			this.toggleFilter(identifier);
			this.onFilterChange();
		},
		isSelected(identifier) {
			return _.includes(this.filters, identifier);
		},
		formatFacetName(facet) {
			return this.facets[facet] || facet;
		}
	}
};
</script>

<style></style>
