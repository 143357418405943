<template>
	<AsyncLoader :is-loading="isLoading">
		<b-modal
			:visible="visible"
			:title="$t('Edit Permissions')"
			modal-class="EditPermissionsModal"
			@show="handleShowModal"
			@hide="handleCloseModal"
		>
			<div>
				<img src="../../../../public/images/editPermissions.svg" />
				<div class="partner-info">
					{{ $t('Linked Account: ') }}
					<span class="partner-name">
						<b data-test-id="linkedAccountValue">{{ partnerName }}</b>
					</span>
				</div>
				<of-form-checkbox
					name="orderSubmission"
					:label-cols="0"
					:right-side-label="permissionTypes.orderSubmission.label"
					:description="permissionTypes.orderSubmission.description"
					:disabled="isOrderSubmissionPending"
					@input="handleOrderSubmissionPermission"
				>
				</of-form-checkbox>
				<of-form-checkbox
					name="outsourcing"
					:label-cols="0"
					:right-side-label="permissionTypes.outsourcing.label"
					:description="permissionTypes.outsourcing.description"
					:disabled="isOutsourcingPending"
					@input="handleOutsourcingPermission"
				>
				</of-form-checkbox>
			</div>
			<section slot="modal-footer">
				<b-btn v-t="'Close'" data-test-id="closeButton" class="mr-1" @click="handleCloseModal" />
			</section>
		</b-modal>
	</AsyncLoader>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { OfFormCheckbox, withForm } from '@workflow-solutions/ofs-vue-layout';
import { AsyncLoader } from '@workflow-solutions/siteflow-section-layout';
import { $t } from '../../../vuex';
import _ from 'lodash';

export default {
	components: {
		OfFormCheckbox,
		AsyncLoader
	},
	mixins: [withForm('EditPermissions')],
	props: {
		visible: {
			type: Boolean,
			default: false
		},
		partnerName: {
			type: String,
			default: ''
		},
		partnerId: {
			type: String,
			default: ''
		},
		accountLink: {
			type: Object,
			default: () => ({})
		}
	},
	data() {
		return {
			permissionTypes: {
				orderSubmission: {
					label: this.$t('Order submission'),
					description: $t(
						'This permission allows you and the account you are linked with \
						to send and receive orders to each other via our API'
					)
				},
				outsourcing: {
					label: this.$t('Outsourcing'),
					description: $t(
						'This permission allows you and the account you are \
						linked with to outsource paired product SKUs. \
						Order Submission permission is required to enable Outsourcing.'
					)
				}
			},
			isOrderSubmissionChecked: false,
			isOutsourcingChecked: false,
			isOrderSubmissionPending: false,
			isOutsourcingPending: false,
			isLoading: false
		};
	},
	computed: {
		...mapGetters({
			allPermissionRequests: 'linkcode/linkcodes'
		})
	},
	async created() {
		this.isLoading = true;
		await this.fetchData();
		this.isLoading = false;
	},
	methods: {
		...mapActions({
			addPermission: 'accountlink/addPermission',
			disablePermission: 'accountlink/disablePermission',
			getAllLinkcodes: 'linkcode/findAll'
		}),
		async fetchData() {
			await this.getAllLinkcodes({ query: { status: 'pending' } });
		},
		async handleShowModal() {
			await this.fetchData();
			this.isOrderSubmissionPending = this.isRequestExist(this.accountLink.id, 'ordersubmission');
			this.isOutsourcingPending = this.isRequestExist(this.accountLink.id, 'outsource');
			const permissions = this.accountLink.permissions.split(', ');
			if (permissions.indexOf('Order Submission') > -1) {
				this.isOrderSubmissionChecked = true;
			}
			if (permissions.indexOf('Outsourcing') > -1) {
				this.isOutsourcingChecked = true;
			}
			this.setCheckboxesData();
		},
		isRequestExist(accountId, permission) {
			return _.some(
				this.allPermissionRequests,
				request =>
					(request.accountDestinationId == accountId || request.accountId == accountId) &&
					request.permission == permission
			);
		},
		setCheckboxesData() {
			// checkbox is checked when permission already exists or when request is pending
			this.setFormData({
				orderSubmission: !!this.isOrderSubmissionChecked || this.isOrderSubmissionPending,
				outsourcing: !!this.isOutsourcingChecked || this.isOutsourcingPending
			});
		},
		handleOrderSubmissionPermission() {
			this.isOrderSubmissionChecked = !this.isOrderSubmissionChecked;
			this.handleChangePermission('ordersubmission');
		},
		handleOutsourcingPermission() {
			this.isOutsourcingChecked = !this.isOutsourcingChecked;
			this.handleChangePermission('outsource');
		},
		handleChangePermission(permission) {
			let isPermissionChecked = '';
			if (permission === 'ordersubmission') {
				isPermissionChecked = this.isOrderSubmissionChecked;
			} else if (permission === 'outsource') {
				isPermissionChecked = this.isOutsourcingChecked;
			}
			if (isPermissionChecked) {
				this.$bvModal
					.msgBoxConfirm(
						$t(
							'Are you sure you want to send a new permission invite? \
							This request will be pending until the other account responds.'
						),
						{
							title: $t('Add permission request'),
							okTitle: $t('Confirm'),
							cancelTitle: $t('Cancel'),
							centered: true
						}
					)
					.then(async confirmed => {
						if (confirmed) {
							await this.addPermission({ accountDestinationId: this.partnerId, permission: permission });
							if (permission === 'ordersubmission') {
								this.isOrderSubmissionPending = true;
							} else if (permission === 'outsource') {
								this.isOutsourcingPending = true;
							}
							this.$notify({ type: 'success', text: $t('Permission request added') });
						} else {
							this.setCheckboxesCheckedValue(permission, false);
							this.$notify({ type: 'info', text: $t('Permission request has not been added') });
						}
						this.setCheckboxesData();
					})
					.catch(err => {
						this.setCheckboxesCheckedValue(permission, false);
						this.setCheckboxesData();
						const message = $t(this.$errorUtils.displayError(err));
						this.$notify({ type: 'error ', text: message });
					});
			} else {
				this.$bvModal
					.msgBoxConfirm($t('Are you sure you want to disable this permission?'), {
						title: $t('Disable permission'),
						okTitle: $t('Confirm'),
						cancelTitle: $t('Cancel'),
						centered: true
					})
					.then(async confirmed => {
						if (confirmed) {
							await this.disablePermission({
								accountDestinationId: this.partnerId,
								permission: permission
							});
							this.$notify({ type: 'success', text: $t('Permission disabled') });
						} else {
							this.setCheckboxesCheckedValue(permission, true);
							this.$notify({ type: 'info', text: $t('Permission has not been disabled') });
						}
						this.setCheckboxesData();
					})
					.catch(err => {
						this.setCheckboxesCheckedValue(permission, true);
						this.setCheckboxesData();
						const message = $t(this.$errorUtils.displayError(err));
						this.$notify({ type: 'error ', text: message });
					});
			}
		},
		setCheckboxesCheckedValue(permission, val) {
			if (permission === 'ordersubmission') {
				this.isOrderSubmissionChecked = val;
			} else if (permission === 'outsource') {
				this.isOutsourcingChecked = val;
			}
		},
		handleCloseModal() {
			this.isOrderSubmissionChecked = false;
			this.isOutsourcingChecked = false;
			this.isOrderSubmissionPending = false;
			this.isOutsourcingPending = false;
			this.setCheckboxesData();
			this.$emit('update');
			this.$emit('hide');
		}
	}
};
</script>

<style lang="scss">
@import '../../../styles/shared';
.EditPermissionsModal {
	img {
		display: block;
		margin-left: auto;
		margin-right: auto;
		width: 25%;
	}

	.partner-info {
		margin: 15px 0;
		padding: 20px 10px;
		background-color: #f5f9fc;

		.partner-name {
			padding: 20px 10px;
		}
	}
}
</style>
