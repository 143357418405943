<template>
	<div id="app" v-if="ready" :data-version="version">
		<router-view />
		<notification />
		<confirmation-modal />
		<GettingStartedModal />
	</div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import _ from 'lodash';
import { Notification, ConfirmationModal } from '@workflow-solutions/ofs-vue-layout';
import GettingStartedModal from './components/help/GettingStartedModal';

export default {
	name: 'Connect',
	components: {
		Notification,
		ConfirmationModal,
		GettingStartedModal
	},
	data() {
		return {
			ready: false,
			version: process.env.VERSION
		};
	},
	async created() {
		if (!this.isAuthenticated && this.tempToken) {
			try {
				await this.loginHpId(this.tempToken); // Exchange tempToken
				await this.fetchBaseAccountData();
			} catch (err) {}
		} else if (this.isAuthenticated) {
			await this.fetchBaseAccountData();
		}

		// Scope Search Indexes
		this.setIndexes([
			{
				name: 'shipments',
				label: this.$t('Order'),
				labelPlural: this.$t('Orders')
			}
		]);

		this.$router.afterEach(this.redirectIfNotAuth);
		await this.loadLanguage({ lang: this.lang });
		this.ready = true;
	},
	async beforeCreate() {
		this.tempToken = _.get(this, '$router.currentRoute.query.token');
	},
	watch: {
		isAuthenticated(value) {
			if (value) {
				this.setupHourlyTokenRefresh();
			}
		}
	},
	computed: {
		...mapGetters({
			isAuthenticated: 'auth/isAuthenticated',
			availableAccounts: 'auth/availableAccounts',
			activeTopItem: 'menu/activeTopItem',
			activeSubmenu: 'menu/activeSubmenu',
			lang: 'lang/lang',
			vars: 'account/vars'
		}),
		userId() {
			return _.get(this.vars, 'userId');
		}
	},
	methods: {
		...mapActions({
			login: 'user/login',
			loginHpId: 'auth/loginHpId',
			setupHourlyTokenRefresh: 'auth/setupHourlyTokenRefresh',
			getVars: 'account/getVars',
			setIndexes: 'search/setIndexes',
			loadLanguage: 'lang/loadLanguage',
			fetchBaseAccountData: 'account/fetchBaseAccountData'
		}),
		redirectIfNotAuth() {
			if (!this.isAuthenticated && this.$route.name !== 'login') {
				this.$router.push('/login');
			}
		}
	}
};
</script>
