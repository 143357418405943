import Work from './Work';

const routes = [
	{
		path: '/work',
		component: { template: '<router-view/>' },
		children: [
			{
				name: 'work',
				path: '',
				component: Work,
				props: {
					status: 'live'
				}
			},
			{
				name: 'work.completed',
				path: 'completed',
				component: Work,
				props: {
					status: 'complete'
				}
			}
		]
	}
];

export default routes;
