<template>
	<DefaultLayout>
		<b-form novalidate @submit.prevent="onSave" class="form-horizontal">
			<ofs-panel class="TriggerCreateEdit">
				<ContentHeader :title="this.headerTitle" :noPadding="true" class="mb-3" />
				<SmartLoader :isLoading="isLoading">
					<div>
						<of-toggle name="active" :label="$t('Active')" />
						<of-form-input name="name" type="text" :label="$t('Name')" :placeholder="$t('Name')" required />
						<of-form-select
							name="triggerSourceId"
							:label="$t('Event')"
							:options="triggerEventsOptions"
							required
						/>
						<of-form-select name="type" :label="$t('Type')" :options="dropdownTypes" required />
						<of-multi-select name="templateId" :label="$t('Template')" :options="triggerTemplatesOptions" />
						<div v-if="formData && formData.type === 'email'" class="emailContainer">
							<of-form-input
								name="address"
								type="text"
								:label="$t('Address')"
								:placeholder="$t('Address')"
								required
							/>
							<of-toggle name="retry" :label="$t('Retry on failure')" />
							<of-toggle name="share" :label="$t('Share')" />
						</div>

						<div v-if="formData && formData.type === 'http'" class="httpContainer">
							<of-form-input
								name="address"
								type="text"
								:label="$t('Address')"
								:placeholder="$t('Address')"
								:show-errors="true"
								required
							/>
							<p v-if="isHttp" class="pending-info">
								{{
									$t(`It is strongly recommended that you use a secure HTTPS endpoint. This will ensure that
								any information sent to the endpoint is securely encrypted.`)
								}}
							</p>
							<of-form-select name="method" :label="$t('Method')" :options="dropdownMethods" />
							<of-toggle name="retry" :label="$t('Retry on failure')" />
							<of-toggle name="share" :label="$t('Share')" />
							<div class="form-row">
								<legend class="col-sm-3 col-form-legend d-flex justify-content-end align-items-center">
									<span class="TriggerCreateEdit-label">{{ $t('Request headers') }}</span>
								</legend>
								<div class="col-sm-9 mb-3">
									<table class="TriggerCreateEdit-table table table-sm">
										<thead>
											<tr>
												<th>{{ $t('Header') }}</th>
												<th>{{ $t('Value') }}</th>
											</tr>
										</thead>
										<tbody>
											<tr
												v-for="(header, index) in Object.keys(formData.headers || {})"
												:key="index"
											>
												<td>{{ header }}</td>
												<td>{{ formData.headers[header] }}</td>
												<td>
													<b-button
														@click="removeRequestHeader(header)"
														variant="danger"
														class="d-flex justify-content-center align-items-center"
													>
														<v-icon name="times" />
													</b-button>
												</td>
											</tr>
											<tr>
												<td>
													<of-form-input
														withoutLabel
														name="requestHeaderHeader"
														type="text"
														:placeholder="$t('Header')"
													/>
												</td>
												<td>
													<of-form-input
														withoutLabel
														name="requestHeaderValue"
														type="text"
														:placeholder="$t('Value')"
													/>
												</td>
												<td>
													<b-button
														@click="addRequestHeader"
														variant="success"
														class="d-flex justify-content-center align-items-center"
													>
														<v-icon name="plus" />
													</b-button>
												</td>
											</tr>
										</tbody>
									</table>
								</div>
							</div>
						</div>
						<of-multi-select
							:label="$t('Linked Accounts')"
							multiple
							label-by="name"
							track-by="_id"
							name="linkedAccountIds"
							:options="accountOptions"
						></of-multi-select>
					</div>
				</SmartLoader>
				<template slot="actions">
					<b-button variant="primary" type="submit" :disabled="!canSubmit">
						{{ mode === 'edit' ? $t('Save') : $t('Submit') }}
					</b-button>
					<b-button v-if="mode === 'edit'" @click="openModal" variant="info" class="ml-1">
						{{ $t('Test Trigger') }}
					</b-button>
					<b-button @click="backToList" variant="secondary" class="ml-1">{{ $t('Cancel') }}</b-button>
				</template>
			</ofs-panel>

			<b-modal v-model="showModal" :title="$t('Test Trigger')" size="lg">
				<p>
					<span>{{ $t('The following template will be sent.') }}</span>
					<span>{{
						$t('Feel free to edit the data in the handlebars before sending the test trigger.')
					}}</span>
				</p>
				<of-form-select name="templateId" :label="$t('Template')" :options="triggerTemplatesOptions" />

				<editor v-model="templateText" lang="json" theme="chrome" width="100%" height="400px" />

				<div slot="modal-footer">
					<b-button @click="showModal = false" variant="secondary">{{ $t('Cancel') }}</b-button>
					<b-button @click="runTestTrigger" variant="success" class="ml-2">{{ $t('Run Test') }}</b-button>
				</div>
			</b-modal>
		</b-form>
		<b-modal
			v-model="showModalWarning"
			size="md"
			:title="$t('Insecure HTTP Address')"
			:ok-title="$t('Save Anyway')"
			@ok="onSave(null, true)"
		>
			<p>
				{{
					$t(`The URL you have specified for this trigger is not using the HTTPS protocol.
							 Information sent via this trigger will not be encrypted.`)
				}}
			</p>
			<p class="mb-0">
				{{
					$t(`We strongly recommend that you use a secure endpoint for this trigger, this will ensure that
							any sensitive data sent by the trigger is encrypted.`)
				}}
			</p>
		</b-modal>
	</DefaultLayout>
</template>

<script>
import editor from 'vue2-ace-editor';
import 'vue-ace-editor/node_modules/brace/mode/json';
import 'vue-ace-editor/node_modules/brace/theme/chrome';
import { mapGetters, mapActions } from 'vuex';
import _ from 'lodash';
import Promise from 'bluebird';
import _set from 'lodash/fp/set';
import _omit from 'lodash/fp/omit';

import {
	ContentHeader,
	OfFormInput,
	OfFormSelect,
	OfMultiSelect,
	withForm,
	OfToggle,
	OfsPanel
} from '@workflow-solutions/ofs-vue-layout';
import DefaultLayout from '../../../components/DefaultLayout';
import SmartLoader from '../../../components/SmartLoader';
import { email, required, url } from 'vuelidate/lib/validators';

export default {
	name: 'TriggerCreateEdit',
	mixins: [withForm('TriggerCreateEditForm')],
	components: {
		DefaultLayout,
		ContentHeader,
		OfFormInput,
		OfFormSelect,
		OfMultiSelect,
		OfToggle,
		OfsPanel,
		SmartLoader,
		editor
	},
	data() {
		return {
			isLoading: false,
			dropdownTypes: [
				{ text: 'HTTPS', value: 'http' },
				{ text: 'email', value: 'email' }
			],
			dropdownMethods: [
				{ text: 'POST', value: 'POST' },
				{ text: 'PUT', value: 'PUT' },
				{ text: 'GET', value: 'GET' }
			],
			showModal: false,
			templateText: '',
			mode: '',
			showModalWarning: false
		};
	},
	mounted() {
		this.fetchData();
	},
	computed: {
		...mapGetters({
			trigger: 'trigger/trigger',
			vars: 'account/vars',
			triggerEvents: 'trigger/triggerEvents',
			triggerTemplates: 'trigger/triggerTemplates',
			isFeatureFlagActive: 'featureToggle/isActive',
			accountlinks: 'accountlink/accountlinks'
		}),
		headerTitle() {
			if (this.mode === 'edit') {
				return this.$t('Edit Trigger');
			}

			return this.$t('Create Trigger');
		},
		id() {
			return _.get(this.$route, 'params.id');
		},
		accountOptions() {
			let accounts = [];
			if (this.isFeatureFlagActive('siteflow-setup-accountlinks')) {
				accounts = [{ _id: this.vars.accountId, name: this.vars.currentAccount }];
				let disabledLinks = [];
				_.each(this.accountlinks, link => {
					if (link.active) {
						link.accountIdA === this.vars.accountId
							? accounts.push({ _id: link.accountIdB, name: link.accountNameB })
							: accounts.push({ _id: link.accountIdA, name: link.accountNameA });
					} else {
						link.accountIdA === this.vars.accountId
							? disabledLinks.push({ _id: link.accountIdB, name: link.accountNameB })
							: disabledLinks.push({ _id: link.accountIdA, name: link.accountNameA });
					}
				});
				this.addMissingAccountOptions(accounts, disabledLinks);
				return accounts;
			} else {
				accounts = _.sortBy(
					_.map(this.vars.userAccountLookup, (name, _id) => ({ name, _id })),
					'name'
				);
			}
			return accounts;
		},
		availableEvents() {
			const excludeExchanges = ['oneflow.stock.alert', 'oneflow.component.printed'];

			return _.filter(
				this.triggerEvents,
				e => !excludeExchanges.includes(e.exchange) && e.resource !== 'subbatch'
			);
		},
		triggerEventsOptions() {
			return _.map(this.availableEvents, ({ name, _id }) => ({ text: name, value: _id }));
		},
		triggerTemplatesOptions() {
			return _.map(this.triggerTemplates, ({ name, _id }) => ({ text: name, value: _id }));
		},
		activeTemplate() {
			if (!this.trigger) return {};
			return _.find(this.triggerTemplates, { _id: this.formData?.templateId });
		},
		canSubmit() {
			return !this.formState.invalid && this.formState.status !== 'pending';
		},
		validationRules() {
			const type = _.get(this, 'formData.type');
			if (type === 'http') {
				return {
					formData: {
						address: { required, url }
					}
				};
			} else if (type === 'email') {
				return {
					formData: {
						address: { required, email }
					}
				};
			}
			return false;
		},
		isHttp() {
			const type = _.get(this.formData, 'type');
			const address = _.get(this.formData, 'address');
			return type === 'http' && /^http:/.test(address);
		}
	},
	watch: {
		activeTemplate(template) {
			const text = _.get(template, 'text');

			if (text) {
				this.templateText = text;
			} else {
				this.templateText = '';
			}
		},
		trigger() {
			this.setFormData(this.trigger);
		},
		id(newValue, oldValue) {
			if (!newValue && oldValue) {
				this.fetchData();
			}
		}
	},
	methods: {
		...mapActions({
			getTrigger: 'trigger/get',
			testTrigger: 'trigger/testTrigger',
			updateTrigger: 'trigger/update',
			createTrigger: 'trigger/create',
			getEvents: 'trigger/getEvents',
			getTemplates: 'trigger/getTemplates',
			getAccountLinks: 'accountlink/findAll'
		}),
		async fetchData() {
			try {
				this.isLoading = true;

				if (this.id) {
					this.mode = 'edit';
					await Promise.all([
						this.getEvents(),
						this.getTemplates(),
						this.getTrigger({ id: this.id }),
						this.getAccountLinks()
					]);
					this.setFormData(this.trigger);
				} else {
					this.mode = 'create';
					await Promise.all([
						this.getEvents(),
						this.getTemplates(),
						this.getAccountLinks({ query: { active: true } })
					]);
					this.setFormData({});
				}
			} catch (err) {
				this.backToList();
				this.$notify({
					type: 'error',
					title: this.$t('Error'),
					text: this.$t('An error occurred while fetching data')
				});
			} finally {
				this.isLoading = false;
			}
		},
		addRequestHeader() {
			const { requestHeaderHeader, requestHeaderValue } = this.formData;
			if (requestHeaderHeader && requestHeaderValue) {
				this.updateFormData({
					headers: _set(requestHeaderHeader, requestHeaderValue, this.formData.headers),
					requestHeaderHeader: '',
					requestHeaderValue: ''
				});
			}
		},
		removeRequestHeader(propName) {
			this.updateFormData({
				headers: _omit(propName, this.formData.headers)
			});
		},
		async runTestTrigger() {
			if (!this.formData.templateId) {
				this.$notify({
					type: 'error',
					title: this.$t('Error'),
					text: this.$t('Please select a template before testing your trigger')
				});
				return;
			}

			this.showModal = false;

			try {
				await this.$confirm({
					title: this.$t('Test trigger'),
					body: this.$t('Are you sure you want to test this trigger?')
				});
				await this.testTrigger({ trigger: this.formData, template: this.activeTemplate });
				this.$notify({
					type: 'success',
					title: this.$t('Success'),
					text: this.$t('Trigger test has been sent')
				});
			} catch (err) {
				this.$notify({
					type: 'error',
					title: this.$t('Error'),
					text: this.$t('An error occurred while testing your trigger')
				});
			}
		},
		onSave(event, forceSave) {
			const type = _.get(this.formData, 'type');
			const address = _.get(this.formData, 'address');
			this.showModalWarning = type === 'http' && /^http:/.test(address);
			if (!forceSave && this.showModalWarning) return;
			if (this.id) {
				return this.handleUpdateTrigger();
			}

			return this.handleCreateTrigger();
		},
		async handleUpdateTrigger() {
			try {
				await this.updateTrigger({ id: this.id, data: this.formData });
				this.$router.push({ name: 'trigger.list' });
				this.$notify({
					type: 'success',
					title: this.$t('Success'),
					text: this.$t('Trigger has been updated')
				});
			} catch (err) {
				this.$notify({
					type: 'error',
					title: this.$t('Error'),
					text: this.$t('An error occurred while updating your trigger')
				});
			}
		},
		async handleCreateTrigger() {
			try {
				await this.createTrigger(this.formData);
				this.$router.push({ name: 'trigger.list' });
				this.$notify({
					type: 'success',
					title: this.$t('Success'),
					text: this.$t('Trigger has been created')
				});
			} catch (err) {
				this.$notify({
					type: 'error',
					title: this.$t('Error'),
					text: this.$t('An error occurred while creating trigger')
				});
			}
		},
		openModal() {
			this.showModal = true;
		},
		backToList() {
			this.$router.push({ name: 'trigger.list' });
		},
		addMissingAccountOptions(accounts, disabledLinks) {
			if (this.formData?.linkedAccountIds) {
				this.formData.linkedAccountIds.forEach(id => {
					let accountExists = _.find(accounts, acc => acc._id === id);
					if (!accountExists) {
						let userAccount = _.find(disabledLinks, acc => acc._id === id);
						if (userAccount) {
							userAccount.class = 'linkInactive';
							userAccount.tooltip = this.$t('Account link disabled');
						} else {
							userAccount = { _id: id, name: id };
							userAccount.class = 'notLinked';
							userAccount.tooltip = this.$t('Account not linked');
						}
						accounts.push(userAccount);
					}
				});
			}
			return accounts;
		},
	}
};
</script>

<style lang="scss">
@import '../../../styles/_variables.scss';

.TriggerCreateEdit {
	&-table {
		td,
		th {
			padding-left: 0;
		}
	}

	label.col-form-label,
	legend.col-form-label,
	&-label {
		text-align: end;
		font-size: 0.9rem;
		color: $darkGreyColour;
	}

	.notLinked {
		background-color: $colourErrorComponent;
		color: $colourPrimaryForeground;
	}

	.linkInactive {
		background-color: $liteGreyColour;
		color: $darkGreyColour;
	}
}
.pending-info {
	padding: 10px 15px;
	background: #fef3ce;
	color: rgba(0, 0, 0, 0.7);
	border: 1px solid #cbc5af;
	border-radius: 5px;
}
</style>
