<template>
	<DefaultLayout>
		<b-form novalidate class="Template-form">
			<ofs-panel>
				<ContentHeader
					:title="formType === 'Edit' ? $t('Edit Template') : $t('Create Template')"
					:noPadding="true"
					class="mb-3"
				/>
				<SmartLoader :isLoading="isLoading" :absolutePosition="true">
					<section class="Template d-flex flex-column justify-content-between w-100" :key="formType">
						<b-row class="m-0">
							<b-col cols="12" xl="6" class="p-0">
								<b-row class="m-0">
									<b-col cols="12" sm="4" class="px-0 pr-sm-3">
										<of-form-input
											name="name"
											type="text"
											:label="$t('Name')"
											required
											:horizontal="false"
											:placeholder="$t('Name')"
										/>
									</b-col>

									<b-col cols="12" sm="4" class="px-0 pr-sm-3">
										<of-form-input
											name="extension"
											:label="$t('Extension')"
											type="text"
											:horizontal="false"
											:placeholder="$t('Extension')"
											required
										/>
									</b-col>

									<b-col cols="12" sm="4" class="px-0 pr-xl-3">
										<of-form-input
											name="contentType"
											type="text"
											:horizontal="false"
											:label="$t('MIME Content Type')"
											:placeholder="$t('MIME Content Type')"
											required
										/>
									</b-col>
								</b-row>
							</b-col>

							<b-col cols="12" xl="6" class="p-0">
								<b-row class="m-0">
									<b-col cols="12" sm="2" class="pl-0">
										<of-toggle :horizontal="false" name="active" :label="$t('Active')" />
									</b-col>

									<b-col cols="12" sm="10" class="p-0">
										<of-form-radio
											buttons
											:label="$t('Type')"
											name="type"
											:horizontal="false"
											:options="typeOptions"
											buttonVariant="outline-primary"
											class="Template-type"
											required
										/>
									</b-col>
								</b-row>
							</b-col>
						</b-row>

						<b-row class="m-0">
							<b-col cols="12" xl="6" class="Template-editor p-0 pr-xl-3">
								<editor
									v-model="templateBody"
									@init="editorInit()"
									@input="onChangeTemplateBody"
									:lang="dataType"
									theme="chrome"
									width="100%"
									height="600px"
								></editor>
							</b-col>
							<b-col cols="12" xl="6" class="Template-editor px-0 pt-3 pt-xl-0">
								<div class="Template-label Template-labelData">{{ $t('Test Data') }}</div>
								<editor
									v-model="testData"
									@init="editorInit()"
									lang="json"
									theme="chrome"
									width="100%"
									height="300px"
								></editor>

								<div class="Template-label Template-labelPreview">{{ $t('Preview') }}</div>

								<editor
									v-if="dataType !== 'html'"
									v-model="output"
									@init="editorInit()"
									:options="{ readOnly: true }"
									:lang="dataType"
									theme="chrome"
									width="100%"
									height="300px"
								></editor>
								<iframe v-else :srcdoc="output" height="300px" width="100%" />
							</b-col>
						</b-row>
					</section>
				</SmartLoader>
				<template slot="actions">
					<b-button @click="backToList" class="mr-2">{{ $t('Cancel') }}</b-button>
					<b-button variant="info" @click="runTemplate" v-if="formType === 'Edit'">
						<i class="fa fa-play"></i>
						{{ $t('Save and Run') }}
					</b-button>
					<b-button variant="primary" class="ml-2" :disabled="!canSubmit" @click="onSave">{{
						$t('Save')
					}}</b-button>
				</template>
			</ofs-panel>
		</b-form>
	</DefaultLayout>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import _ from 'lodash';
import { OfFormInput, OfFormRadio, OfToggle, OfsPanel, ContentHeader, withForm } from '@workflow-solutions/ofs-vue-layout';
import editor from '../../../lib/aceEditor';
import DefaultLayout from '../../../components/DefaultLayout';
import SmartLoader from '../../../components/SmartLoader';

export default {
	mixins: [withForm('templateForm')],
	components: {
		DefaultLayout,
		editor,
		OfFormInput,
		OfFormRadio,
		OfToggle,
		OfsPanel,
		ContentHeader,
		SmartLoader
	},
	data() {
		return {
			isLoading: false,
			templateBody: '',
			testData: '',
			output: '',
			formType: '',
			typeOptions: [
				{ value: 'html', text: 'HTML' },
				{ value: 'xml', text: 'XML' },
				{ value: 'svg', text: 'SVG' },
				{ value: 'json', text: 'JSON' },
				{ value: 'zlp', text: 'ZLP' }
			]
		};
	},
	mounted() {
		this.fetchData();
	},
	watch: {
		template() {
			this.setFormData(this.template);
		},
		id(newValue, oldValue) {
			if (!newValue && oldValue) {
				this.templateBody = '';
				this.fetchData();
			}
		}
	},
	computed: {
		...mapGetters({
			template: 'template/template'
		}),
		id() {
			return _.get(this.$route, 'params.id');
		},
		dataType() {
			if (this.formData.type === 'zlp') {
				return 'plain_text';
			}
			return this.formData.type;
		},
		canSubmit() {
			return !this.formState.invalid && this.formState.status !== 'pending';
		}
	},
	methods: {
		...mapActions({
			getTemplate: 'template/get',
			updateTemplate: 'template/update',
			createTemplate: 'template/create',
			mergeTemplate: 'template/merge'
		}),
		editorInit() {
			require('brace/mode/json');
			require('brace/theme/chrome');
			require('brace/mode/html');
			require('brace/mode/xml');
			require('brace/mode/svg');
			require('brace/mode/plain_text');
		},
		async fetchData() {
			try {
				this.isLoading = true;

				if (this.$route.name === 'template.edit') {
					this.formType = 'Edit';

					const { text } = await this.getTemplate({ id: this.id });
					this.templateBody = this.stringify(text);
				} else {
					this.formType = 'Create';
					this.setFormData({});
				}
			} catch (err) {
				this.backToList();
				this.$notify({
					type: 'error',
					title: this.$t('Error'),
					text: this.$t('Unable to fetch template')
				});
			} finally {
				this.isLoading = false;
			}
		},
		backToList() {
			this.$router.push({ name: 'template.list' });
		},
		onChangeTemplateBody(newTemplate) {
			this.templateBody = newTemplate;
		},
		async runTemplate() {
			try {
				await this.updateTemplate({ id: this.id, data: { ...this.formData, text: this.templateBody } });
				const output = await this.mergeTemplate({ id: this.id, data: JSON.parse(this.testData || '{}') });
				this.output = this.stringify(output);
			} catch (err) {
				this.$notify({
					type: 'error',
					title: this.$t('Error'),
					text: `${err.statusCode} - ${err.message}`
				});
			}
		},
		stringify(object) {
			if (_.isObject(object)) {
				return JSON.stringify(object, null, 4);
			}

			return object;
		},
		async onSave() {
			this.formData.text = this.templateBody;
			try {
				if (this.$route.name === 'template.edit') {
					await this.updateTemplate({
						id: this.id,
						data: this.formData
					});
				} else {
					await this.createTemplate(this.formData);
				}
				this.$notify({
					type: 'success',
					title: this.$t('Success'),
					text: `Template has been ${this.formType === 'Edit' ? 'updated' : 'created'}`
				});
				this.$router.push({ name: 'template.list' });
			} catch (err) {
				this.$notify({
					type: 'error',
					title: this.$t('Error'),
					text: `${err.statusCode} - ${err.message}`
				});
			}
		}
	}
};
</script>

<style lang="scss">
.Template {
	flex: 1;

	&-footer {
		display: flex;
		justify-content: space-between;
		padding-top: 1rem;
	}

	&-editor {
		border: 1px solid #c3c3c3;

		.ace_editor {
			overflow-x: auto;
		}
	}

	&-ActiveSlider {
		text-align: center;
	}

	&-label {
		position: absolute;
		right: 20px;
		z-index: 200;
		padding: 5px;
		background: #f3f3f3;
		border: 1px solid #c3c3c3;
		font-size: 10px;

		&Data {
			top: 25px;
		}

		&Preview {
			top: 425px;
		}
	}

	&-ButtonGroup {
		margin-top: 26px;

		&--orange {
			color: #fff;
			background-color: #ed9a06;
		}
	}
}

.ace_editor * {
	font-family: Monaco;
}
</style>
