import Vue from 'vue';

export default function() {
	Vue.$ofsCrud.addResource({
		name: 'accountlink',
		pluralName: 'accountlinks',
		transport: 'connect',
		options: {
			namespaced: true,
			actions: {
				find: {
					dataProp: 'data', // -> `data` in the response payload
					countProp: 'count' // -> `count` in the response payload
				}
			}
		},
		actions: {
			deactivate({ dispatch }, { linkId }) {
				const path = `accountlink/${linkId}`;
				const payload = { active: false };
				return dispatch('request', { method: 'PUT', path, payload });
			},
			activate({ dispatch }, { linkId }) {
				const path = `accountlink/${linkId}`;
				const payload = { active: true };
				return dispatch('request', { method: 'PUT', path, payload });
			},
			create({ dispatch }, { code }) {
				const path = 'accountlink';
				const payload = { code: code };
				return dispatch('request', { method: 'POST', path, payload });
			},
			addPermission({ dispatch }, { accountDestinationId, permission }) {
				const path = 'accountlink/addpermissionrequest';
				const payload = { accountDestinationId: accountDestinationId, permission: permission };
				return dispatch('request', { method: 'POST', path, payload });
			},
			acceptPermission({ dispatch }, { requestId }) {
				const path = `accountlink/acceptpermissionrequest/${requestId}`;
				return dispatch('request', { method: 'PUT', path });
			},
			declinePermission({ dispatch }, { requestId }) {
				const path = `accountlink/declinepermissionrequest/${requestId}`;
				return dispatch('request', { method: 'PUT', path });
			},
			acknowledgeAccepted({ dispatch }, { requestId }) {
				const path = `accountlink/acceptpermissionrequest/${requestId}/acknowledge`;
				return dispatch('request', { method: 'PUT', path });
			},
			acknowledgeDeclined({ dispatch }, { requestId }) {
				const path = `accountlink/declinepermissionrequest/${requestId}/acknowledge`;
				return dispatch('request', { method: 'PUT', path });
			},
			disablePermission({ dispatch }, { accountDestinationId, permission }) {
				const path = 'accountlink/disablepermission';
				const payload = { accountDestinationId: accountDestinationId, permission: permission };
				return dispatch('request', { method: 'PUT', path, payload });
			},
			acknowledgeDisabled({ dispatch }, { requestId }) {
				const path = `accountlink/disablepermission/${requestId}/acknowledge`;
				return dispatch('request', { method: 'PUT', path });
			}
		}
	});
}
