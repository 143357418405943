<template>
	<div class="jobsheet" :class="{ 'jobsheet--loading': loading }">
		<Loader class="jobsheet__loader"></Loader>
		<job-order
			v-for="orderId in orderIds"
			:key="orderId"
			:orderId="orderId"
			:componentIds="componentIds"
			@loaded="onLoad"
		></job-order>
	</div>
</template>

<script>
import _ from 'lodash';
import JobOrder from './JobSheet/Order';
import Loader from './Loader';

export default {
	components: {
		JobOrder,
		Loader
	},
	data() {
		return {
			loading: true,
			loadedCount: 0
		};
	},
	computed: {
		orderIds() {
			const orderIds = _.get(this.$route, 'query.orderIds');

			if (Array.isArray(orderIds)) {
				return _.uniq(orderIds);
			} else if (typeof orderIds === 'string') {
				return _.uniq(orderIds.split(','));
			}

			return [];
		},
		componentIds() {
			const componentIds = _.get(this.$route, 'query.componentIds');

			if (Array.isArray(componentIds)) {
				return _.uniq(componentIds);
			} else if (typeof componentIds === 'string') {
				return _.uniq(componentIds.split(','));
			}

			return [];
		}
	},
	methods: {
		onLoad() {
			this.loadedCount++;

			if (this.loadedCount === this.orderIds.length) {
				this.loading = false;

				setTimeout(() => {
					window.print();
				}, 1000);
			}
		}
	}
};
</script>

<style lang="scss">
@page {
	size: A4 portrait;
	margin: 0.2cm;
}

.jobsheet {
	-webkit-print-color-adjust: exact;
	font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
	font-size: 0.9rem;
	box-sizing: border-box;

	&--loading {
		&.jobsheet:before {
			opacity: 1;
			transition: opacity 0.6s ease-in-out;
		}

		.jobsheet__loader {
			opacity: 1;
			transition: opacity 0.3s ease-in-out;
		}
	}

	&:before {
		transition: opacity 0.6s ease-in-out;
		opacity: 0;
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background: #fff;
		pointer-events: none;
		content: ' ';
		z-index: 2;
	}

	&__loader {
		opacity: 0;
		transition: opacity 0.3s ease-in-out;
		position: fixed;
		pointer-events: none;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		z-index: 3;
	}

	.localfile {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;

		&__icon {
			margin-bottom: 0.5rem;
			color: rgba(0, 0, 0, 0.7);
		}

		&__text {
			text-transform: uppercase;
			letter-spacing: 0.1rem;
			font-size: 0.8rem;
			color: rgba(0, 0, 0, 0.7);
			font-weight: 600;
		}
	}

	.page {
		page-break-before: always;
	}

	.header {
		background: rgba(0, 0, 0, 0.05);
		display: flex;
		justify-content: space-between;
		align-items: center;

		&__aside {
			display: flex;
			text-align: right;
			flex-direction: column;
			padding: 1.5rem;
		}

		&__barcode {
			max-width: 300px;
			height: 100px;
			background: #fff;
			margin: 1.5rem;
			flex: 1;
			padding: 0.75rem;
			color: #222;
		}

		&__quantity {
			font-weight: bold;
			color: #222;
		}
	}

	.header__title {
		margin: 0;
		padding: 0;
		font-size: 2rem;
		color: rgba(0, 0, 0, 0.8);
	}

	.header__subtitle {
		margin: 0.5rem 0 0;
		padding: 0;
		font-size: 1.2rem;
		font-weight: 500;
		color: rgba(0, 0, 0, 0.4);
	}

	.artwork {
		max-width: 100%;
		max-height: 300px;
	}

	.panel {
		border: 8px solid rgba(0, 0, 0, 0.05);
		margin-bottom: 1.5rem;
	}

	.panel--sidebar {
		display: grid;
		grid-template-columns: 1fr minmax(0, 2fr);
	}

	.panel__sidebar {
		background: rgba(0, 0, 0, 0.05);
		padding: 1.5rem;
		display: flex;
		justify-content: center;
		align-items: flex-start;
	}

	.grid {
		display: grid;
		grid-gap: 1.5rem;
		padding: 1.5rem;
		align-self: start;
	}

	.grid--2 {
		grid-template-columns: repeat(2, 1fr);
	}

	.grid--3 {
		grid-template-columns: repeat(3, 1fr);
	}

	.content {
		padding: 1.5rem;
	}

	.component {
		&:not(:last-child) {
			&:after {
				content: ', ';
			}
		}
	}

	.seperator {
		width: 100%;
		border: none;
		border-top: 3px solid rgba(0, 0, 0, 0.05);
		margin: 0;
		padding: 0;
	}

	.spec__heading {
		text-transform: uppercase;
		letter-spacing: 0.1rem;
		font-size: 0.8rem;
		color: rgba(0, 0, 0, 0.3);
		font-weight: 600;
		margin: 0 0 0.5rem;
		padding: 0;
	}

	.spec__value {
		font-size: 1rem;
		line-height: 1.6rem;
		color: rgba(0, 0, 0, 0.8);
		font-weight: normal;
		margin: 0;
		padding: 0;
		word-break: break-word;
	}

	.attributes {
		padding: 0 1.5rem 1rem;
	}

	.pill-list {
		margin: 0;
		padding: 0;
		list-style: none;
		display: flex;
		flex-wrap: wrap;
	}

	.pill-list__item {
		margin: 0 0.5rem 0.5rem 0;
	}

	.pill {
		border: 3px solid #f4f4f4;
		background: #f4f4f4;
		display: inline-flex;
		flex-direction: row;
		align-items: center;
		overflow: hidden;
		border-radius: 0.5rem;
	}

	.pill__key {
		text-transform: uppercase;
		letter-spacing: 0.1rem;
		font-size: 0.8rem;
		color: rgba(0, 0, 0, 0.8);
		font-weight: 600;
		padding: 0.3rem 0.6rem;
	}

	.pill__value {
		padding: 0.3rem 0.6rem;
		background: #fff;
	}

	.highlight {
		background: #222;
		color: #fff;
		border-radius: 0.5rem;
		padding: 0.3rem 0.6rem;
	}

	.spec-list {
		display: flex;
		margin: 0;
		padding: 0;
		list-style: none;

		&__item {
			margin: 0 0.2rem 0.2rem 0;
			padding: 0.3rem 0.6rem;
			line-height: 1;
			background: rgba(0, 0, 0, 0.05);
			border-radius: 0.5rem;

			&--highlight {
				background: #222;
				color: #fff;

				&:after {
					display: none;
				}
			}
		}
	}
}
</style>
