<template>
	<span class="FormattedDate">
		<loader v-if="!this.date || !this.dateFormat" width="14" height="14"></loader>
		<time v-else datetime="date" v-text="formattedDate"></time>
	</span>
</template>

<script>
import _ from 'lodash';
import moment from 'moment';
import { mapActions, mapGetters } from 'vuex';
import Loader from './Loader';

export default {
	components: {
		Loader
	},
	props: {
		date: {
			type: String
		},
		showTime: {
			type: Boolean,
			default: false
		}
	},
	mounted() {
		if (!this.accountSettings) {
			this.getVars();
		}
	},
	computed: {
		...mapGetters({
			accountSettings: 'account/accountSettings'
		}),
		dateFormat() {
			return _.toUpper(_.get(this.accountSettings, 'dateFormat'));
		},
		formattedDate() {
			if (!this.dateFormat) {
				return '-';
			}

			if (this.showTime) {
				return moment(this.date).format(`${this.dateFormat} HH:mm`);
			}
			return moment(this.date).format(this.dateFormat);
		}
	},
	methods: {
		...mapActions({
			getVars: 'account/getVars'
		})
	}
};
</script>

<style lang="scss">
.FormattedDate {
	display: flex;
	align-items: center;
}
</style>
