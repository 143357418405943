<template>
	<DefaultLayout>
		<OfsPanel class="mx-3 mb-3">
			<ListTable
				:table-title="$t('Paired Product SKUs')"
				:items="pairedProducts"
				:fields="fields"
				:total-items="pairedProductCount"
				:per-page="perPage"
				:current-page="currentPage"
				:fetchData="fetchData"
				:isBusy="isBusy"
				@table-change="handleTableChange"
				hover
			>
				<template slot="TableHeader">
					<OfFilterBar
						:filters="filters"
						:values="queryFilters"
						:label="$t('Filter')"
						:reset-label="$t('Reset Filters')"
						@change="filtersChanged"
					/>
				</template>
				<template slot="TableButtons-Slot-right" slot-scope="{}">
					<OfInlineFilter
						:filters="filters"
						:values="queryFilters"
						:label="$t('Filter')"
						:reset-label="$t('Reset Filters')"
						class="mr-2"
						data-test-id="filter"
						@change="filtersChanged"
					/>
				</template>
				<template #cell(sourceProduct)="data">
					<span class="d-flex" data-test-id="sourceProductSkuData">
						{{ data.item.sourceProduct.productCode }}
						/
					</span>
					<span
						v-if="vars.accountId === data.item.sourceAccountId"
						data-test-id="sourceProductSkuLink"
						class="d-flex"
					>
						{{ data.item.sourceSku && data.item.sourceSku.code }}
					</span>
					<span v-else class="d-flex" data-test-id="sourceProductSkuNotLink">
						{{ data.item.sourceSku && data.item.sourceSku.code }}
					</span>
				</template>
				<!-- eslint-disable-next-line vue/valid-v-slot -->
				<template #cell(destinationAccount.name)="data">
					{{ data.item.destinationAccount ? data.item.destinationAccount.name : '-' }}
				</template>
				<template #cell(destinationProduct)="data">
					<template v-if="data.item.destinationProduct && data.item.destinationProduct.productCode">
						<span class="d-flex" data-test-id="destinationProductData">
							{{ data.item.destinationProduct.productCode }}
							/
						</span>
						<span
							v-if="vars.accountId === data.item.destinationAccountId"
							data-test-id="destinationProductLink"
							class="d-flex"
						>
							{{ data.item.destinationSku && data.item.destinationSku.code }}
						</span>
						<span v-else class="d-flex" data-test-id="destinationProductNotLink">
							{{ data.item.destinationSku && data.item.destinationSku.code }}
						</span>
					</template>
					<span v-else>-</span>
				</template>
				<template #cell(status)="data">
					<span :role="getStatusBadgeRole(data.item)">
						<OfsBadge
							v-b-tooltip.hover
							data-test-id="badge"
							:title="getDetailedStatus(data.item, vars.accountId)"
							:status="getOutsourcingStatus(data.item.status)"
							:text="getOutsourcingStatusText(data.item.status)"
						/>
					</span>
				</template>
				<template #cell(dateCreated)="data">
					{{ formatDate(data.item.createdAt) }}
				</template>
				<template #cell(actions)="data">
					<Actions :action-handler="actionHandler" :paired-product="data.item" :account-id="vars.accountId" />
				</template>
				<template v-if="!isFilterApplied && !isBusy" slot="empty">
					<Empty
						:title="$t('You have no paired product SKUs yet')"
						:description="$t('Once you receive a paired product request you will see it on this screen')"
					>
					</Empty>
				</template>
			</ListTable>
		</OfsPanel>
	</DefaultLayout>
</template>

<script>
import moment from 'moment';
import { mapActions, mapGetters } from 'vuex';
import { OfsPanel, ListTable, OfFilterBar, OfInlineFilter, OfsBadge, Empty } from '@workflow-solutions/ofs-vue-layout';
import { navigationMixin, tableMixin, dateFormatMixin } from '@workflow-solutions/siteflow-section-layout';
import DefaultLayout from '../../../components/DefaultLayout';
import Actions from './PairedProductActions.vue';
import _ from 'lodash';
import _get from 'lodash/get';
import _isEqual from 'lodash/isEqual';
import _throttle from 'lodash/throttle';
import { $t } from '../../../vuex';
import { getOutsourcingStatusText, getOutsourcingStatus, getDetailedStatus } from './pairedProductHelpers';
import { displayError } from '../../../lib/helpers';

export default {
	name: 'PairedProduct',
	components: {
		OfsPanel,
		ListTable,
		OfFilterBar,
		OfInlineFilter,
		OfsBadge,
		DefaultLayout,
		Actions,
		Empty
	},
	mixins: [navigationMixin, tableMixin, dateFormatMixin(moment)],
	data() {
		return {
			values: {},
			previousFilterState: {},
			filterKey: 'account',
			filters: [
				{
					header: this.$t('Status'),
					key: 'status',
					type: 'radio',
					items: [
						{ title: this.$t('All'), value: 'all' },
						{ title: this.$t('Active'), value: 'paired' },
						{ title: this.$t('Disabled'), value: 'disabled' },
						{ title: this.$t('Pending'), value: 'pending' }
					]
				}
			],
			isBusy: false,
			fields: [
				{
					key: 'sourceAccount.name',
					label: this.$t('Source Account')
				},
				{
					key: 'sourceProduct',
					label: this.$t('Source Product / SKU')
				},
				{
					key: 'destinationAccount.name',
					label: this.$t('Destination Account')
				},
				{
					key: 'destinationProduct',
					label: this.$t('Destination Product / SKU')
				},
				{
					key: 'status',
					label: this.$t('Status'),
					thClass: 'text-center',
					tdClass: 'text-center'
				},
				{
					key: 'dateCreated',
					label: this.$t('Date Created')
				},
				{
					key: 'actions',
					label: this.$t('Actions')
				}
			],
			deleteConfVisible: false,
			pairedProductToDelete: null,
			pairedProductToAccept: {}
		};
	},
	computed: {
		...mapGetters({
			pairedProducts: 'pairedProduct/pairedProducts',
			pairedProductCount: 'pairedProduct/count',
			vars: 'account/vars'
		}),
		filter() {
			const queryFilter = _get(this.$route, 'query.filter', true);
			return typeof queryFilter === 'string' ? queryFilter === 'true' : queryFilter;
		},
		queryFilters() {
			return this.$route.query.filters;
		},
		isFilterApplied() {
			return !!this.queryFilters && Object.keys(this.queryFilters).length > 0;
		}
	},
	watch: {
		async isFilterApplied(applied) {
			if (!applied) {
				await this.getPairedProducts();
			}
		}
	},
	async created() {
		await this.getPairedProducts();
	},
	methods: {
		...mapActions({
			findPairedProducts: 'pairedProduct/find',
			declineRequest: 'pairedProduct/declineRequest',
			deletePairedProduct: 'pairedProduct/deleteById',
			disablePairedProducts: 'pairedProduct/disable',
			enablePairedProducts: 'pairedProduct/enable',
			getSinglePairing: 'pairedProduct/findById',
			acceptRequest: 'pairedProduct/acceptRequest'
		}),
		fetchData: _throttle(
			async function(filtersChanged) {
				try {
					this.isBusy = true;
					const page = filtersChanged ? this.firstPage : this.currentPage;
					const pageSize = this.perPage;
					const newQueryFilters = { ...this.queryFilters };
					if (this.queryFilters?.account === 'destinationAccount') {
						newQueryFilters.destinationAccountId = this.vars.accountId;
					} else if (this.queryFilters?.account === 'sourceAccount') {
						newQueryFilters.sourceAccountId = this.vars.accountId;
					}
					if (this.queryFilters?.status === 'all') {
						delete newQueryFilters['status'];
					}
					delete newQueryFilters['account'];
					const query = {
						$limit: this.perPage,
						$skip: (page ? page - 1 : 0) * pageSize,
						$sort: { createdAt: -1 },
						$where: newQueryFilters
					};
					this.updateUrl({
						currentPage: page,
						perPage: this.perPage,
						queryFilters: this.queryFilters
					});
					await this.findPairedProducts({ query: { query: query } });
				} catch (err) {
					this.$notify({
						type: 'error',
						title: $t('Error'),
						text: $t(this.$t('There was an error fetching paired products'))
					});
				} finally {
					this.isBusy = false;
				}
			},
			500,
			{ trailing: false }
		),
		async getPairedProducts() {
			this.isLoading = true;
			await this.fetchData();
			this.isLoading = false;
		},
		filtersChanged(queryFilters) {
			if (!_isEqual(queryFilters, this.previousFilterState)) {
				this.updateUrl({
					queryFilters
				});
				this.fetchData(true);
				this.previousFilterState = queryFilters;
			}
		},
		getStatusBadgeRole(pairedProduct) {
			return pairedProduct.status.toLowerCase() === 'validateerror' ? 'button' : '';
		},
		getOutsourcingStatus,
		getOutsourcingStatusText,
		getDetailedStatus,
		async actionHandler(currentAction, selectedPairedProduct) {
			try {
				switch (currentAction) {
					case 'acceptRequest':
						this.pairedProductToAccept = selectedPairedProduct;
						this.handleAcceptRequest();
						break;
					case 'declineRequest':
						await this.declineRequest({ id: selectedPairedProduct._id });
						this.fetchData();
						break;
					case 'delete':
						this.pairedProductToDelete = selectedPairedProduct;
						this.deleteConfVisible = true;
						break;
					case 'disable':
						await this.disablePairedProducts({ id: selectedPairedProduct._id });
						this.fetchData();
						break;
					case 'enable':
						await this.enablePairedProducts({ id: selectedPairedProduct._id });
						this.fetchData();
						break;
				}
			} catch (err) {
				this.$notify({ type: 'error', text: $t(displayError(err)) });
			}
		},
		async handleAcceptRequest() {
			let isValidPairedProduct = false;
			try {
				isValidPairedProduct = await this.getSinglePairing({ id: this.pairedProductToAccept._id });
				if (isValidPairedProduct) {
					const acceptedPairedProduct = await this.acceptRequest({
						id: this.pairedProductToAccept._id,
						payload: {}
					});

					if (acceptedPairedProduct) {
						this.$notify({
							type: 'success',
							title: $t('Pairing Successful'),
							text: $t('Product paired successfully.')
						});
					}

					await this.fetchData();
				}
			} catch (err) {
				this.$notify({ type: 'error', text: $t(displayError(err)) });
			} finally {
				this.isNewPairModalVisible = false;
			}
		}
	}
};
</script>

<style lang="scss">
@import '../../../styles/shared';
</style>
