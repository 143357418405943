import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';

const createStore = () => {
	Vue.use(Vuex);
	return new Vuex.Store({
		config: window.$config,
		plugins: [
			createPersistedState({
				key: 'connect',
				paths: [
					'lang.lang',
					'auth.token',
					'auth.isAuthenticated',
					'account.currentAccount',
					'help.isFirstAccess'
				]
			})
		]
	});
};

export default createStore;
