import Vue from 'vue';
import actions from './actions';
import getters from './getters';
import mutations from './mutations';

export default function() {
	Vue.$ofsCrud.addResource({
		name: 'pairedProduct',
		basePath: 'pairedproduct',
		pluralName: 'pairedProducts',
		transport: 'connect',
		options: {
			namespaced: true,
			actions: {
				find: {
					dataProp: 'data', // -> `data` in the response payload
					countProp: 'total' // -> `total` in the response payload
				}
			}
		},
		state: {
			newPair: {}
		},
		actions,
		getters,
		mutations
	});
}
