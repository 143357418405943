export const barcodeScanner = {
	created() {
		this.scanningOn();
	},
	beforeDestroy() {
		this.scanningOff();
	},
	data() {
		return {
			barcode: ''
		};
	},
	methods: {
		scanKeyPress(e) {
			if (e.target.tagName.toUpperCase() === 'INPUT') {
				return;
			}

			const keyCodes = [13, 9, 91, 18, 17, 16];
			const keyCode = e.which;

			if (keyCodes.indexOf(keyCode) < 0) {
				this.barcode += String.fromCharCode(keyCode);
			} else if (keyCode === 13 || keyCode === 9) {
				// enter or tab
				this.scanBarcode();
			}
		},
		scanningOn() {
			document.addEventListener('keypress', this.scanKeyPress);
		},
		scanningOff() {
			document.removeEventListener('keypress', this.scanKeyPress);
		}
	}
};

export default barcodeScanner;
