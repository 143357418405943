<template>
	<DefaultLayout>
		<ofs-panel>
			<ListTable
				:table-title="$t('Submission Errors')"
				:config="config"
				bordered
				:isBusy="isLoading"
				:items="items"
				:currentPage="currentPage"
				:fields="fields"
				:filter="filter"
				:total-items="count"
				:per-page="perPage"
				:fetch-data="fetchData"
				:loaderAbsolutePosition="true"
				:page-position-prefix="$t('Showing')"
				:page-position-join="$t('of')"
				@row-clicked="rowClicked"
				@table-change="handleTableChange"
				hover
			>
				<template slot="prev-text" slot-scope="{}">
					{{ $t('Prev') }}
				</template>
				<template slot="next-text" slot-scope="{}">
					{{ $t('Next') }}
				</template>
				<template slot="empty">
					<span>
						<i>{{ $t('No Data') }}</i>
					</span>
				</template>
				<template v-slot:TableButtons-Slot-left>
					<Filters :filters="filterConfig" :label="$t('Filter')" @filters-changed="handleFiltersChanged" />
				</template>
				<template v-slot:cell(user_account_lookup)="data">
					{{ userAccountLookup[data.item.accountId] || data.item.accountId }}
				</template>
				<template v-slot:cell(timestamp)="data">{{ data.item.createdAt | amDateFormat(dateFormat) }}</template>
				<template v-slot:cell(resubmitted)="data">
					<b-badge
						v-if="data.item.resubmitted"
						size="md"
						disabled
						class="text-capitalize SubmissionErrorList-badge"
					>
						{{ $t('resubmitted') }}
					</b-badge>
					<span v-else>-</span>
				</template>

				<template v-slot:cell(preview)="data">
					<div v-if="data.item.preview" class="text-truncated">{{ data.item.preview }}</div>
				</template>
			</ListTable>
		</ofs-panel>
	</DefaultLayout>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import moment from 'moment';
import _ from 'lodash';
import { ListTable, OfsPanel } from '@workflow-solutions/ofs-vue-layout';
import DefaultLayout from '../../components/DefaultLayout';
import Filters from '../../components/Filters';

const config = {
	breadcrumbs: { visible: false },
	filter: { visible: false },
	search: { visible: false },
	add: { visible: false },
	refresh: { visible: false },
	columns: { visible: false }
};

export default {
	components: {
		DefaultLayout,
		ListTable,
		OfsPanel,
		Filters
	},
	mounted() {
		this.fetchData();
	},
	data() {
		return {
			isLoading: false,
			filters: {
				source: [],
				brandIds: []
			},
			items: [],
			dateFormat: 'DD/MM/YYYY',
			userAccountLookup: {},
			config,
			perPage: 10,
			currentPage: 1,
			isBusy: false,
			filter: true,
			count: 0
		};
	},
	computed: {
		...mapGetters({
			accountlinks: 'accountlink/accountlinks',
			isFeatureFlagActive: 'featureToggle/isActive',
			vars: 'account/vars'
		}),
		isSourceFilterVisible() {
			return _.get(this.filters, 'source[0].value') === 'brand';
		},
		fields() {
			return [
				{ key: 'resubmitted', label: this.$t('Resubmitted'), thClass: 'SubmissionErrorList__resubmitted' },
				{ key: 'timestamp', label: this.$t('Timestamp') },
				{ key: 'user_account_lookup', label: this.$t('Source') },
				{ key: 'destinationAccountName', label: this.$t('Destination') },
				{ key: 'preview', label: this.$t('Error message') }
			];
		},
		filterConfig() {
			return [
				{
					header: this.$t('Error source'),
					key: 'source',
					type: 'single',
					items: [
						{ title: this.$t('Resubmitted'), value: 'resubmitted' },
						{ title: this.$t('Not Resubmitted'), value: 'notresubmitted' },
						{ title: this.$t('My errors'), value: 'mine' },
						{ title: this.$t("My brand's errors"), value: 'brand' }
					]
				},
				{
					header: this.$t('Source'),
					key: 'brandIds',
					visible: this.isSourceFilterVisible,
					type: 'single',
					items: this.userAccountLookupOptions
				}
			];
		},
		fetchFilter() {
			const sourceFilter = _.get(this.filters, 'source[0].value');

			const filter = {
				offset: (this.currentPage - 1) * this.perPage,
				limit: this.perPage,
				order: '_id DESC',
				asDestination: sourceFilter === 'brand'
			};

			if (this.filters.brandIds && this.filters.brandIds.length) {
				_.set(filter, 'where.accountId', this.filters.brandIds[0].value);
			}

			if (sourceFilter === 'resubmitted') {
				_.set(filter, 'where.resubmitted', { exists: true });
			}

			if (sourceFilter === 'notresubmitted') {
				_.set(filter, 'where.resubmitted', { exists: false });
			}

			return filter;
		},
		userAccountLookupOptions() {
			let accounts = [];
			if (this.isFeatureFlagActive('siteflow-setup-accountlinks')) {
				accounts = _.map(this.accountlinks, accountlink => {
					return {
						value:
							this.vars.accountId === accountlink.accountIdA
								? accountlink.accountIdB
								: accountlink.accountIdA,
						title:
							this.vars.accountId === accountlink.accountIdA
								? accountlink.accountNameB
								: accountlink.accountNameA,
						labelClass: !accountlink.active ? 'disabled' : null
					};
				});
			} else {
				accounts = _.sortBy(
					_.map(this.userAccountLookup, (title, value) => ({ title, value })),
					'title'
				);
			}
			return accounts;
		}
	},
	methods: {
		...mapActions({
			getSubmissionErrors: 'order/getSubmissionErrors',
			getSubmissionErrorsCount: 'order/getSubmissionErrorsCount',
			getVars: 'account/getVars',
			getAccountLinks: 'accountlink/findAll'
		}),
		async fetchData() {
			try {
				this.isLoading = true;
				this.count = (await this.getSubmissionErrorsCount(this.fetchFilter)).data.count;
				this.items = (await this.getSubmissionErrors(this.fetchFilter)).data;
				this.items.forEach(error => {
					const firstError = _.first(error.errorList);
					error.preview = (firstError && firstError.message) || 'Unknown error';
				});
				const vars = await this.getVars();
				await this.getAccountLinks();
				this.dateFormat = vars.oneflowAccountSettings.dateFormat.toUpperCase();
				if (this.isFeatureFlagActive('siteflow-setup-accountlinks')) {
					this.userAccountLookup = { [vars.accountId]: vars.currentAccount };
					_.each(this.accountlinks, accountlink => {
						accountlink.accountIdA === this.vars.accountId
							? (this.userAccountLookup[accountlink.accountIdB] = accountlink.accountNameB)
							: (this.userAccountLookup[accountlink.accountIdA] = accountlink.accountNameA);
					});
				} else {
					this.userAccountLookup = vars.userAccountLookup;
				}
			} catch (err) {
				this.$notify({
					type: 'error',
					title: this.$t('Error'),
					text: this.$t('An error occurred while fetching submission errors')
				});
			} finally {
				this.isLoading = false;
			}
		},
		rowClicked(item) {
			this.$router.push(`/order-submission-errors/view/${item._id}`);
		},
		handleTableChange({ currentPage, perPage, filter }) {
			this.currentPage = currentPage;
			this.perPage = perPage;
			if (filter !== undefined) {
				this.filter = filter;
			}
			this.fetchData();
		},
		handleFiltersChanged(selectedFilters) {
			const filters = _.groupBy(selectedFilters, 'key');
			this.$set(this, 'filters', filters);
			this.filters = { ...filters };
			this.currentPage = 1;
			this.fetchData();
		}
	},
	filters: {
		amDateFormat(date, dateFormat) {
			return moment(date).format(`${dateFormat} HH:mm:ss`);
		}
	},
	watch: {
		page() {
			this.fetchData();
		}
	}
};
</script>

<style lang="scss">
.SubmissionErrorList {
	&-badge {
		background-color: #a8c7ec;
		border: 1px solid;
		border-color: #699fde;
		color: #fff;
		padding: 0.3rem 0.6rem;
		font-size: 12px;
	}

	&__resubmitted {
		min-width: 100px;
	}
}
</style>
