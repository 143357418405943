<template>
	<div class="barcode">
		<div :style="{ backgroundImage: `url('${image}')` }" class="barcode__image" />
		<span class="barcode__label" v-text="text"></span>
	</div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
	props: {
		text: {
			required: true,
			default: null
		},
		showLabel: {
			type: Boolean,
			default: true
		},
		type: {
			default: 'code128'
		},
		rotate: {
			type: String, // N, R, L, I
			default: 'N'
		},
		wscale: {
			type: Number,
			default: 1
		},
		hscale: {
			type: Number,
			default: 1
		}
	},
	watch: {
		text: {
			immediate: true,
			handler: 'loadBarcode'
		}
	},
	data() {
		return {
			image: null
		};
	},
	methods: {
		...mapActions({
			generateBarcode: 'barcode/generate'
		}),
		async loadBarcode() {
			const barcode = await this.generateBarcode({
				text: this.text,
				type: this.type,
				rotate: this.rotate,
				wscale: this.wscale,
				hscale: this.hscale
			});
			const reader = new FileReader();
			reader.readAsDataURL(barcode);
			reader.onload = () => {
				this.image = reader.result;
			};
		}
	}
};
</script>

<style lang="scss">
.barcode {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	&__image {
		display: flex;
		flex: 1;
		width: 100%;
		height: 20px;
		background-size: cover;
	}

	&__label {
		flex: 0;
		text-transform: uppercase;
		font-size: 0.8rem;
		line-height: 0.8rem;
		padding-top: 0.5rem;
	}
}
</style>
