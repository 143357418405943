<template>
	<DefaultLayout>
		<ContentHeader title="Create new Order"></ContentHeader>
		Order Add content
	</DefaultLayout>
</template>

<script type="text/javascript">
import { ContentHeader } from '@workflow-solutions/ofs-vue-layout';
import DefaultLayout from '../../components/DefaultLayout';

export default {
	components: {
		DefaultLayout,
		ContentHeader
	}
};
</script>
