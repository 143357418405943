<template>
	<section class="ShipmentDetails">
		<header class="ShipmentDetails__header">
			<h5 class="ShipmentDetails__heading">{{ $t('Shipping details') }}</h5>
			<order-status class="ShipmentDetails-badge" :status="status"></order-status>
		</header>
		<div class="ShipmentDetails-body d-flex flex-column mt-2">
			<span data-test-id="name">{{ name }}</span>
			<span data-test-id="company" v-if="company">{{ company }}</span>
			<span data-test-id="address1">{{ address1 }}</span>
			<span data-test-id="address2">{{ address2 }}</span>
			<span data-test-id="town">{{ town }}</span>
			<span data-test-id="state">{{ state }}</span>
			<span data-test-id="postcode">{{ postcode }}</span>
			<span data-test-id="isoCountry">{{ isoCountry }}</span>
			<span data-test-id="phone">{{ phone }}</span>
		</div>
		<b-row class="ShipmentDetails-label mx-0 mt-3">{{ $t('Courier') }}</b-row>
		<b-row class="ShipmentDetails-courier mx-0 text-capitalize" data-test-id="courier">{{ courier }}</b-row>
		<b-row class="ShipmentDetails-label mx-0 mt-3">{{ $t('Ship By') }}</b-row>
		<b-row class="ShipmentDetails-shipBy mx-0 mb-1 text-capitalize">
			<date :date="shipBy" data-test-id="shipBy"></date>
		</b-row>
		<template v-if="trackingNumber">
			<b-row class="ShipmentDetails-label mx-0 mt-3">{{ $t('Tracking') }}</b-row>
			<b-row class="m-0">
				<b-button
					data-test-id="Orders_ShipmentTracking"
					variant="secondary"
					class="ShipmentDetails-tracking p-0"
					@click="handleEditTrackingNumber"
					>{{ trackingNumber }}</b-button
				>
			</b-row>
		</template>
	</section>
</template>

<script>
import _ from 'lodash';
import { duration } from '../../../lib/filters';
import OrderStatus from '../../../components/OrderStatus';
import Date from '../../../components/Date';

export default {
	name: 'ShipmentDetails',
	props: {
		details: {
			type: Object,
			default: () => ({})
		}
	},
	components: {
		OrderStatus,
		Date
	},
	filters: {
		duration
	},
	computed: {
		status() {
			return _.get(this.details, 'status');
		},
		company() {
			return _.get(this.details, 'companyName');
		},
		name() {
			return _.get(this.details, 'name');
		},
		address1() {
			return _.get(this.details, 'address1');
		},
		address2() {
			return _.get(this.details, 'address2');
		},
		state() {
			return _.get(this.details, 'state');
		},
		town() {
			return _.get(this.details, 'town');
		},
		postcode() {
			return _.get(this.details, 'postcode');
		},
		isoCountry() {
			return _.get(this.details, 'isoCountry');
		},
		courier() {
			return _.get(this.details, 'courier');
		},
		shipBy() {
			return _.get(this.details, 'shipBy');
		},
		trackingNumber() {
			return _.get(this.details, 'trackingNumber');
		},
		phone() {
			return _.get(this.details, 'phone');
		}
	},
	methods: {
		handleEditTrackingNumber() {
			this.$emit('edit-tracking-number', this.trackingNumber);
		}
	}
};
</script>
<style lang="scss" scoped>
@import '../../../styles/variables';

.ShipmentDetails {
	font-size: 0.95em;
	padding: 0.75rem 1rem 0.75rem 0;

	&__header {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: space-between;
	}

	&__heading {
		font-size: 0.9rem;
		font-weight: bold;
		margin: 0;
	}

	&-courier {
		word-wrap: break-word;
		display: inline-block;
		max-width: 100%;
	}

	&-courier {
		word-break: break-word;
	}

	&-label {
		font-size: 0.85em;
		font-weight: normal;
		color: $greyColour;
		text-transform: uppercase;
		border: none;
	}
}
</style>
