<template>
	<defaultLayout :key="status">
		<section class="work">
			<ContentHeader :title="$t('Work List')" data-test-id="Production_WorkListTitle" class="pb-0 flex-grow-0">
				<aside class="Production_actions">
					<Filters :filters="filterItems" :label="$t('Filter')" @filters-changed="handleFiltersChanged">
						<div slot="left-content" v-if="hasResults && status !== 'complete'">
							<b-button
								variant="link"
								v-if="items.length !== selectedItems.length"
								@click.prevent="selectAll"
								data-test-id="Production_selectAllItems"
							>
								{{ $t('Select All') }}
							</b-button>
							<b-button
								v-else
								variant="link"
								@click.prevent="deselectAll"
								data-test-id="Production_deselectAllItems"
							>
								{{ $t('Deselect All') }}
							</b-button>
						</div>
					</Filters>
					<BarcodeScanner :onScan="handleScan" :showInput="true" class="ml-3" />
				</aside>
			</ContentHeader>
			<div class="work__inner">
				<div v-if="loading && !items.length" class="text-center p-3">{{ $t('Loading') }}...</div>
				<transition-group tag="ol" name="work-list" class="work__items" v-if="items.length">
					<li
						class="work__item"
						v-for="(item, index) in items"
						:key="`${item._id}-${item.shipmentId}-${index}`"
					>
						<work-item :item="item" @on-selected="itemSelected" :selectedItems="selectedItems"></work-item>
					</li>
				</transition-group>
				<infinite-loading @infinite="loadNextPage" spinner="spiral">
					<template slot="no-more">
						<span></span>
					</template>
				</infinite-loading>
				<p v-if="!loading && !hasResults" class="work__empty">
					{{ $t('There are currently no jobs matching this filter') }}
				</p>
			</div>
			<div class="work__toolbar work-toolbar" v-if="selectedItems.length">
				<div class="work-toolbar__content">
					<p
						class="work-toolbar__selected"
						v-text="
							selectedItems.length === 1
								? $t('1 Item Selected')
								: selectedItems.length + ' ' + $t('Items Selected')
						"
					></p>
					<a class="link" href="#" @click.prevent="deselectAll" data-test-id="Production_deselectItems">
						{{ $t('Deselect All') }}
					</a>
				</div>
				<div class="work-toolbar__actions">
					<b-button
						@click.prevent="viewJobSheet"
						class="mr-2"
						:disabled="selectedItems.length > 5"
						title="Print up to 5 Items at a time"
						v-b-tooltip.hover>
						{{ $t('Print Job Sheets') }}
					</b-button>
					<b-button
						variant="primary"
						@click.prevent="completeItems"
						data-test-id="Production_batchCompleteButton">
						{{ $t('Complete') }}
					</b-button>
				</div>
			</div>
		</section>
		<item-modal
			:item="item"
			:show="isItemModalVisible"
			:order="order"
			:modal-close="handleItemModalClosed"
		></item-modal>
	</defaultLayout>
</template>

<script>
import _ from 'lodash';
import { ContentHeader } from '@workflow-solutions/ofs-vue-layout';
import { mapActions, mapGetters } from 'vuex';
import InfiniteLoading from 'vue-infinite-loading';
import Promise from 'bluebird';
import moment from 'moment';
import DefaultLayout from '../../components/DefaultLayout';
import WorkItem from './WorkItem';
import ItemModal from './ItemModal';
import BarcodeScanner from '../../components/BarcodeScanner';
import Filters from '../../components/Filters';

export default {
	components: {
		DefaultLayout,
		ContentHeader,
		WorkItem,
		InfiniteLoading,
		ItemModal,
		BarcodeScanner,
		Filters
	},
	props: {
		status: {
			type: String,
			value: 'live'
		}
	},
	data() {
		return {
			loading: true,
			page: 1,
			pages: 1,
			pageLimit: 10,
			findCriteria: {},
			item: null,
			order: null,
			selectedItems: [],
			isItemModalVisible: false,
			hasMore: true
		};
	},
	watch: {
		async status() {
			this.resetPagination();
		}
	},
	computed: {
		...mapGetters({
			items: 'item/items'
		}),
		hasResults() {
			return this.items.length > 0;
		},
		filterItems() {
			return [
				{
					header: this.$t('Due date'),
					key: 'shipByDate',
					type: 'single',
					items: [
						{ title: this.$t('Today'), value: 'today' },
						{ title: this.$t('Tomorrow'), value: 'tomorrow' },
						{ title: this.$t('This week'), value: 'week' }
					]
				}
			];
		}
	},
	mounted() {
		this.resetItems();
	},
	methods: {
		...mapActions({
			resetItems: 'item/reset',
			markAsComplete: 'item/markAsComplete',
			fetchItems: 'item/find',
			scanBarcode: 'barcode/getById',
			getShipmentsByOrderId: 'order/getShipmentsByOrderId'
		}),
		async fetchData() {
			this.loading = true;
			try {
				return await this.fetchItems({
					query: {
						page: this.page,
						pagesize: this.pageLimit,
						status: this.status,
						sort: 'shipByDate',
						direction: 1,
						...this.findCriteria
					}
				});
			} finally {
				this.loading = false;
				this.page += 1;
			}
		},
		resetPagination() {
			this.page = 1;
			this.pages = 1;
			this.loading = true;
			this.hasMore = true;
			this.resetItems();
		},
		itemSelected(selectedItem, selected) {
			if (!selected) {
				this.selectedItems = _.filter(this.selectedItems, item => item !== selectedItem);
			} else {
				this.selectedItems = _.uniq([...this.selectedItems, selectedItem]);
			}
		},
		selectAll() {
			this.selectedItems = [...this.items];
		},
		deselectAll() {
			this.selectedItems = [];
		},
		async completeItems() {
			const length = this.selectedItems.length;
			try {
				const results = await Promise.map(this.selectedItems, item => this.markAsComplete(item._id), {
					concurrency: 1
				});

				const ordersCompleted = _.filter(results, ({ completedOrder }) => completedOrder);
				let text = `${length === 1 ? '1 Item completed' : `${length} Items completed`}.`;

				if (ordersCompleted.length) {
					text = `${text}<br/>${ordersCompleted.length} orders has been marked as complete.`;
				}

				this.$notify({ type: 'success', text });
				this.deselectAll();

				if (this.items.length === 0) {
					await this.fetchData();
				}
			} catch (e) {
				this.$notify({
					type: 'error',
					text: 'There was a problem completing your selected batch(es)',
					title: 'Ooops'
				});
			}
		},
		async loadNextPage($state) {
			if (this.hasMore) {
				const result = await this.fetchData();
				if (!result.data.length) {
					this.hasMore = false;
				}
				$state.loaded();
			} else {
				$state.complete();
			}

			this.loading = false;
		},
		async handleScan(barcode) {
			if (barcode.length >= 5) {
				this.isItemModalVisible = false;

				try {
					const { order } = await this.scanBarcode(barcode.toUpperCase());
					if (order) {
						this.order = order;
						const { data: shipments } = await this.getShipmentsByOrderId(order._id);

						this.item = _.reduce(
							shipments,
							(acc, shipment) => {
								if (acc) return acc;

								let foundItem = null;

								_.each(shipment.items, item => {
									if (_.find(item.components, component => component.barcode === barcode)) {
										foundItem = item;
									}
								});

								if (foundItem) return { ...foundItem, shipmentId: shipment._id };

								return null;
							},
							null
						);

						if (this.item) {
							this.isItemModalVisible = true;
						} else {
							throw new Error('No component found');
						}
					}
				} catch (e) {
					this.$notify({
						type: 'error',
						title: this.$t('Not Found'),
						text: this.$t('No component was found matching the supplied barcode')
					});

					this.item = null;
				}
			}
		},
		viewJobSheet() {
			const orderIds = this.selectedItems.map(item => item.orderId);
			let components = [];

			this.selectedItems.forEach(item => {
				components = [...components, ...item.components];
			});

			const componentIds = components.map(component => component._id);
			const routeData = this.$router.resolve({ name: 'job-sheet', query: { orderIds, componentIds } });
			window.open(routeData.href, '_blank');
		},
		handleItemModalClosed() {
			this.item = null;
			this.isItemModalVisible = false;
		},
		handleFiltersChanged(filters) {
			const filtersSwitch = {
				shipByDate: value => {
					let $gte;
					let $lte;

					if (value === 'today') {
						$gte = moment().startOf('day');
						$lte = moment().endOf('day');
					}

					if (value === 'tomorrow') {
						$gte = moment()
							.add(1, 'day')
							.startOf('day');
						$lte = moment()
							.add(1, 'day')
							.endOf('day');
					}

					if (value === 'week') {
						$gte = moment().startOf('week');
						$lte = moment().endOf('week');
					}

					return { 'shipByDate[$gte]': $gte.toDate(), 'shipByDate[$lte]': $lte.toDate() };
				}
			};
			this.findCriteria = _.reduce(
				filters,
				(acc, { key: filterKey, value }) => {
					if (filtersSwitch[filterKey]) {
						return {
							...acc,
							...filtersSwitch[filterKey](value)
						};
					}

					return acc;
				},
				{}
			);

			this.deselectAll();
			this.resetPagination();
			return this.fetchData();
		}
	}
};
</script>

<style lang="scss">
.Production_actions {
	display: flex;
	flex-direction: row;
}
</style>
