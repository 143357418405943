<template>
	<div>
		<b-modal
			:visible="isModalVisible"
			size="lg"
			title="Hi there!"
			modal-class="GettingStartedModal"
			:class="classes"
			body-class="GettingStartedModal-body"
			hide-header
			hide-footer
			:no-close-on-backdrop="!canBeClosed"
			:no-close-on-esc="!canBeClosed"
			centered
			:hide-header-close="!canBeClosed"
		>
			<SmartLoader :isLoading="isLoading">
				<div>
					<div class="article" v-if="!isSuccess" data-test-identifier="getting-started">
						<section class="article-header article__header">
							<div class="article-header__inner">
								<h2 class="article-header__title">{{ $t('Hi There!') }}</h2>
								<p class="article-header__text">
									{{ $t('Welcome to Site Flow Light, your route into the HP global ordering network, allowing you to receive orders from brands located around the world; validated, processed and delivered in the cloud!') }}
								</p>
								<p>
									{{ $t('Before we start there’s two pieces of information we need from you to make sure your account operates correctly.') }}
								</p>
							</div>
						</section>
						<form
							class="article__panel"
							@submit.prevent="handleSubmit"
							data-test-identifier="help-form"
							autocomplete="off"
						>
							<div class="article-panel article-panel--highlight">
								<b>{{ $t('Timezone') }}</b>
								<p>
									{{ $t('Lets us calculate what time it is for you when you get an order.') }}
								</p>
								<of-form-select
									:horizontal="false"
									name="timezoneGroup"
									:label="$t('Timezone Group')"
									:options="timezoneGroupOptions"
								/>
								<of-form-select
									:horizontal="false"
									name="timezone"
									:label="$t('Timezone')"
									:options="timezones"
									required
								/>
							</div>
							<div class="article-panel article-panel--highlight">
								<b>{{ $t('Default SLA Days') }}</b>
								<p>
									{{ $t('Lets us calculate when you should ship an order if a date isn’t provided.') }}
								</p>
								<of-form-select
									:horizontal="false"
									name="defaultSlaDays"
									:label="$t('Default SLA Days')"
									:options="slaDays"
									required
								/>
							</div>

							<div class="GettingStartedModal-actions">
								<b-button
									variant="primary"
									size="lg"
									type="submit"
									:disabled="formState.invalid || isSubmitting"
									data-test-identifier="help-form-submit"
								>
									<template v-if="isSubmitting">{{ $t('Saving') }}</template>
									<template v-else>{{ $t('Save settings') }}</template>
								</b-button>
							</div>
						</form>
					</div>

					<div class="article" v-if="isSuccess" data-test-identifier="getting-started-success">
						<section class="article__panel">
							<h2 class="article-header__title">{{ $t('Thanks!') }}</h2>
							<p class="article-header__text mb-3">
								{{ $t('You’re ready to begin exploring Site Flow Light.') }}
								<br />
								{{ $t('Why not head over to our help section to submit a test order and follow it through the production process!') }}
							</p>

							<div class="mt-2">
								<b-button
									variant="primary"
									@click="createTestOrder"
									data-test-identifier="help-order-tutorial"
								>
									{{ $t('Try a test order tutorial') }}
								</b-button>
								<b-button
									variant="link"
									@click="closeModal"
									data-test-identifier="help-account-settings"
								>
									{{ $t('Review account settings') }}
								</b-button>
							</div>
						</section>
					</div>
				</div>
			</SmartLoader>
		</b-modal>
	</div>
</template>

<script>
import _ from 'lodash';
import _fp from 'lodash/fp';
import { mapActions, mapGetters } from 'vuex';
import { withForm, OfFormSelect } from '@workflow-solutions/ofs-vue-layout';
import SmartLoader from '../SmartLoader';

export default {
	mixins: [withForm('gettingStartedModal')],
	components: {
		OfFormSelect,
		SmartLoader
	},
	data() {
		return {
			modalTimeout: null,
			isModalVisible: false,
			isSuccess: false,
			isLoading: false,
			isSubmitting: false,
			timezoneGroups: [],
			slaDays: [
				{ text: this.$t('Same Day'), value: 0 },
				{ text: this.$t('1 Day'), value: 1 },
				{ text: this.$t('2 Days'), value: 2 },
				{ text: this.$t('3 Days'), value: 3 },
				{ text: this.$t('4 Days'), value: 4 },
				{ text: this.$t('5 Days'), value: 5 },
				{ text: this.$t('6 Days'), value: 6 },
				{ text: this.$t('1 Week'), value: 7 }
			]
		};
	},
	computed: {
		...mapGetters({
			currentAccount: 'account/currentAccount',
			accountSettings: 'account/accountSettings',
			isFirstAccess: 'help/isFirstAccess',
			isAuthenticated: 'auth/isAuthenticated'
		}),
		classes() {
			return {
				'is-loading': this.isLoading
			};
		},
		timezoneGroupOptions() {
			return _.map(this.timezoneGroups, item => ({ text: item.group, value: item.group }));
		},
		timezones() {
			return _fp.flow(
				groups => {
					const selectedTimezoneGroup = _.get(this.formData, 'timezoneGroup');
					if (selectedTimezoneGroup) {
						return _.filter(groups, { group: selectedTimezoneGroup });
					}
					return groups;
				},
				_fp.map('zones'),
				_fp.flatten
			)(this.timezoneGroups);
		},
		canBeClosed() {
			return this.isSuccess;
		}
	},
	watch: {
		accountSettings: {
			immediate: true,
			handler() {
				// eslint-disable-next-line no-prototype-builtins
				if (!this.accountSettings || !this.accountSettings.hasOwnProperty('timezone')) return;
				const selectedTimezoneValue = _.get(this.formData, 'timezone');
				const selectedTimezoneGroup =
					_.find(this.timezoneGroups, group => _.find(group.zones, { value: selectedTimezoneValue })) || {};
				this.updateFormData({ ...this.accountSettings, timezoneGroup: selectedTimezoneGroup.group });
			}
		},
		isAuthenticated: {
			immediate: true,
			handler: 'checkModalVisibility'
		},
		isFirstAccess: {
			immediate: true,
			handler: 'checkModalVisibility'
		},
		currentAccount: {
			immediate: true,
			handler: 'checkModalVisibility'
		},
		isModalVisible: {
			immediate: true,
			handler() {
				if (this.isModalVisible) return this.fetchData();

				return null;
			}
		}
	},
	methods: {
		...mapActions({
			getVars: 'account/getVars',
			saveAccountSettings: 'account/saveAccountSettings',
			setFirstAccess: 'help/setFirstAccess'
		}),
		checkModalVisibility() {
			if (this.modalTimeout) clearTimeout(this.modalTimeout);
			if (!this.currentAccount) return; // function will run again when currentAccount is available

			if (this.isFirstAccess && this.isAuthenticated && this.currentAccount.role === 'owner') {
				this.modalTimeout = setTimeout(() => {
					this.isModalVisible = true;
				}, 1000);
				this.fetchData();
			} else {
				this.isModalVisible = false;
			}
		},
		async fetchData() {
			try {
				this.isLoading = true;
				await this.getVars();
				this.timezoneGroups = await import('../../data/timezones').then(res => res.default.timezones);
			} catch (err) {
				this.$notify({
					type: 'error',
					title: 'Error',
					text: this.$t('An error occurred while fetching account settings')
				});

				throw err;
			} finally {
				this.isLoading = false;
			}
		},
		async handleSubmit() {
			try {
				this.isSubmitting = true;
				await this.saveAccountSettings(this.formData);
				this.isSuccess = true;
				return this.setFirstAccess(false);
			} catch (err) {
				this.$notify({
					type: 'error',
					title: 'Error',
					text: this.$t('An error occurred while saving the account settings')
				});
				this.isSuccess = false;
				throw err;
			} finally {
				this.isSubmitting = false;
			}
		},
		createTestOrder() {
			this.isModalVisible = false;
			this.$router.push({ name: 'help.orderSubmission' });
		},
		closeModal() {
			this.isModalVisible = false;
		}
	}
};
</script>

<style lang="scss">
.GettingStartedModal {
	&.is-loading {
		.modal-body {
			padding: 3rem;
		}
	}
	.modal-body {
		background: #f9fafb;
		padding: 0;
	}

	.article-header__inner {
		padding: 2rem;
	}

	.article__panel {
		width: 100%;
		padding: 2rem;
	}

	&-actions {
		margin: 2rem -1rem 0;
	}
}
</style>
