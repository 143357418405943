export default {
	acceptRequest({ dispatch }, { id, payload }) {
		const path = `pairedproduct/accept/${id}`;
		return dispatch('request', { method: 'PATCH', path, payload });
	},
	declineRequest({ dispatch }, { id }) {
		const path = `pairedproduct/decline/${id}`;
		return dispatch('request', { method: 'PATCH', path });
	},
	disable({ dispatch }, { id }) {
		const path = `pairedproduct/disable/${id}`;
		return dispatch('request', { method: 'PATCH', path });
	},
	enable({ dispatch }, { id }) {
		const path = `pairedproduct/enable/${id}`;
		return dispatch('request', { method: 'PATCH', path });
	},
	setNewPair({ commit }, pairedProduct) {
		commit('NEW_PAIRED_PRODUCT', pairedProduct);
	},
	addSkus({ commit }, skus) {
		commit('ADD_SKUS', skus);
	},
	addAccountLinks({ commit }, accountLinks) {
		commit('ADD_ACCOUNT_LINKS', accountLinks);
	}
};
