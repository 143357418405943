<template>
	<section class="OrderLogs pb-3 mb-3">
		<b-table :items="logs" :fields="fields">
			<template v-slot:cell(timestamp)="log">
				<date :date="log.item.timestamp" :showTime="true"></date>
			</template>
		</b-table>
		<b-row class="m-0 justify-content-center" v-if="orderLogsCount > defaultLogsCount">
			<b-button variant="secondary" class="OrderLogs-showButton" @click="isShowingAll = !isShowingAll">{{
				isShowingAll ? $t('Show Less') : $t('Show All')
			}}</b-button>
		</b-row>
	</section>
</template>

<script>
import { mapGetters } from 'vuex';
import _ from 'lodash';
import Date from '../../../components/Date';

export default {
	name: 'OrderLogs',
	components: {
		Date
	},
	data() {
		return {
			isShowingAll: false,
			defaultLogsCount: 3
		};
	},
	computed: {
		...mapGetters({
			order: 'order/order'
		}),
		fields() {
			return [
				{ key: 'message', label: this.$t('Message') },
				{ key: 'timestamp', label: this.$t('Date') }
			];
		},
		orderLogs() {
			return _.get(this.order, 'order.logs');
		},
		orderLogsCount() {
			return _.size(this.orderLogs);
		},
		logs() {
			if (!this.isShowingAll) {
				return _.take(this.orderLogs, this.defaultLogsCount);
			}

			return this.orderLogs;
		}
	},
	methods: {}
};
</script>

<style lang="scss">
@import '../../../styles/variables';

.OrderLogs {
	font-size: 0.95em;
	border-bottom: 1px dashed $cardBorderColor;

	&-showButton {
		margin-top: 1rem;
	}

	table {
		background: transparent;
		th {
			font-size: 0.85em;
			font-weight: normal;
			color: $greyColour;
			text-transform: uppercase;
		}

		td,
		th {
			padding: 0.4em 0.6em 0.4em 0;
			border: none;

			&[aria-colindex='1'] {
				width: 60%;
			}
		}

		td {
			&[aria-colindex='2'] {
				color: $greyColour;
			}
		}
	}
}
</style>
