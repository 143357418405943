import _ from 'lodash';

export default store => {
	const { help } = store.state;
	const initialState = _.merge({ isFirstAccess: true }, help);

	return store.registerModule('help', {
		namespaced: true,
		state: initialState,
		actions: {
			setFirstAccess({ commit }, isFirstAccess) {
				commit('SET_FIRST_ACCESS', isFirstAccess);
			}
		},
		mutations: {
			SET_FIRST_ACCESS(state, isFirstAccess) {
				state.isFirstAccess = isFirstAccess;
			}
		},
		getters: {
			isFirstAccess: state => {
				if (typeof state.isFirstAccess === 'boolean') {
					return state.isFirstAccess;
				}

				return true;
			}
		}
	});
};
