<template>
	<default-layout>
		<iframe class="iframe" :src="frameUrl" frameborder="0" ref="iframe"></iframe>
	</default-layout>
</template>

<script>
import DefaultLayout from '../components/DefaultLayout';

export default {
	components: {
		DefaultLayout
	},
	props: {
		frameUrl: {
			required: true,
			type: String
		}
	}
};
</script>

<style lang="scss" scoped>
.iframe {
	flex: 1;
}
</style>
