<template>
	<DefaultLayout v-if="!isEmbedded">
		<OrderSubmissionForm />
	</DefaultLayout>
	<section class="OrderSubmission OrderSubmission--embedded" v-else>
		<div class="OrderSubmission-top">
			<b-button variant="link" :to="{ name: 'orders.list' }" size="lg">{{ $t('Skip >>') }}</b-button>
		</div>
		<div class="OrderSubmission-content">
			<OrderSubmissionForm />
		</div>
	</section>
</template>

<script>
import DefaultLayout from '../../components/DefaultLayout';
import OrderSubmissionForm from './OrderSubmissionForm';

export default {
	components: {
		DefaultLayout,
		OrderSubmissionForm
	},
	computed: {
		isEmbedded() {
			return !!this.$route.query.embedded;
		}
	}
};
</script>

<style lang="scss">
.OrderSubmission {
	&--embedded {
		display: flex;
		width: 100vw;
		height: 100vh;
		flex-direction: column;
		.OrderSubmission-top {
			width: 100%;
			flex-shrink: 1;
			text-align: right;
		}
		.OrderSubmission-content {
			width: 100%;
			flex-grow: 1;
			overflow: auto;
		}
	}
}
</style>
