<template>
	<DefaultLayout class="TemplateList">
		<ofs-panel>
			<ListTable
				:table-title="$t('Template List')"
				:items="templates"
				:config="config"
				:filter="filter"
				:fields="fieldsArray"
				:total-items="count"
				:per-page="perPage"
				:current-page="currentPage"
				:fetchData="fetchData"
				:loaderAbsolutePosition="true"
				:page-position-prefix="$t('Showing')"
				:page-position-join="$t('of')"
				@table-change="handleTableChange"
				@row-clicked="onTemplateClick"
				:active-filter-options="activeFilterOptions"
				hover
			>
				<template slot="prev-text" slot-scope="{}">
					{{ $t('Prev') }}
				</template>
				<template slot="next-text" slot-scope="{}">
					{{ $t('Next') }}
				</template>
				<template slot="empty">
					<span>
						<i>{{ $t('No Data') }}</i>
					</span>
				</template>
				<template v-slot:cell(name)="trigger">{{ trigger.item.name }}</template>
			</ListTable>
		</ofs-panel>
	</DefaultLayout>
</template>

<script>
import _ from 'lodash';
import { OfsPanel, ListTable } from '@workflow-solutions/ofs-vue-layout';
import { mapGetters, mapActions } from 'vuex';
import DefaultLayout from '../../../components/DefaultLayout';

export default {
	components: {
		ListTable,
		DefaultLayout,
		OfsPanel
	},
	data() {
		return {
			perPage: 10,
			currentPage: 1,
			filter: true,
			isLoading: false
		};
	},
	computed: {
		...mapGetters({
			templatesData: 'template/templates'
		}),
		templates() {
			return _.get(this.templatesData, 'data', []);
		},
		count() {
			return _.get(this.templatesData, 'count', 0);
		},
		fieldsArray() {
			return [{ key: 'name', label: this.$t('Name') }];
		},
		activeFilterOptions() {
			return [
				{ text: this.$t('Active'), value: true },
				{ text: this.$t('Inactive'), value: false }
			];
		},
		config() {
			return {
				activeFilter: { visible: true, title: this.$t('View Active/Inactive') },
				breadcrumbs: { visible: false }
			};
		}
	},
	mounted() {
		this.fetchData();
	},
	methods: {
		...mapActions({ getTemplates: 'template/find' }),
		async fetchData() {
			try {
				this.isLoading = true;
				const query = {
					active: this.filter,
					page: this.currentPage,
					pagesize: this.perPage
				};
				await this.getTemplates({ query });
			} catch (err) {
				this.$notify({
					type: 'error',
					title: this.$t('Error'),
					text: this.$t('An error occurred while fetching templates')
				});
			} finally {
				this.isLoading = false;
			}
		},
		handleTableChange({ currentPage, perPage, filter }) {
			this.currentPage = currentPage;
			this.perPage = perPage;

			if (filter !== undefined) {
				this.filter = filter;
			}
		},
		onTemplateClick({ _id }) {
			this.$router.push({ name: 'template.edit', params: { id: _id } });
		}
	}
};
</script>
