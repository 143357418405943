<template>
	<div>
		<b-modal
			v-model="shouldShowModal"
			size="lg"
			title="File Details"
			modal-class="FileDetail"
			body-class="FileDetail__inner"
			hide-footer
		>
			<ComponentErrors
				v-if="upToDateFile && processingFile"
				:errors="componentFileErrors"
				class="FileDetail__errors"
			/>
			<section class="FileDetail__info">
				<aside class="FileDetail__aside">
					<div class="FilePreview FileDetail__preview" v-if="!upToDateFile.localFile">
						<ul class="FilePreview__thumbnails">
							<Loader class="FilePreview__loader" v-if="processingFile" />
							<li
								v-else
								class="FilePreview__thumbnail"
								v-for="(thumbnail, index) in thumbnails"
								:class="{
									// Thumbnails only generated for first 5
									'FilePreview__thumbnail--active': index === thumbnailIndex,
									'FilePreview__thumbnail--unavailable': index > 4
								}"
								:data-unavailable-title="$t('No Preview')"
								:data-unavailable-text="$t('Download file to view this page')"
								:key="thumbnail"
								:style="{ backgroundImage: index < 5 ? `url('${thumbnail}')` : 'none' }"
							></li>
						</ul>
						<div class="FilePreview__actions" v-if="thumbnails.length > 1">
							<b-button class="FilePreview__action" @click="previousThumbnail">
								<img src="../../public/images/arrow-left.svg" />
							</b-button>
							<span
								class="FilePreview__status"
								v-text="`${thumbnailIndex + 1}/${thumbnails.length}`"
							></span>
							<b-button class="FilePreview__action" @click="nextThumbnail">
								<img src="../../public/images/arrow-right.svg" />
							</b-button>
						</div>
					</div>
					<div class="FilePreview FileDetail__preview" :class="{ error: componentFileErrorsCount }" v-else>
						<ul class="FilePreview__thumbnails">
							<li class="FilePreview__thumbnail FilePreview__thumbnail--active">
								<v-icon class="FilePreview__thumbnail-icon" name="custom-hdd" scale="2" />
								<span class="">Local File</span>
							</li>
						</ul>
					</div>
					<div class="FileDetail__actions" v-if="!processingFile">
						<b-button @click="download(downloadUrl)" v-if="downloadUrl" variant="primary">
							{{ $t('Download File') }}
						</b-button>
						<b-button class="mt-2" @click="viewJobSheet">{{ $t('Print Job Sheet') }}</b-button>
						<b-button
							class="mt-2"
							@click="refetch"
							v-if="!localFile"
							:disabled="!canRefetchFile || isRefetchDisable"
						>
							{{ $t('Refetch file') }}
						</b-button>
					</div>
				</aside>
				<div class="FileDetail__specs">
					<section class="FileDetailSpec">
						<h3 class="FileDetailSpec__title">{{ $t('Document') }}</h3>
						<ul class="FileDetailSpec__list">
							<li class="FileDetailSpec__item">
								<p class="FileDetailSpec__value" :title="upToDateFile.status">
									<span class="FileDetailSpec__key">{{ $t('Status:') }}</span>
									<b-badge :variant="fileStatusVariant">{{ fileStatusLabel }}</b-badge>
								</p>
								<p class="FileDetailSpec__value" v-b-tooltip.hover :title="componentFileName">
									<span class="FileDetailSpec__key">{{ $t('Name:') }}</span> {{ componentFileName }}
								</p>
								<p class="FileDetailSpec__value" v-if="upToDateFile.contentType">
									<span class="FileDetailSpec__key">{{ $t('Type:') }}</span>
									{{ upToDateFile.contentType }}
								</p>
								<p class="FileDetailSpec__value" v-if="upToDateFile.pageCount">
									<span class="FileDetailSpec__key">{{ $t('Page Count:') }}</span>
									{{ upToDateFile.pageCount }}
								</p>
								<p class="FileDetailSpec__value" v-if="upToDateFile.fileSize">
									<span class="FileDetailSpec__key">{{ $t('File Size:') }}</span>
									{{ upToDateFile.fileSize | fileSize }}
								</p>
								<p class="FileDetailSpec__value">
									<span class="FileDetailSpec__key">{{ $t('Date Created:') }}</span>
									{{ upToDateFile.created | date }}
								</p>
								<p class="FileDetailSpec__value">
									<span class="FileDetailSpec__key">{{ $t('Date Modified:') }}</span>
									{{ upToDateFile.updatedAt | date }}
								</p>
							</li>
						</ul>
					</section>
					<section class="FileDetailSpec">
						<template v-if="processColors.length">
							<h3 class="FileDetailSpec__title">{{ $t('Process Inks') }}</h3>
							<ul class="FileDetailSpec__list">
								<li class="FileDetailSpec__item" v-for="color in processColors" :key="color">
									<p class="FileDetailSpec__colour">
										<span
											class="FileDetailSpec__swatch"
											:style="{ background: color }"
											v-text="color"
										></span>
										{{ color }}
									</p>
								</li>
							</ul>
						</template>
						<template v-if="spotColors.length">
							<h3 class="FileDetailSpec__title mt-3">{{ $t('Spot Inks') }}</h3>
							<ul class="FileDetailSpec__list">
								<li class="FileDetailSpec__item" v-for="color in spotColors" :key="color">
									<p class="FileDetailSpec__colour">
										<span
											class="FileDetailSpec__swatch"
											:style="{ background: color }"
											v-text="color"
										></span>
										{{ color }}
									</p>
								</li>
							</ul>
						</template>
					</section>
					<section
						class="FileDetailSpec FileDetailSpec--full"
						v-if="currentRevision && currentRevision.pageConfigs && currentRevision.pageConfigs.length"
					>
						<h2 class="FileDetailSpec__heading">{{ $t('PDF Boxes') }}</h2>
						<div class="FileDetailSpec__boxes">
							<section
								v-for="(config, index) in currentRevision.pageConfigs"
								class="FileDetailSpec__box"
								:key="`config-${index}`"
							>
								<div v-if="getPagesForConfig(index).length">
									<h3
										class="FileDetailSpec__title FileDetailSpec__title--no-margin"
										v-text="`Type ${index + 1}`"
										v-if="hasMultiplePageBoxes"
									></h3>
									<ul class="FileDetailSpec__list" v-if="hasMultiplePageBoxes">
										<li class="FileDetailSpec__item">
											<p class="FileDetailSpec__value">
												<span class="FileDetailSpec__key FileDetailSpec__key--fluid">
													{{ $t('Pages:') }}
												</span>
												{{ getPageRangeStringForConfig(index) }}
											</p>
										</li>
									</ul>
									<measurements
										class="FileDetailSpec__measurements"
										:bleed="getBoxSize(config.boxes.bleed)"
										:trim="getBoxSize(config.boxes.trim)"
										:media="getBoxSize(config.boxes.media)"
									></measurements>
								</div>
							</section>
						</div>
					</section>
				</div>
			</section>
		</b-modal>
	</div>
</template>

<script>
import _ from 'lodash';
import { withSubscriptionMixin } from '@workflow-solutions/ofs-vuex-crud';
import { mapActions, mapGetters } from 'vuex';
import { fileSize, niceDate } from '../lib/filters';
import { downloadFileByUrls as download } from '../lib/helpers';
import Measurements from './Measurements';
import ComponentErrors from './ComponentFileErrors';
import Loader from './Loader';

const processColors = ['cyan', 'magenta', 'yellow', 'black'];

export default {
	mixins: [withSubscriptionMixin],
	components: {
		Measurements,
		Loader,
		ComponentErrors
	},
	props: {
		show: {
			type: Boolean,
			value: false
		},
		onClose: {
			type: Function
		},
		component: {
			type: Object,
			required: true
		},
		componentFile: {
			type: Object,
			required: true
		},
		shipmentStatus: {
			type: String,
			default: ''
		}
	},
	computed: {
		...mapGetters({
			order: 'order/order',
			file: 'file/file',
			orderShipmentsData: 'order/shipments'
		}),
		shouldShowModal: {
			get() {
				return this.show;
			},
			set(show) {
				if (!show) {
					this.onClose();
				}
			}
		},
		isPdf() {
			return this.upToDateFile.contentType === 'application/pdf';
		},
		isImage() {
			return (
				this.upToDateFile.contentType === 'image/jpeg' ||
				this.upToDateFile.contentType === 'image/jpg' ||
				this.upToDateFile.contentType === 'image/png'
			);
		},
		upToDateFile() {
			return this.file || this.componentFile;
		},
		currentRevision() {
			return _.find(this.upToDateFile.versions, { revision: this.upToDateFile.currentRevision }) || {};
		},
		hasMultiplePageBoxes() {
			return this.currentRevision && this.currentRevision.pageConfigs.length > 1;
		},
		processColors() {
			if (this.currentRevision && this.currentRevision.pageConfigs) {
				// Using sets ensures unique values
				const colors = new Set();

				this.currentRevision.pageConfigs.forEach(config => {
					config.colors.forEach(color => {
						const colorKey = color.toLowerCase();

						if (processColors.includes(colorKey)) {
							colors.add(color.charAt(0).toUpperCase() + color.slice(1));
						}
					});
				});

				return Array.from(colors);
			}

			return [];
		},
		spotColors() {
			if (this.currentRevision && this.currentRevision.pageConfigs) {
				// Using sets ensures unique values
				const colors = new Set();

				this.currentRevision.pageConfigs.forEach(config => {
					config.colors.forEach(color => {
						const colorKey = color.toLowerCase();

						if (!processColors.includes(colorKey)) {
							colors.add(color.charAt(0).toUpperCase() + color.slice(1));
						}
					});
				});

				return Array.from(colors);
			}

			return [];
		},
		job() {
			if (this.order) {
				return _.find(this.order.jobs, { fileId: this.component.fileId });
			}

			return null;
		},
		processingFile() {
			return this.upToDateFile.status === 'notreceived';
		},
		componentFileThumbnail() {
			return _.get(this.upToDateFile, 'thumbnailUrl');
		},
		componentFileErrors() {
			const conmponentsFileStatus = _.get(this.upToDateFile, 'status');

			if (conmponentsFileStatus !== 'failed') {
				return [];
			}

			return _.get(this.upToDateFile, 'versions[0].errorList');
		},
		componentFileErrorsCount() {
			return _.size(this.componentFileErrors);
		},
		fileStatusVariant() {
			const variantSwitch = {
				ready: 'success',
				notreceived: 'warning',
				failed: 'danger'
			};
			return variantSwitch[this.upToDateFile.status] || 'secondary';
		},
		fileStatusLabel() {
			const variantSwitch = {
				notreceived: this.$t('Refetching')
			};
			return variantSwitch[this.upToDateFile.status] || this.upToDateFile.status;
		},
		localFile() {
			return _.get(this.component, 'localFile');
		},
		componentFileName() {
			const code = _.get(this.component, 'code');
			const sourceItemId = _.get(this.component, 'sourceItemId');
			const fileExtension = _.last(_.split(_.get(this.upToDateFile, 'path'), '.'));

			return `${sourceItemId}_${code}.${fileExtension}`;
		},
		isRefetchDisable() {
			return ['shipped', 'cancelled'].includes(this.shipmentStatus);
		}
	},
	data() {
		return {
			thumbnails: [],
			canRefetchFile: true,
			thumbnailIndex: 0,
			downloadUrl: null
		};
	},
	filters: {
		fileSize,
		date(d) {
			return niceDate(d, 'ddd Do MMM YYYY HH:mm');
		}
	},
	watch: {
		show: {
			async handler() {
				if (this.show && this.componentFile && this.componentFile._id) {
					await this.getFileById({ id: this.componentFile._id });
					if (this.file && this.file.status === 'notreceived') {
						return this.startFilePolling();
					}
				}

				return this.stopFilePolling();
			},
			immediate: true
		},
		async upToDateFile(newFile) {
			if (newFile && newFile.status === 'ready') {
				await this.stopFilePolling();
				await this.handleFileChange();
			}
		}
	},
	methods: {
		...mapActions({
			getJobSheet: 'template/getInfotech',
			getDownload: 'file/getDownload',
			getThumbnails: 'file/getThumbnails',
			updateFile: 'file/updateFile',
			refetchFile: 'file/refetch',
			observeFile: 'file/observeOne',
			getFileById: 'file/findById',
			resetFile: 'file/reset',
			getShipmentsByOrderId: 'order/getShipmentsByOrderId'
		}),
		nextThumbnail() {
			if (this.thumbnailIndex === this.thumbnails.length - 1) {
				this.thumbnailIndex = 0;
			} else {
				this.thumbnailIndex++;
			}
		},
		previousThumbnail() {
			if (this.thumbnailIndex === 0) {
				this.thumbnailIndex = this.thumbnails.length - 1;
			} else {
				this.thumbnailIndex--;
			}
		},
		getBoxSize(box) {
			if (box) {
				return {
					width: Math.round(box.right - box.left),
					height: Math.round(box.top - box.bottom)
				};
			}

			return null;
		},
		getPagesForConfig(index) {
			const pageConfig = this.currentRevision ? this.currentRevision.pageConfigs[index] : null;
			const pages = [];

			if (!pageConfig) {
				return '-';
			}

			// Iterate over pages and collate all matching config
			_.forEach(this.currentRevision.pages, (config, page) => {
				if (config === index) pages.push(page);
			});

			// Ensure page numbers are in order
			pages.sort((a, b) => a - b);

			return pages;
		},
		// Generates a formatted string of pages e.g. 1-49, 51-100
		getPageRangeStringForConfig(index) {
			const pages = this.getPagesForConfig(index);
			const pageGroups = [];
			let lastPage = pages[0];
			let lastPageGroup = [];

			// Group adjoining blocks of pages
			pages.forEach((page, i) => {
				const diff = Math.abs(page - lastPage);

				if (diff > 1) {
					pageGroups.push(lastPageGroup);
					lastPageGroup = [page];
				} else if (i === pages.length - 1) {
					lastPageGroup.push(page);
					pageGroups.push(lastPageGroup);
				} else {
					lastPageGroup.push(page);
				}

				lastPage = page;
			});

			// Format the groups of pages into a string
			const pageGroupStrings = [];

			pageGroups.forEach(group => {
				if (group.length > 1) {
					pageGroupStrings.push(`${group[0] + 1}-${group[group.length - 1] + 1}`);
				} else {
					pageGroupStrings.push(`${group[0] + 1}`);
				}
			});

			return pageGroupStrings.join(', ');
		},
		async download(url) {
			download(this.$el, [url]);
		},
		async viewJobSheet() {
			const componentIds = [];

			// Get Shipment Component ID
			_.each(this.orderShipmentsData.data, shipment => {
				_.each(shipment.items, item => {
					_.each(item.components, component => {
						if (component.barcode === this.component.barcode) {
							componentIds.push(component._id);
							return false;
						}
					});
				});
			});

			const routeData = this.$router.resolve({
				name: 'job-sheet',
				query: {
					orderIds: [this.order.orderId],
					componentIds
				}
			});
			window.open(routeData.href, '_blank');
		},
		async refetch() {
			try {
				await this.refetchFile(this.upToDateFile._id);
				await this.startFilePolling();

				// Implement throttle to prevent constant refetching
				this.canRefetchFile = false;

				setTimeout(() => {
					this.canRefetchFile = true;
				}, 60000);

				this.$notify({
					type: 'success',
					title: 'Refetching File',
					text: 'It may take a few minutes for us to process the file and for the thumbnail(s) to update'
				});
			} catch (e) {
				this.$notify({
					type: 'error',
					title: 'Ooops',
					text: 'There was a problem refetching the file'
				});
			}
		},
		async startFilePolling() {
			await this.unsubscribeAll();
			return this.addSubscription(this.observeFile({ id: this.upToDateFile._id }));
		},
		async stopFilePolling() {
			return this.unsubscribeAll();
		},
		async handleFileChange() {
			this.downloadUrl = await this.getDownload({
				fileId: this.upToDateFile._id,
				params: { filename: this.componentFileName, preview: false }
			});

			this.thumbnails = await this.getThumbnails(this.upToDateFile._id);

			if (this.componentFileThumbnail && _.isEmpty(this.thumbnails)) {
				this.thumbnails = [this.componentFileThumbnail];
			}
		}
	},
	async mounted() {
		await this.getShipmentsByOrderId(this.order.orderId);
		await this.resetFile();
		this.handleFileChange();
	}
};
</script>

<style lang="scss">
.FileDetail {
	&__inner {
		padding: 0 1rem;
	}

	.modal-dialog {
		max-width: 910px;
	}

	&__info {
		padding: 0;
		display: flex;
		flex-direction: column;

		@media all and (min-width: 600px) {
			flex-direction: row;
		}
	}

	&__button {
		display: flex;
		align-items: center;
		justify-content: center;

		&-icon {
			display: inline-flex;
			width: 16px;
			height: 16px;
			margin: 0 0 1px 5px;
		}
	}

	&__specs {
		padding: 0 1rem 1rem;
		display: flex;
		flex-wrap: wrap;
		flex: 1;
		overflow: hidden;

		@media all and (min-width: 600px) {
			padding: 1rem 0 0;
			flex-direction: row;
		}

		.FileDetailSpec {
			margin-bottom: 1rem;
			max-width: 100%;

			@media all and (min-width: 600px) {
				max-width: 50%;
			}

			&--full {
				max-width: 100%;
				width: 100%;
				padding: 0;
			}
		}
	}

	&__aside {
		padding: 1rem 1rem 1rem 0;
		display: flex;
		flex-direction: column;
		align-items: center;

		@media all and (min-width: 600px) {
			margin-bottom: 0;
		}

		@media all and (min-width: 1000px) {
			margin-right: 1rem;
		}
	}

	&__actions {
		display: flex;
		flex-direction: column;
		max-width: 160px;
	}
}

.FilePreview {
	display: flex;
	position: relative;
	background: rgba(#29c6ff, 0.1);
	padding: 1rem;
	border: 1px solid #ededf5;
	border-radius: 3px;
	margin-bottom: 1rem;
	flex-direction: column;

	&.error {
		background-color: #f8eded;
		border: solid 1px #e3b0b3;
	}

	&__actions {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;
		list-style: none;
		margin: 0;
		padding: 1rem 0 0;
	}

	&__action {
		border-radius: 50%;
		width: 26px;
		height: 26px;
		padding: 0;
		display: flex;
		justify-content: center;
		align-items: center;
	}

	&__status {
		font-size: 0.9rem;
		padding: 0 1rem;
	}

	&__thumbnails {
		flex: 1;
		position: relative;
		margin: 0;
		padding: 0;
		list-style: none;
		min-width: 200px;
		min-height: 250px;

		@media all and (min-width: 800px) {
			min-width: 260px;
			min-height: 300px;
		}
	}

	&__thumbnail {
		background-position: 50%;
		background-size: contain;
		background-repeat: no-repeat;
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		opacity: 0;
		transition: opacity 0.2s ease-in-out;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		font-weight: bold;
		font-size: 0.8rem;

		&-icon {
			margin-bottom: 0.25rem;
			opacity: 0.7;
		}

		&--active {
			opacity: 1;
		}

		&--unavailable {
			display: flex;
			flex-direction: column;
			font-size: 0.8rem;

			&:before {
				content: attr(data-unavailable-title);
				text-transform: uppercase;
				letter-spacing: 1px;
				opacity: 0.8;
			}

			&:after {
				content: attr(data-unavailable-text);
				font-weight: normal;
				opacity: 0.6;
			}
		}
	}

	&__loader {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}
}

.FileDetailSpec {
	$lightText: #96a0af;
	$darkText: #3a3f51;

	padding-right: 1rem;

	&__heading {
		text-transform: uppercase;
		color: lighten($darkText, 30);
		font-size: 12px;
		font-weight: bold;
		letter-spacing: 1px;
		margin: 0;
		padding: 0;
	}

	&__title {
		text-transform: uppercase;
		color: #8088a4;
		font-size: 12px;
		font-weight: bold;
		letter-spacing: 1px;
		margin: 0 0 0.5rem;
		padding: 0;

		&--no-margin {
			margin: 0;
		}
	}

	&__note {
		font-size: 0.8rem;
		color: $lightText;
	}

	&__key {
		font-size: 0.8rem;
		color: $lightText;
		min-width: 100px;
		display: inline-block;

		&--fluid {
			min-width: auto;
		}
	}

	&__value {
		font-size: 0.8rem;
		color: $darkText;
		margin: 0;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		width: 100%;
	}

	&__boxes {
		display: flex;
		flex-wrap: wrap;
	}

	&__box {
		margin-top: 1rem;
		padding-right: 1rem;
		flex-basis: 100%;

		@media all and (min-width: 500px) {
			flex-basis: 50%;
		}
	}

	&__measurements {
		min-height: 200px;
		max-width: 180px;
	}

	&__list {
		list-style: none;
		margin: 0;
		padding: 0;
	}

	&__item {
		margin: 0;
		padding: 0;
		line-height: 1.8rem;
	}

	&__colour {
		display: flex;
		align-items: center;
		margin: 0;
		line-height: 1.8rem;
		font-size: 0.8rem;
		color: $darkText;
	}

	&__swatch {
		width: 15px;
		height: 15px;
		text-indent: -999px;
		overflow: hidden;
		border-radius: 50%;
		display: inline-block;
		margin-right: 1rem;
		background: rgba(#000000, 0.05);
	}
}
</style>
