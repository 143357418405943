import Vue from 'vue';
import _ from 'lodash';

export default function() {
	Vue.$ofsCrud.addResource({
		name: 'search',
		pluralName: 'search',
		options: {
			namespaced: true
		},
		state: {
			searchTerm: '',
			results: [],
			showSearchModal: false,
			page: 0,
			total: 0,
			totalPages: 0,
			facets: null,
			filters: [],
			indexes: [],
			index: null
		},
		mutations: {
			SET_SEARCH_TERM(state, searchTerm) {
				state.searchTerm = searchTerm;
			},
			CLEAR_SEARCH_RESULTS(state) {
				state.total = 0;
				state.totalPages = 0;
				state.page = 0;
				state.results = [];
			},
			CLEAR_FILTERS(state) {
				state.filters = [];
			},
			RESET_SEARCH(state) {
				state.facets = null;
				state.searchTerm = '';
				state.filters = [];
			},
			SET_SEARCH_RESULTS(state, response) {
				const data = response.data;

				state.results = _.uniqBy([...state.results, ...data.hits], 'objectID');
				state.total = data.nbHits;
				state.facets = data.facets;
				state.totalPages = data.nbPages;
			},
			SHOW_SEARCH(state) {
				state.showSearchModal = true;
			},
			HIDE_SEARCH(state) {
				state.showSearchModal = false;
			},
			SET_PAGE(state, page) {
				state.page = page;
			},
			ADD_FILTER(state, filter) {
				state.filters = [...state.filters, filter];
			},
			REMOVE_FILTER(state, filter) {
				state.filters = _.without(state.filters, filter);
			},
			SET_INDEX(state, index) {
				state.index = _.find(state.indexes, { name: index });
			},
			SET_INDEXES(state, indexes) {
				state.indexes = indexes;
				state.index = indexes[0]; // Default
			}
		},
		actions: {
			setIndex({ commit, state }, name) {
				if (name !== state.index.name) {
					commit('CLEAR_FILTERS', name);
					commit('SET_INDEX', name);
				}
			},
			setIndexes({ commit }, indexes) {
				commit('SET_INDEXES', indexes);
			},
			showSearchModal({ commit }) {
				commit('SHOW_SEARCH');
			},
			hideSearchModal({ commit }) {
				commit('HIDE_SEARCH');
			},
			search({ state, dispatch, commit }, { searchTerm }) {
				commit('SET_SEARCH_TERM', searchTerm);

				const path = `search/facet/${state.index.name}?page=${state.page}`;
				const payload = {
					facetFilters: state.filters,
					query: state.searchTerm
				};

				return dispatch('request', { method: 'POST', path, payload }).then(response => {
					commit('SET_SEARCH_RESULTS', response);
				});
			},
			toggleFilter({ state, commit }, filter) {
				if (_.includes(state.filters, filter)) {
					commit('REMOVE_FILTER', filter);
				} else {
					commit('ADD_FILTER', filter);
				}
			},
			loadMore({ commit, dispatch, state }) {
				if (state.page === state.totalPages - 1) return;

				commit('SET_PAGE', state.page + 1);

				dispatch('search', {
					searchTerm: state.searchTerm
				});
			},
			// Completely resets search results, filters and search term
			reset({ commit }) {
				commit('CLEAR_SEARCH_RESULTS');
				commit('RESET_SEARCH');
			},
			// Clears all search results
			clear({ commit }) {
				commit('CLEAR_SEARCH_RESULTS');
			}
		},
		getters: {
			maxTotal: state => state.maxTotal,
			totalResults: state => state.total,
			totalPages: state => state.totalPages,
			page: state => state.page,
			results: state => state.results,
			searchTerm: state => state.searchTerm,
			showSearchModal: state => state.showSearchModal,
			facets: state => state.facets,
			indexes: state => state.indexes,
			index: state => state.index,
			filters: state => state.filters
		}
	});
}
