import * as LDClient from 'launchdarkly-js-client-sdk';
import Vue from 'vue';

/**
 * Configuration from the combined.js file
 */
const clientKey = window.$config.ldClientKey;

/**
 * Saves the feature flags into the store
 * @param store
 * @param client
 * @returns {Promise<*>}
 */
const storeFeatures = async ({ store, client }) => {
	const features = client.allFlags();
	await store.dispatch('featureToggle/setFeatures', { features, method: 'replace' });
	return features;
};

/**
 * Creates a LD user object
 * @param store
 * @returns {Promise<*>}
 */
const createLDUser = async store => {
	const vars = store.getters['account/vars'];

	if (!vars || !vars.user) {
		return {
			key: 'anonymous'
		};
	}

	return {
		key: vars.user.username,
		name: vars.user.name,
		email: vars.user.email,
		custom: {
			appName: 'Siteflow Light',
			authId: vars.user.authId,
			accountId: vars.accountId
		}
	};
};

/**
 * Creates a LD client
 * @param store
 * @param user
 * @returns {Promise<LDClient>}
 */
const createClient = async ({ store, user }) => {
	const client = LDClient.initialize(clientKey, user);
	await client.waitForInitialization();
	client.on('change', () => storeFeatures({ store, client }));

	return client;
};

/**
 * Setup the feature flags store
 * @param store The store instance
 * @returns {Promise<void>}
 */
export default async function(store) {
	// reset the feature flag list
	await Vue.nextTick(() => store.dispatch('featureToggle/setFeatures', { features: null, method: 'replace' }));

	// initialise launchdarkly client
	let isAuthenticated = store.getters['auth/isAuthenticated'];
	let user = store.getters['auth/user'];
	let vars = store.getters['account/vars'];

	const checkAndStoreFlags = async (forceCheck = false) => {
		const isUserChanged = store.getters['auth/user']?._id !== user?._id;
		const isAuthenticationChanged = store.getters['auth/isAuthenticated'] !== isAuthenticated;
		const areVarsChanged = store.getters['account/vars']?.accountId !== vars?.accountId;
		const hasUser = !!store.getters['auth/user'];
		const hasAccountId = !!store.getters['account/vars']?.accountId;
		const hasAuthInfo = hasUser && hasAccountId && (isUserChanged || isAuthenticationChanged || areVarsChanged);

		// something has changed, we have to re-identify and fetch the features
		if (forceCheck || hasAuthInfo) {
			isAuthenticated = store.getters['auth/isAuthenticated'];
			user = store.getters['auth/user'];
			vars = store.getters['account/vars'];
			const userObj = await createLDUser(store);

			const client = await createClient({ store, user: userObj });
			await storeFeatures({ store, client });
		}
	};

	const featureStore = {
		namespaced: true,
		actions: {
			checkAndStoreFlags
		}
	};

	store.registerModule('featureFlags', featureStore);

	// listen for changes on authentication status, so we can re-identify the user
	store.subscribe(() => checkAndStoreFlags());

	// run it the first time if not authenticated
	return checkAndStoreFlags(!isAuthenticated);
}
