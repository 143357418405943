<template>
	<b-card no-body class="Content" :class="{ 'Content--highlighted': itemHighlighted }">
		<b-card-header
			header-tag="header"
			role="tab"
			v-b-toggle="id"
			class="Content-header"
			:class="{ error: totalItemErrors }"
		>
			<b-row class="m-0 w-100 align-items-center">
				<section class="Content-icon d-flex justify-content-center align-items-center">
					<i class="ion-ios-arrow-down" />
				</section>
				<section class="Content-headerText d-flex justify-content-between align-items-center pl-2">
					<div class="d-flex align-items-center">
						<span class="Content-headerTitle">{{ itemHeader }}</span>
						<b-badge
							v-if="totalItemErrors"
							variant="danger"
							class="Content-headerError ml-1 px-2 py-1 rounded-circle"
						>
							{{ totalItemErrors }}
						</b-badge>
					</div>
					<div class="d-flex flex-row justify-content-center align-items-center">
						<order-status :status="status" class="Content-headerStatus"></order-status>
						<div class="Content-headerTextCopies">
							<span class="Content-headerTextCopiesLabel">{{ $t('Copies') }}: </span>
							<span class="Content-headerTextCopiesText">{{ quantity }}</span>
						</div>
					</div>
				</section>
			</b-row>
		</b-card-header>
		<b-row class="Content-content m-0" :class="{ last: isLast }">
			<b-collapse :id="id" v-model="localIsVisible" role="tabpanel">
				<section class="Content-info d-flex px-3" :class="{ error: totalItemErrors }">
					<div class="Content-barcode mr-2" v-if="barcode">
						<span class="Content-infoLabel">{{ $t('Barcode') }}</span>
						<span class="Content-infoText">{{ barcode }}</span>
					</div>
					<div class="Content-description" v-if="description">
						<span class="Content-infoLabel">{{ $t('Description') }}</span>
						<span class="Content-infoText">{{ description }}</span>
					</div>
				</section>
				<ContentComponent
					v-for="component in components"
					:highlightedComponent="highlightedComponent"
					:refresh="refresh"
					:key="component._id"
					:component="component"
					:shipmentStatus="shipmentStatus"
				/>
			</b-collapse>
		</b-row>
	</b-card>
</template>

<script>
import _ from 'lodash';
import { mapGetters } from 'vuex';
import OrderStatus from '../../../../components/OrderStatus';
import ContentComponent from './ContentComponent';

export default {
	name: 'Content',
	components: {
		ContentComponent,
		OrderStatus
	},
	props: {
		showAllErroredItems: {
			type: Boolean,
			default: false
		},
		item: {
			type: Object,
			default: () => ({})
		},
		highlightedComponent: {
			type: [String, null]
		},
		isVisible: {
			type: Boolean,
			default: false
		},
		isLast: {
			type: Boolean,
			default: false
		},
		refresh: {
			type: Function,
			default: () => {}
		},
		shipmentStatus: {
			type: String,
			default: ''
		}
	},
	data() {
		return {
			localIsVisible: false
		};
	},
	computed: {
		...mapGetters({
			order: 'order/order'
		}),
		id() {
			return _.get(this.item, '_id');
		},
		itemHeader() {
			return `${this.item.sourceItemId} (${this.item.sku})`;
		},
		quantity() {
			return _.get(this.item, 'quantity');
		},
		status() {
			return _.get(this.item, 'status');
		},
		sourceItemId() {
			return _.get(this.item, 'sourceItemId');
		},
		components() {
			const components = _.get(this.item, 'components', []);
			return _.map(components, component => ({ ...component, sourceItemId: this.sourceItemId }));
		},
		componentFileIds() {
			return _.map(this.components, 'fileId', []);
		},
		barcode() {
			return _.get(this.item, 'barcode');
		},
		description() {
			return _.get(this.item, 'description');
		},
		itemHighlighted() {
			return (
				this.item.barcode === this.highlightedComponent ||
				this.components.map(component => component.barcode).includes(this.highlightedComponent)
			);
		},
		allFiles() {
			return _.get(this.order, 'files', []);
		},
		itemFiles() {
			return _.filter(this.allFiles, ({ _id }) => this.componentFileIds.includes(_id));
		},
		failedIemFiles() {
			return _.filter(this.itemFiles, { status: 'failed' });
		},
		filesErrorsCount() {
			return _.map(this.failedIemFiles, file => {
				const fileErrors = _.get(file, 'versions[0].errorList');
				return _.size(fileErrors);
			});
		},
		totalItemErrors() {
			return _.sum(this.filesErrorsCount);
		}
	},
	watch: {
		showAllErroredItems(newValue) {
			if (newValue && this.totalItemErrors) {
				this.localIsVisible = newValue;
			}
		},
		isVisible: {
			immediate: true,
			handler(value) {
				this.localIsVisible = value;
			}
		}
	}
};
</script>

<style lang="scss" scoped>
@import '../../../../styles/variables';

.Content {
	border-radius: 0;
	border: none;
	overflow: hidden;
	transition: background 0.3s ease-in-out;

	&-info {
		font-size: 0.85rem;

		&.error {
			background-color: $colourErrorComponentBackground;
		}

		&Label {
			&:after {
				content: ':';
			}
		}
	}

	&--highlighted {
		background: rgba($primaryColour, 0.1);
	}

	&-header {
		background: transparent;
		border: none;
		cursor: pointer;
		line-height: 1;
		display: flex;
		flex-direction: row;
		align-items: center;

		&.error {
			background-color: $colourErrorComponentBackground;
		}

		&Error {
			font-size: 0.8rem;
			background-color: $colourOrderFileErrorBackground;
		}

		.Content-icon {
			transition: all 0.3s;
			font-size: 14px;
			width: 14px;
			height: 14px;
		}

		&.collapsed {
			.Content-icon {
				transform: rotate(-90deg);
			}
		}

		&Text {
			flex: 1;

			&Copies {
				&Label {
					font-size: 0.85em;
					font-weight: normal;
					color: $greyColour;
				}
			}
		}

		&Status {
			margin-right: 0.5rem;
		}
	}

	&-content {
		border-bottom: 1px solid $cardBorderColor;
		display: flex;
		flex-direction: column;
		flex: 1;

		> .collapse {
			max-width: 100%;
		}

		&.last {
			border-bottom: none;
		}
	}
}
</style>
