import Vue from 'vue';
import _ from 'lodash';

export default function() {
	Vue.$ofsCrud.addResource({
		name: 'job',
		options: { namespaced: true },
		actions: {
			getJobByComponentBatchId: ({ dispatch }, id) => {
				const path = `job/?productComponentBatchId=${id}`;
				return dispatch('request', { method: 'GET', path }).then(r => r.data.data[0]);
			},
			getJob: ({ dispatch }, params) =>
				dispatch('request', { method: 'GET', path: 'job', params }).then(r => {
					const reprint = _.maxBy(_.filter(r.data.data, { reprint: true }), job => new Date(job.createdAt));

					return reprint || r.data.data[0];
				}),
			getJobById: ({ dispatch }, id) => {
				const path = `job/${id}`;
				return dispatch('request', { method: 'GET', path }).then(r => r.data.data[0]);
			}
		}
	});
}
