<template>
	<DefaultLayout class="LinkedAccount">
		<OfsPanel class="mx-3 mb-3">
			<AsyncLoader :fetch-data="fetchData">
				<ListTable
					:table-title="$t('Linked Accounts')"
					:config="config"
					bordered
					:items="accounts"
					:current-page="currentPage"
					:fields="fields"
					:total-items="count"
					:per-page="perPage"
					:selected="selected"
					:fetch-data="fetchData"
					:page-position-prefix="$t('Showing')"
					:page-position-join="$t('of')"
					:active-filter-options="activeFilterOptions"
					:default-active-filter-value="filter"
					@table-change="handleTableChange"
				>
					<template slot="TableHeader" slot-scope="{}">
						<div class="info-table">
							<font-awesome-icon icon="info-circle" class="info-icon" @click="openInfoModal" />
							<div v-for="(request, key) in permissionsToAcknowledge" :key="key" class="acknowledge-info">
								<font-awesome-icon icon="check-circle" class="check-icon" />
								<span v-if="request.status === 'accepted'" data-test-id="permissionAccepted">
									{{ $t('Permission ') }}{{ request.permission }} {{ $t(' was accepted by account ')
									}}{{ accountNames[request.accountDestinationId] }}
								</span>
								<span v-else-if="request.status === 'declined'" data-test-id="permissionDeclined">
									{{ $t('Permission ') }}{{ request.permission }}
									{{ $t(' was declined by account ') }}
									{{ accountNames[request.accountDestinationId] }}
								</span>
								<span v-else-if="request.status === 'disabled'" data-test-id="permissionRemoved">
									{{ $t('Permission ') }}{{ request.permission }}
									{{ $t(' was removed by account ') }}
									{{ accountNames[request.accountId] }}
								</span>
								<font-awesome-icon
									icon="times-circle"
									class="acknowledge-icon"
									@click="handleAcknowledgePermission(request)"
								/>
							</div>
							<router-link
								v-if="pendingPermissionsCount"
								:to="{ name: 'linkcode.list' }"
								class="info-text"
								data-test-id="pendingRequests"
							>
								<div class="pending-info">
									<font-awesome-icon icon="info-circle" class="pending-icon" />
									<span v-if="pendingPermissionsCount === 1">
										{{ $t('You have ') }}{{ pendingPermissionsCount }}
										{{ $t(' pending request') }}
									</span>
									<span v-else>
										{{ $t('You have ') }}{{ pendingPermissionsCount }}
										{{ $t(' pending requests') }}
									</span>
								</div>
							</router-link>
						</div>
					</template>
					<template slot="TableButtons-Slot-right" slot-scope="{}">
						<b-btn class="ml-2" variant="secondary" @click="openVerifyLinkModal">
							{{ $t('Verify Invitation') }}
						</b-btn>
						<b-btn class="ml-2" variant="primary" @click="openNewLinkModal">
							{{ $t('New Invitation') }}
						</b-btn>
					</template>
					<template slot="empty" slot-scope="{}">
						<span>
							<i>{{ $t('No data') }}</i>
						</span>
					</template>
					<template slot="next-text" slot-scope="{}">
						{{ $t('Next') }}
					</template>
					<template slot="prev-text" slot-scope="{}">
						{{ $t('Prev') }}
					</template>
					<template #cell(active)="{ item }">
						<of-toggle v-if="isActive" :value="isActive" @input="openDeactivateLinkModal(item)" />
						<of-toggle v-else :value="isActive" @input="handleActivate(item)" />
					</template>
					<template #cell(actions)="{ item }">
						<b-dropdown right size="sm" no-caret>
							<template slot="button-content">
								<font-awesome-icon icon="ellipsis-h" />
							</template>
							<b-dropdown-item
								data-test-id="openEditPermissionsModal"
								@click="openEditPermissionsModal(item)"
							>
								<icon name="edit" class="mr-1" />
								{{ $t('Edit Permissions') }}
							</b-dropdown-item>
						</b-dropdown>
					</template>
					<template v-if="!displayEmptyList && !pendingPermissionsCount" slot="empty">
						<Empty
							:title="$t('You have no linked accounts yet')"
							:description="$t('Invite an account to get started')"
						>
							<template slot="action">
								<b-btn @click="openNewLinkModal">
									<icon name="plus" class="mr-1" />
									New Invite
								</b-btn>
							</template>
						</Empty>
					</template>
				</ListTable>
			</AsyncLoader>
		</OfsPanel>
		<new-link-modal :visible="isNewLinkModalVisible" @hide="handleNewLinkModalClose" @create="getLinkcodesCount" />
		<verify-link-modal
			:visible="isVerifyLinkModalVisible"
			:filter="filter"
			:update-url="updateUrl"
			@update="handleItemUpdated"
			@hide="handleVerifyLinkModalClose"
		/>
		<deactivate-link-modal
			:visible="isDeactivateLinkModalVisible"
			:item="selectedItem"
			:partner-name="partnerName"
			@update="handleItemUpdated"
			@hide="handleDeactivateLinkModalClose"
		/>
		<info-modal :visible="isInfoModalVisible" @hide="handleInfoModalClose" />
		<edit-permissions-modal
			:visible="isEditPermissionsModalVisible"
			:partner-name="partnerName"
			:partner-id="partnerId"
			:account-link="selectedItem"
			@update="handleItemUpdated"
			@hide="handleEditPermissionsModalClose"
		/>
	</DefaultLayout>
</template>

<script>
import moment from 'moment';
import { mapActions, mapGetters } from 'vuex';
import { ListTable, OfsPanel, OfToggle, Empty } from '@workflow-solutions/ofs-vue-layout';
import { AsyncLoader, navigationMixin, tableMixin } from '@workflow-solutions/siteflow-section-layout';
import VerifyLinkModal from './VerifyLinkModal';
import NewLinkModal from './NewLinkModal';
import DefaultLayout from '../../../components/DefaultLayout';
import DeactivateLinkModal from './DeactivateLinkModal';
import InfoModal from './InfoModal';
import EditPermissionsModal from './EditPermissionsModal';
import _ from 'lodash';
import { $t } from '../../../vuex';
import { displayError } from '../../../lib/helpers';
import { account } from '../../../vuex/account/getters';

export default {
	name: 'LinkedAccount',
	components: {
		ListTable,
		OfsPanel,
		OfToggle,
		DefaultLayout,
		VerifyLinkModal,
		NewLinkModal,
		DeactivateLinkModal,
		InfoModal,
		AsyncLoader,
		EditPermissionsModal,
		Empty
	},
	mixins: [navigationMixin, tableMixin],
	data() {
		return {
			fields: [
				{ key: 'name', label: this.$t('Name') },
				{ key: 'permissions', label: this.$t('Permissions') },
				{ key: 'createdAt', label: this.$t('Date added') },
				{ key: 'active', label: this.$t('Active') },
				{ key: 'actions', label: this.$t('Actions') }
			],
			config: {
				activeFilter: { visible: true, title: this.$t('View Active/Inactive') },
				search: { visible: false },
				breadcrumbs: { visible: false },
				refresh: { visible: false },
				columns: { visible: false }
			},
			activeFilterOptions: [
				{ text: this.$t('Active'), value: true },
				{ text: this.$t('Inactive'), value: false }
			],
			selected: [],
			isVerifyLinkModalVisible: false,
			isNewLinkModalVisible: false,
			isDeactivateLinkModalVisible: false,
			isInfoModalVisible: false,
			isEditPermissionsModalVisible: false,
			selectedItem: null,
			partnerName: '',
			partnerId: null,
			allLinks: []
		};
	},
	computed: {
		...mapGetters({
			vars: 'account/vars',
			count: 'accountlink/count',
			accountlinks: 'accountlink/accountlinks',
			linkcodes: 'linkcode/linkcodes',
			permissionsToAcknowledge: 'linkcode/permissionsToAcknowledge',
			pendingPermissionsCount: 'linkcode/pendingPermissionsCount'
		}),
		filter() {
			const queryFilter = _.get(this.$route, 'query.filter', true);
			return typeof queryFilter === 'string' ? queryFilter === 'true' : queryFilter;
		},
		displayEmptyList() {
			return this.count === 0 && !this.filter;
		},
		accounts() {
			return this.accountlinks.map(accountlink => {
				const account = {};
				account._id = accountlink._id;
				account.name = accountlink.accountNameA;
				account.id = accountlink.accountIdA;
				if (this.vars.currentAccount === accountlink.accountNameA) {
					account.name = accountlink.accountNameB;
					account.id = accountlink.accountIdB;
				}
				if (accountlink.permissions.length > 0) {
					account.permissions = accountlink.permissions
						.map(p => {
							if (p === 'ordersubmission') return this.$t('Order Submission');
							if (p === 'outsource') return this.$t('Outsourcing');
						})
						.join(', ');
				} else {
					account.permissions = '-';
				}
				account.createdAt = this.formatDate(accountlink.createdAt);
				account.active = accountlink.active;
				return account;
			});
		},
		accountNames() {
			let names = [];
			_.each(this.allLinks, link => {
				link.accountIdA === this.vars.accountId
					? (names[link.accountIdB] = link.accountNameB)
					: (names[link.accountIdA] = link.accountNameA);
			});
			return names;
		},
		isActive() {
			return this.filter;
		}
	},
	watch: {
		filter() {
			this.fetchData();
		},
		'$route.query.verify': {
			immediate: true,
			handler(val) {
				if (val === 'true' && !this.isVerifyLinkModalVisible) {
					this.openVerifyLinkModal();
				}
			}
		},
		'$route.params.invite': {
			immediate: true,
			handler(val) {
				if (val === 'newinvite' && !this.isNewLinkModalVisible) {
					this.openNewLinkModal();
				}
			}
		}
	},
	async created() {
		this.loadNotifications();
		await this.fetchData();
	},
	methods: {
		...mapActions({
			getAccountLinks: 'accountlink/find',
			getAllAccountLinks: 'accountlink/findAll',
			getLinkcodes: 'linkcode/fetchPermissionsByStatus',
			activate: 'accountlink/activate',
			acknowledgeAccepted: 'accountlink/acknowledgeAccepted',
			acknowledgeDeclined: 'accountlink/acknowledgeDeclined',
			acknowledgeDisabled: 'accountlink/acknowledgeDisabled',
			resetPermissions: 'linkcode/resetPermissions'
		}),
		async fetchData() {
			const modalOpen = this.$route.query.verify;
			const linkCode = this.$route.query.linkCode;
			this.updateUrl({ filter: this.filter, modalOpen, linkCode });
			try {
				await this.getAccountLinks({
					query: {
						active: this.filter,
						page: this.currentPage,
						pagesize: this.perPage
					}
				});
			} catch (err) {
				this.$notify({ type: 'error', text: $t(this.$errorUtils.displayError(err)) });
			}
		},
		async loadNotifications() {
			this.resetPermissions();
			this.allLinks = [];
			try {
				// getting all links to determine partners' account names
				this.allLinks = await this.getAllAccountLinks({
					query: { active: true },
					options: { skipMutations: true }
				});
				await Promise.all([
					this.getLinkcodes({ query: { status: 'pending' } }),
					this.getLinkcodes({ query: { status: 'acknowledge' } })
				]);
			} catch (err) {
				this.$notify({ type: 'error', text: $t(this.$errorUtils.displayError(err)) });
			}
		},
		async getLinkcodesCount() {
			await this.getLinkcodes({ query: { status: 'pending' } });
		},
		displayPartnerName(accountId) {
			return accountId;
		},
		openVerifyLinkModal() {
			this.isVerifyLinkModalVisible = true;
		},
		handleVerifyLinkModalClose() {
			this.isVerifyLinkModalVisible = false;
		},
		openNewLinkModal() {
			this.isNewLinkModalVisible = true;
		},
		handleNewLinkModalClose() {
			this.isNewLinkModalVisible = false;
		},
		openDeactivateLinkModal(item) {
			this.selectedItem = item;
			this.partnerName = item.name;
			this.isDeactivateLinkModalVisible = true;
		},
		handleDeactivateLinkModalClose() {
			this.isDeactivateLinkModalVisible = false;
		},
		openInfoModal() {
			this.isInfoModalVisible = true;
		},
		handleInfoModalClose() {
			this.isInfoModalVisible = false;
		},
		async handleItemUpdated() {
			// get updated list of linked accounts' permisions
			this.fetchData();
		},
		// activate doesn't require confirmation modal so we handle it here,
		// while deactivation is triggered from deactivate modal
		async handleActivate(item) {
			try {
				await this.activate({ linkId: item._id });
				this.$notify({ type: 'success', text: $t('Account link activated') });
				this.fetchData();
			} catch (err) {
				this.$notify({ type: 'error', text: $t(this.$errorUtils.displayError(err)) });
			}
		},
		async handleAcknowledgePermission(request) {
			try {
				if (request.status === 'accepted') {
					await this.acknowledgeAccepted({ requestId: request._id });
				} else if (request.status === 'declined') {
					await this.acknowledgeDeclined({ requestId: request._id });
				} else if (request.status === 'disabled') {
					await this.acknowledgeDisabled({ requestId: request._id });
				}
				this.$notify({ type: 'success', text: $t('Permission request acknowledged') });
				await this.getLinkcodes({ query: { status: 'acknowledge' } });
			} catch (err) {
				this.$notify({ type: 'error', text: $t(this.$errorUtils.displayError(err)) });
			}
		},
		openEditPermissionsModal(accountLink) {
			this.isEditPermissionsModalVisible = true;
			this.selectedItem = accountLink;
			this.partnerName = accountLink.name;
			this.partnerId = accountLink.id;
		},
		handleEditPermissionsModalClose() {
			this.isEditPermissionsModalVisible = false;
			this.selectedItem = null;
		},
		formatDate(time) {
			return moment(time).accountFormat(' LT');
		}
	}
};
</script>

<style lang="scss">
@import '../../../styles/shared';

.LinkedAccount {
	.acknowledge-info {
		text-align: left;
		padding-top: 5px;
		padding-bottom: 5px;
		margin-bottom: 4px;
		border: 1px solid $of-color-status-ready;
		border-radius: 3px;
		background-color: #ebf9f2;
	}

	.check-icon {
		color: $of-color-status-ready;
		margin: 5px 10px auto 15px;
	}

	.acknowledge-icon {
		color: $of-color-grey-1;
		margin: 5px 10px auto 15px;
		float: right;
		cursor: pointer;

		&:hover {
			color: $of-color-grey-2;
		}
	}

	.pending-info {
		display: flex;
		align-items: center;
		padding: 0.5rem 1rem;
		margin-bottom: 20px;
		border: 1px solid $of-color-orange;
		border-radius: 3px;
		background-color: #fef2e6;
	}

	.pending-icon {
		color: $of-color-orange;
		margin: 0 0.5rem 0 0;
	}

	.info-table {
		position: relative;

		.info-text {
			color: inherit;
			text-decoration: none;
		}

		.info-icon {
			color: $of-color-grey-3;
			position: absolute;
			top: -45px;
			left: 150px;
			height: 15px;
			width: 15px;
			cursor: pointer;
			z-index: 5;
		}
	}
}
</style>
