import { i18n } from '../../../vuex';
const $t = str => i18n.t(str);

const getOutsourcingStatusText = status => {
	const variants = {
		pending: $t('Pending'),
		paired: $t('Active'),
		validateerror: $t('Error'),
		declined: $t('Declined'),
		disabled: $t('Disabled')
	};

	return variants[status.toLowerCase()];
};

const getOutsourcingStatus = status => {
	const variants = {
		pending: $t('Warning'),
		paired: $t('Live'),
		validateerror: $t('Error'),
		declined: $t('Error'),
		disabled: $t('Disabled')
	};

	return variants[status.toLowerCase()];
};

const getDetailedStatus = (pairedProduct, currentUserAccountId) => {
	const isSourceAccount = currentUserAccountId === pairedProduct.sourceAccountId;
	const isDestinationAccount = currentUserAccountId === pairedProduct.destinationAccountId;
	const disabledBy = pairedProduct.disabledBy && getDisabledByAccountName(pairedProduct);
	let variants = {};
	if (isSourceAccount) {
		variants = {
			pending: $t('Request sent - Awaiting response'),
			declined: $t('Pairing request declined'),
			validateerror: $t('Request sent - Awaiting response'),
			paired: null,
			disabled: `${$t('Disabled by ')}${disabledBy}`
		};
	} else if (isDestinationAccount) {
		variants = {
			pending: $t('Awaiting your response'),
			declined: null,
			validateerror: $t('SKU is not compatible, click for details'),
			paired: null,
			disabled: `${$t('Disabled by ')}${disabledBy}`
		};
	}

	return variants[pairedProduct.status.toLowerCase()];
};

const getPairedProductActionsList = (pairedProduct, currentUserAccountId) => {
	const isSourceAccount = currentUserAccountId === pairedProduct.sourceAccountId;
	const isDestinationAccount = currentUserAccountId === pairedProduct.destinationAccountId;
	let actionsIds = {};
	let disabledBy = pairedProduct.status === 'disabled' ? pairedProduct.disabledBy : null;
	const isDisabledBySource = isSourceAccount && pairedProduct.sourceAccountId === disabledBy;
	const isDisabledByDestination = isDestinationAccount && pairedProduct.destinationAccountId === disabledBy;

	if (isSourceAccount) {
		actionsIds = {
			pending: ['delete'],
			declined: ['delete'],
			validateerror: ['delete'],
			paired: ['disable', 'delete'],
			disabled: isDisabledBySource ? ['enable', 'delete'] : ['delete']
		};
	} else if (isDestinationAccount) {
		actionsIds = {
			pending: ['acceptRequest', 'declineRequest'],
			declined: ['hidden'],
			paired: ['disable'],
			disabled: isDisabledByDestination ? ['enable'] : []
		};
	}

	return actionsIds[pairedProduct.status.toLowerCase()];
};

const getPairedProductActionTextById = actionId => {
	const variants = {
		delete: $t('Delete'),
		disable: $t('Disable'),
		enable: $t('Enable'),
		acceptRequest: $t('Accept Request'),
		declineRequest: $t('Decline Request'),
		hidden: null
	};
	return variants[actionId];
};
const getActionIconName = actionId => {
	const variants = {
		delete: 'trash-alt',
		disable: 'power-off',
		enable: 'power-off',
		acceptRequest: 'check',
		declineRequest: 'times',
		hidden: null
	};
	return variants[actionId];
};

const getDisabledByAccountName = pairedProductData => {
	return pairedProductData.disabledBy === pairedProductData.sourceAccount.id
		? pairedProductData.sourceAccount.name
		: pairedProductData.destinationAccount.name;
};

export {
	getOutsourcingStatusText,
	getOutsourcingStatus,
	getDetailedStatus,
	getPairedProductActionsList,
	getPairedProductActionTextById,
	getActionIconName
};
