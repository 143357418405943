<template>
	<DefaultLayout>
		<ofs-panel class="OrderList">
			<ListTable
				:table-title="$t('All Orders')"
				:items="orders"
				:config="config"
				:fields="fieldsArray"
				:total-items="ordersCount"
				:per-page="perPage"
				:current-page="page"
				:fetchData="fetchData"
				:isBusy="isLoading"
				:page-position-prefix="$t('Showing')"
				:page-position-join="$t('of')"
				@table-change="handleTableChange"
				@row-clicked="rowClicked"
				hover
			>
				<template slot="prev-text" slot-scope="{}">
					{{ $t('Prev') }}
				</template>
				<template slot="next-text" slot-scope="{}">
					{{ $t('Next') }}
				</template>
				<template slot="empty">
					<span>
						<i>{{ $t('No Data') }}</i>
					</span>
				</template>
				<template v-slot:TableButtons-Slot-left>
					<BarcodeScanner :showInput="true" :onScan="handleScan" class="mr-2" />
					<Filters :filters="filterItems" :label="$t('Filter')" @filters-changed="handleFiltersChanged" />
				</template>
				<template v-slot:cell(status)="data">
					<order-status :status="data.item.orderData.status"></order-status>
				</template>
				<template v-slot:cell(sourceOrderId)="data">{{ data.item.orderData.sourceOrderId }}</template>
				<template v-slot:cell(source)="data">{{ data.item.source.name }}</template>
				<template v-slot:cell(lineItems)="data">{{ data.item.orderData.items | elementsCount }}</template>
				<template v-slot:cell(submitted)="data">
					<date :date="data.item.orderData.date" :showTime="true"></date>
				</template>
				<template v-slot:cell(quantity)="data">{{ totalItems(data.item.orderData.items) }}</template>
				<template v-slot:cell(shipments)="data">{{ data.item.orderData.shipments | elementsCount }}</template>
			</ListTable>
		</ofs-panel>
	</DefaultLayout>
</template>

<script>
import { ListTable, OfsPanel } from '@workflow-solutions/ofs-vue-layout';
import { mapGetters, mapActions } from 'vuex';
import _ from 'lodash';
import { elementsCount } from '../../lib/filters';
import { getStartDate, isoDateRegex } from '../../lib/helpers';
import DefaultLayout from '../../components/DefaultLayout';
import OrderStatus from '../../components/OrderStatus';
import Filters from '../../components/Filters';
import Date from '../../components/Date';
import BarcodeScanner from '../../components/BarcodeScanner';

const config = {
	breadcrumbs: { visible: false },
	filter: { visible: false },
	search: { visible: false },
	add: { visible: false },
	refresh: { visible: true },
	columns: { visible: false }
};

export default {
	data() {
		return {
			config,
			isLoading: false
		};
	},
	components: {
		DefaultLayout,
		ListTable,
		OrderStatus,
		Filters,
		OfsPanel,
		BarcodeScanner,
		Date
	},
	filters: {
		elementsCount
	},
	async mounted() {
		await this.fetchData();
	},
	computed: {
		...mapGetters({
			orders: 'order/orders',
			totalPages: 'order/totalPages',
			ordersCount: 'order/count',
			currentAccount: 'account/currentAccount'
		}),
		fieldsArray() {
			return [
				{ key: 'status', label: this.$t('Status') },
				{ key: 'sourceOrderId', label: this.$t('Order ID') },
				{ key: 'source', label: this.$t('From') },
				{ key: 'lineItems', label: this.$t('Line Items') },
				{ key: 'quantity', label: this.$t('Quantity') },
				{ key: 'shipments', label: this.$t('Shipments') },
				{ key: 'submitted', label: this.$t('Submitted') }
			];
		},
		fieldsArraySearchResults() {
			return [
				{ key: 'status', label: this.$t('Status') },
				{ key: 'sourceOrderId', label: this.$t('Order ID') },
				{ key: 'date', label: this.$t('Date') },
				{ key: 'shipTo', label: this.$t('Ship To') }
			];
		},
		filterItems() {
			return [
				{
					header: this.$t('Status'),
					key: 'status',
					type: 'multi',
					cssClass: 'OrderList-status',
					items: [
						{ title: this.$t('Data Ready'), value: 'dataready' },
						{ title: this.$t('Print Ready'), value: 'printready' },
						{ title: this.$t('Complete'), value: 'complete' },
						{ title: this.$t('Shipped'), value: 'shipped' },
						{ title: this.$t('Error'), value: 'error' },
						{ title: this.$t('Cancelled'), value: 'cancelled' },
						{ title: this.$t('Pending'), value: 'pending' }
					]
				},
				{
					header: this.$t('Order Date'),
					key: 'date',
					type: 'single',
					cssClass: 'OrderList-date',
					items: [
						{ title: this.$t('Today'), value: 'day' },
						{ title: this.$t('Past Week'), value: 'past-week' },
						{ title: this.$t('Past Month'), value: 'past-month' }
					]
				}
			];
		},
		filters() {
			return JSON.parse(_.get(this.$route, 'query.filters', '{}'));
		},
		page() {
			return _.toInteger(_.get(this.$route, 'query.page', 1));
		},
		perPage() {
			return _.toInteger(_.get(this.$route, 'query.perPage', 10));
		},
		processedFilters() {
			let date = _.get(this.filters, 'date[0].value');
			let status = _.get(this.filters, 'status');

			if (date) {
				date = getStartDate(date);
			}

			if (status) {
				status = status.map(f => f.value);
			}

			return {
				status,
				date
			};
		},
		facets() {
			const facets = {
				status: this.searchFacets.status,
				city: this.searchFacets['shipTo.town'],
				carrier: this.searchFacets['carrier.code'],
				service: this.searchFacets['carrier.service']
			};
			return _.reduce(
				facets,
				(acc, val, key) => {
					if (val) acc[key] = val;
					return acc;
				},
				{}
			);
		}
	},
	methods: {
		...mapActions({
			scanBarcode: 'barcode/getById',
			getOrders: 'order/getOrders'
		}),
		updateUrl({ page, perPage, filters } = {}) {
			// This is to ignore NavigationDuplicated error, which happens after the vue-router upgrade
			this.$router
				.replace({
					name: 'orders.list',
					query: {
						page: page || this.page,
						perPage: perPage || this.perPage,
						filters: JSON.stringify(filters || this.filters)
					}
				})
				.catch(() => {});
		},
		async fetchData() {
			try {
				// this page is loaded on app start, don't make the request if not logged in
				if (!this.currentAccount) return;

				this.isLoading = true;
				const query = {
					page: this.page,
					pagesize: this.perPage
				};

				_.forEach(this.processedFilters, (filter, key) => {
					if (!_.isEmpty(filter)) {
						const isTodayFilter = key === 'date' && isoDateRegex.test(filter);
						query[`${!isTodayFilter ? '*' : ''}orderData.${key}`] = filter;
					}
				});

				await this.getOrders({ query });
				this.updateUrl();
			} catch (err) {
				this.$notify({
					type: 'error',
					title: this.$t('Error'),
					text: this.$t('An error occurred while fetching orders')
				});
			} finally {
				this.isLoading = false;
			}
		},
		totalItems(items) {
			return items.reduce((memo, item) => memo + item.quantity, 0);
		},
		handleTableChange({ currentPage, perPage }) {
			this.updateUrl({ page: currentPage, perPage });
		},
		handleFiltersChanged(selectedFilters) {
			const filters = _.groupBy(selectedFilters, 'key');
			this.updateUrl({ page: 1, filters: _.isEmpty(selectedFilters) ? {} : filters });
			this.fetchData();
		},
		rowClicked(item) {
			// eslint-disable-next-line
			this.$router.push({ name: 'orders.view', params: { id: item._id } });
		},
		async handleScan(barcode) {
			if (barcode.length >= 5) {
				try {
					const result = await this.scanBarcode(barcode.toUpperCase());
					if (result.order) {
						this.$router.push({
							name: 'orders.view',
							// eslint-disable-next-line
							params: { id: result.order._id },
							query: { barcode }
						});
					}
				} catch (e) {
					this.$notify({
						type: 'error',
						title: this.$t('Not Found'),
						text: this.$t('No order was found matching the supplied barcode')
					});
				}
			}
		}
	},
	created() {
		// redirect user to login page if not logged in
		if (!this.currentAccount) {
			this.$router.push('/login');
		}
	}
};
</script>

<style lang="scss">
.OrderList {
	.BarcodeScan {
		margin-left: 1.5rem;
	}
}
</style>
