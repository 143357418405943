<template>
	<b-modal :visible="visible" :title="$t('New Invite')" modal-class="NewLinkModal" @hide="handleClose">
		<div>
			<img src="../../../../public/images/code.svg" />
			<div class="generate-info">
				<!-- eslint-disable-next-line max-len, prettier/prettier -->
				{{
					$t(
						'Invite an account owner to link with your account. Once the invitation is accepted,\
						 orders can be submitted between accounts.'
					)
				}}
			</div>
			<div class="NewLinkModal-code">
				<of-form-input
					:label="$t('Recipient email')"
					name="email"
					type="email"
					class="mb-1"
					data-test-id="recipientEmail"
					:show-errors="true"
				/>
				<of-form-select
					name="accountType"
					:label="$t('Account type')"
					:options="accountTypeValues"
					data-test-id="accountType"
					required
				/>
				<of-form-checkbox
					:label="$t('Permissions')"
					:name="permissionTypes.orderSubmission.name"
					:data-test-id="permissionTypes.orderSubmission.name"
					:label-cols="0"
					:right-side-label="permissionTypes.orderSubmission.label"
					:description="permissionTypes.orderSubmission.description"
					:disabled="permissionTypes.orderSubmission.disabled"
				>
				</of-form-checkbox>
				<of-form-checkbox
					:name="permissionTypes.outsourcing.name"
					:data-test-id="permissionTypes.outsourcing.name"
					:label-cols="0"
					:right-side-label="permissionTypes.outsourcing.label"
					:description="permissionTypes.outsourcing.description"
					:disabled="permissionTypes.outsourcing.disabled"
				>
				</of-form-checkbox>
			</div>
		</div>
		<section slot="modal-footer">
			<b-btn v-t="'Cancel'" data-test-id="cancelButton" class="mr-1" @click="$emit('hide')" />
			<of-submit-button v-t="'Send'" data-test-id="sendButton" :disabled="disableSubmit" @click="handleSend" />
		</section>
	</b-modal>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { required, email } from 'vuelidate/lib/validators';
import {
	OfFormInput,
	OfFormSelect,
	OfFormCheckbox,
	validateWithMessage,
	OfSubmitButton,
	withForm
} from '@workflow-solutions/ofs-vue-layout';
import { $t } from '@/vuex';

export default {
	components: {
		OfFormInput,
		OfSubmitButton,
		OfFormSelect,
		OfFormCheckbox
	},
	mixins: [withForm('NewLink')],
	props: {
		visible: {
			type: Boolean,
			default: false
		}
	},
	data() {
		return {
			accountTypeValues: [
				{ text: this.$t('PSP'), value: 'psp' },
				{ text: this.$t('Brand'), value: 'brand' }
			],
			permissionTypes: {
				orderSubmission: {
					name: 'orderSubmissionPermission',
					label: this.$t('Order submission'),
					description: $t(
						'This permission allows you and the account you are linked with ' +
							'to send and receive orders to each other via our API.'
					),
					disabled: false
				},
				outsourcing: {
					name: 'outsourcingPermission',
					label: this.$t('Outsourcing'),
					description: $t(
						'This permission allows you and the account you are linked with to outsource paired ' +
							'product SKUs. Order Submission permission is required to enable Outsourcing.'
					),
					disabled: false
				}
			}
		};
	},
	computed: {
		...mapGetters({ linkCode: 'linkcode/linkCode' }),
		validationRules() {
			return {
				formData: {
					email: {
						required: validateWithMessage($t('An email address is required'), required),
						email: validateWithMessage($t('Must enter a valid email address'), email)
					}
				}
			};
		},
		disableSubmit() {
			return (
				!this.formData.orderSubmissionPermission ||
				!this.formData.email ||
				!this.formData.accountType ||
				this.formState.invalid
			);
		}
	},
	watch: {
		'formData.accountType': {
			handler() {
				if (this.formData.accountType === 'brand') {
					this.formData.outsourcingPermission = false;
					this.permissionTypes.outsourcing.disabled = true;
				} else {
					this.formData.outsourcingPermission = true;
					this.permissionTypes.outsourcing.disabled = false;
				}
			}
		},
		'formData.outsourcingPermission': {
			handler() {
				if (this.formData.outsourcingPermission) {
					this.formData.orderSubmissionPermission = true;
				}
			}
		}
	},
	created() {
		this.setFormData({
			orderSubmissionPermission: true,
			outsourcingPermission: true
		});
	},
	methods: {
		...mapActions({ createLinkCode: 'linkcode/createLinkCode' }),
		async handleSend() {
			try {
				await this.createLinkCode({
					email: this.formData?.email,
					accountType: this.formData?.accountType,
					orderSubmissionPermission: this.formData?.orderSubmissionPermission,
					outsourcingPermission: this.formData?.outsourcingPermission
				});
				if (this.linkCode?.emailSuccess === false) {
					this.$notify({ type: 'warning', text: $t('Invitation created but sending email has failed') });
				} else if (this.linkCode?.emailSuccess === true) {
					this.$notify({ type: 'success', text: $t('Invitation email sent') });
				}
				this.$emit('create');
			} catch (err) {
				this.$notify({ type: 'error', text: $t(this.$errorUtils.displayError(err)) });
			} finally {
				this.handleClose();
			}
		},
		handleClose() {
			this.resetFormData();
			this.$emit('hide');
		}
	}
};
</script>

<style lang="scss">
.NewLinkModal {
	img {
		display: block;
		margin-left: auto;
		margin-right: auto;
		width: 25%;
	}

	.generate-info {
		text-align: center;
		padding: 20px 70px;
	}
}
</style>
