import Vue from 'vue';
import _ from 'lodash';

export default function() {
	Vue.$ofsCrud.addResource({
		name: 'batch',
		options: { namespaced: true },
		state: {
			batches: []
		},
		actions: {
			getBatches: async ({ dispatch, commit }, { page = 1, pageSize = 100, reset = false, filter = 'live' }) => {
				const result = await dispatch('request', {
					method: 'GET',
					path: 'batch',
					params: {
						page,
						pagesize: pageSize,
						status: filter,
						sort: 'sla',
						direction: -1
					}
				}).then(r => r.data);
				const batches = result.data;
				if (reset) {
					commit('SET_BATCHES', batches);
				} else {
					commit('ADD_BATCHES', batches);
				}

				return result;
			},
			getSubBatchesForBatchId: ({ dispatch }, batchId) => {
				const path = `subbatch?mainBatchId=${batchId}`;
				return dispatch('request', { method: 'GET', path }).then(r => r.data.data);
			},
			completeBatch: ({ dispatch }, barcode) => {
				const path = `batch/complete/barcode/${barcode}`;
				return dispatch('request', { method: 'POST', path });
			},
			resetBatches: ({ commit }) => {
				commit('RESET_BATCHES');
			},
			getThumbnailUrl: async ({ dispatch }, id) =>
				dispatch('request', { method: 'GET', path: `subbatch/${id}/thumbnail` }).then(r => r.data.thumbnailUrl)
		},
		mutations: {
			RESET_BATCHES: state => {
				state.batches = [];
			},
			ADD_BATCHES: (state, batches) => {
				const newBatches = [...state.batches, ...batches];
				state.batches = _.uniqBy(newBatches, '_id');
			},
			SET_BATCHES: (state, batches) => {
				state.batches = [...batches];
			}
		},
		getters: {
			batches: state => state.batches
		}
	});
}
