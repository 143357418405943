import orderRoutes from '../containers/Orders';
import productionRoutes from '../containers/Production';
import LoginRoutes from '../containers/Login';
import SetupRoutes from '../containers/Setup';
import submissionErrorsRoutes from '../containers/SubmissionErrors';
import JobSheet from '../components/JobSheet';
import helpRoutes from '../containers/Help';

const routes = [
	{
		path: '/',
		redirect: '/orders/list'
	},
	{
		path: '/job-sheet',
		name: 'job-sheet',
		component: JobSheet,
		meta: {
			title: 'Job Sheet'
		}
	},
	...orderRoutes,
	...productionRoutes,
	...LoginRoutes,
	...SetupRoutes,
	...submissionErrorsRoutes,
	...helpRoutes
];

export default routes;
