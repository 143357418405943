<template>
	<section
		class="UserActionsDropdown-container d-flex justify-content-center align-items-center position-relative"
		:class="{ visible: isDropdownVisible }"
	>
		<b-dropdown
			variant="link"
			right
			no-caret
			class="UserActionsDropdown"
			@hide="handleDropdownHide"
			@show="handleDropdownShow"
		>
			<template slot="button-content">
				<v-icon name="ellipsis-h" />
			</template>
			<b-dropdown-item @click.stop="handleEditUser">{{ $t('Edit User') }}</b-dropdown-item>
			<b-dropdown-item @click.stop="handleResendInvite">{{ $t('Resend Invite') }}</b-dropdown-item>
		</b-dropdown>
	</section>
</template>

<script>
import get from 'lodash/get';
import find from 'lodash/find';
import { mapGetters, mapActions } from 'vuex';

export default {
	name: 'UserActionsDropdown',
	props: {
		user: {
			type: Object,
			default: () => ({})
		},
		onUserClick: {
			type: Function,
			default: () => {}
		}
	},
	data() {
		return {
			isDropdownVisible: false
		};
	},
	computed: {
		...mapGetters({
			currentAccount: 'account/currentAccount',
			userAccountRoles: 'user/userAccountRoles'
		}),
		currentAccountName() {
			return get(this.currentAccount, 'name');
		},
		currentAccountId() {
			return get(this.currentAccount, '_id');
		},
		userEmail() {
			return get(this.user, 'email');
		}
	},
	methods: {
		...mapActions({
			resendInvitation: 'user/resendInvitation',
			getUserAccountRoles: 'user/getUserAccountRoles'
		}),
		handleDropdownHide() {
			this.isDropdownVisible = false;
		},
		handleDropdownShow() {
			this.isDropdownVisible = true;
		},
		async handleResendInvite() {
			try {
				await this.getUserAccountRoles({ userId: this.user._id });
				const accountRole = find(this.userAccountRoles, { id: this.currentAccountId });
				const role = get(accountRole, 'role');
				const resendInvitationData = {
					domain: `account:${this.currentAccountName}:${role}`,
					email: this.userEmail,
					appName: 'sfl'
				};
				await this.resendInvitation(resendInvitationData);
				this.$notify({
					type: 'success',
					title: this.$t('Success'),
					text: this.$t('User invitation has been sent successfully')
				});
			} catch (err) {
				this.$notify({
					type: 'error',
					title: this.$t('Error'),
					text: this.$t('An error occurred while sending user invitation')
				});
			}
		},
		handleEditUser() {
			this.onUserClick(this.user);
		}
	}
};
</script>

<style lang="scss">
@import '../../../styles/_variables.scss';

.UserActionsDropdown {
	height: 2em;

	&-container {
		width: 1em;
		height: 1em;
		padding: 0.75em 1em;
		border-radius: $borderRadius;
		border: 1px solid transparent;

		&.visible,
		&:hover {
			border-color: $liteInfoColour;
		}
	}

	button {
		margin-top: 0.085em;
		width: 2em;
		height: 2em;
		padding: 0;
	}

	svg {
		color: #58666e;
		width: 1em;
		height: 1em;
	}

	.dropdown-menu-right {
		border-radius: 0.2rem;
		border: 1px solid rgba(0, 0, 0, 0.05);
	}

	.dropdown-item {
		font-size: 0.8rem;
		color: #58666e;
	}
}
</style>
