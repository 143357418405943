<template>
	<b-modal
		v-model="isVisible"
		:title="modalTitle"
		hide-footer
		class="Search"
		:class="{ 'Search--empty': !initialised }"
		body-class="Search__inner"
		size="lg"
		@shown="onModalShown"
		@hidden="modalClose"
	>
		<b-form class="Search__form" @submit.prevent="search">
			<b-input-group>
				<b-input
					class="Search__input"
					ref="search"
					v-model="searchQuery"
					:placeholder="placeholderText"
				></b-input>
				<b-dropdown :text="dropdownText" slot="append" v-if="indexes.length > 1" class="Search__dropdown">
					<b-dropdown-item
						v-for="index in indexes"
						v-text="index.labelPlural"
						:key="index.name"
						@click.prevent="updateIndex(index.name)"
					></b-dropdown-item>
				</b-dropdown>
			</b-input-group>
			<b-button class="Search__sidebar-toggle" @click="sidebarExpanded = !sidebarExpanded" v-if="totalFacets"
				>{{ $t('Toggle Filters') }} ({{ filters.length }})</b-button
			>
		</b-form>
		<div class="Search__content" v-if="searchResults.length || totalFacets">
			<search-filters
				v-if="totalFacets"
				:class="{ 'SearchSidebar--expanded': isSidebarExpanded }"
				:on-filter-change="onFilterChange"
			></search-filters>
			<ul
				class="Search__results"
				ref="searchResults"
				v-on:scroll="onScroll"
				:class="{ 'Search__results--expanded': !isSidebarExpanded }"
			>
				<template v-for="result in searchResults">
					<li class="Search__result" :key="result.objectID" v-if="getCellTypeForName(index.name)">
						<component v-bind:is="getCellTypeForName(index.name)" :item="result"></component>
					</li>
				</template>
				<li v-if="isLoadingMore || isLoading">
					<loader class="Search__loader"></loader>
				</li>
			</ul>
		</div>
		<loader class="Search__loader" v-if="!totalFacets && isLoading && !searchResults.length"></loader>
		<p class="Search__empty" v-if="isEmpty && !isLoading">
			{{ $t('No results for') }} <strong>"{{ searchTerm }}"</strong>
		</p>
	</b-modal>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import Loader from './Loader';
import Pill from './Pill';
import SearchOrderCell from './Search/OrderCell';
import SearchProductCell from './Search/ProductCell';
import SearchEnquiryCell from './Search/EnquiryCell';
import SearchFilters from './Search/Filters';

export default {
	components: {
		SearchOrderCell,
		SearchProductCell,
		SearchEnquiryCell,
		Loader,
		Pill,
		SearchFilters
	},
	data() {
		return {
			isLoading: false,
			isLoadingMore: false,
			isEmpty: false,
			searchQuery: null,
			results: [],
			initialised: false,
			sidebarExpanded: false
		};
	},
	computed: {
		...mapGetters({
			visible: 'search/showSearchModal',
			searchTerm: 'search/searchTerm',
			searchResults: 'search/results',
			currentPage: 'search/page',
			filters: 'search/filters',
			totalPages: 'search/totalPages',
			totalResults: 'search/totalResults',
			searchFacets: 'search/facets',
			indexes: 'search/indexes',
			index: 'search/index'
		}),
		isVisible: {
			get() {
				return this.visible;
			},
			set(show) {
				if (!show) {
					this.hideSearchModal();
				}
			}
		},
		isSidebarExpanded() {
			return !!(this.sidebarExpanded && this.totalFacets);
		},
		modalTitle() {
			if (this.totalResults > 0) {
				return this.$t('Search') + ' (' + this.searchResults.length + this.$t('of') + this.totalResults + ')';
			}

			return this.$t('Search');
		},
		totalFacets() {
			if (this.searchFacets) {
				return Object.keys(this.searchFacets).length;
			}

			return 0;
		},
		placeholderText() {
			return this.$t('Search for an ') + this.index.label;
		},
		dropdownText() {
			return this.index.labelPlural;
		}
	},
	methods: {
		...mapActions({
			runSearch: 'search/search',
			loadMore: 'search/loadMore',
			resetSearch: 'search/reset',
			clearSearch: 'search/clear',
			hideSearchModal: 'search/hideSearchModal',
			setIndex: 'search/setIndex'
		}),
		async search() {
			this.isLoading = true;
			this.isEmpty = false;

			// Clear Search
			this.clearSearch();

			await this.runSearch({
				searchTerm: this.searchQuery
			});

			if (!this.searchResults.length) {
				this.isEmpty = true;
			}

			this.initialised = true;
			this.isLoading = false;
		},
		// Called when algolia index is changed
		updateIndex(index) {
			this.sidebarExpanded = false;
			this.setIndex(index);
			this.search();
		},
		async onScroll() {
			const searchContainer = this.$refs.searchResults;

			window.requestAnimationFrame(async () => {
				if (this.searchResults.length === this.totalResults) {
					return;
				}

				if (searchContainer.scrollTop + searchContainer.clientHeight >= searchContainer.scrollHeight) {
					this.isLoadingMore = true;
					await this.loadMore();
					this.isLoadingMore = false;
				}
			});
		},
		async onFilterChange() {
			this.search();
		},
		getCellTypeForName(name) {
			const types = {
				shipments: 'search-order-cell',
				products: 'search-product-cell',
				cases: 'search-enquiry-cell'
			};

			return types[name];
		},
		onModalShown() {
			this.$nextTick(() => this.$refs.search.focus());
		},
		modalClose() {
			this.hideSearchModal();
			this.searchQuery = '';
			this.initialised = false;
			this.resetSearch();
		}
	}
};
</script>

<style lang="scss">
$maxSearchHeight: 600px;

.Search {
	.modal-dialog {
		max-width: 800px;
		padding: 1rem;
		margin: 0 auto;

		@media all and (min-width: 900px) {
			padding: 2rem;
		}
	}

	&--empty {
		.Search__form {
			border-radius: 0 0 0.3rem 0.3rem;
			border-bottom: none;
		}
	}

	&__sidebar-toggle {
		margin: 0.5rem 0 0;

		@media all and (min-width: 900px) {
			display: none;
		}
	}

	&__inner {
		padding: 0;
		display: flex;
		flex-direction: column;
		border-radius: 0.3rem;
	}

	&__form {
		background: #f5f7f9;
		display: flex;
		flex-direction: column;
		padding: 1rem;
		border-bottom: 1px solid rgba(0, 0, 0, 0.05);
	}

	&__empty {
		padding: 1rem;
		margin: 0;
	}

	&__content {
		display: flex;
		border-radius: 0 0 0.3rem 0.3rem;
		overflow: hidden;

		@media all and (min-width: 600px) {
			flex-direction: row-reverse;
		}
	}

	&__results {
		flex: 1;
		margin: 0;
		padding: 0;
		list-style: none;
		max-height: $maxSearchHeight;
		overflow: auto;
		display: none;

		&--expanded {
			display: block;
		}

		@media all and (min-width: 900px) {
			display: block;
		}
	}

	&__result {
		margin: 0;
		padding: 0;
		border-top: 1px solid rgba(0, 0, 0, 0.05);

		&:first-child {
			border-top: none;
		}
	}

	&__loader {
		margin: 1rem auto;
		display: block;
	}
}

.SearchCell {
	padding: 0.75rem 1rem;
	display: flex;
	flex-direction: row;
	color: #333;
	align-items: center;
	user-select: none;

	&:hover {
		color: #333;
		text-decoration: none;
		background: #f5f7f9;
	}

	&:focus {
		color: #333;
		background: darken(#f5f7f9, 1);
		outline: none;
	}

	&__content {
		flex: 1;
	}

	&__pill {
		margin: 0.2rem 0;
	}

	&__status {
		$size: 14px;
		width: $size;
		height: $size;
		overflow: hidden;
		text-indent: -999px;
		background: #e2e8ee;
		display: inline-flex;
		margin-right: 0.5rem;
		border-radius: 50%;

		&--success {
			background: #27c24c;
		}

		&--error {
			background: #f05050;
		}
	}

	&__icon {
		$size: 26px;
		width: $size;
		height: $size;
		color: darken(#f5f7f9, 30);
		margin-right: 1rem;
	}

	&__heading {
		font-weight: bold;
		text-transform: uppercase;
		font-size: 0.85rem;
		margin: 0;
		letter-spacing: 0.05rem;
		display: flex;
		align-items: center;
	}

	&__title {
		margin: 0.25rem 0;
	}

	&__subtitle {
		margin: 0;
	}

	&__header {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	&__date {
		color: rgba(0, 0, 0, 0.4);
	}

	em {
		font-weight: bold;
		font-style: normal;
	}
}

.SearchSidebar {
	padding: 0.8rem;
	user-select: none;
	background: #f5f7f9;
	overflow: auto;
	max-height: $maxSearchHeight;
	display: none;
	flex: 1;

	@media all and (min-width: 900px) {
		max-width: 300px;
		display: block;
	}

	&--expanded {
		display: block;
	}

	&__title {
		margin: 0.2rem;
		font-size: 0.8rem;
		letter-spacing: 1px;
		color: lighten(#58666e, 20);
		font-weight: bold;
		text-transform: uppercase;
	}

	&__items {
		margin: 0.5rem 0 0;
		padding: 0;
		list-style: none;
	}

	&__item {
		display: inline-flex;
		padding: 0.2rem;
	}

	&__section {
		margin-top: 1rem;

		&:first-child {
			margin: 0;
		}
	}
}
</style>
