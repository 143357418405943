<template>
	<section class="px-2" data-test-id="Orders_shipmentList">
		<Item
			v-for="(shipment, i) in processedShipments"
			:showAllErroredItems="showAllErroredItems"
			:hasErroredOrderItem="shipment.hasErroredOrderItem"
			:key="shipment._id"
			:itemId="shipment._id"
			:shipmentIndex="i + 1"
			:details="shipment.details"
			:shippingDoc="shipment.shippingDoc"
			:highlightedComponent="highlightedComponent"
			:items="shipment.items"
			:refresh="refresh"
			class="mb-3"
			@highlightChange="handleHighlightChange"
		/>
	</section>
</template>

<script>
import { mapGetters } from 'vuex';
import _ from 'lodash';
import Item from './Item';

export default {
	name: 'List',
	components: {
		Item
	},
	props: {
		refresh: {
			type: Function,
			default: () => {}
		},
		erroredItemsIds: {
			type: Array,
			default: () => []
		},
		showAllErroredItems: {
			type: Boolean,
			default: false
		},
		highlightedComponent: [String, null]
	},
	computed: {
		...mapGetters({
			order: 'order/order',
			orderShipmentsData: 'order/shipments'
		}),
		orderItems() {
			return _.get(this.order, 'order.orderData.items', []);
		},
		shipments() {
			return _.get(this.orderShipmentsData, 'data', []);
		},
		processedShipments() {
			return _.map(this.shipments, shipment => {
				const erroredShipmetOrderItemIds = _.intersection(this.erroredItemsIds, shipment.itemIds);
				shipment.hasErroredOrderItem = !_.isEmpty(erroredShipmetOrderItemIds);

				// Make courier for each shipment
				let courier = _.get(shipment, 'carrier.alias', '') || _.get(shipment, 'carrier.code', '');
				const carrierService = _.get(shipment, 'carrier.service');

				if (carrierService) {
					courier = `${courier}/${carrierService}`;
				}

				shipment.details = {
					...shipment.shipTo,
					courier,
					shipBy: shipment.shipByDate,
					status: shipment.status,
					trackingNumber: shipment.trackingNumber
				};

				return shipment;
			});
		}
	},
	methods: {
		handleHighlightChange(...args) {
			this.$emit('highlightChange', ...args);
		}
	}
};
</script>
