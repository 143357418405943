import Vue from 'vue';

export default function () {
	Vue.$ofsCrud.addResource({
		name: 'trigger',
		pluralName: 'triggers',
		transport: 'connect',
		options: {
			namespaced: true
		},
		state: { events: [], templates: [] },
		actions: {
			saveTrigger({ dispatch }, trigger) {
				const path = 'trigger/save';
				const payload = { data: trigger };
				return dispatch('request', { method: 'PUT', path, payload }).then(r => r.data);
			},
			testTrigger({ dispatch }, data) {
				const path = 'trigger/test';
				return dispatch('request', { method: 'POST', path, payload: data }).then(r => r.data);
			},
			async getEvents({ dispatch, commit }) {
				const path = 'trigger-source';
				const { data } = await dispatch('request', {
					method: 'GET',
					path,
					query: {
						pagesize: 100,
						sort: 'name',
						direction: 1,
						systems: 'siteflow'
					}
				}).then(r => r.data);
				commit('TRIGGER_EVENTS_RECEIVED', data);
				return data;
			},
			async getTemplates({ dispatch, commit }) {
				const path = 'template';
				const { data } = await dispatch('request', { method: 'GET', path }).then(r => r.data);
				commit('TRIGGER_TEMPLATES_RECEIVED', data);
				return data;
			}
		},
		mutations: {
			TRIGGER_EVENTS_RECEIVED(state, events) {
				state.events = events;
			},
			TRIGGER_TEMPLATES_RECEIVED(state, templates) {
				state.templates = templates;
			}
		},
		getters: {
			triggerEvents(state) {
				return state.events;
			},
			triggerTemplates(state) {
				return state.templates;
			},
			count(state) {
				return state.triggers.count;
			}
		}
	});
}
