import List from './SubmissionErrorList';

const $t = str => str;

const routes = [
	{
		path: '/order-submission-errors',
		name: 'submission-errors',
		component: { template: '<router-view/>' },
		meta: {
			title: 'Submission Errors'
		},
		children: [
			{
				name: 'submission-errors',
				path: 'list',
				meta: {
					title: $t('List')
				},
				component: List
			},
			{
				name: 'submission.view',
				path: 'view/:id',
				meta: {
					title: $t('View')
				},
				component: () => import('./view/SubmissionErrorView')
			}
		]
	}
];

export default routes;
