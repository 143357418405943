<template>
	<DefaultLayout>
		<b-form novalidate autocomplete="off">
			<ofs-panel class="AccountSettings" :isPadded="false">
				<ContentHeader :title="$t('Account Settings')" class="mb-3"></ContentHeader>
				<SmartLoader :fetchData="fetchData">
					<b-container class="mb-3">
						<b-row>
							<b-col cols="12" lg="6">
								<h5 class="Form-header" data-test-id="profile-settings-title">
									{{ $t('Profile Settings') }}
								</h5>
								<b-form-row>
									<of-form-input
										:horizontal="false"
										name="companyName"
										type="text"
										:label="$t('Company Name')"
										:placeholder="$t('Company Name')"
										class="w-100 m-0"
										required
									/>
								</b-form-row>
								<b-form-row class="mx-0 mt-2">
									<of-form-input
										withoutLabel
										name="emailAddress"
										type="email"
										:placeholder="$t('Your email address')"
										required
									/>
								</b-form-row>
								<b-form-row class="mx-0 mt-2 mb-3">
									<b-col class="pl-0">
										<of-form-input
											withoutLabel
											name="phoneNumber"
											data-test-id="Setup_phoneNumber"
											type="text"
											:placeholder="$t('Phone Number')"
											required
										/>
									</b-col>
									<b-col class="pr-0">
										<of-form-input
											withoutLabel
											name="faxNumber"
											data-test-id="Setup_faxNumber"
											type="text"
											:placeholder="$t('Fax Number')"
										/>
									</b-col>
								</b-form-row>
								<b-form-row>
									<of-form-input
										:horizontal="false"
										name="address1"
										data-test-id="Setup_address1"
										type="text"
										:label="$t('Address')"
										:placeholder="$t('Address 1')"
										class="w-100 m-0"
										required
									/>
								</b-form-row>
								<b-form-row class="mx-0 mt-2">
									<b-col class="pl-0">
										<of-form-input
											withoutLabel
											name="town"
											data-test-id="Setup_town"
											type="text"
											:placeholder="$t('City')"
											required
										/>
									</b-col>
									<b-col class="pr-0">
										<of-form-input
											withoutLabel
											name="postcode"
											data-test-id="Setup_postcode"
											type="text"
											:placeholder="$t('Postcode')"
										/>
									</b-col>
									<b-col class="pr-0">
										<of-form-input
											withoutLabel
											name="state"
											data-test-id="Setup_state"
											type="text"
											:placeholder="$t('State')"
										/>
									</b-col>
								</b-form-row>
								<b-form-row class="mx-0 mt-2">
									<b-col class="pl-0">
										<of-form-select
											withoutLabel
											name="isoCountry"
											data-test-id="Setup_isoCountry"
											label="ISO Country:"
											:options="ISOcountries"
											required
										/>
									</b-col>
									<b-col></b-col>
								</b-form-row>
								<b-form-row class></b-form-row>
							</b-col>
							<b-col class="d-flex flex-column" cols="12" lg="6">
								<h5 class="Form-header mt-3 mt-lg-0" data-test-id="general-settings-title">
									{{ $t('General Settings') }}
								</h5>
								<b-form-row>
									<b-col>
										<of-form-select
											:horizontal="false"
											name="weightUnit"
											:label="$t('Weight Units')"
											:options="weightUnits"
											class="w-100 m-0"
											required
										/>
									</b-col>
									<b-col>
										<of-form-select
											:horizontal="false"
											name="dimensionUnit"
											:label="$t('Dimension Units')"
											:options="dimensionUnits"
											class="w-100 m-0"
											required
										/>
									</b-col>
								</b-form-row>
								<b-form-row class="mt-2">
									<b-col>
										<of-form-select
											:horizontal="false"
											name="currency"
											:label="$t('Currency')"
											:options="currencies"
											class="w-100 m-0"
										/>
									</b-col>
									<b-col>
										<of-form-select
											:horizontal="false"
											name="dateFormat"
											:label="$t('Date Format')"
											:options="dateFormats"
											class="w-100 m-0"
										/>
									</b-col>
								</b-form-row>
								<b-form-row class="mt-2">
									<b-col>
										<of-form-select
											:horizontal="false"
											name="timezoneGroup"
											:label="$t('Timezone Group')"
											@input="onChangeTimezoneGroup"
											:options="timezoneGroups"
										/>
									</b-col>
									<b-col>
										<of-form-select
											:horizontal="false"
											name="timezone"
											:label="$t('Timezone')"
											:options="timeZones"
										/>
									</b-col>
								</b-form-row>
								<b-form-row>
									<b-col>
										<of-form-select
											:horizontal="false"
											name="defaultSlaDays"
											:label="$t('Default SLA Days')"
											:options="slaDays"
										/>
									</b-col>
									<b-col></b-col>
								</b-form-row>
							</b-col>
						</b-row>
					</b-container>
				</SmartLoader>
				<template slot="actions">
					<b-button @click="onSaveSettings" variant="primary">
						<span class="px-3">{{ $t('Save') }}</span>
					</b-button>
				</template>
			</ofs-panel>
		</b-form>
	</DefaultLayout>
</template>

<script>
import Promise from 'bluebird';
import get from 'lodash/get';
import filter from 'lodash/filter';
import find from 'lodash/find';
import head from 'lodash/head';
import isEmpty from 'lodash/isEmpty';
import { ContentHeader, OfFormInput, OfFormSelect, OfsPanel, withForm } from '@workflow-solutions/ofs-vue-layout';
import { mapGetters, mapActions } from 'vuex';
import SmartLoader from '../../../components/SmartLoader';
import DefaultLayout from '../../../components/DefaultLayout';

export default {
	name: 'AccountSettings',
	mixins: [withForm('AccountSettingsForm')],
	components: {
		ContentHeader,
		DefaultLayout,
		OfFormInput,
		OfFormSelect,
		SmartLoader,
		OfsPanel
	},
	filters: {},
	data() {
		return {
			timeZones: [],
			currencies: [],
			timezonesList: [],
			countries: [],
			slaDays: [
				{ text: this.$t('Same Day'), value: 0 },
				{ text: this.$t('1 Day'), value: 1 },
				{ text: this.$t('2 Days'), value: 2 },
				{ text: this.$t('3 Days'), value: 3 },
				{ text: this.$t('4 Days'), value: 4 },
				{ text: this.$t('5 Days'), value: 5 },
				{ text: this.$t('6 Days'), value: 6 },
				{ text: this.$t('1 Week'), value: 7 }
			],
			weightUnits: [
				{ text: 'Grams', value: 'g' },
				{ text: 'Ounces', value: 'oz' }
			],
			dimensionUnits: [
				{ text: 'Millimeters', value: 'mm' },
				{ text: 'Inches', value: 'inch' }
			],
			dateFormats: [
				{ text: 'YYYY-MM-DD', value: 'yyyy-MM-dd' },
				{ text: 'YYYY/MM/DD', value: 'yyyy/MM/dd' },
				{ text: 'DD-MM-YYYY', value: 'dd-MM-yyyy' },
				{ text: 'DD/MM/YYYY', value: 'dd/MM/yyyy' },
				{ text: 'MM-DD-YYYY', value: 'MM-dd-yyyy' },
				{ text: 'MM/DD/YYYY', value: 'MM/dd/yyyy' }
			]
		};
	},
	watch: {
		accountSettings: {
			immediate: true,
			handler() {
				const isDataLoaded =
					!isEmpty(this.currencies) && !isEmpty(this.timezonesList) && !isEmpty(this.countries);
				if (this.accountSettings && isDataLoaded) {
					const { group, zones } = this.getTimeZoneGroupByZone(this.accountSettings.timezone);
					this.timeZones = zones;
					this.updateFormData({ ...this.accountSettings, timezoneGroup: group });
				}
			}
		}
	},
	computed: {
		...mapGetters({
			accountSettings: 'account/accountSettings',
			memberships: 'account/memberships',
			user: 'auth/user'
		}),
		ISOcountries() {
			if (!this.countries) {
				return [];
			}

			return this.countries.map(item => ({ text: item.name, value: get(item, 'alpha-2') }));
		},
		timezoneGroups() {
			if (!this.timezonesList) {
				return [];
			}

			return this.timezonesList.map(item => ({ text: item.group, value: item.group }));
		}
	},
	methods: {
		...mapActions({
			getVars: 'account/getVars',
			saveAccountSettings: 'account/saveAccountSettings'
		}),
		async fetchData() {
			try {
				const [currencies, timezones, countries] = await Promise.all([
					import('../../../data/currencies').then(res => res.default.currencies),
					import('../../../data/timezones').then(res => res.default.timezones),
					import('../../../data/ISOcountries').then(res => res.default)
				]);
				this.currencies = currencies;
				this.timezonesList = timezones;
				this.countries = countries;
				await this.getVars();
			} catch (err) {
				this.$notify({
					type: 'error',
					title: 'Error',
					text: this.$t('An error occurred while fetching account settings')
				});
			}
		},
		async onSaveSettings() {
			try {
				await this.saveAccountSettings(this.formData);
				this.$notify({
					type: 'success',
					title: 'Success',
					text: this.$t('Account Settings saved successfully')
				});
			} catch (err) {
				this.$notify({
					type: 'error',
					title: 'Error',
					text: this.$t('An error occurred while updating account settings')
				});
			}
		},
		getTimeZoneGroupByZone(timezone) {
			const currentTimeZones = filter(this.timezonesList, item => find(item.zones, { value: timezone }));
			return head(currentTimeZones);
		},
		getTimeZoneGroupByGroup(group) {
			return find(this.timezonesList, { group });
		},
		onChangeTimezoneGroup(timezoneGroup) {
			if (this.accountSettings && timezoneGroup) {
				const { zones } = this.getTimeZoneGroupByGroup(timezoneGroup);
				this.timeZones = zones;
				this.updateFormData({ timezone: zones[0].value });
			}
		}
	}
};
</script>

<style lang="scss">
.AccountSettings {
	max-width: 1160px;
}
</style>
