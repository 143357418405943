<template>
	<div class="article">
		<header class="article__header article-header">
			<div class="article-header__inner">
				<h1 class="article-header__title">{{ $t('Submit a single item order') }}</h1>
				<p class="article-header__text">
					{{ $t("Okay, let's get an order submitted into your Site Flow Light account. Orders can be submitted by yourself or brands that you work with who wish to send you work.") }}
				</p>
			</div>
		</header>

		<section class="article__panel article__step">
			<h2 class="article__heading">{{ $t('What is an order?') }}</h2>
			<p>
				{{ $t('An order consists of one or more shipments. Each shipment contains one or more items, and each item contains one or more components.') }}
			</p>
			<p>
				{{ $t('This example will create an order with one, single component item (think greeting card rather than a book), sent as a single shipment.') }}
			</p>
			<p class="mb-0">
				{{ $t('Full documentation on order structure is available') }}
				<a href="/#/help/docs/api">{{ $t('here') }}</a
				>.
			</p>
		</section>

		<section class="article__panel article__step">
			<h2 class="article__heading">{{ $t('Populating the Order') }}</h2>
			<p>
				{{ $t("To order a single item, we'll need the following information:") }}
			</p>

			<div class="article-panel article-panel--highlight">
				<b>{{ $t('An ID for the order') }} <code>sourceOrderId</code></b>
				<p>
					{{ $t('This is “your reference” and will be displayed on job sheets and order lists. It must be unique, using the same sourceOrderId twice will result in a submission error.') }}
				</p>
				<of-form-input
					:horizontal="false"
					name="order.orderData.sourceOrderId"
					no-label
					:placeholder="$t('Eg. MyPSPTestOrder')"
					required
					class="mb-0"
				/>
				<small class="form-text text-muted">
					{{ $t("Note: for this example, we'll append a timestamp to ensure you get a unique value.") }}</small>
			</div>
			<div class="article-panel article-panel--highlight">
				<b>{{ $t('The SLA for the order') }} <code>slaDays</code></b>
				<p>
					{{ $t('This will determine the number of days before your order should leave the factory. It will be used to calculate the due dates on the work list.') }}
				</p>
				<of-form-input
					:horizontal="false"
					name="order.orderData.shipments.0.slaDays"
					type="number"
					no-label
					required
					class="mb-0"
				/>
				<small class="form-text text-muted">{{
					$t('If not supplied, the default in your account settings will be used')
				}}</small>
			</div>
			<div class="article-panel article-panel--highlight">
				<b>{{ $t('A shipping method') }} <code>alias</code></b>
				<p>
					{{ $t("This dictates the shipping method your order should be shipped on, it will be displayed on the order's details and should be something meaningful to you") }}
				</p>
				<of-form-input
					:horizontal="false"
					no-label
					name="order.orderData.shipments.0.carrier.alias"
					required
					class="mb-0"
				/>
				<small class="form-text text-muted">{{ $t('Eg. “Standard”, “Express”, “Signed”') }}</small>
			</div>
			<div class="article-panel article-panel--highlight">
				<b>{{ $t('A shipping address') }}</b>
				<p>
					{{ $t('An address for the order to be shipped to, the miniumum fields are as below.') }}
				</p>

				<of-form-input
					:horizontal="false"
					name="order.orderData.shipments.0.shipTo.name"
					no-label
					class="mb-1"
					:placeholder="$t('Name')"
					required
				/>
				<of-form-input
					:horizontal="false"
					name="order.orderData.shipments.0.shipTo.address1"
					no-label
					class="mb-1"
					:placeholder="$t('Address')"
					required
				/>
				<of-form-input
					:horizontal="false"
					name="order.orderData.shipments.0.shipTo.town"
					no-label
					class="mb-1"
					:placeholder="$t('Town')"
					required
				/>
				<of-form-select
					:horizontal="false"
					name="order.orderData.shipments.0.shipTo.isoCountry"
					no-label
					class="mb-1"
					:placeholder="$t('Country')"
					:options="countries"
					required
				/>
				<of-form-input
					:horizontal="false"
					name="order.orderData.shipments.0.shipTo.postcode"
					no-label
					:placeholder="$t('Postcode')"
					required
					class="mb-0"
				/>
			</div>
			<div class="article-panel article-panel--highlight">
				<b>{{ $t('An item SKU') }} <code>sku</code></b>
				<p>
					{{ $t('This describes the item to be produced, it will be displayed against each item and should be something meaningful to you.') }}
				</p>
				<of-form-input :horizontal="false" name="order.orderData.items.0.sku" no-label required class="mb-0" />
				<small class="form-text text-muted">{{ $t('Eg. “Flyer”, “BusinessCard”, “Poster”') }}</small>
			</div>
			<div class="article-panel article-panel--highlight">
				<b>{{ $t('An item quantity') }} <code>quantity</code></b>
				<p>
					{{ $t('The quantity of the item to be produced, will default to 1 if not entered.') }}
				</p>
				<of-form-input
					:horizontal="false"
					type="number"
					no-label
					name="order.orderData.items.0.quantity"
					:placeholder="$t('Eg, 1-100000')"
					required
					class="mb-0"
				/>
			</div>
			<div class="article-panel article-panel--highlight">
				<b>{{ $t('Component artwork URL') }} <code>path</code></b>
				<p>
					{{ $t('The URL to the component artwork. Site Flow Light will fetch a copy from here.') }}
				</p>
				<of-form-input
					:horizontal="false"
					name="order.orderData.items.0.components.0.path"
					disabled
					no-label
					required
					class="mb-0"
				/>
				<small class="form-text text-muted">{{
					$t('Any publicly available URL to a PDF or image file')
				}}</small>
			</div>
			<div class="article-panel article-panel--highlight">
				<b>{{ $t('A component code') }} <code>code</code></b>
				<p>
					{{ $t("This describes the part of the item to be produced, each component will get it's own artwork and job sheet.") }}
				</p>
				<of-form-input
					:horizontal="false"
					name="order.orderData.items.0.components.0.code"
					disabled
					no-label
					required
					class="mb-0"
				/>
				<small class="form-text text-muted">
					{{$t('Your item has a single component called “Content”. In the case of a book, you might have separate components for things like “Cover” and “Text”') }}
				</small>
			</div>
			<div class="article-panel article-panel--highlight">
				<b>{{ $t("'Finish' attribute") }} <code>finish</code></b>
				<p>
					{{ $t('A single attribute describing the finish of the component') }}
				</p>
				<of-form-input
					:horizontal="false"
					disabled
					no-label
					name="order.orderData.items.0.components.0.attributes.finish"
					required
					class="mb-0"
				/>
				<small class="form-text text-muted">{{
					$t('As many attributes can be added as necessary to describe the component')
				}}</small>
			</div>
		</section>

		<section class="article__panel article__step">
			<h2 class="article__heading">{{ $t('Submitting the order') }}</h2>
			<p>{{ $t("Here's the structure of your order in JSON") }}</p>
			<div class="mb-3">
				<!--<prism language="json">{{ JSON.stringify(order, null, 2) }}</prism>-->
				<codemirror :value="orderString" :options="options" />
			</div>
			<p>
				{{ $t('Orders to Site Flow Light are submitted via REST API, using HTTP header authorisation. Full documentation on how to do this can be viewed') }}
				<a href="/#/help/docs/api">{{ $t('here') }}</a
				>.
			</p>
			<template v-if="!isSuccess">
				<p>
					{{ $t('To submit our sample we will need a token and secret (from your') }}
					<router-link :to="{ name: 'user.details', params: { id: userId } }">
						{{ $t('user profile') }}
					</router-link>
					{{ $t(") and a destination account (your account name). We've pulled these in below.") }}
				</p>
				<div class="article-panel article-panel--highlight">
					<of-form-input :label="$t('Token')" name="credentials.token" required disabled />
					<of-form-input :label="$t('Secret')" name="credentials.secret" required disabled />
					<of-form-input :label="$t('Account')" name="order.destination.name" required disabled />
					<of-form-input :label="$t('POST URL')" name="orderSubmissionUrl" disabled class="mb-0" />
				</div>

				<hr class="article__rule" />

				<div class="article-panel mt-0">
					<h2 class="article__heading">{{ $t("You're ready to go") }}</h2>
					<p>{{ $t("That's everything we need, you're ready to submit your order!") }}</p>

					<b-button
						variant="info"
						size="lg"
						@click="handleSubmit"
						:disabled="isSubmitting"
						data-test-identifier="order-submission-submit"
					>
						<template v-if="isSubmitting"> <v-icon name="spinner" spin /> {{ $t('Submitting') }} </template>
						<template v-else>
							{{ $t('Submit') }}
						</template>
					</b-button>
				</div>
			</template>
			<template v-else>
				<hr class="article__rule" />
				<div class="article-panel mt-0" data-test-identifier="order-submission-success">
					<h2 class="article__heading">
						<v-icon name="check" class="article__heading-icon text-success" scale="2" />
						{{ $t('Your order submission was successful') }}
					</h2>
					<p>
						{{ $t('Your order') }}
						<a
							:href="orderResponse.url"
							target="_blank"
							data-test-identifier="order-submission-success-url"
							>{{ $t('request') }}</a
						>
						{{ $t("was successfully received, it'll now appear in your orders list!") }}
					</p>
					<p>
						{{ $t('To follow your order through the production process,') }}
						<router-link :to="{ name: 'help.docs.tutorial' }">{{ $t('view our tutorial') }}</router-link
						>.
					</p>
					<b-button
						variant="primary"
						size="lg"
						:to="{ name: 'orders.view', params: { id: orderResponse._id } }"
						data-test-identifier="order-submission-success-view"
						>{{ $t('View Order') }}</b-button
					>
					<a
						class="btn btn-lg btn-link"
						@click="handleStartFromScratchBtnClick"
						data-test-identifier="order-submission-success-another"
						>{{ $t('Submit another') }}</a
					>
				</div>
			</template>
		</section>
	</div>
</template>

<script>
import { withForm, OfFormInput, OfFormSelect } from '@workflow-solutions/ofs-vue-layout';
import _ from 'lodash';
import moment from 'moment';
import _cloneDeep from 'lodash/cloneDeep';
import _find from 'lodash/find';
import _fp from 'lodash/fp';
import _get from 'lodash/get';
import _map from 'lodash/map';
import _merge from 'lodash/merge';
import { mapActions, mapGetters } from 'vuex';
import { niceDate } from '../../lib/filters';
import ISOcountries from '../../data/ISOcountries';
import order from '../../data/order';

const emptyOrder = _cloneDeep(order);

export default {
	mixins: [withForm('orderGettingStarted')],
	components: {
		OfFormInput,
		OfFormSelect,
		codemirror: async () => {
			await Promise.all([
				import('codemirror/lib/codemirror.css'),
				import('codemirror/theme/material.css'),
				import('codemirror/addon/lint/lint.css'),
				import('codemirror/mode/javascript/javascript'),
				import('codemirror/addon/selection/active-line'),
				import('codemirror/addon/edit/closebrackets'),
				import('codemirror/addon/edit/matchbrackets'),
				import('codemirror/addon/comment/comment'),
				import('codemirror/addon/lint/lint'),
				import('codemirror/addon/lint/json-lint')
			]);

			return import('vue-codemirror').then(r => r.codemirror);
		}
	},
	filters: {
		date(d) {
			return niceDate(d, 'ddd Do MMM YYYY HH:mm');
		}
	},
	data() {
		return {
			isLoading: false,
			isSubmitting: false,
			isSuccess: false,
			orderResponse: null,
			options: {
				lint: true,
				autoCloseBrackets: true,
				mode: 'application/json',
				lineWrapping: true,
				gutters: ['CodeMirror-lint-markers'],
				tabSize: 4,
				styleActiveLine: false,
				lineNumbers: true,
				theme: 'material'
			}
		};
	},
	computed: {
		...mapGetters({
			accountSettings: 'account/accountSettings',
			currentAccount: 'account/currentAccount',
			vars: 'account/vars',
			userAccountRoles: 'user/userAccountRoles'
		}),
		countries() {
			return _map(ISOcountries, country => ({
				text: country.name,
				value: country['alpha-2']
			}));
		},
		order() {
			return _merge({}, emptyOrder, _get(this.formData, 'order'));
		},
		orderString() {
			return JSON.stringify(this.order, null, 2);
		},
		userId() {
			return _get(this, 'vars.userId');
		},
		currentAccountRole() {
			if (!this.currentAccount || !this.userAccountRoles) return null;
			return _find(this.userAccountRoles, { id: this.currentAccount._id });
		}
	},
	watch: {
		currentAccountRole() {
			if (!this.currentAccountRole) return null;
			return this.updateFormData({
				order: _.merge({}, emptyOrder, _.get(this.formData, 'order'), {
					destination: { name: this.currentAccountRole.name }
				}),
				credentials: this.currentAccountRole
			});
		}
	},
	async mounted() {
		this.setFormData({
			order: emptyOrder,
			orderSubmissionUrl: `${window.$config.orderSubmissionApiBase}/order`
		});

		await this.fetchData();
	},
	methods: {
		...mapActions({
			getVars: 'account/getVars',
			submitOrder: 'order/submitOrder',
			setFirstAccess: 'help/setFirstAccess',
			getUserAccountRoles: 'user/getUserAccountRoles'
		}),
		async fetchData() {
			try {
				this.isLoading = true;
				await this.getVars();
				await this.getUserAccountRoles({ userId: this.userId });
			} catch (err) {
				this.$notify({
					type: 'error',
					title: this.$t('Error'),
					text: this.$t('An error occurred while fetching user details')
				});
			} finally {
				this.isLoading = false;
			}
		},
		async handleSubmit() {
			try {
				this.orderResponse = null;
				this.isSubmitting = true;
				let sanitizedOrder = this.sanitizeOrder(this.order);
				sanitizedOrder = _fp.set(
					'orderData.sourceOrderId',
					// eslint-disable-next-line new-cap
					`${sanitizedOrder.orderData.sourceOrderId}-${new moment().format('YYYYMMDDHHSS')}`,
					sanitizedOrder
				);
				this.orderResponse = await this.submitOrder({
					data: sanitizedOrder,
					credentials: this.formData.credentials
				});
				this.isSuccess = true;
				// this.$notify({ type: 'info', text: this.$t('Order successfully submitted') });
			} catch (err) {
				const { message, response: { status, data: { errors = [] } = {} } = {} } = err;

				if (status === 400) {
					const errorsString = errors.map(error => `${error.dataPath} ${error.message}`).join('<br/><br/>');
					this.$notify({ type: 'error', text: `${this.$t('Invalid data')}: ${errorsString}` });
				} else {
					this.$notify({ type: 'error', text: `${message || this.$t('Error during submit order')}` });
				}
			} finally {
				this.isSubmitting = false;
			}
		},
		sanitizeOrder() {
			const quantity = this.order.orderData.items[0].quantity;
			const slaDays = this.order.orderData.shipments[0].slaDays;
			return _fp.flow(
				_fp.set('orderData.items[0].quantity', Number(quantity)),
				_fp.set('orderData.shipments[0].slaDays', Number(slaDays))
			)(this.order);
		},
		handleStartFromScratchBtnClick() {
			this.isSuccess = false;
			this.updateFormData({
				order: _merge({}, emptyOrder, { destination: { name: this.currentAccountRole.name } })
			});
		}
	}
};
</script>

<style lang="scss">
.article .CodeMirror {
	height: 810px;
}
</style>
