<template>
	<b-modal
		:visible="visible"
		:ok-title="$t('Close')"
		@hide="$emit('hide')"
		ok-only
		hide-header
		static
		size="sm"
		ok-variant="secondary"
		footer-class="ShipmentErrorModal-footer"
		body-class="ShipmentErrorModal"
	>
		<section class="ShipmentErrorModal-icon text-center mt-2 mb-3">
			<img src="../../../../../../public/images/shipment-alert.svg" />
		</section>
		<p class="ShipmentErrorModal-body text-center">
			{{$t(displayError(error))}}
		</p>
	</b-modal>
</template>

<script>
import { displayError } from '../../../../../lib/helpers';

export default {
	name: 'ShipmentErrorModal',
	props: {
		visible: {
			type: Boolean,
			default: false
		},
		error: {
			type: Object,
			default: () => ({})
		}
	},
	methods: { displayError }
};
</script>

<style lang="scss">
@import '../../../../../styles/shared';

.ShipmentErrorModal {
	&-body {
		font-size: $of-font-size-normal;
		color: $modalBodyColor;
	}

	&-footer {
		padding: 10px;

		.btn-secondary {
			margin: auto;
			padding-left: 25px;
			padding-right: 25px;
		}
	}
}
</style>
