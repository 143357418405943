import FirstTime from './OrderSubmission';
import ShipmentShipped from './ShipmentShipped';
import Frame from '../Frame';

const routes = [
	{
		path: '/help',
		name: 'help',
		component: { template: '<router-view/>' },
		children: [
			{
				name: 'help.orderSubmission',
				path: 'order-submission',
				component: FirstTime
			},
			{
				name: 'help.shipmentShipped',
				path: 'shipment-shipped',
				component: ShipmentShipped
			},
			{
				name: 'help.docs.descriptions',
				path: 'docs/descriptions',
				component: Frame,
				props: {
					frameUrl: 'https://hpsiteflow.com/docs/siteflow-light/site-guide.html?embedded=true'
				}
			},
			{
				name: 'help.docs.tutorial',
				path: 'docs/tutorial',
				component: Frame,
				props: {
					frameUrl: 'https://hpsiteflow.com/docs/siteflow-light/tutorial.html?embedded=true'
				}
			},
			{
				name: 'help.docs.api',
				path: 'docs/api',
				component: Frame,
				props: {
					frameUrl: 'https://hpsiteflow.com/docs/api-reference/siteflow-light.html?embedded=true'
				}
			}
		]
	}
];

export default routes;
