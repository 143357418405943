<template>
	<div class="sub-batch-thumbnail" :class="{ 'sub-batch-thumbnail--loaded': loaded }">
		<img
			:src="thumbnailUrl"
			@error="refreshThumbnail"
			@load="onImageLoad"
			v-if="thumbnailUrl"
			class="sub-batch-thumbnail__image"
		/>
		<img
			v-else
			src="../../../public/images/image-missing.svg"
			class="sub-batch-thumbnail__image sub-batch-thumbnail__image--missing"
		/>
	</div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
	props: {
		item: {
			type: Object,
			required: true
		}
	},
	data() {
		return {
			loaded: false,
			refreshed: false,
			thumbnailUrl: this.item.components[0].path
		};
	},
	methods: {
		...mapActions({
			getThumbnail: 'item/getThumbnail'
		}),
		async refreshThumbnail() {
			if (this.refreshed) {
				return;
			}

			this.refreshed = true;
			this.thumbnailUrl = await this.getThumbnail(this.item.components[0].fileId);
		},
		onImageLoad() {
			this.loaded = true;
		}
	}
};
</script>

<style lang="scss">
.sub-batch-thumbnail {
	display: flex;
	align-items: center;
	justify-content: center;

	&--loaded {
		.sub-batch-thumbnail__image {
			opacity: 1;
		}
	}

	&__image {
		transition: opacity 0.3s ease-in-out;
		opacity: 0;
		transform: translateZ(0);
		max-width: 100%;
		max-height: 100%;

		&--missing {
			opacity: 0.7;
			max-width: 60%;
			max-height: 60%;
		}
	}
}
</style>
