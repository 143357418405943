import AccountSettings from './AccountSettings/AccountSettings';
import UserList from './UserList/UserList';
import TriggerList from './Triggers/TriggerList';
import TriggerEdit from './Triggers/TriggerEdit';
import TemplateList from './Templates/TemplateList';
import TemplateAddEdit from './Templates/TemplateAddEdit';
import LinkedAccountList from './LinkedAccount/LinkedAccountList';
import UserDetails from './UserDetails/UserDetails';
import PendingLinks from './PendingLinks/PendingLinksList';
import PairedProduct from './PairedProducts/PairedProductList';

const routes = [
	{
		path: '/setup',
		name: 'setup',
		component: { template: '<router-view/>' },
		children: [
			{
				name: 'account.settings',
				path: 'account/settings',
				component: AccountSettings
			},
			{
				name: 'user.list',
				path: 'users',
				component: UserList
			},
			{
				name: 'linkcode.list',
				path: 'linkcode/list',
				component: PendingLinks
			},
			{
				name: 'trigger.add',
				path: 'triggers/add',
				component: TriggerEdit
			},
			{
				name: 'trigger.edit',
				path: 'triggers/edit/:id',
				component: TriggerEdit
			},
			{
				name: 'trigger.list',
				path: 'triggers',
				component: TriggerList
			},
			{
				name: 'template.add',
				path: 'templates/add',
				component: TemplateAddEdit
			},
			{
				name: 'template.edit',
				path: 'templates/edit/:id',
				component: TemplateAddEdit
			},
			{
				name: 'template.list',
				path: 'templates',
				component: TemplateList
			},
			{
				name: 'user.details',
				path: 'users/:id',
				component: UserDetails
			},
			{
				name: 'accountlink.list',
				path: 'accountlink/list',
				component: LinkedAccountList
			},
			{
				name: 'pairedproduct.list',
				path: 'pairedproduct/list',
				component: PairedProduct
			}
		]
	}
];

export default routes;
