export const lang = state => {
	if (state) {
		return state.lang;
	}
};

export const languages = () => [
	{ code: 'en-US', name: 'English' },
	{ code: 'en-GB', name: 'English UK' },
	{ code: 'ja-JP', name: 'Japanese' }
];

export const tokens = state => {
	if (state) {
		return state.tokens;
	}
};
