<template>
	<AppLayout>
		<TopBar slot="top-bar">
			<template slot="logo">
				<div class="brand">
					<img src="images/hp.svg" alt="HP Logo" class="brand-logo" />
					<span class="brand-title">Site Flow Light</span>
				</div>
			</template>
			<template slot="account">
				<MenuControls languages-placement="left"></MenuControls>
			</template>
			<template slot="actions">
				<button @click="openSearch" class="SearchButton" :title="$t('Search (CTRL+S, ALT+S)')"></button>
				<Search v-if="indexes && indexes.length" />
			</template>
		</TopBar>
		<template slot="content">
			<slot></slot>
		</template>

		<template slot="mobile-menu">
			<MobileMenu>
				<section slot="account">
					<MenuControls languages-placement="right"></MenuControls>
				</section>
			</MobileMenu>
		</template>
		<template slot="footer"></template>
	</AppLayout>
</template>

<script>
import Mousetrap from 'mousetrap';
import { mapActions, mapGetters } from 'vuex';
import { AppLayout, TopBar, MobileMenu } from '@workflow-solutions/ofs-vue-layout';
import Search from './Search';
import MenuControls from './MenuControls';

export default {
	components: {
		AppLayout,
		TopBar,
		MobileMenu,
		MenuControls,
		Search
	},
	methods: {
		...mapActions({
			openSearch: 'search/showSearchModal',
			closeSearch: 'search/hideSearchModal'
		})
	},
	computed: {
		...mapGetters({
			indexes: 'search/indexes'
		})
	},
	mounted() {
		Mousetrap.bind(['alt+s', 'ctrl+s'], this.openSearch);
	}
};
</script>

<style lang="scss">
$switcher-color: #b0b2b9;
$menu-controls-color: #3a3f51;
.OneflowTopBar {
	.brand {
		display: flex;
		align-items: center;

		&-title {
			font-size: 18px;
			line-height: 1.2;
			color: rgb(255, 255, 255);
		}

		&-logo {
			margin-right: 10px;
		}
	}
	.dropdown-menu {
		border: 1px solid rgba(0, 0, 0, 0.1);
		border-radius: 2px;
		box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
	}

	&-Actions {
		display: flex;
		align-items: center;
	}

	.OneflowTopBar-Account {
		padding: 0 0.5rem;
	}

	.dropdown-item,
	.OneflowTopBar-Account a {
		padding: 5px 15px;
		cursor: pointer;
		font-weight: 400;
		color: #333;

		&:hover {
			color: #58666e;
		}
	}

	.MenuControls-userDropdown .dropdown-toggle {
		color: $switcher-color;
		&:hover {
			color: lighten($switcher-color, 20%);
		}
	}
}
.OneflowMobileMenuPanel {
	.AccountSwitcher {
		&-current {
			float: right;
			margin: 10px 0;
			font-weight: bold;
		}
		&-input {
			margin-bottom: 10px;
		}
	}
	.MenuControls {
		padding: 10px;
		.MenuControls-userDropdown {
			border: 1px solid lighten($menu-controls-color, 30%);
			border-radius: 5px;
			&-languageMenuItem {
				& > .dropdown-menu {
					left: 160px;
				}
			}
		}
	}

	.MenuControls-userDropdown .dropdown-toggle {
		color: $menu-controls-color;
		&:hover {
			color: lighten($menu-controls-color, 20%);
		}
	}
}
.SearchButton {
	width: 24px;
	height: 24px;
	margin: 0;
	padding: 0;
	border: none;
	background: transparent url('../../public/images/search.svg') no-repeat 50%;
	background-size: cover;
	opacity: 0.8;
	transition: opacity 0.15s ease-in-out;

	&:hover,
	&:focus {
		opacity: 1;
		outline: none;
		background: transparent url('../../public/images/search.svg') no-repeat 50%;
	}
}
</style>
