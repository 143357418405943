<template>
	<a href="#" class="SearchCell" @click.prevent="cellClicked">
		<v-icon name="inbox" class="SearchCell__icon"></v-icon>
		<span class="SearchCell__content">
			<header class="SearchCell__header">
				<h3 class="SearchCell__heading">
					<span
						class="SearchCell__status"
						v-if="item.status"
						:class="{
							'SearchCell__status--success': item.status === 'shipped',
							'SearchCell__status--error': item.status === 'error'
						}"
						:title="`Status: ${item.status}`"
						v-text="item.status"
					></span>
					{{ $t('Order') }}
				</h3>
				<time class="SearchCell__date" :datetime="created | toDate">{{ created | fromNow }}</time>
			</header>
			<p class="SearchCell__title" v-html="customer"></p>
			<pill
				class="SearchCell__pill"
				:label="item._highlightResult.carrier.code.value"
				:value="item._highlightResult.carrier.service.value"
			></pill>
			<p
				class="SearchCell__subtitle"
				v-html="`${$t('Order ID:')} ${orderId}, ${$t('Items:')} ${item.items.length}`"
			></p>
		</span>
	</a>
</template>

<script>
import moment from 'moment';
import _ from 'lodash';
import { mapActions, mapGetters } from 'vuex';
import Pill from '../Pill';

export default {
	components: {
		Pill
	},
	props: {
		item: {
			required: true,
			type: Object
		}
	},
	computed: {
		...mapGetters({
			accounts: 'account/accounts'
		}),
		created() {
			return moment(this.item.created);
		},
		accountList() {
			const accounts = _.map(_.uniq(this.item._tags), tag => {
				// eslint-disable-next-line
				const account = _.find(this.accounts, { _id: tag });
				if (account) {
					return account.name;
				}
			}).join(', ');

			return accounts || null;
		},
		customer() {
			const addressLines = [];

			/* eslint-disable */
			if (this.item._highlightResult.shipTo.name) {
				addressLines.push(this.item._highlightResult.shipTo.name.value);
			} else if (this.item.shipTo.name) {
				addressLines.push(this.item.shipTo.name);
			}

			if (this.item._highlightResult.shipTo.companyName) {
				addressLines.push(this.item._highlightResult.shipTo.companyName.value);
			} else if (this.item.shipTo.companyName) {
				addressLines.push(this.item.shipTo.companyName);
			}

			if (this.item._highlightResult.shipTo.postcode) {
				addressLines.push(this.item._highlightResult.shipTo.postcode.value);
			} else if (this.item.shipTo.postcode) {
				addressLines.push(this.item.shipTo.postcode);
			}

			if (this.item._highlightResult.shipTo.town) {
				addressLines.push(this.item._highlightResult.shipTo.town.value);
			} else if (this.item.shipTo.town) {
				addressLines.push(this.item.shipTo.town);
			}
			/* eslint-enable */

			return `To: ${addressLines.join(', ')}`;
		},
		orderId() {
			/* eslint-disable */
			if (this.item._highlightResult.sourceOrderId) {
				return this.item._highlightResult.sourceOrderId.value;
			}
			/* eslint-enable */

			return this.item.sourceOrderId;
		}
	},
	filters: {
		fromNow: date => date.fromNow(),
		toDate: date => date.toString()
	},
	methods: {
		...mapActions({
			hideModal: 'search/hideSearchModal'
		}),
		cellClicked() {
			this.hideModal();
			this.$router.push({ name: 'orders.view', params: { id: this.item.orderId } });
		}
	},
	mounted() {}
};
</script>

<style></style>
