<template>
	<span class="Pill" :class="{ 'Pill--clickable': onClick, 'Pill--selected': selected }" @click.prevent="pillClicked">
		<span class="Pill__label" v-html="label"></span>
		<span class="Pill__value" v-html="value"></span>
	</span>
</template>

<script>
export default {
	props: {
		label: {
			required: true,
			type: [String, Number]
		},
		value: {
			required: true,
			type: [String, Number]
		},
		selected: {
			required: false,
			type: Boolean
		},
		onClick: {
			required: false,
			type: Function
		},
		identifier: {
			required: false,
			type: String
		}
	},
	methods: {
		pillClicked() {
			if (this.onClick) this.onClick(this.identifier);
		}
	}
};
</script>

<style lang="scss">
.Pill {
	$color: #f5f7f9;
	background: darken($color, 2);
	color: #333;
	display: inline-flex;
	border-radius: 0.3rem;
	overflow: hidden;
	font-size: 0.8rem;

	&--condensed {
		.Pill__label,
		.Pill__value {
			text-overflow: ellipsis;
			white-space: nowrap;
			max-width: 100px;
			overflow: hidden;
		}
	}

	&--clickable {
		user-select: none;

		&:hover {
			cursor: pointer;
			background: darken($color, 6);
			color: #111;

			.Pill__label {
				background: darken($color, 12);
			}
		}
	}

	&--selected {
		$selectedBackgroundColor: #008ec7;

		background: lighten($selectedBackgroundColor, 10);
		color: #fff;

		&:hover {
			background: lighten($selectedBackgroundColor, 7);
			color: #fff;

			.Pill__label {
				background: darken($selectedBackgroundColor, 5);
			}
		}

		.Pill__label {
			background: $selectedBackgroundColor;
		}
	}

	&__label,
	&__value {
		padding: 0.2rem 0.5rem;
		display: inline-block;
	}

	&__label {
		background: darken($color, 6);
	}
}
</style>
