<template><p>$t('Logout')</p></template>

<script>
import { mapActions } from 'vuex';
export default {
	created() {
		this.logout();
	},
	methods: {
		...mapActions({
			logout: 'user/logout'
		})
	}
};
</script>
