<template>
	<section class="panel">
		<div class="grid grid--3">
			<div class="spec">
				<h3 class="spec__heading">{{ $t('Source Account') }}</h3>
				<p class="spec__value" v-text="order.source.name"></p>
			</div>
			<div class="spec">
				<h3 class="spec__heading">{{ $t('Ordered On') }}</h3>
				<p class="spec__value">{{ order.createdAt | formatDate }}</p>
			</div>
			<div class="spec">
				<h3 class="spec__heading">{{ $t('Carrier') }}</h3>
				<p
					class="spec__value"
					v-if="shipment.carrier.alias"
					v-text="$t('Alias:') + ' ' + shipment.carrier.alias"
				></p>
				<p class="spec__value" v-else v-text="`${shipment.carrier.code} / ${shipment.carrier.service}`"></p>
			</div>
			<div class="spec">
				<h3 class="spec__heading" v-text="$t('Ship By') + ' (' + shipment.slaDays + $t(' day SLA)')"></h3>
				<p class="spec__value">{{ shipment.shipByDate | formatDate }}</p>
			</div>
			<div class="spec">
				<h3 class="spec__heading">{{ $t('Can Ship Early') }}</h3>
				<p class="spec__value" v-text="shipment.canShipEarly"></p>
			</div>
			<div class="spec">
				<h3 class="spec__heading">{{ $t('Shipment') }}</h3>
				<ul class="spec__value spec-list">
					<li
						class="spec-list__item"
						:class="{ 'spec-list__item--highlight': value.shipmentIndex === shipment.shipmentIndex }"
						v-for="(value, i) in shipments"
						:key="i"
					>
						{{ i + 1 }}
					</li>
				</ul>
			</div>
		</div>
		<hr class="seperator" />
		<div class="grid grid--2">
			<div class="spec">
				<h3 class="spec__heading">{{ $t('Ship To') }}</h3>
				<p class="spec__value" v-text="shipTo"></p>
			</div>
			<div class="spec" v-if="returnTo">
				<h3 class="spec__heading">{{ $t('Return To') }}</h3>
				<p class="spec__value" v-text="returnTo"></p>
			</div>
		</div>
	</section>
</template>

<script>
import moment from 'moment';

export default {
	props: ['item', 'file', 'order', 'component', 'shipment', 'shipments'],
	computed: {
		shipTo() {
			return this.generateShippingAddress(this.shipment.shipTo);
		},
		returnTo() {
			return this.generateShippingAddress(this.shipment.returnAddress);
		}
	},
	filters: {
		formatDate(date) {
			return moment(date).format('ddd, Do MMM, YYYY');
		}
	},
	methods: {
		generateShippingAddress(address) {
			if (!address) return '';
			return ['name', 'companyName', 'address1', 'address2', 'address3', 'postcode', 'town', 'state', 'isoCountry', 'phone']
				.reduce((acc, key) => (address[key] ? [...acc, address[key]] : acc), '')
				.join(', ');
		}
	}
};
</script>

<style></style>
