<template>
	<section class="OrderView-item" v-click-outside="handleClickOutside">
		<ofs-panel :isPadded="false" class="m-0">
			<header v-b-toggle="itemId" class="Item-header">
				<h3 class="m-0">{{ $t('Shipment') }} #{{ shipmentIndex }}</h3>
				<div>
					<b-button
						data-test-id="Orders_shipOrder"
						v-if="shipmentStatus === 'live'"
						size="sm"
						@click.stop="isShipmentConfimationModalVisible = true"
					>
						{{ $t('Ship') }}
					</b-button>
					<b-button
						data-test-id="Orders_downloadDoc"
						v-else-if="shippingDocFileId && shipmentStatus === 'shipped'"
						size="sm"
						class="text-uppercase"
						@click.stop="onDownloadDoc"
					>
						{{ $t('Download Doc') }}
					</b-button>
				</div>
			</header>
			<b-collapse :id="itemId" v-model="isExpanded" role="tabpanel" class="Item-panel">
				<b-row class="m-0">
					<b-col class="p-0" cols="12" lg="9">
						<Content
							v-for="(item, index) in items"
							:key="item._id"
							:item="item"
							:showAllErroredItems="showAllErroredItems"
							:highlightedComponent="highlightedComponent"
							:refresh="refresh"
							:isVisible="index === 0"
							:isLast="index + 1 === itemsCount"
							:shipmentStatus="shipmentStatus"
						/>
					</b-col>
					<b-col cols="12" lg="3" class="Item-shipmentDetails pr-0">
						<ShipmentDetails :details="details" @edit-tracking-number="onEditTrackingNumber" />
					</b-col>
				</b-row>
			</b-collapse>
		</ofs-panel>
		<TrackingNumberModal
			:trackingNumber="trackingNumber"
			:shipmentId="itemId"
			:showModal="isTrackingNumberModalVisible"
			:onClose="handleCloseTrackingNumberModal"
			:refresh="refresh"
			@error="onShipError"
			@shipped="isShipmentDocumentationModalVisible = !!shippingDocFileId"
		/>
		<ShipmentConfirmationModal
			:visible="isShipmentConfimationModalVisible"
			@ok="onOkConfirmationModal"
			@hide="onHideConfirmationModal"
		/>
		<ShipmentDocumentationModal
			:visible="isShipmentDocumentationModalVisible"
			:shippingDocFileId="shippingDocFileId"
			@hide="isShipmentDocumentationModalVisible = false"
		/>
		<ShipmentErrorModal
			:visible="isSshipmentErrorModalVisible"
			:error="shipError"
			@hide="isSshipmentErrorModalVisible = false; shipError = {}"
		/>
	</section>
</template>

<script>
import { mapActions } from 'vuex';
import _ from 'lodash';
import { OfsPanel } from '@workflow-solutions/ofs-vue-layout';
import Content from './Content';
import ShipmentDetails from '../ShipmentDetails';
import TrackingNumberModal from '../Ship/TrackingNumberModal';
import clickOutside from '../../../../directives/clickOutside';
import { downloadFileByUrls as download } from '../../../../lib/helpers';
import ShipmentConfirmationModal from './modals/ShipmentConfirmation';
import ShipmentDocumentationModal from './modals/ShipmentDocumentation';
import ShipmentErrorModal from './modals/ShipmentError';
import { $t } from '../../../../vuex';

export default {
	name: 'Item',
	components: {
		Content,
		ShipmentDetails,
		TrackingNumberModal,
		OfsPanel,
		ShipmentConfirmationModal,
		ShipmentDocumentationModal,
		ShipmentErrorModal
	},
	directives: {
		clickOutside
	},
	props: {
		showAllErroredItems: {
			type: Boolean,
			default: false
		},
		hasErroredOrderItem: {
			type: Boolean,
			default: false
		},
		shipmentIndex: {
			type: Number,
			default: 1
		},
		highlightedComponent: {
			type: [String, null]
		},
		itemId: {
			type: String,
			default: ''
		},
		details: {
			type: Object,
			default: () => ({})
		},
		shippingDoc: {
			type: Object,
			default: () => ({})
		},
		items: {
			type: Array,
			default: () => []
		},
		refresh: {
			type: Function,
			default: () => {}
		}
	},
	computed: {
		shippingDocFileId() {
			return this.shippingDoc?.fileId;
		},
		itemsCount() {
			return this.items.length;
		},
		shipmentStatus() {
			return _.get(this.details, 'status');
		},
		itemHighlighted() {
			return (
				!!_.find(this.items, { barcode: this.highlightedComponent }) ||
				!!_.find(this.items, item => _.find(item.components, { barcode: this.highlightedComponent }))
			);
		}
	},
	data() {
		return {
			shipError: {},
			isShipmentConfimationModalVisible: false,
			isShipmentDocumentationModalVisible: false,
			isSshipmentErrorModalVisible: false,
			isExpanded: false,
			trackingNumber: '',
			isTrackingNumberModalVisible: false
		};
	},
	mounted() {
		if (this.shipmentIndex === 1) {
			this.isExpanded = true;
		}
	},
	watch: {
		highlightedComponent() {
			if (this.highlightedComponent && this.itemHighlighted) {
				this.isExpanded = true;
			}
		},
		showAllErroredItems(newVal) {
			if (newVal) {
				this.isExpanded = true;
			}
		}
	},
	methods: {
		...mapActions({
			getDownload: 'file/getDownload'
		}),
		async onDownloadDoc() {
			try {
				const url = await this.getDownload({
					fileId: this.shippingDocFileId,
					params: { filename: `${this.shippingDocFileId}.pdf`, preview: false }
				});
				download(this.$el, [url]);
			} catch (err) {
				this.$notify({
					type: 'error',
					title: $t('Error'),
					text: $t('An error occurred while downloading a document')
				});
			}
		},
		onEditTrackingNumber(trackingNumber) {
			this.isTrackingNumberModalVisible = true;
			this.trackingNumber = trackingNumber;
		},
		handleCloseTrackingNumberModal() {
			this.isTrackingNumberModalVisible = false;
			this.trackingNumber = '';
		},
		handleClickOutside() {
			if (this.itemHighlighted) {
				this.$emit('highlightChange', false);
			}
		},
		onHideConfirmationModal() {
			this.isShipmentConfimationModalVisible = false;
		},
		async onOkConfirmationModal() {
			this.isShipmentConfimationModalVisible = false;
			await this.$nextTick();
			this.isTrackingNumberModalVisible = true;
		},
		async onShipError(err) {
			this.shipError = err;
			this.isTrackingNumberModalVisible = false;
			await this.$nextTick();
			this.isSshipmentErrorModalVisible = true;
		}
	}
};
</script>
<style lang="scss" scoped>
@import '../../../../styles/variables';

.Item {
	border-radius: 0;

	&-header {
		cursor: pointer;
		background: none;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		padding: 0.75rem 1rem;

		h3 {
			font-size: 1.2em;
			font-weight: bold;
		}

		button {
			min-width: 80px;
		}
	}

	&-shipment {
		&Details {
			border-left: 1px solid $cardBorderColor;
			background-color: $extraLiteGreyColour;

			@media screen and (max-width: 991px) {
				border-left: none;
				border-top: 1px solid $cardBorderColor;
			}
		}
	}

	&-panel {
		border-top: 1px solid $cardBorderColor;
	}
}
</style>
