<template>
	<b-modal
		v-model="show"
		ok-only
		centered
		:title="$t('API Token')"
		@show="onModalShow"
		modal-class="ApiTokenModal"
		:ok-title="$t('Close')"
		size="md"
	>
		<b-form>
			<of-form-input
				:label="$t('Token')"
				:labelCols="2"
				name="token"
				type="text"
				class="ApiTokenModal-field"
				disabled
			/>
			<of-form-input
				:label="$t('Secret')"
				:labelCols="2"
				name="secret"
				type="text"
				class="ApiTokenModal-field mb-0"
				disabled
			/>
		</b-form>
	</b-modal>
</template>

<script>
import { OfFormInput, withForm } from '@workflow-solutions/ofs-vue-layout';

export default {
	name: 'ApiTokenModal',
	mixins: [withForm('ApiTokenModalForm')],
	props: {
		showModal: {
			type: Boolean,
			default: false
		},
		token: {
			type: String,
			default: ''
		},
		secret: {
			type: String,
			default: ''
		},
		onClose: {
			type: Function,
			default: () => {}
		}
	},
	components: {
		OfFormInput
	},
	data() {
		return {};
	},
	computed: {
		show: {
			get() {
				return this.showModal;
			},
			set(value) {
				if (value === false) {
					this.onClose();
				}
			}
		}
	},
	methods: {
		onModalShow() {
			this.updateFormData({ token: this.token, secret: this.secret });
		}
	}
};
</script>

<style lang="scss">
.ApiTokenModal {
	&-field {
		input {
			font-size: 0.8rem;
		}
	}
}
</style>
