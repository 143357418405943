<template>
	<b-dropdown v-if="!isDisabled" class="mx-1 btn-secondary PairedProductAction" right size="sm" no-caret>
		<template slot="button-content">
			<font-awesome-icon icon="ellipsis-h" />
		</template>
		<b-dropdown-item
			v-for="(action, index) in pairedProductActionsList"
			:key="index"
			data-test-id="actionsDropdownItem"
			:variant="action === 'delete' ? 'danger' : 'normal'"
			@click="actionHandler(action, pairedProduct)"
		>
			<icon :name="getActionIconName(action)" class="PairedProductAction__action" />{{
				getPairedProductActionTextById(action)
			}}
		</b-dropdown-item>
	</b-dropdown>
</template>

<script>
import { mapActions } from 'vuex';
import { getPairedProductActionsList, getPairedProductActionTextById, getActionIconName } from './pairedProductHelpers';

/* eslint-disable vue/require-valid-default-prop */
export default {
	components: {},
	props: {
		actionHandler: {
			type: Function,
			default: () => {}
		},
		pairedProduct: {
			type: Object,
			default: () => ({}),
			required: true
		},
		accountId: {
			type: String,
			default: () => ({}),
			required: true
		}
	},
	computed: {
		isDisabled() {
			return (
				this.pairedProductActionsList.length === 0 ||
				(this.pairedProductActionsList.length === 1 && this.pairedProductActionsList[0] === 'hidden')
			);
		},
		pairedProductActionsList() {
			return getPairedProductActionsList(this.pairedProduct, this.accountId);
		}
	},
	methods: {
		...mapActions({}),
		getPairedProductActionsList,
		getPairedProductActionTextById,
		getActionIconName
	}
};
</script>
<style lang="scss">
.PairedProductAction {
	.dropdown-item {
		display: flex;
		align-items: center;
	}
	&__action {
		margin-right: 0.5rem;
	}
}
</style>
