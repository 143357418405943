/* eslint-disable new-cap */
import Vue from 'vue';
import actions from './actions';
import getters from './getters';
import mutations from './mutations';

export default function() {
	Vue.$ofsCrud.addResource({
		name: 'order',
		pluralName: 'orders',
		transport: 'connect',
		options: {
			namespaced: true
		},
		state: {
			status: null,
			totalPages: null,
			postbacks: null,
			shipments: null,
			extraData: { shippingMethods: [] }
		},
		mutations,
		actions,
		getters
	});
}
