// All methods except 'getOrders' for future use.

import _ from 'lodash';
import CryptoJS from 'crypto-js';

export default {
	facetSearch({ commit, dispatch, state }, { query, filter }) {
		const path = 'search/facet/shipments';
		const { facetAlias } = state;
		commit('SET_SEARCH_QUERY', { query });
		commit('SET_SEARCH_FILTER', { filter });
		const payload = {
			query: state.search.query,
			facetFilters: _.reduce(
				state.search.filter,
				(acc, facetValue = [], facetName) => {
					const filterKey = facetAlias[facetName] || facetName;
					acc.push(facetValue.map(value => `${filterKey}:${value}`));
					return acc;
				},
				[]
			)
		};
		dispatch('request', { method: 'POST', path, payload }).then(r => {
			commit('SET_SEARCH_RESULTS', { hits: r.data.hits });
			commit('SET_SEARCH_FACETS', { facets: r.data.facets });
		});
	},
	async getOrderDetails({ dispatch, commit }, id) {
		const path = `order/details/${id}`;
		const includes = ['batches', 'files', 'jobs', 'shipments'];
		const data = await dispatch('request', { method: 'GET', path, params: { includes } }).then(r => r.data);
		commit('GET_ITEM_SUCCESS', { data });
		return data;
	},
	async getOrdersBySourceOrderId({ dispatch, commit }, id) {
		const path = `order/bysourceid/${id}`;
		return await dispatch('request', { method: 'GET', path }).then(r => r.data);
	},
	async getShipmentsByOrderId({ dispatch, commit }, id) {
		const path = `shipment/byorder/${id}`;
		const data = await dispatch('request', { method: 'GET', path }).then(r => r.data);
		commit('SET_ORDER_SHIPMENTS', data);
		return data;
	},
	clearOrderShipments({ commit }) {
		commit('SET_ORDER_SHIPMENTS', []);
	},
	cancelOrder({ dispatch }, id) {
		const path = `order/${id}/cancel`;
		return dispatch('request', { method: 'PUT', path }).then(r => r.data);
	},
	createPartialShipment({ dispatch }, { id, data }) {
		const path = `shipment/partial/${id}`;
		return dispatch('request', { method: 'POST', path, payload: data }).then(r => r.data);
	},
	getThumbnailsFS({ dispatch }, fileId) {
		const path = `file/${fileId}/thumbnails`;
		return dispatch('request', { method: 'GET', path }).then(r => r.data);
	},
	async getOrderPostbacks({ dispatch, commit }, id) {
		const postbacks = await dispatch('request', {
			method: 'GET',
			path: 'notification',
			params: {
				where: {
					'source.ref': 'order',
					'source.refId': id
				}
			}
		}).then(r => r.data);
		commit('SET_ORDER_POSTBACKS', postbacks);
	},
	getPostback({ dispatch }, id) {
		const path = `notification/${id}`;
		return dispatch('request', { method: 'GET', path }).then(r => r.data);
	},
	downloadFile({ dispatch }, fileId) {
		const path = `file/download/${fileId}/?_dc=${Math.random()}`;
		return dispatch('request', { method: 'GET', path }).then(r => r.data);
	},
	async getOrders({ commit, dispatch }, { query, mutate = true }) {
		const results = await dispatch('request', {
			method: 'GET',
			path: 'order',
			query
		}).then(r => r.data);

		if (mutate) {
			commit('SET_ORDERS_COUNT', results.count);
			commit('SET_TOTAL_PAGES', results.pages);
			commit('SET_ORDERS', results.data);
		}

		return results;
	},
	getExtraData({ commit, dispatch }) {
		const path = 'order/extra';
		return dispatch('request', { method: 'GET', path }).then(r => {
			commit('SET_EXTRA_DATA', r.data);
		});
	},
	getShipment({ dispatch }, id) {
		const path = `shipment/${id}`;
		return dispatch('request', { method: 'GET', path }).then(r => r.data);
	},
	saveShipment({ dispatch }, { id, payload }) {
		const path = `shipment/${id}/shipping`;
		return dispatch('request', { method: 'PUT', path, payload }).then(r => r.data);
	},
	setShipped({ dispatch }, { id, payload }) {
		const path = `shipment/shipped/${id}`;
		return dispatch('request', { method: 'PUT', path, payload }).then(r => r.data);
	},
	getJobsByShipment({ dispatch }, id) {
		const path = `job/byshipment/${id}`;
		return dispatch('request', { method: 'GET', path }).then(r => r.data);
	},
	getJobsByTag({ dispatch }, tag) {
		const path = `job?tag=/${encodeURIComponent(tag)}`;
		return dispatch('request', { method: 'GET', path }).then(r => r.data);
	},
	async getSubmissionErrors({ dispatch }, options) {
		const path = 'ordersubmissionerror';

		const params = {
			'filter[offset]': options.offset,
			'filter[limit]': options.limit,
			'filter[order]': '_id DESC'
		};
		if (options.asDestination) {
			params['filter[asDestination]'] = true;
			const accountId = _.get(options, 'where.accountId');
			if (accountId) {
				params['filter[where][accountId]'] = accountId;
			}
		}

		return dispatch('request', { method: 'GET', path, query: params });
	},
	async getSubmissionErrorsCount({ dispatch }, options) {
		const path = 'ordersubmissionerror/count';
		const params = {};

		if (options.asDestination) {
			params.asDestination = true;
			const accountId = _.get(options, 'where.accountId');
			if (accountId) {
				params['where[accountId]'] = accountId;
			}
		}

		return dispatch('request', { method: 'GET', path, query: params });
	},
	getSubmissionError({ dispatch }, id) {
		const path = `ordersubmissionerror/${id}`;
		return dispatch('request', { method: 'GET', path });
	},
	updateSubmissionError({ dispatch }, { id, data }) {
		const path = `ordersubmissionerror/${id}`;
		return dispatch('request', { method: 'PUT', path, payload: data, preventLogout: true });
	},
	async submitOrder({ dispatch }, { data, credentials }) {
		const url = `${window.$config.orderSubmissionApiBase}/order`;
		const timestamp = new Date().toISOString();
		const urlParts = url.split('/');
		urlParts.splice(0, 3, ''); // remove domain
		const path = urlParts.join('/');
		const signingStr = `POST ${path} ${timestamp}`;
		// eslint-disable-next-line
		const signature = CryptoJS.HmacSHA1(signingStr, credentials.secret);
		const token = `${credentials.token}:${signature}`;

		const headers = {
			'Content-Type': 'application/json',
			'x-oneflow-date': timestamp,
			'x-oneflow-authorization': token
		};

		return dispatch('request', {
			method: 'POST',
			path: url,
			payload: data,
			headers,
			replaceHeaders: true
		}).then(r => r.data);
	},
	refetchFile({ dispatch }, fileId) {
		const path = `file/${fileId}/refetch`;
		return dispatch('request', { method: 'PUT', path }).then(r => r.data);
	},
	addFacet({ commit }, { key, value }) {
		commit('ADD_SELECTED_FACET', { key, value });
	},
	removeFacet({ commit }, key) {
		commit('REMOVE_SELECTED_FACET', key);
	}
};
