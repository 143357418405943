import Vue from 'vue';

export default function() {
	Vue.$ofsCrud.addResource({
		name: 'template',
		options: { namespaced: true },
		state: {},
		actions: {
			getInfotech: async ({ dispatch }, jobId) => {
				const path = `template/infotech/${jobId}`;
				return dispatch('request', { method: 'GET', path }).then(r => r.data);
			},
			merge({ dispatch }, { id, data }) {
				const path = `template/${id}/merge`;
				return dispatch('request', {
					method: 'POST',
					path,
					payload: data
				}).then(r => r.data);
			}
		},
		mutations: {},
		getters: {}
	});
}
