export default {
	getAccounts: async ({ dispatch, commit, getters }) => {
		const path = `users/${getters.user._id}/memberships`;
		const {
			data: { data: memberships }
		} = await dispatch('request', {
			path,
			method: 'GET',
			params: {
				query: {
					$populate: 'account'
				}
			}
		});
		const accounts = memberships.map(m => m.account);

		commit('SET_AVAILABLE_ACCOUNTS', accounts);

		return accounts;
	},

	async getUserTokens({ dispatch, commit }) {
		const userTokenData = await dispatch('request', { method: 'GET', path: 'user/me', transport: 'connect' }).then(
			r => r.data
		);
		commit('SET_USER_TOKEN_DATA', userTokenData);
		return userTokenData;
	},

	generateNewToken({ dispatch }) {
		return dispatch('request', { method: 'POST', path: 'user/token', transport: 'connect' }).then(r => r.data);
	},

	updateUserToken({ dispatch, state }, data) {
		const { _id } = state.userTokenData;
		return dispatch('request', { method: 'PUT', path: `user/${_id}`, payload: data, transport: 'connect' }).then(
			r => r.data
		);
	}
};
