<template>
	<li
		class="work-item-components__item work-item-component"
		:class="{ 'work-item-component--loading': !component || !order }"
	>
		<loader class="work-item-component__loader" />
		<div class="work-item-component__inner" v-if="component">
			<p
				class="work-item-component__name"
				data-test-id="Production_workItemComponent"
				v-text="component.code"
				:class="{ 'mb-0': !component.attributes }"
			></p>
			<ul class="tag-list" v-if="component.attributes">
				<li class="tag-list__item" v-for="(value, key) in component.attributes" :key="key">
					<span class="tag-list__key" v-text="`${key}:`"></span>
					<span class="tag-list__value" v-text="value" :title="value"></span>
				</li>
			</ul>
			<div class="work-item__actions">
				<b-button
					variant="primary"
					size="sm"
					@click.prevent="download"
					:disabled="component.localFile"
					class="work-item__action"
					v-text="component.localFile ? $t('Local File') : $t('Download File')"
				></b-button>
				<b-button variant="secondary" size="sm" @click.prevent="viewJobSheet" class="work-item__action">
					{{ $t('Print Job Sheet') }}
				</b-button>
			</div>
		</div>
	</li>
</template>

<script>
import { mapActions } from 'vuex';
import _ from 'lodash';
import _fp from 'lodash/fp';
import Loader from '../../components/Loader';
import { downloadFileByUrls as download } from '../../lib/helpers';

export default {
	components: {
		Loader
	},
	props: {
		sourceItemId: {
			type: String,
			defaul: ''
		},
		files: {
			type: Array,
			defaul: () => []
		},
		component: {
			type: Object,
			required: true
		},
		order: {
			type: [Object, null]
		}
	},
	computed: {
		orderComponent() {
			return _fp.flow(
				_fp.get('orderData.items'),
				_fp.map('components'),
				_fp.flatten,
				_fp.find({ fileId: this.component.fileId })
			)(this.order);
		},
		fileId() {
			return _.get(this.component, 'fileId');
		},
		file() {
			return _.find(this.files, { _id: this.fileId });
		},
		filePath() {
			return _.get(this.file, 'path');
		},
		fileName() {
			const code = _.get(this.component, 'code');
			const fileExtension = _.last(_.split(this.filePath, '.'));
			return `${this.sourceItemId}_${code}.${fileExtension}`;
		}
	},
	methods: {
		...mapActions({
			getDownload: 'file/getDownload',
			getJobSheet: 'template/getInfotech'
		}),
		async download() {
			try {
				const url = await this.getDownload({
					fileId: this.fileId,
					params: { filename: this.fileName, preview: false }
				});
				download(this.$el, [url]);
			} catch (err) {
				this.$notify({
					type: 'error',
					title: this.$t('Error'),
					text: this.$t('An error occurred while downloading file')
				});
			}
		},
		viewJobSheet() {
			const routeData = this.$router.resolve({
				name: 'job-sheet',
				query: { orderIds: [this.order._id], componentIds: [this.component._id] }
			});
			window.open(routeData.href, '_blank');
		}
	}
};
</script>

<style></style>
