import Vue from 'vue';

export default function() {
	Vue.$ofsCrud.addResource({
		name: 'barcode',
		options: { namespaced: true },
		actions: {
			getById: async ({ dispatch }, id) =>
				dispatch('request', { method: 'GET', path: `barcode/details/${id}` }).then(r => r.data),
			generate: ({ dispatch }, { text, type, wscale, hscale, rotate }) => {
				const params = {
					type,
					wscale,
					hscale,
					rotate
				};

				return dispatch('request', {
					method: 'GET',
					responseType: 'blob',
					path: `barcode/generate/${text}`,
					params
				}).then(r => r.data);
			}
		}
	});
}
