import store from '../vuex';
import { MenuService } from '@workflow-solutions/siteflow-section-layout';

const $t = str => str;

const menuStructure = [
	{
		title: $t('Orders'),
		matches: [{ name: 'orders.view' }, { name: 'submission.view' }],
		items: [
			{
				items: [
					{
						title: $t('All Orders'),
						name: 'orders.list',
						matches: [{ name: 'orders.view' }]
					},
					{
						title: $t('Submission errors'),
						name: 'submission-errors',
						matches: [{ name: 'submission.view' }]
					}
				]
			}
		]
	},
	{
		title: $t('Production'),
		items: [
			{
				items: [
					{ title: $t('Live Jobs'), name: 'work' },
					{ title: $t('Completed Jobs'), name: 'work.completed' }
				]
			}
		]
	},
	{
		title: $t('Setup'),
		matches: [
			{ name: 'user.details' },
			{ name: 'template.edit' },
			{ name: 'trigger.edit' },
			{ name: 'linkcode.list' },
			{ name: 'accountlink.list' },
			{ name: 'pairedproduct.list' }
		],
		items: [
			{
				items: [
					{ title: $t('Account Settings'), name: 'account.settings' },
					{ title: $t('Manage Users'), name: 'user.list' },
					{
						title: $t('Linked Accounts'),
						name: 'accountlink.list',
						permissions: ['accountlink-read', 'accountlink-write'],
						roles: ['admin', 'owner'],
						matches: [{ name: 'linkcode.list' }],
						featureFlag: 'siteflow-setup-accountlinks'
					},
					{
						title: $t('Paired Products'),
						name: 'pairedproduct.list',
						permissions: ['pairedproduct-read', 'pairedproduct-write'],
						roles: ['admin', 'owner'],
						matches: [{ name: 'pairedproduct.list' }]
					}
				]
			},
			{
				title: $t('Triggers'),
				items: [
					{ title: $t('Create Trigger'), name: 'trigger.add' },
					{ title: $t('View Triggers'), name: 'trigger.list' }
				]
			},
			{
				title: $t('Templates'),
				items: [
					{ title: $t('Create Template'), name: 'template.add' },
					{ title: $t('View Templates'), name: 'template.list' }
				]
			}
		]
	},
	{
		title: $t('Help'),
		matches: [{ name: 'help.orderSubmission' }],
		items: [
			{
				title: $t('Submitting Orders'),
				items: [
					{ title: $t('Single item order'), name: 'help.orderSubmission' },
					{ title: $t('Shipment Shipped'), name: 'help.shipmentShipped' }
				]
			},
			{
				title: $t('Documentation'),
				items: [
					{ title: $t('Tutorial'), name: 'help.docs.tutorial' },
					{ title: $t('Site Guide'), name: 'help.docs.descriptions' },
					{ title: $t('API Reference'), name: 'help.docs.api' }
				]
			}
		]
	}
];

MenuService.setMenuItemsVisibility(menuStructure);

export default menuStructure;
