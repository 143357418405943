export default {
	destination: {
		name: ''
	},
	orderData: {
		sourceOrderId: 'order',
		customerName: '',
		items: [
			{
				sku: 'Flyer',
				quantity: 1,
				sourceItemId: 'getting-started-item',
				components: [
					{
						code: 'Content',
						path: 'https://files-static.hpsiteflow.com/samples/Flyer.pdf',
						fetch: true,
						attributes: {
							finish: 'Gloss'
						}
					}
				]
			}
		],
		shipments: [
			{
				shipTo: {
					name: 'Oneflow Systems',
					address1: '1 Primrose Street',
					town: 'London',
					isoCountry: 'GB',
					country: 'United Kingdom',
					postcode: 'EC2A 2EX'
				},
				carrier: {
					alias: 'express'
				},
				slaDays: 3
			}
		]
	}
};
