<template>
	<div
		class="work-item"
		:class="{ 'work-item--selected': itemSelected, 'work-item--expanded': itemExpanded }"
		:data-test-id="`Production_OrderId_${orderId}`"
	>
		<div
			class="work-item__toggle"
			@click.prevent.stop="toggleSelected"
			v-if="!completed"
			data-test-id="orderToggle"
		></div>
		<div class="work-item__inner">
			<div class="work-item__bar work-item__bar--clickable" @click.prevent="toggleExpanded">
				<div class="work-item__content work-item-content">
					<item-thumbnail class="work-item-content__image" :item="item" />
					<div class="work-item-content__body">
						<div class="work-item-content__details">
							<p class="work-item-content__title" v-text="itemTitle" data-test-id="batchTitle"></p>
							<p class="work-item-content__text" data-test-id="quantity">
								{{ $t('Qty:') + item.quantity }}
							</p>
						</div>
						<div class="work-item-content__actions" v-if="itemExpanded">
							<router-link
								:to="{
									name: 'orders.view',
									params: { id: shipment && shipment.orderId },
									query: { barcode: item.barcode }
								}"
							>
								<b-button size="sm">{{ $t('Go to shipment') }}</b-button>
							</router-link>
						</div>
					</div>
				</div>
				<div
					class="work-item__status work-item-status"
					:class="`work-item-status--${status}`"
					v-if="!completed"
					data-test-id="status"
				>
					<span class="work-item-status__label">{{ $t('Out on') }}</span>
					<span class="work-item-status__value">{{ item.shipByDate | day }}</span>
					<date class="work-item-status__label" data-test-id="shipByDate" :date="item.shipByDate"></date>
				</div>
				<div
					class="work-item__status work-item-status"
					:class="`work-item-status--${status}`"
					v-else
					data-test-id="status"
				>
					<span class="work-item-status__label">{{ $t('Completed') }}</span>
					<span class="work-item-status__value">{{ item.completed | day }}</span>
					<date class="work-item-status__label" data-test-id="completedDate" :date="item.completed"></date>
				</div>
			</div>
			<div class="work-item__extended-content" v-if="itemExpanded">
				<ul class="work-item-components">
					<work-component
						:component="component"
						:sourceItemId="sourceItemId"
						:files="orderFiles"
						v-for="component in item.components"
						:order="order"
						:key="component._id"
					/>
				</ul>
			</div>
		</div>
	</div>
</template>

<script>
import _ from 'lodash';
import moment from 'moment';
import { mapActions } from 'vuex';
import WorkComponent from './WorkComponent';
import ItemThumbnail from './ItemThumbnail';
import Date from '../../components/Date';

export default {
	components: {
		WorkComponent,
		ItemThumbnail,
		Date
	},
	props: {
		item: {
			type: Object,
			required: true
		},
		selectedItems: {
			type: Array,
			default: () => []
		}
	},
	data() {
		return {
			itemExpanded: false,
			shipment: null,
			orderFiles: [],
			order: null
		};
	},
	filters: {
		day(date) {
			return moment(date).format('ddd');
		}
	},
	computed: {
		completed() {
			return this.item.status === 'complete';
		},
		orderId() {
			return _.get(this.item, 'orderId');
		},
		itemSelected() {
			return this.selectedItems.includes(this.item);
		},
		sourceItemId() {
			return _.get(this.item, 'sourceItemId');
		},
		itemTitle() {
			return `${this.sourceItemId} (${this.item.sku})`;
		},
		componentNames() {
			return _.map(this.item.components, 'code').join(', ');
		},
		status() {
			const shipByDate = moment(this.item.shipByDate);

			if (!this.completed) {
				if (shipByDate.isBefore(moment())) {
					return 'error';
				} else if (moment().isBetween(shipByDate, moment(shipByDate).subtract(2, 'hours'))) {
					return 'warning';
				}
			} else {
				const completed = moment(this.item.completed);

				if (completed.isAfter(shipByDate)) {
					return 'error';
				} else if (completed.isBetween(shipByDate, moment(shipByDate).subtract(2, 'hours'))) {
					return 'warning';
				}
			}

			return 'success';
		}
	},
	methods: {
		...mapActions({
			getShipmentById: 'item/getShipmentById',
			getFilesByOrderId: 'file/getFilesByOrderId',
			getOrderById: 'order/findById'
		}),
		async toggleExpanded() {
			this.itemExpanded = !this.itemExpanded;
			this.shipment = this.shipment || (await this.getShipmentById(this.item.shipmentId));
			this.order = this.order || (await this.getOrderById({ id: this.item.orderId, skipMutations: true }));
			const { data: files } = await this.getFilesByOrderId(this.item.orderId);
			this.orderFiles = files;
		},
		toggleSelected() {
			this.$emit('on-selected', this.item, !this.itemSelected);
		}
	}
};
</script>

<style></style>
