<template>
	<b-modal :visible="visible" modal-class="InfoModal" :hide-footer="true" @hide="handleClose">
		<section slot="modal-title" class="header">
			<span class="fa-stack info-modal-icon">
				<font-awesome-icon icon="square" class="fa-stack-2x icon-shape" />
				<font-awesome-icon icon="info" class="fa-stack-1x fa-inverse icon-text" />
			</span>
			<span class="modal-title">{{ $t('Linked Accounts') }}</span>
		</section>
		<div class="p-2">
			<p class="mb-4">
				<!-- eslint-disable-next-line max-len, prettier/prettier -->
				{{ $t('Linked accounts are a connection between brand and PSP accounts working on Site Flow. Once linked, these accounts can send and receive orders and view order information from each other.') }}
			</p>
			<h3 v-t="'Create New Linked Account'" class="mb-2"></h3>
			<ul class="mb-4">
				<li>{{ $t('Click "New invitation"') }}</li>
				<!-- eslint-disable-next-line max-len, prettier/prettier -->
				<li>{{ $t('Take note of the pairing code and share it with the account holder of which you want to invite')}}
				</li>
				<li>{{ $t('They will click "Verify invitation" and then enter the code') }}</li>
				<li>{{ $t('The code will be verified and your accounts will be linked') }}</li>
			</ul>
			<h3 v-t="'Verify Invitation'" class="mb-2"></h3>
			<ul class="mb-4">
				<li>{{ $t('Click "Verify invitation"') }}</li>
				<li>{{ $t('Enter the invitation code you received from the account holder') }}</li>
				<li>{{ $t('The code will be verified and your accounts will be linked') }}</li>
			</ul>
		</div>
	</b-modal>
</template>

<script>
export default {
	props: {
		visible: {
			type: Boolean,
			default: false
		}
	},
	methods: {
		handleClose() {
			this.$emit('hide');
		}
	}
};
</script>

<style lang="scss">
@import '../../../styles/shared';

.header {
	display: flex;
	align-items: center;
}

.info-modal-icon {
	margin: 3px 15px auto 10px;
}

.icon-shape {
	color: #e6f7ff;
}

.icon-text {
	color: $of-color-aqua;
}
</style>
