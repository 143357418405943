import _get from 'lodash/get';

export const account = state => state.account;
export const accountSettings = state => _get(state, 'vars.oneflowAccountSettings');
export const accounts = state => state.accounts;
export const vars = state => state.vars;
export const pspAccounts = state => state.pspAccounts;
export const currentAccount = state => state.currentAccount;
export const memberships = state => state.memberships;
export const membershipsCount = state => state.membershipsCount;
export const member = state => state.member;

/**
 * The list of settings Connect is allow to change in an account
 * @return {string[]}
 */
export const accountSettingsWhitelist = () => [
	'companyName',
	'emailAddress',
	'phoneNumber',
	'faxNumber',
	'address1',
	'address2',
	'address3',
	'state',
	'town',
	'postcode',
	'isoCountry',
	'weightUnit',
	'dimensionUnit',
	'currency',
	'dateFormat',
	'timezone',
	'defaultSlaDays'
];
